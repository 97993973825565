import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import CustomTable from "components/Table/Table";
import { ROWS_PER_PAGE } from "../../constants";
import { downloadProfilesACTI } from "../../actions/profiles";

const columns = [
  { id: "nombre", label: "Nombre", minWidth: 75, width: "100%" }
];

const profilesTitleToolbar = {
  color: "textSecondary",
  content: "Perfiles"
};

function Profiles(props) {
  const { setProfileIds, selected, downloadProfiles } = props;

  const [rows, setRows] = useState(null);
  const [profiles, setProfiles] = useState({});

  const onChangePage = (newPage, rowsPerPage) => {
    const params = {
      isPaginated: true,
      pageNo: newPage,
      pageSize: rowsPerPage,
      setProfiles
    };
    downloadProfiles(params);
  };

  function createData(id, nombre) {
    return { id, nombre };
  }

  const generateRows = useCallback(profiles => {
    if (!isEmpty(profiles)) {
      const newRows = Array.from(profiles, profile =>
        createData(profile.id, profile.nombre)
      );
      setRows(newRows);
    } else {
      setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
    }
  }, []);

  useEffect(() => {
    const params = {
      isPaginated: true,
      pageNo: 0,
      pageSize: ROWS_PER_PAGE,
      setProfiles
    };

    if (null === rows && null !== selected) {
      downloadProfiles(params);
    }
  }, [rows, selected, downloadProfiles]);

  useEffect(() => {
    if (undefined !== profiles.content) {
      generateRows(profiles.content);
    }
  }, [generateRows, profiles]);

  return (
    <CustomTable
      checkColor="primary"
      columns={columns}
      rows={rows}
      totalElements={isEmpty(profiles.content) ? 0 : profiles.totalElements}
      action="checkbox"
      titleToolbar={profilesTitleToolbar}
      onChangePage={onChangePage}
      getSelected={setProfileIds}
      defaultSelection={null !== selected ? selected : []}
    />
  );
}

Profiles.propTypes = {
  setProfileIds: PropTypes.func.isRequired,
  selected: PropTypes.array,
  downloadProfiles: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    downloadProfiles: params => {
      dispatch(downloadProfilesACTI(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Profiles);
