import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  AppBar,
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Copyright from "components/Copyright/Copyright";
import InputFormik from "components/CustomInput/InputFormik";
import AlertError from "components/Snackbar/AlertError";
import {
  authenticationACTI,
  disableRememberUserACTI,
  setOpenAlertErrorACTI
} from "../../actions";
import { getFirstName } from "helpers/functions";
import { getKeyLoginVor, strDecrypt } from "../../api/access";

const urlImage = process.env.REACT_APP_VORTAL_REPO_IMAGES;

const useStyles = makeStyles(theme => ({
  root: {
    "&::before": {
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      content: "''",
      display: "block",
      zIndex: 2,
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.65)"
    },
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${urlImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  header: {
    display: "flex",
    backgroundColor: "transparent",
    zIndex: 1029,
    border: 0,
    padding: "10px 0",
    boxShadow: "none",
    minHeight: "50px",
    borderBottom: 0,
    marginBottom: 0
  },
  toolbar: {
    minHeight: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "15px",
    paddingRight: "15px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      padding: 0,
      margin: 0,
      marginRight: "5px"
    },
    [theme.breakpoints.up("md")]: {
      width: "750px",
      margin: "auto"
    },
    [theme.breakpoints.up("lg")]: {
      width: "970px"
    },
    [theme.breakpoints.up("xl")]: {
      width: "1170px"
    }
  },
  logo: {
    flex: 1
  },
  logoLink: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "30px",
    borderRadius: "3px",
    textTransform: "none",
    border: "none",
    margin: "0.3125rem 1px",
    padding: "12px 30px",
    textAlign: "center",
    verticalAlign: "middle",
    "&:hover,&:focus": {
      color: "#fff",
      backgroundColor: "transparent"
    }
  },
  customList: {
    padding: 0
  },
  listItemButton: {
    margin: "0 5px",
    padding: "10px 15px",
    fontSize: "12px",
    fontWeight: 500,
    borderRadius: "3px"
  },
  customIcon: {
    color: "#fff",
    minWidth: "28px"
  },
  customText: {
    textTransform: "uppercase",
    "& > span": {
      fontSize: "12px",
      fontWeight: 400
    }
  },
  main: {
    margin: "auto",
    justifyContent: "center",
    zIndex: 3
  },
  footer: {
    zIndex: 4,
    width: "100%",
    padding: "15px 0",
    fontWeight: 300,
    lineHeight: "1.5em"
  },
  cntFooter: {
    zIndex: 3,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "15px",
    paddingRight: "15px",
    [theme.breakpoints.up("md")]: {
      width: "750px",
      margin: "auto"
    },
    [theme.breakpoints.up("lg")]: {
      width: "970px"
    },
    [theme.breakpoints.up("xl")]: {
      width: "1170px"
    },
    "& > span": {
      color: "#fff",
      margin: 0,
      padding: "15px",
      textAlign: "right"
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 4,
    marginRight: 18,
    marginBottom: 0,
    marginLeft: 18
  },
  large: {
    width: theme.spacing(11),
    height: theme.spacing(11)
  },
  name: {
    textTransform: "inherit",
    color: "#3C4858",
    fontSize: "1.3em",
    fontWeight: 300,
    margin: "3px auto",
    marginTop: "16px"
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function LockScreen(props) {
  const classes = useStyles();
  const {
    openAlertError,
    messageAlert,
    handlerAlertError,
    rememberUser,
    disableRememberUser,
    authentication
  } = props;
  const splitName = rememberUser.name && rememberUser.name.trim().split(" ");
  const firstName = getFirstName(splitName);
  const renderLogin = () => {
    localStorage.removeItem("rememberUserVor");
    localStorage.removeItem("keyLoginVor");
    disableRememberUser();
  };
  const handleAuthentication = (
    values,
    setSubmitting,
    setFieldValue,
    resetForm
  ) => {
    const username = strDecrypt(rememberUser.user, getKeyLoginVor());

    if (username) {
      values.username = username;
    }

    authentication(values, setSubmitting, setFieldValue, resetForm);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <Button
              href="http://www.unipamplona.edu.co"
              className={classes.logoLink}
              target="_blank"
            >
              Unipamplona
            </Button>
          </div>
          <List
            component="ul"
            aria-label="opciones"
            className={classes.customList}
          >
            <ListItem
              button
              className={classes.listItemButton}
              onClick={renderLogin}
            >
              <ListItemIcon className={classes.customIcon}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Salir" className={classes.customText} />
            </ListItem>
          </List>
        </Toolbar>
      </AppBar>
      <Grid container component="main" className={classes.main}>
        <Grid
          item
          xs={12}
          lg={3}
          component={Paper}
          elevation={3}
          style={{ maxWidth: "327px" }}
        >
          <div className={classes.paper}>
            <Avatar
              alt={firstName}
              src={
                !isEmpty(rememberUser.userImage)
                  ? rememberUser.userImage.src
                  : "/static/images/avatar/1.jpg"
              }
              className={classes.large}
            />
            <Typography variant="h4" gutterBottom className={classes.name}>
              {firstName}
            </Typography>
            <Formik
              initialValues={{ password: "" }}
              validationSchema={Yup.object({
                password: Yup.string()
                  .min(2, "Requerido")
                  .required("Requerido")
              })}
              onSubmit={(
                values,
                { setSubmitting, setFieldValue, resetForm }
              ) => {
                handleAuthentication(
                  values,
                  setSubmitting,
                  setFieldValue,
                  resetForm
                );
              }}
            >
              {({ isSubmitting }) => (
                <Form className={classes.form}>
                  <InputFormik
                    label="Contraseña"
                    id="password"
                    name="password"
                    type="password"
                    formControlProps={{
                      margin: "dense",
                      fullWidth: true
                    }}
                    formControlRoot
                    autoComplete="current-password"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    Desbloquear
                  </Button>
                  <AlertError
                    open={openAlertError}
                    setOpen={handlerAlertError}
                    text={messageAlert}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
      <footer className={classes.footer}>
        <div className={classes.cntFooter}>
          <Copyright />
        </div>
      </footer>
    </div>
  );
}

LockScreen.propTypes = {
  openAlertError: PropTypes.bool.isRequired,
  messageAlert: PropTypes.string,
  handlerAlertError: PropTypes.func.isRequired,
  rememberUser: PropTypes.object.isRequired,
  disableRememberUser: PropTypes.func.isRequired,
  authentication: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    openAlertError: state.alertErrorREDU,
    messageAlert: state.messageAlertREDU,
    rememberUser: state.rememberUserREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handlerAlertError: isOpen => {
      dispatch(setOpenAlertErrorACTI(isOpen));
    },
    disableRememberUser: () => {
      dispatch(disableRememberUserACTI());
    },
    authentication: (values, setSubmitting, setFieldValue, resetForm) => {
      dispatch(
        authenticationACTI(values, setSubmitting, setFieldValue, resetForm, 1)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockScreen);
