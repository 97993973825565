import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import CustomTable from "components/Table/Table";
import { downloadUnitsACTI } from "../../actions/users";
import { ROWS_PER_PAGE } from "../../constants";

const titleToolbar = {
  color: "textSecondary",
  content: "Unidades regionales"
};

const columns = [
  { id: "nombre", label: "Nombre", minWidth: 75, width: "100%" }
];

function RegionalUnits(props) {
  const { setSelectedUnit, downloadUnits } = props;

  const [rows, setRows] = useState(null);
  const [units, setUnits] = useState({});
  const [firstRender, setFirstRender] = useState(true);

  const onChangePage = (newPage, rowsPerPage) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage,
      setUnits
    };
    setRows(null);
    downloadUnits(params);
  };

  const handleSelect = useCallback(
    value => {
      if (!isEmpty(units.content) && !isEmpty(value)) {
        setSelectedUnit(oldUnit => {
          if (oldUnit && oldUnit.id === value[0]) {
            return oldUnit;
          } else {
            const unit = units.content.find(unit => unit.id === value[0]);
            return unit ? unit : null;
          }
        });
      } else if (!isEmpty(value)) {
        setSelectedUnit(null);
      }
    },
    [units, setSelectedUnit]
  );

  function createData(id, nombre) {
    return { id, nombre };
  }

  const generateRows = useCallback(units => {
    if (!isEmpty(units)) {
      const newRows = Array.from(units, unit =>
        createData(unit.id, unit.nombre)
      );
      setRows(newRows);
    } else {
      setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
    }
  }, []);

  useEffect(() => {
    if (undefined !== units.content) {
      generateRows(units.content);
    }
  }, [units, generateRows]);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE,
      setUnits
    };

    if (null === rows && firstRender) {
      downloadUnits(params);
      setFirstRender(false);
    }
  }, [rows, firstRender, downloadUnits]);

  return (
    <CustomTable
      checkColor="primary"
      columns={columns}
      rows={rows}
      totalElements={isEmpty(units.content) ? 0 : units.totalElements}
      action="radio"
      titleToolbar={titleToolbar}
      onChangePage={onChangePage}
      getSelected={handleSelect}
    />
  );
}

RegionalUnits.propTypes = {
  setSelectedUnit: PropTypes.func.isRequired,
  downloadUnits: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    downloadUnits: params => {
      dispatch(downloadUnitsACTI(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RegionalUnits);
