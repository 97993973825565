import * as Yup from "yup";

export const passwordValidate = Yup.string()
  .required("Requerido")
  .min(8, "Mínimo 8 caracteres")
  .matches(/[A-Z]{1}/, "Por lo menos una mayúscula")
  .matches(/[a-z]{1}/, "Por lo menos una minúscula")
  .matches(/[0-9]{1}/, "Por lo menos un número");

export const passwordValidateOptional = Yup.string()
  .min(8, "Mínimo 8 caracteres")
  .matches(/[A-Z]{1}/, "Por lo menos una mayúscula")
  .matches(/[a-z]{1}/, "Por lo menos una minúscula")
  .matches(/[0-9]{1}/, "Por lo menos un número");

export const schemaCredentialsLogin = Yup.object({
  username: Yup.string()
    .trim()
    .required("Requerido")
    .min(2, "Requerido"),
  password: Yup.string()
    .required("Requerido")
    .min(2, "Requerido")
});

export const schemaPasswordReset = {
  newPassword: passwordValidate,
  repeatPassword: Yup.string()
    .required("Requerido")
    .oneOf([Yup.ref("newPassword"), null], "Las contraseñas deben coincidir")
};
