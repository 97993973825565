import React, { useEffect, useRef } from "react";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { LANG_ES } from "constants/videojs";

const useStyles = makeStyles(theme => ({
  customVideo: {
    "&:hover": {
      cursor: "pointer"
    },
    "& .vjs-big-play-button": {
      width: "70px",
      height: "70px",
      background: "none",
      lineHeight: "70px",
      fontSize: "80px",
      border: "none",
      top: "50%",
      left: "50%",
      marginTop: "-35px",
      marginLeft: "-35px",
      color: theme.palette.primary.main
    },
    "& .vjs-control-bar": {
      backgroundColor: "rgba(0, 0, 0, 0.7)"
    },
    "&:hover .vjs-big-play-button": {
      backgroundColor: "transparent",
      color: "#fff"
    },
    "& .vjs-volume-panel": {
      order: 2
    },
    "& .vjs-volume-vertical": {
      backgroundColor: "rgba(0, 0, 0, 0.7)"
    },
    "& .vjs-slider": {
      backgroundColor: "rgba(255, 255, 255, 0.2)"
    },
    "& .vjs-load-progress": {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    "& .vjs-play-progress": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    "& .vjs-fullscreen-control": {
      order: 2
    }
  }
}));

function SignInMarketing() {
  const classes = useStyles();
  // const { videoSrc } = props;
  const playerRef = useRef(null);

  useEffect(() => {
    if (null !== playerRef.current) {
      const options = {
        autoplay: false,
        controls: true,
        fluid: true,
        language: "es",
        controlBar: {
          pictureInPictureToggle: false,
          volumePanel: {
            inline: false
          }
        },
        sources: [
          {
            src: process.env.REACT_APP_VORTAL_REPO_VIDEO_LOGIN,
            type: "video/mp4"
          }
        ]
      };
      videojs.addLanguage("es", LANG_ES);
      // Instantiate Video.js
      const player = videojs(playerRef.current, options, () => {
        // callback here...
      });

      return () => {
        // Destroy player on unmount
        player.dispose();
      };
    }
  }, []);

  return (
    <div data-vjs-player>
      <video
        ref={playerRef}
        className={`video-js vjs-16-9 vjs-big-play-centered ${classes.customVideo}`}
      ></video>
    </div>
  );
}

// SignInMarketing.propTypes = {
//   open: PropTypes.bool.isRequired,
//   setOpen: PropTypes.func.isRequired
// };

export default SignInMarketing;
