import {
  takeEvery,
  takeLatest,
  call,
  put,
  delay,
  select
} from "redux-saga/effects";
import { isEmpty } from "lodash";
import CryptoJS from "crypto-js";
import { ACTIONS } from "../constants";
import {
  sagaConfirmResetPass,
  sagaForgotPassword,
  sagaResetPassword
} from "./forgotPassword";
import {
  sagaDeleteProfileImage,
  sagaUpdatePassword,
  sagaUpdateSomeData,
  sagaUploadProfileImage
} from "./userProfile";
import {
  sagaDownloadWidgets,
  sagaUpdateLayouts,
  sagaInsertWidget,
  sagaUpdateWidget,
  sagaDeleteWidget,
  viewNotificationError
} from "./dashboard";
import {
  downloadAnnouncements,
  sagaDownloadNotifications,
  sagaLoadAnnouncements,
  sagaLoadHeaderNotifications
} from "./notification";
import {
  sagaAssociateUserToSecurityRoles,
  sagaDownloadApplications,
  sagaDownloadFunctionalitiesByAppAndParent,
  sagaDownloadRoleApplications,
  sagaDownloadRootFunctionalitiesByApp,
  sagaDownloadSecurityRolesAdminFunc,
  sagaDownloadUsersWithRoles,
  sagaUpdateApplication
} from "./administrator";
import {
  sagaAssociateFuncionalities,
  sagaDeleteRole,
  sagaDownloadProgramUnits,
  sagaDownloadRoles,
  sagaDownloadUnits,
  sagaDownloadUserProfiles,
  sagaDownloadUserRoles,
  sagaFindExistingUser,
  sagaInsertRole,
  sagaProcessAutomaticUser,
  sagaSaveUser,
  sagaUpdateRole,
  sagaUpdateUserStatus
} from "./users";
import {
  sagaListCategoriesCat,
  sagaInsertCategoria,
  sagaDeleteCategoria,
  sagaListAplicacionesCat,
  sagaUpdateAplicacionCat,
  sagaUpdateCategoria,
  sagaDownloadParentCategories
} from "./categorias";
import { sagaDownloadGlobalProfiles, sagaDownloadProfiles } from "./profiles";
import {
  sagaDeleteAnnouncement,
  sagaDownloadAnnouncementsAdmin,
  sagaFindAnnouncementUserByDocument,
  sagaFindAnnouncementUsersByName,
  sagaInsertAnnouncement,
  sagaUpdateAnnouncement
} from "./announcements";
import {
  access,
  accessAppOauth,
  accessAppValidate,
  logout,
  onAuthStateChangedObj,
  strEncrypt,
  userLogout
} from "../api/access";
import {
  addAppsStoreACTI,
  addImageUserProfileACTI,
  addFunctionalityUsersACTI,
  addUserStoreACTI,
  setMessageAlertACTI,
  setMessageNotificationACTI,
  setOpenAlertErrorACTI,
  setOpenNotificationErrorACTI,
  addFunctionalityPeopleACTI
} from "../actions";
import { addAnnouncementsStoreACTI } from "../actions/notification";
import {
  b64EncodeUnicode,
  getObjectForPaginatedTable,
  history,
  openNewWindow
} from "../helpers/functions";
import { apiGET, apiGETById, apiGETParam } from "../api";
import {
  urlCategorias,
  urlImagenes,
  urlPersonas,
  urlUsuarios,
  urlUsuariosManager
} from "../api/urls";
import { generateHOTP } from "../helpers/cripto-api-2fa";

const downloadCategories = id =>
  apiGETById(`${urlCategorias}/listar-aplicaciones-usuario`, id).then(
    response => response
  );
const downloadImage = (id, tipo) =>
  apiGET(`${urlImagenes}/buscar-por-usuario-tipo/${id}/${tipo}`).then(
    response => response
  );
const findUserByDocument = userDocument =>
  apiGET(`${urlUsuarios}/buscar-documento/${userDocument}`).then(user => user);
const findUsers = (pageNo, pageSize) =>
  apiGET(`${urlUsuarios}/pagina/${pageNo + 1}/${pageSize}`).then(
    users => users
  );
const findUsersByParams = (pageNo, pageSize, params) =>
  apiGETParam(`${urlUsuarios}/pagina/${pageNo + 1}/${pageSize}`, params).then(
    users => users
  );
const findPersonByDocument = userDocument =>
  apiGET(`${urlPersonas}/buscar-documento/${userDocument}`).then(user => user);
const findPeople = (pageNo, pageSize) =>
  apiGET(`${urlPersonas}/pagina/${pageNo + 1}/${pageSize}`).then(
    users => users
  );
const findPeopleByParams = (pageNo, pageSize, params) =>
  apiGETParam(`${urlPersonas}/pagina/${pageNo + 1}/${pageSize}`, params).then(
    users => users
  );
const getCodeApp = (id, applicationId, roleId) =>
  apiGET(
    `${urlUsuariosManager}/generar-codigo/${id}/aplicacion/${applicationId}/rol/${roleId}`
  ).then(code => code);

function* sagaAuthentication(action) {
  try {
    const rememberUser = yield select(state => state.rememberUserREDU);
    let data = yield call(
      access,
      action.values.username,
      action.values.password,
      rememberUser.state || action.fromLocked
    );
    let user = {
      loggedIn: true,
      access_token: data.access_token,
      refresh_token: data.refresh_token
    };
    action.resetForm({});
    yield put(addUserStoreACTI(user));
    history.push("/e-campus/login/redirect");
  } catch (error) {
    yield put(setMessageAlertACTI(error));
    yield put(setOpenAlertErrorACTI(true));
    action.setFieldValue("password", "", false);
    action.setSubmitting(false);
  }
}

function* loadAnnouncements(user) {
  const params = {
    users: user.id.toString()
  };

  if (!isEmpty(user.perfiles)) {
    const profiles = Array.from(user.perfiles, perfil => perfil.id);
    params.profiles = profiles.toString();
  }

  const announcements = yield call(downloadAnnouncements, params);
  yield put(addAnnouncementsStoreACTI(announcements));
}

function* sagaLoadOperation() {
  try {
    let user = yield select(state => state.userREDU);
    let authState = yield call(onAuthStateChangedObj, "1"); // 1: bandera para cargar funcionalidades de usuario
    // console.log(authState.data);
    if (authState.data) {
      user = { ...user };
      user.id = authState.data.id;
      user.pegeId = authState.data.pegeId;
      user.documento = authState.data.documento;
      user.nombre = authState.data.nombre;
      user.correo = authState.data.correo;
      user.descripcion = authState.data.descripcion;
      user.perfiles = authState.data.perfiles;
      user.funcionalidades = authState.data.funcionalidades;
      yield put(addUserStoreACTI(user));
      const rememberUser = yield select(state => state.rememberUserREDU);

      if (rememberUser.state) {
        const userCredential = strEncrypt(authState.data.usuario);

        if (userCredential) {
          rememberUser.loggedIn = true;
          rememberUser.name = user.nombre;
          rememberUser.user = userCredential.encryptValue;
          localStorage.setItem("rememberUserVor", JSON.stringify(rememberUser));
          localStorage.setItem("keyLoginVor", userCredential.keyEncryption);
        }
      }

      yield call(loadAnnouncements, user);
      const profileImage = yield call(downloadImage, user.id, "PRO");

      if (
        !isEmpty(profileImage) &&
        profileImage.base64 &&
        profileImage.mimeType
      ) {
        const userImage = {
          id: profileImage.id,
          src: `data:${profileImage.mimeType};base64,${profileImage.base64}`
        };
        yield put(addImageUserProfileACTI(userImage));

        if (rememberUser.state && rememberUser.loggedIn) {
          rememberUser.userImage = userImage;
          localStorage.setItem("rememberUserVor", JSON.stringify(rememberUser));
        }
      } else {
        yield put(addImageUserProfileACTI({}));
      }

      const categories = yield call(downloadCategories, authState.data.id);
      yield put(addAppsStoreACTI(categories));
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    yield call(viewNotificationError, error);
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

function* sagaLogout() {
  yield call(logout);
  yield put(addUserStoreACTI(userLogout(1)));
  history.push("/e-campus/login/redirect");
}

// Deprecated
function* aplgenApplication(action, user, contextApp) {
  const fullUrl = action.application.url;
  const extraParams = action.extraParams;
  const role = extraParams
    ? action.application.roles[extraParams.roleIndex]
    : action.application.roles[0];
  const time = Date.now();
  const userCode = user.id;
  const pegeCode = user.pegeId;
  const key = process.env.REACT_APP_VORTAL_PRIVATE_KEY + userCode;
  const code = yield call(
    generateHOTP,
    key + action.application.id,
    Math.floor(time / 30000)
  );
  const data = {
    code,
    username: "generic_app",
    password: "123456789",
    userCode
  };
  const response = yield call(
    accessAppValidate,
    `${contextApp}/login/controller`,
    data,
    key
  );

  if (200 === response.status) {
    const userCodeEncrypted = CryptoJS.AES.encrypt(userCode.toString(), key);
    const codeEncrypted = CryptoJS.AES.encrypt(code, key);
    const pegeIdEncrypted = CryptoJS.AES.encrypt(pegeCode.toString(), key);
    const token = b64EncodeUnicode(
      userCodeEncrypted.toString() +
        ":" +
        codeEncrypted.toString() +
        ":" +
        pegeIdEncrypted.toString()
    );

    if (extraParams) {
      extraParams.setSubmitting(false);
      extraParams.setOpen(false);
      yield delay(600);
    }

    openNewWindow(
      `${contextApp}/login?rol=${role.id}&token=${token}&url=${fullUrl}&codigoUsuario=${user.id}`,
      "_blank"
    );
  } else {
    throw String(
      "No se ha podido validar el acceso a la aplicación, intenta más tarde."
    );
  }
}

function* applicationOauth(action, user, url) {
  const extraParams = action.extraParams;
  const role = extraParams
    ? action.application.roles[extraParams.roleIndex]
    : action.application.roles[0];
  const codeApp = yield call(
    getCodeApp,
    user.id,
    action.application.id,
    role.id
  );
  const data = {
    role,
    code: codeApp.accessCode
  };
  const result = yield call(accessAppOauth, data);

  if (200 === result.status) {
    openNewWindow(
      `${url}/${result.data.access_token}/${result.data.refresh_token}/${result.keyEncryption}`,
      "_blank"
    );
  } else {
    throw String(
      "No se ha podido validar el acceso a la aplicación, intenta más tarde."
    );
  }
}

function* sagaValidateApp(action) {
  try {
    const user = yield select(state => state.userREDU);
    const fullUrl = action.application.url;
    const urlParts = fullUrl.split("/");
    let contextApp;

    if (
      urlParts.length > 3 &&
      (("http:" === urlParts[0] || "https:" === urlParts[0]) &&
        -1 !== fullUrl.indexOf(".jsp"))
    ) {
      contextApp = fullUrl.substring(0, fullUrl.indexOf(".jsp"));
      contextApp = contextApp.substring(0, contextApp.lastIndexOf("/"));
      yield call(aplgenApplication, action, user, contextApp);
    } else if (
      "https:" === urlParts[0] ||
      ("production" !== process.env.REACT_APP_ENV &&
        "http://localhost" === fullUrl.substring(0, 16))
    ) {
      yield call(applicationOauth, action, user, fullUrl);
    } else {
      throw String("La aplicación no está configurada correctamente.");
    }
  } catch (error) {
    if (action.extraParams) {
      action.extraParams.setSubmitting(false);
    }

    yield call(viewNotificationError, error);
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

function* handleStateUser(users, params, dispatchAction) {
  if (!isEmpty(users)) {
    if (undefined === params.setUsers) {
      yield put(dispatchAction(getObjectForPaginatedTable([users])));
    } else {
      if (params.isPaginated) {
        params.setUsers(getObjectForPaginatedTable([users]));
      } else {
        params.setUsers(users);
      }
    }
  } else {
    if (undefined === params.setUsers) {
      yield put(dispatchAction({ content: [] }));
    } else {
      if (params.isPaginated) {
        params.setUsers({ content: [] });
      } else {
        params.setUsers({});
      }
    }
  }
}

function* handleStateUsers(users, params, dispatchAction) {
  if (!isEmpty(users)) {
    if (undefined === params.setUsers) {
      yield put(dispatchAction(users));
    } else {
      params.setUsers(users);
    }
  } else {
    if (undefined === params.setUsers) {
      yield put(dispatchAction({ content: [] }));
    } else {
      if (params.isPaginated) {
        params.setUsers({ content: [] });
      } else {
        params.setUsers({});
      }
    }
  }
}

function* handleStateUserError(error, params, dispatchAction) {
  yield put(setMessageNotificationACTI(error));

  if (undefined === params.setUsers) {
    yield put(dispatchAction({ content: [] }));
  } else {
    if (params.isPaginated) {
      params.setUsers({ content: [] });
    } else {
      params.setUsers({});
    }
  }

  yield put(setOpenNotificationErrorACTI(true));
  yield delay(6000);
  yield put(setOpenNotificationErrorACTI(false));
}

function* sagaFindUserByDocument(action) {
  try {
    action.params.setRows(null);
    const user = yield call(findUserByDocument, action.params.userDocument);
    yield call(handleStateUser, user, action.params, addFunctionalityUsersACTI);
  } catch (error) {
    yield call(
      handleStateUserError,
      error,
      action.params,
      addFunctionalityUsersACTI
    );
  }
}

function* sagaFindUsersByParams(action) {
  try {
    const params = {};
    let users;
    action.params.setRows(null);

    if (
      undefined !== action.params.userName &&
      null !== action.params.userName
    ) {
      params.nombre = action.params.userName;
    }

    if (isEmpty(params)) {
      users = yield call(
        findUsers,
        action.params.pageNo,
        action.params.pageSize
      );
    } else {
      users = yield call(
        findUsersByParams,
        action.params.pageNo,
        action.params.pageSize,
        params
      );
    }

    yield call(
      handleStateUsers,
      users,
      action.params,
      addFunctionalityUsersACTI
    );
  } catch (error) {
    yield call(
      handleStateUserError,
      error,
      action.params,
      addFunctionalityUsersACTI
    );
  }
}

function* sagaFindPersonByDocument(action) {
  try {
    action.params.setRows(null);
    const person = yield call(findPersonByDocument, action.params.userDocument);
    yield call(
      handleStateUser,
      person,
      action.params,
      addFunctionalityPeopleACTI
    );
  } catch (error) {
    yield call(
      handleStateUserError,
      error,
      action.params,
      addFunctionalityPeopleACTI
    );
  }
}

function* sagaFindPeopleByParams(action) {
  try {
    const params = {};
    let people;
    action.params.setRows(null);

    if (
      undefined !== action.params.userName &&
      null !== action.params.userName
    ) {
      params.nombre = action.params.userName;
    }

    if (isEmpty(params)) {
      people = yield call(
        findPeople,
        action.params.pageNo,
        action.params.pageSize
      );
    } else {
      people = yield call(
        findPeopleByParams,
        action.params.pageNo,
        action.params.pageSize,
        params
      );
    }

    yield call(
      handleStateUsers,
      people,
      action.params,
      addFunctionalityPeopleACTI
    );
  } catch (error) {
    yield call(
      handleStateUserError,
      error,
      action.params,
      addFunctionalityPeopleACTI
    );
  }
}

export default function* sagas() {
  yield takeLatest(ACTIONS.AUTHENTICATION, sagaAuthentication);
  yield takeEvery(ACTIONS.LOAD_OPERATION, sagaLoadOperation);
  yield takeLatest(ACTIONS.LOGOUT, sagaLogout);
  yield takeLatest(ACTIONS.VALIDATE_APPLICATION, sagaValidateApp);
  yield takeLatest(ACTIONS.FORGOT_PASSWORD, sagaForgotPassword);
  yield takeLatest(ACTIONS.CONFIRM_RESET_PASSWORD, sagaConfirmResetPass);
  yield takeLatest(ACTIONS.RESET_PASSWORD, sagaResetPassword);
  yield takeEvery(ACTIONS.UPDATE_SOME_DATA_USER, sagaUpdateSomeData);
  yield takeLatest(ACTIONS.UPDATE_PASSWORD, sagaUpdatePassword);
  yield takeEvery(ACTIONS.UPLOAD_PROFILE_IMAGE, sagaUploadProfileImage);
  yield takeLatest(ACTIONS.DELETE_PROFILE_IMAGE, sagaDeleteProfileImage);
  yield takeLatest(ACTIONS.DOWNLOAD_NOTIFICATIONS, sagaDownloadNotifications);
  yield takeLatest(
    ACTIONS.LOAD_HEADER_NOTIFICATIONS,
    sagaLoadHeaderNotifications
  );
  yield takeLatest(ACTIONS.LOAD_ANNOUNCEMENTS, sagaLoadAnnouncements);
  yield takeLatest(ACTIONS.DOWNLOAD_WIDGETS, sagaDownloadWidgets);
  yield takeEvery(ACTIONS.UPDATE_LAYOUTS, sagaUpdateLayouts);
  yield takeEvery(ACTIONS.INSERT_WIDGET, sagaInsertWidget);
  yield takeEvery(ACTIONS.UPDATE_WIDGET, sagaUpdateWidget);
  yield takeEvery(ACTIONS.DELETE_WIDGET, sagaDeleteWidget);

  yield takeEvery(ACTIONS.LIST_CATEGORIES_CAT, sagaListCategoriesCat);
  yield takeEvery(
    ACTIONS.DOWNLOAD_PARENT_CATEGORIES,
    sagaDownloadParentCategories
  );
  yield takeEvery(ACTIONS.NEW_CATEGORY, sagaInsertCategoria);
  yield takeEvery(ACTIONS.UPDATE_CATEGORY, sagaUpdateCategoria);
  yield takeEvery(ACTIONS.DELETE_LIST_CATEGORIES, sagaDeleteCategoria);
  yield takeEvery(ACTIONS.LIST_APPLICATION_PAGINATE, sagaListAplicacionesCat);
  yield takeEvery(ACTIONS.ASOCIATE_APPLICATION_CATE, sagaUpdateAplicacionCat);
  yield takeLatest(
    ACTIONS.DOWNLOAD_GLOBAL_PROFILES,
    sagaDownloadGlobalProfiles
  );
  yield takeLatest(ACTIONS.DOWNLOAD_PROFILES, sagaDownloadProfiles);
  yield takeLatest(
    ACTIONS.DOWNLOAD_ANNOUNCEMENTS_ADMIN,
    sagaDownloadAnnouncementsAdmin
  );
  yield takeLatest(
    ACTIONS.FIND_ANNOUNCEMENT_USER_BY_DOCUMENT,
    sagaFindAnnouncementUserByDocument
  );
  yield takeLatest(
    ACTIONS.FIND_ANNOUNCEMENT_USERS_BY_NAME,
    sagaFindAnnouncementUsersByName
  );
  yield takeEvery(ACTIONS.INSERT_ANNOUNCEMENT, sagaInsertAnnouncement);
  yield takeEvery(ACTIONS.UPDATE_ANNOUNCEMENT, sagaUpdateAnnouncement);
  yield takeEvery(ACTIONS.DELETE_ANNOUNCEMENT, sagaDeleteAnnouncement);
  yield takeLatest(ACTIONS.FIND_USER_BY_DOCUMENT, sagaFindUserByDocument);
  yield takeLatest(ACTIONS.FIND_USERS_BY_PARAMS, sagaFindUsersByParams);
  yield takeLatest(ACTIONS.DOWNLOAD_ROLES, sagaDownloadRoles);
  yield takeEvery(ACTIONS.INSERT_ROLE, sagaInsertRole);
  yield takeEvery(ACTIONS.UPDATE_ROLE, sagaUpdateRole);
  yield takeEvery(ACTIONS.DELETE_ROLE, sagaDeleteRole);
  yield takeLatest(
    ACTIONS.DOWNLOAD_ROLE_APPLICATIONS,
    sagaDownloadRoleApplications
  );
  yield takeLatest(
    ACTIONS.DOWNLOAD_ROOT_FUNCTIONALITIES_BY_APP,
    sagaDownloadRootFunctionalitiesByApp
  );
  yield takeLatest(
    ACTIONS.DOWNLOAD_FUNCTIONALITIES_BY_APP_AND_PARENT,
    sagaDownloadFunctionalitiesByAppAndParent
  );
  yield takeEvery(
    ACTIONS.ASSOCIATE_FUNCTIONALITIES_TO_ROLE,
    sagaAssociateFuncionalities
  );
  yield takeLatest(ACTIONS.DOWNLOAD_USER_PROFILES, sagaDownloadUserProfiles);
  yield takeLatest(ACTIONS.DOWNLOAD_USER_ROLES, sagaDownloadUserRoles);
  yield takeLatest(ACTIONS.FIND_PERSON_BY_DOCUMENT, sagaFindPersonByDocument);
  yield takeLatest(ACTIONS.FIND_PEOPLE_BY_PARAMS, sagaFindPeopleByParams);
  yield takeLatest(ACTIONS.FIND_EXISTING_USER, sagaFindExistingUser);
  yield takeEvery(ACTIONS.UPDATE_USER_STATUS, sagaUpdateUserStatus);
  yield takeEvery(ACTIONS.SAVE_USER, sagaSaveUser);
  yield takeLatest(ACTIONS.DOWNLOAD_UNITS, sagaDownloadUnits);
  yield takeLatest(ACTIONS.DOWNLOAD_PROGRAM_UNITS, sagaDownloadProgramUnits);
  yield takeEvery(ACTIONS.PROCESS_AUTOMATIC_USER, sagaProcessAutomaticUser);
  yield takeLatest(ACTIONS.DOWNLOAD_APPLICATIONS, sagaDownloadApplications);
  yield takeEvery(ACTIONS.UPDATE_APPLICATION, sagaUpdateApplication);
  yield takeLatest(
    ACTIONS.DOWNLOAD_USERS_ADMIN_FUNC,
    sagaDownloadUsersWithRoles
  );
  yield takeLatest(
    ACTIONS.DOWNLOAD_SECURITY_ROLES_ADMIN_FUNC,
    sagaDownloadSecurityRolesAdminFunc
  );
  yield takeEvery(
    ACTIONS.ASSOCIATE_USER_TO_SECURITY_ROLES,
    sagaAssociateUserToSecurityRoles
  );
}
