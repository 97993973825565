import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  FormHelperText
} from "@material-ui/core";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import "../../assets/css/main.css";
import { useField } from "formik";

const useStyles = makeStyles(checkboxAdnRadioStyle);

const CheckboxFormik = ({ label, checked, handleClick, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField({ ...props, type: "checkbox" });

  const handleOnClick = e => {
    if (handleClick) {
      handleClick(e.target.value);
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={
              <Check
                className={
                  meta.touched && meta.error
                    ? classes.errorUncheckedIcon
                    : classes.uncheckedIcon
                }
              />
            }
            color="default"
            classes={{
              checked: classes.checked,
              root: classes.root
            }}
            checked={checked}
            onClick={handleOnClick}
            {...props}
            {...field}
          />
        }
        label={label}
        className={
          meta.touched && meta.error
            ? "espacio-check color-error"
            : "espacio-check"
        }
      />
      {meta.touched && meta.error ? (
        <FormHelperText className="color-error">{meta.error}</FormHelperText>
      ) : null}
    </>
  );
};

CheckboxFormik.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  handleClick: PropTypes.func
};

export default CheckboxFormik;
