import React from "react";
import PropTypes from "prop-types";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import ColumnChart from "./ColumnChart";
import PieChart from "./PieChart";

function SwitchCharts(props) {
  const { type, componentProps } = props;
  const {
    data,
    handleOpenColor,
    handleOpenColorSingle,
    fromDash,
    extraData
  } = componentProps;

  const renderChart = () => {
    switch (type) {
      case "LineChart":
        return (
          <LineChart
            data={data}
            handleOpenColor={handleOpenColor}
            fromDash={fromDash}
            extraData={extraData}
          />
        );
      case "BarChart":
        return (
          <BarChart
            data={data}
            handleOpenColor={handleOpenColor}
            handleOpenColorSingle={handleOpenColorSingle}
            fromDash={fromDash}
            extraData={extraData}
          />
        );
      case "ColumnChart":
        return (
          <ColumnChart
            data={data}
            handleOpenColor={handleOpenColor}
            handleOpenColorSingle={handleOpenColorSingle}
            fromDash={fromDash}
            extraData={extraData}
          />
        );
      case "PieChart":
        return (
          <PieChart
            data={data}
            handleOpenColor={handleOpenColor}
            fromDash={fromDash}
            extraData={extraData}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderChart()}</>;
}

SwitchCharts.propTypes = {
  type: PropTypes.string.isRequired,
  componentProps: PropTypes.object.isRequired
};

export default SwitchCharts;
