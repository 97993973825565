import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Button, Card } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ROOT_PATH } from "../../constants";
import { confirmResetPasswordACTI } from "actions/forgotPassword";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeaderAlt from "components/Card/CardHeaderAlt";
import CardBody from "components/Card/CardBody";
import InputFormik from "components/CustomInput/InputFormik";
import { useButtonStyles } from "assets/jss/material-dashboard-react/buttonStyle";
import AlertError from "components/Snackbar/AlertError";
import { setOpenAlertErrorACTI, setOpenAlertSuccessACTI } from "../../actions";
import NoDataDisplay from "views/NotFound/NoDataDisplay";
import Loading from "components/Progress/Loading";
import AlertSuccess from "components/Snackbar/AlertSuccess";
import { resetPasswordACTI } from "actions/forgotPassword";
import { schemaPasswordReset } from "yupValidations";

function ResetPassword(props) {
  const {
    confirmReset,
    openAlertError,
    messageAlert,
    handlerAlertError,
    openAlertSuccess,
    handlerAlertSuccess,
    resetPassword
  } = props;
  const history = useHistory();

  const classes = useButtonStyles();

  const initialValues = {
    newPassword: "",
    repeatPassword: ""
  };

  const [enabled, setEnabled] = useState(null);
  const [validToken, setValidToken] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (!isEmpty(history.location.search)) {
      const query = new URLSearchParams(history.location.search);
      const token = query.get("token");

      if (!isEmpty(token)) {
        setValidToken(token);
        const extraParams = {
          setEnabled,
          setUserId
        };
        confirmReset(token, extraParams);
      } else {
        history.push(`${ROOT_PATH}/login`);
      }
    } else {
      history.push(`${ROOT_PATH}/login`);
    }
  }, [history, confirmReset]);

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const user = {
      id: userId,
      password: values.newPassword,
      token: validToken
    };
    const extraParams = {
      resetForm,
      setSubmitting
    };
    resetPassword(user, extraParams);
  };

  return (
    <GridContainer
      justify="center"
      alignContent="center"
      style={{ height: "100vh" }}
      m0
    >
      <GridItem xs={12} md={4}>
        {null !== enabled ? (
          enabled ? (
            <Card>
              <CardHeaderAlt title="Restablecer contraseña" />
              <CardBody>
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.object(schemaPasswordReset)}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <InputFormik
                        label="Contraseña"
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        style={{ marginBottom: "8px" }}
                        formControlRoot
                      />
                      <InputFormik
                        label="Confirmar contraseña"
                        id="repeatPassword"
                        name="repeatPassword"
                        type="password"
                        formControlProps={{
                          margin: "normal",
                          fullWidth: true
                        }}
                        formControlRoot
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.root}
                        disabled={isSubmitting || isEmpty(validToken)}
                        fullWidth
                        style={{ marginTop: 16 }}
                      >
                        Enviar
                      </Button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          ) : (
            <NoDataDisplay styles={{ margin: "-5px" }} />
          )
        ) : (
          <Loading open />
        )}
      </GridItem>
      <AlertError
        open={openAlertError}
        setOpen={handlerAlertError}
        text={messageAlert}
      />
      <AlertSuccess
        open={openAlertSuccess}
        setOpen={handlerAlertSuccess}
        text={messageAlert}
      />
    </GridContainer>
  );
}

ResetPassword.propTypes = {
  confirmReset: PropTypes.func.isRequired,
  openAlertError: PropTypes.bool.isRequired,
  messageAlert: PropTypes.string,
  handlerAlertError: PropTypes.func.isRequired,
  openAlertSuccess: PropTypes.bool.isRequired,
  handlerAlertSuccess: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    openAlertError: state.alertErrorREDU,
    messageAlert: state.messageAlertREDU,
    openAlertSuccess: state.alertSuccessREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    confirmReset: (token, extraParams) => {
      dispatch(confirmResetPasswordACTI(token, extraParams));
    },
    handlerAlertError: isOpen => {
      dispatch(setOpenAlertErrorACTI(isOpen));
    },
    handlerAlertSuccess: isOpen => {
      dispatch(setOpenAlertSuccessACTI(isOpen));
    },
    resetPassword: (user, extraParams) => {
      dispatch(resetPasswordACTI(user, extraParams));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
