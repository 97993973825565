import { ACTIONS } from "../constants";

// para el sagas
export const listCategoriesACTI = params => ({
  type: ACTIONS.LIST_CATEGORIES_CAT,
  params
});

//reducer
export const addCategoriesStoreCatACTI = categories => ({
  type: ACTIONS.ADD_CATEGORIESLIST_STORE,
  categories
});

export const downloadParentCategoriesACTI = setParentCategories => ({
  type: ACTIONS.DOWNLOAD_PARENT_CATEGORIES,
  setParentCategories
});

export const addParentCategoriesStoreACTI = categories => ({
  type: ACTIONS.ADD_PARENT_CATEGORIES_STORE,
  categories
});

//SAGA
export const newCategoriesACTI = (categoria, extraParams) => ({
  type: ACTIONS.NEW_CATEGORY,
  categoria,
  extraParams
});

//saga
export const deleteCategoriesACTI = (categoria, extraParams) => ({
  type: ACTIONS.DELETE_LIST_CATEGORIES,
  categoria,
  extraParams
});

//saga
export const updateCategoriesACTI = (categoria, extraParams) => ({
  type: ACTIONS.UPDATE_CATEGORY,
  categoria,
  extraParams
});

export const listApplicationPaginateACTI = params => ({
  type: ACTIONS.LIST_APPLICATION_PAGINATE,
  params
});

export const listApplicationPaginateStoreACTI = applications => ({
  type: ACTIONS.LIST_APPLICATION_PAGINATE_STORE,
  applications
});

export const asociateApplicationCatACTI = (application, extraParams) => ({
  type: ACTIONS.ASOCIATE_APPLICATION_CATE,
  application,
  extraParams
});
