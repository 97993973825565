import { Slider, withStyles } from "@material-ui/core";
import { COLOR_APP_HEX } from "../../constants";

const PrettoSlider = withStyles({
  root: {
    color: COLOR_APP_HEX,
    height: 8
  },
  thumb: {
    height: "24px !important",
    width: "24px !important",
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: "-8px !important",
    marginLeft: "-12px !important",
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

export default PrettoSlider;
