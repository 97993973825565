import React from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";

function ColorSketch(props) {
  const { color, handleChange, handleClose } = props;

  const styles = {
    popover: {
      position: "absolute",
      zIndex: "4",
      top: "5%",
      right: "25%"
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px"
    }
  };

  return (
    <div style={styles.popover}>
      <div style={styles.cover} onClick={() => handleClose()} />
      <SketchPicker
        color={color}
        onChange={value => handleChange(value.hex)}
        presetColors={[]}
        disableAlpha
      />
    </div>
  );
}

ColorSketch.propTypes = {
  color: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ColorSketch;
