import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import CustomTable from "components/Table/Table";
import { ROWS_PER_PAGE } from "../../constants";
import FormDialog from "components/Dialog/FormDialog";
import { downloadUsersAdminFuncACTI } from "actions/administrator";
import ManageSecurityRoleAssociation from "./ManageSecurityRoleAssociation";
import { downloadSecurityRolesAdminFuncACTI } from "actions/administrator";
import { associateUserToSecurityRolesACTI } from "actions/administrator";

const titleToolbar = {
  color: "textSecondary",
  content: "Asociación de roles de seguridad"
};

const columns = [
  { id: "documento", label: "Documento", minWidth: 70, width: "15%" },
  { id: "nombre", label: "Nombre", minWidth: 70, width: "75%" },
  {
    id: "actions",
    label: "Acciones",
    minWidth: 70,
    width: "10%",
    align: "center"
  }
];

const defaultInitialValues = {
  default: false
};

const schema = {
  default: Yup.bool()
};

function SecurityRoleAssociation(props) {
  const { downloadUsers, downloadSecurityRoles, associateUser } = props;

  const [users, setUsers] = useState({});
  const [rows, setRows] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [open, setOpen] = useState(false);
  const [userEdit, setUserEdit] = useState({});
  const [securityRoles, setSecurityRoles] = useState(null);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  const handleOpenEdit = (user, securityRoles) => {
    if (!isEmpty(securityRoles)) {
      setInitialValues(defaultValues => {
        securityRoles.forEach((securityRole, index) => {
          defaultValues[`securityRole_${index}`] = user.roles.some(
            role => role.id === securityRole.id
          );
        });

        return defaultValues;
      });
      setUserEdit(user);
      setOpen(true);
    } else {
      setInitialValues(defaultInitialValues);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const roles = Object.entries(values)
      .filter(([key, value]) => -1 !== key.indexOf("securityRole_") && value)
      .map(([key]) => {
        const index = key.substring(key.indexOf("_") + 1);

        return securityRoles[index];
      });
    const params = {
      user: userEdit,
      roles,
      users,
      setUsers,
      setOpen,
      setSubmitting
    };
    associateUser(params);
  };

  const onChangePage = (newPage, rowsPerPage) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage,
      setUsers
    };
    setRows(null);
    downloadUsers(params);
  };

  const generateActions = useCallback(
    user => {
      return {
        type: "actions",
        values: [
          {
            title: "Roles de seguridad",
            icon: "assignment_turned_in",
            func: () => handleOpenEdit(user, securityRoles)
          }
        ]
      };
    },
    [securityRoles]
  );

  const generateRows = useCallback(
    users => {
      if (!isEmpty(users)) {
        const newRows = Array.from(users, user =>
          createData(
            user.id,
            user.documento,
            user.nombre,
            generateActions(user)
          )
        );
        setRows(newRows);
      } else {
        setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
      }
    },
    [generateActions]
  );

  function createData(id, documento, nombre, actions) {
    return { id, documento, nombre, actions };
  }

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE,
      setUsers
    };

    if (null === rows && firstRender) {
      downloadUsers(params);
      setFirstRender(false);
    }
  }, [rows, firstRender, downloadUsers]);

  useEffect(() => {
    if (undefined !== users.content) {
      generateRows(users.content);
    }
  }, [users, generateRows]);

  useEffect(() => {
    if (null === securityRoles) {
      const params = {
        setSecurityRoles
      };
      downloadSecurityRoles(params);
    }
  }, [securityRoles, downloadSecurityRoles]);

  return (
    <>
      <CustomTable
        tableHeaderColor="primary"
        columns={columns}
        rows={rows}
        totalElements={isEmpty(users.content) ? 0 : users.totalElements}
        action="click"
        titleToolbar={titleToolbar}
        onChangePage={onChangePage}
      />
      <FormDialog
        open={open}
        handleClose={handleClose}
        title="Asociar roles de seguridad"
        width="md"
        initialValues={initialValues}
        yupObject={schema}
        handleSubmit={handleSubmit}
        disableBackClick
      >
        <ManageSecurityRoleAssociation
          user={userEdit}
          securityRoles={securityRoles}
        />
      </FormDialog>
    </>
  );
}

SecurityRoleAssociation.propTypes = {
  downloadUsers: PropTypes.func.isRequired,
  downloadSecurityRoles: PropTypes.func.isRequired,
  associateUser: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    downloadUsers: params => {
      dispatch(downloadUsersAdminFuncACTI(params));
    },
    downloadSecurityRoles: params => {
      dispatch(downloadSecurityRolesAdminFuncACTI(params));
    },
    associateUser: params => {
      dispatch(associateUserToSecurityRolesACTI(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SecurityRoleAssociation);
