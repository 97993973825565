import { ACTIONS } from "../constants";

export const downloadRolesACTI = params => ({
  type: ACTIONS.DOWNLOAD_ROLES,
  params
});

export const addRolesStoreACTI = roles => ({
  type: ACTIONS.ADD_ROLES_STORE,
  roles
});

export const insertRoleACTI = (role, extraParams) => ({
  type: ACTIONS.INSERT_ROLE,
  role,
  extraParams
});

export const updateRoleACTI = (role, extraParams) => ({
  type: ACTIONS.UPDATE_ROLE,
  role,
  extraParams
});

export const deleteRoleACTI = (role, extraParams) => ({
  type: ACTIONS.DELETE_ROLE,
  role,
  extraParams
});

export const associateFunctionalitiesACTI = (
  applicationRoleFunctionality,
  extraParams
) => ({
  type: ACTIONS.ASSOCIATE_FUNCTIONALITIES_TO_ROLE,
  applicationRoleFunctionality,
  extraParams
});

export const downloadUserProfilesACTI = params => ({
  type: ACTIONS.DOWNLOAD_USER_PROFILES,
  params
});

export const downloadUserRolesACTI = params => ({
  type: ACTIONS.DOWNLOAD_USER_ROLES,
  params
});

export const findExistingUserACTI = (person, params) => ({
  type: ACTIONS.FIND_EXISTING_USER,
  person,
  params
});

export const updateUserStatusACTI = (user, extraParams) => ({
  type: ACTIONS.UPDATE_USER_STATUS,
  user,
  extraParams
});

export const saveUserACTI = (id, user, extraParams) => ({
  type: ACTIONS.SAVE_USER,
  id,
  user,
  extraParams
});

export const downloadUnitsACTI = params => ({
  type: ACTIONS.DOWNLOAD_UNITS,
  params
});

export const downloadProgramUnitsACTI = params => ({
  type: ACTIONS.DOWNLOAD_PROGRAM_UNITS,
  params
});

export const processAutomaticUserACTI = (user, extraParams) => ({
  type: ACTIONS.PROCESS_AUTOMATIC_USER,
  user,
  extraParams
});

export const addAutomaticUserStoreACTI = automaticUser => ({
  type: ACTIONS.ADD_AUTOMATIC_USER_STORE,
  automaticUser
});
