import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty, isEqual } from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import {
  Checkbox,
  IconButton,
  Paper,
  Radio,
  TableContainer,
  TableFooter,
  TablePagination,
  Tooltip
} from "@material-ui/core";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import CustomTableHead from "./CustomTableHead";
import TablePaginationActions from "./TablePaginationActions";
// creates a beautiful scrollbar
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { StyledTableRow } from "./StyledTableRow";
import { stableSort, getComparator } from "../../helpers/functions";
import { ROWS_PER_PAGE } from "../../constants";
import styles2 from "assets/jss/material-dashboard-react/components/tableStyle.js";
import CustomTableToolbar from "./CustomTableToolbar";

const useStyles = makeStyles({
  container: {
    maxHeight: 449
  },
  table: {
    minWidth: 500
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
});
const useStyles2 = makeStyles(styles2);
const styles3 = {
  icon: { fontSize: "1rem" }
};

const CHECKBOX = "checkbox";
const CLICK = "click";
const RADIO = "radio";
const rowsPerPageOptions = [ROWS_PER_PAGE, 15, 20, 30]; // [4]: { label: "Todos", value: -1 }
const checkboxRowProps = rowProps => {
  rowProps.hover = true;
  rowProps.role = "checkbox";
  rowProps.tabIndex = -1;
};
const clickRowProps = rowProps => {
  rowProps.hover = true;
  rowProps.role = "click";
  rowProps.tabIndex = -1;
};
const styleCell = column => {
  const styles = {
    maxWidth: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  if (column.width) {
    styles.width = column.width;
  }

  return styles;
};
// const styleEmptyCell = emptyRows => ({ height: 33 * emptyRows });

export default function CustomTable(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  // ref to help us initialize PerfectScrollbar on windows devices
  // const tablePanel = createRef();
  const {
    columns,
    checkColor,
    columnsColor,
    selectedRowColor,
    rows,
    totalElements,
    propCheckColumnName,
    action,
    actionsToolbar,
    titleToolbar,
    getSelected,
    onClickRow,
    hiddenColumns,
    onChangePage,
    onChangeSort,
    forcePage,
    setForcePage,
    resetSelections,
    checkboxAllClick,
    defaultSelection
  } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(
    columns[0].sortable ? columns[0][Object.keys(columns[0])[0]] : ""
  );
  const [selected, setSelected] = useState(defaultSelection);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  if (undefined !== forcePage && forcePage !== page) {
    setPage(forcePage);
    setForcePage(undefined);
  }

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const sortOrder = isAsc ? "desc" : "asc";

    if (onChangeSort) {
      onChangeSort(sortOrder, property);
    }

    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const rowsFiltered = rows.filter(row => !row.checkDisabled);
      const newsSelected = rowsFiltered.map(n =>
        propCheckColumnName ? n[propCheckColumnName] : n.id
      );
      setSelected(newsSelected);
      return;
    }

    setSelected([]);
  };

  const handleItemSelected = row =>
    (CHECKBOX === action || RADIO === action) &&
    isSelected(propCheckColumnName ? row[propCheckColumnName] : row.id);

  const checkboxClick = row => {
    if (!row.checkDisabled) {
      const name = propCheckColumnName ? row[propCheckColumnName] : row.id;
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };

  const radioClick = row => {
    if (!row.checkDisabled) {
      const name = propCheckColumnName ? row[propCheckColumnName] : row.id;
      setSelected(oldSelected => (oldSelected !== name ? [name] : oldSelected));
    }
  };

  const handleCustomClick = (event, row) => {
    if (onClickRow) {
      onClickRow(row, event);
    }
  };

  const handleClick = (event, row) => {
    switch (action) {
      case CHECKBOX:
        checkboxClick(row);
        break;
      case CLICK:
        handleCustomClick(event, row);
        break;
      case RADIO:
        radioClick(row);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    if (onChangePage) {
      onChangePage(newPage, rowsPerPage);
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const intValue = parseInt(event.target.value, 10);

    if (onChangePage) {
      onChangePage(0, intValue);
    }

    setRowsPerPage(intValue);
    setPage(0);
  };

  const handleCellClick = (event, row, column) => {
    if (null !== row[column.id] && typeof row[column.id] === "object") {
      event.preventDefault();
    } else {
      handleClick(event, row);
    }
  };

  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} de ${count !== -1 ? count : "más de " + to}`;

  const isSelected = name => selected.indexOf(name) !== -1;
  const numColSpan = () => {
    let num;

    switch (action) {
      case CHECKBOX:
      case RADIO:
        num = columns.length + 1;
        break;
      default:
        num = columns.length;
    }

    return num;
  };
  const rowProps = {};

  if (CHECKBOX === action) {
    checkboxRowProps(rowProps);
  } else if (CLICK === action) {
    clickRowProps(rowProps);
  }

  const renderIconCell = (key, value) => (
    <IconButton
      key={key}
      color="primary"
      aria-label="access to link"
      size="small"
      onClick={() => value.func()}
    >
      <Icon style={styles3.icon}>{value.icon}</Icon>
    </IconButton>
  );

  const renderCellContent = cell => {
    return null !== cell && typeof cell === "object" && "actions" === cell.type
      ? cell.values.map((value, j) => {
          if (value.title) {
            return (
              <Tooltip
                key={value.title}
                title={value.title}
                aria-label={`icon-${j}`}
                placement="top"
              >
                {renderIconCell(value.title, value)}
              </Tooltip>
            );
          } else {
            return renderIconCell(j, value);
          }
        })
      : cell;
  };

  const renderBody = () => {
    if (null === rows) {
      return (
        <TableRow>
          <TableCell colSpan={numColSpan()} align="center">
            Cargando...
          </TableCell>
        </TableRow>
      );
    }

    return rows.length > 0 ? (
      stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
        const isItemSelected = handleItemSelected(row);
        const labelId = `custom-table-${index}`;

        return (
          <StyledTableRow
            {...rowProps}
            aria-checked={isItemSelected}
            key={row.id}
            selected={isItemSelected}
          >
            {CHECKBOX === action && (
              <TableCell
                padding="checkbox"
                className={
                  row.checkDisabled
                    ? undefined
                    : classes2[checkColor + "BodyCell"]
                }
              >
                <Checkbox
                  color="default"
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={event => handleClick(event, row)}
                  disabled={row.checkDisabled}
                />
              </TableCell>
            )}
            {RADIO === action && (
              <TableCell
                padding="checkbox"
                className={
                  row.checkDisabled
                    ? undefined
                    : classes2[checkColor + "BodyCell"]
                }
              >
                <Radio
                  checked={isItemSelected}
                  onChange={event => handleClick(event, row)}
                  inputProps={{ "aria-label": labelId }}
                  disabled={row.checkDisabled}
                />
              </TableCell>
            )}
            {columns.map((column, i) =>
              0 === i ? (
                <TableCell
                  key={column.id}
                  component="th"
                  id={labelId}
                  scope="row"
                  style={styleCell(column)}
                  align={column.align ? column.align : "left"}
                  onClick={event => handleCellClick(event, row, column)}
                >
                  {renderCellContent(row[column.id])}
                </TableCell>
              ) : (
                <TableCell
                  key={column.id}
                  style={styleCell(column)}
                  align={column.align ? column.align : "left"}
                  onClick={event => handleCellClick(event, row, column)}
                >
                  {renderCellContent(row[column.id])}
                </TableCell>
              )
            )}
          </StyledTableRow>
        );
      })
    ) : (
      <TableRow>
        <TableCell colSpan={numColSpan()} align="center">
          No hay datos para mostrar.
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    if (getSelected) {
      getSelected(selected);
    }
  }, [getSelected, selected]);

  useEffect(() => {
    if (resetSelections) {
      setSelected(oldSelected => (!isEmpty(oldSelected) ? [] : oldSelected));
    }
  }, [resetSelections]); // resetSelections es de tipo array para que el efecto se vuelva a ejecutar cada vez que se llame setResetSelections.

  useEffect(() => {
    if (CHECKBOX === action && undefined !== defaultSelection) {
      setSelected(oldSelected => {
        return isEqual(oldSelected, defaultSelection)
          ? oldSelected
          : isEmpty(oldSelected) && isEmpty(defaultSelection)
          ? oldSelected
          : defaultSelection;
      });
    }
  }, [action, defaultSelection]);

  // initialize and destroy the PerfectScrollbar plugin
  // let ps;
  // useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(tablePanel.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // }, [tablePanel]);

  return (
    <ErrorBoundary>
      {(!isEmpty(actionsToolbar) || !isEmpty(titleToolbar)) && (
        <CustomTableToolbar actions={actionsToolbar} title={titleToolbar} />
      )}
      <TableContainer
        component={Paper}
        className={classes.container}
        // ref={tablePanel}
      >
        <Table
          stickyHeader
          className={classes.table}
          aria-label="custom table"
          size="small"
        >
          {!hiddenColumns && null !== rows && (
            <CustomTableHead
              columns={columns}
              columnsColor={columnsColor}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              action={action}
              checkboxAllClick={checkboxAllClick}
            />
          )}
          <TableBody className={classes2[selectedRowColor + "SelectedRow"]}>
            {renderBody()}
            {/* {rows.length > 0 && emptyRows > 0 && (
              <StyledTableRow style={styleEmptyCell(emptyRows)}>
                <TableCell colSpan={numColSpan()} />
              </StyledTableRow>
            )} */}
          </TableBody>
          {null !== rows && rows.length > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage="Filas por pág:"
                  labelDisplayedRows={labelDisplayedRows}
                  rowsPerPageOptions={rowsPerPageOptions}
                  colSpan={numColSpan()}
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Filas por pág." },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </ErrorBoundary>
  );
}

CustomTable.defaultProps = {
  checkColor: "cimmerian",
  columnsColor: "cimmerian",
  selectedRowColor: "cimmerian",
  defaultSelection: []
};

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minWidth: PropTypes.number.isRequired,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      align: PropTypes.oneOf(["center", "left", "right"]),
      sortable: PropTypes.bool
    })
  ).isRequired,
  checkColor: PropTypes.oneOf([
    "cimmerian",
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  columnsColor: PropTypes.oneOf([
    "cimmerian",
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  selectedRowColor: PropTypes.oneOf([
    "cimmerian",
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  rows: PropTypes.arrayOf(PropTypes.object),
  totalElements: PropTypes.number,
  propCheckColumnName: PropTypes.string,
  action: PropTypes.oneOf(["checkbox", "click", "radio"]),
  actionsToolbar: PropTypes.arrayOf(PropTypes.object),
  titleToolbar: PropTypes.object,
  getSelected: PropTypes.func,
  onClickRow: PropTypes.func,
  hiddenColumns: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeSort: PropTypes.func,
  forcePage: PropTypes.number,
  setForcePage: PropTypes.func,
  resetSelections: PropTypes.array,
  checkboxAllClick: PropTypes.bool,
  defaultSelection: PropTypes.array
};
