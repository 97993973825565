import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import CustomTable from "components/Table/Table";
import AlertDialog from "components/Dialog/AlertDialog";
import { ROWS_PER_PAGE } from "../../constants";
import { deleteRoleACTI, downloadRolesACTI } from "../../actions/users";

const columns = [
  { id: "nombre", label: "Nombre", minWidth: 75, width: "90%" },
  {
    id: "actions",
    label: "Acciones",
    minWidth: 70,
    width: "10%",
    align: "center"
  }
];

const rolesTitleToolbar = {
  color: "textSecondary",
  content: "Roles"
};

function Roles(props) {
  const {
    action,
    handleOpenAdd,
    handleOpenEdit,
    setRoleIds,
    selected,
    roles,
    downloadRoles,
    deleteRole
  } = props;

  const [rows, setRows] = useState(null);
  const [page, setPage] = useState(undefined);
  const [firstRender, setFirstRender] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [roleDelete, setRoleDelete] = useState(null);

  const actionsToolbar = [
    {
      ariaLabel: "add role",
      color: "primary",
      func: () => handleOpenAdd(),
      icon: "playlist_add",
      title: "Agregar nuevo rol"
    }
  ];

  const onChangePage = (newPage, rowsPerPage, newPosition) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage
    };
    setRows(null);
    downloadRoles(params);

    if (undefined !== newPosition) {
      setPage(newPosition);
    }
  };

  const handleOpenAlert = role => {
    setRoleDelete(role);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setRoleDelete(null);
    setOpenAlert(false);
  };

  const handleAccept = () => {
    setSubmitting(true);
    const extraParams = {
      setSubmitting,
      handleCloseAlert,
      onChangePage
    };
    deleteRole({ id: roleDelete.id }, extraParams);
  };

  function createData(id, nombre, actions, checkDisabled) {
    return { id, nombre, actions, checkDisabled };
  }

  const generateActions = useCallback(
    role => {
      const actions = {
        type: "actions",
        values: [
          {
            title: "Modificar",
            icon: "mode_edit",
            func: () => handleOpenEdit(role)
          },
          {
            title: "Eliminar",
            icon: "delete_forever",
            func: () => handleOpenAlert(role)
          }
        ]
      };

      return actions;
    },
    [handleOpenEdit]
  );

  const generateRows = useCallback(
    roles => {
      if (!isEmpty(roles)) {
        const newRows = Array.from(roles, role =>
          createData(
            role.id,
            role.nombre,
            generateActions(role),
            null !== role.perfil
          )
        );
        setRows(newRows);
      } else {
        setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
      }
    },
    [generateActions]
  );

  useEffect(() => {
    if (undefined !== roles.content) {
      generateRows(roles.content);
    }
  }, [roles, generateRows]);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE
    };

    if (null === rows && firstRender && null !== selected) {
      downloadRoles(params);
      setFirstRender(false);
    }
  }, [rows, firstRender, selected, downloadRoles]);

  return (
    <>
      <CustomTable
        tableHeaderColor="primary"
        checkColor="primary"
        columns={columns}
        rows={rows}
        totalElements={isEmpty(roles.content) ? 0 : roles.totalElements}
        action={action || "checkbox"}
        actionsToolbar={actionsToolbar}
        titleToolbar={rolesTitleToolbar}
        onChangePage={onChangePage}
        forcePage={page}
        setForcePage={setPage}
        getSelected={setRoleIds}
        defaultSelection={null !== selected ? selected : []}
      />
      <AlertDialog
        open={openAlert}
        title="Eliminar rol"
        content="¿Deseas continuar con el proceso?"
        isSubmitting={submitting}
        handleClose={handleCloseAlert}
        handleAccept={handleAccept}
      />
    </>
  );
}

Roles.propTypes = {
  action: PropTypes.string,
  handleOpenAdd: PropTypes.func.isRequired,
  handleOpenEdit: PropTypes.func.isRequired,
  setRoleIds: PropTypes.func,
  selected: PropTypes.array,
  roles: PropTypes.object.isRequired,
  downloadRoles: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    roles: state.rolesREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadRoles: params => {
      dispatch(downloadRolesACTI(params));
    },
    deleteRole: (role, extraParams) => {
      dispatch(deleteRoleACTI(role, extraParams));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Roles);
