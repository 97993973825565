import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomTable from "components/Table/Table";
import ViewNotification from "./ViewNotification";
import {
  addNotificationsStoreACTI,
  downloadNotificationsACTI,
  setHeaderNotificationACTI
} from "../../actions/notification";
import { ROWS_PER_PAGE } from "../../constants";
import { openNewWindow } from "../../helpers/functions";

const styles = {
  card: { marginTop: 10 },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

const columns = [
  { id: "content", label: "Tema", minWidth: 170, width: "90%" },
  {
    id: "link",
    label: "Enlace",
    minWidth: 80,
    width: "10%",
    align: "center"
  }
];

function createData(id, content, link) {
  return { id, content, link };
}

const handleClickLaunch = objectActions => {
  if (objectActions instanceof Object) {
    const objectLaunch = objectActions.values.find(
      value => "launch" === value.icon
    );
    objectLaunch.func();
  } else {
    openNewWindow(objectActions, "_blank");
  }
};

const generateActions = link => {
  if (link) {
    return {
      type: "actions",
      values: [
        {
          title: "Ir a",
          icon: "launch",
          func: () => openNewWindow(link)
        }
      ]
    };
  } else {
    return "";
  }
};

function Notifications(props) {
  const classes = useStyles();
  const {
    notifications,
    downloadNotifications,
    selectedNoti,
    setNotification,
    setNotifications
  } = props;
  const [content, setContent] = useState("");
  const [link, setLink] = useState(null);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState(null);
  const [firstRender, setFirstRender] = useState(true);

  const handleCloseDialog = () => {
    if (!isEmpty(selectedNoti)) {
      setNotification(null);
    }

    setOpen(false);
  };

  const onClickRow = useCallback(
    row => {
      const notification = notifications.content.find(
        noti => noti.id === row.id
      );
      setContent(notification.contenido);

      if (row.link) {
        setLink(row.link);
      } else if (row.enlace) {
        setLink(row.enlace);
      } else {
        setLink(null);
      }

      setOpen(true);
    },
    [notifications.content]
  );

  const generateRows = notifications => {
    if (!isEmpty(notifications)) {
      const newRows = Array.from(notifications, notification => {
        let topic = notification.tema ? notification.tema : "(Sin asunto)";

        if (notification.banner) {
          topic = `📣 ${topic}`;
        }

        return createData(
          notification.id,
          topic,
          generateActions(notification.enlace)
        );
      });
      setRows(newRows);
    } else {
      setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
    }
  };

  const onChangePage = (newPage, rowsPerPage) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage
    };
    setRows(null);
    downloadNotifications(params);
  };

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE
    };

    if (null === rows && firstRender) {
      downloadNotifications(params);
      setFirstRender(false);
    }
  }, [rows, firstRender, downloadNotifications]);

  useEffect(() => {
    if (undefined !== notifications.content) {
      generateRows(notifications.content);
    }
  }, [notifications]);

  useEffect(() => {
    if (!isEmpty(selectedNoti) && !isEmpty(notifications.content)) {
      onClickRow(selectedNoti);
    }
  }, [selectedNoti, notifications, onClickRow]);

  useEffect(() => {
    return () => {
      setNotifications({});
    };
  }, [setNotifications]);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Lista de notificaciones</h4>
          <p className={classes.cardCategoryWhite}>
            La lista desplegada corresponde a los anuncios recientes.
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomTable
                tableHeaderColor="primary"
                columns={columns}
                rows={rows}
                totalElements={
                  isEmpty(notifications) ? 0 : notifications.totalElements
                }
                action="click"
                onClickRow={onClickRow}
                onChangePage={onChangePage}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <ViewNotification
        open={open}
        handleCloseDialog={handleCloseDialog}
        handleClickLaunch={handleClickLaunch}
        content={content}
        link={link}
      />
    </>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.object.isRequired,
  downloadNotifications: PropTypes.func.isRequired,
  selectedNoti: PropTypes.object,
  setNotification: PropTypes.func.isRequired,
  setNotifications: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    notifications: state.notificationsREDU,
    selectedNoti: state.headerNotificationsREDU.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadNotifications: params => {
      dispatch(downloadNotificationsACTI(params));
    },
    setNotification: notification => {
      dispatch(setHeaderNotificationACTI(notification));
    },
    setNotifications: notifications => {
      dispatch(addNotificationsStoreACTI(notifications));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
