import React from "react";
import Card from "components/Card/Card";
import { makeStyles } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import GovernRole from "./GovernRole";
import ManageApplications from "./ManageApplications";
import SecurityRoleAssociation from "./SecurityRoleAssociation";

import functionalityStyle from "assets/jss/material-dashboard-react/functionalityStyle";

const styles = theme => ({
  ...functionalityStyle,
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  }
});
const useStyles = makeStyles(styles);

function Administrator() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Administrador</h4>
        <p className={classes.cardCategoryWhite}>
          Administrador de datos de aplicaciones, gestión de roles y asociación
          de roles de seguridad a usuarios.
        </p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <ManageApplications />
          </GridItem>
          <GridItem xs={12} md={6}>
            <GovernRole />
          </GridItem>
          <GridItem xs={12}>
            <SecurityRoleAssociation />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

export default Administrator;
