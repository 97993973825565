import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ROOT_PATH } from "../../constants";

function PrivateRoute(props) {
  const { component: Component, user, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props =>
        user.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `${ROOT_PATH}/login`,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.userREDU
  };
};

export default connect(
  mapStateToProps,
  null
)(PrivateRoute);
