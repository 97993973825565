import { Link, Typography } from "@material-ui/core";
import React from "react";

function Copyright() {
  return (
    <Typography
      component="span"
      variant="body2"
      color="textSecondary"
      display="block"
      align="center"
    >
      {"© CIADTI, "}
      <Link
        color="inherit"
        href="http://www.unipamplona.edu.co"
        target="_blank"
      >
        Universidad de Pamplona
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default Copyright;
