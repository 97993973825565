import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { useField } from "formik";

const styles = {
  root: {
    width: "100%",
    marginBottom: "8px",
    "& label.MuiFormLabel-root": {
      color: "#AAA",
      fontSize: "1rem"
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#D2D2D2"
    }
  }
};
const useStyles = makeStyles(styles);

function KeyboardDatePickerFormik(props) {
  const classes = useStyles();
  const {
    id,
    label,
    KeyboardButtonProps,
    setFieldError,
    setFieldValue,
    handleChange
  } = props;
  const [field, meta] = useField(props);

  const handleOnChange = date => {
    const { name } = field;
    setFieldValue(name, date);

    if (handleChange) {
      handleChange(date);
    }
  };

  return (
    <KeyboardDatePicker
      {...field}
      autoOk
      disableToolbar
      id={id}
      label={label}
      KeyboardButtonProps={KeyboardButtonProps}
      variant="inline"
      format="DD/MM/yyyy"
      margin="none"
      keyboardIcon={<EventIcon color="primary" />}
      invalidDateMessage="Fecha inválida"
      invalidLabel="Desconocido"
      autoComplete="off"
      helperText={meta.touched && meta.error ? meta.error : null}
      error={Boolean(meta.touched && meta.error)}
      onError={error => {
        // handle as a side effect
        if (error && error !== meta.error) {
          setFieldError(field.name, error);
        }
      }}
      // if you are using custom validation schema you probably want to pass `true` as third argument
      onChange={handleOnChange}
      className={classes.root}
    />
  );
}

KeyboardDatePickerFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  KeyboardButtonProps: PropTypes.object,
  setFieldError: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func
};

export default KeyboardDatePickerFormik;
