import React from "react";
import PropTypes from "prop-types";
import Chart from "react-google-charts";

function PieChart(props) {
  const { data, handleOpenColor, fromDash, extraData } = props;
  const { width, height, values, color, colors } = data;
  const { legend, pieHole } = extraData;

  return (
    <Chart
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      chartType="PieChart"
      loader={<div>Cargando gráfica...</div>}
      data={values}
      options={{
        backgroundColor: {
          fill: "transparent"
        },
        colors: colors ? colors : [color],
        legend: legend
          ? {
              textStyle: { color: color ? color : "#fff" }
            }
          : "none",
        tooltip: {
          showColorCode: !!colors
        },
        pieHole: pieHole ? 0.3 : 0,
        pieSliceTextStyle: {
          color: pieHole
            ? color
              ? color
              : "#fff"
            : colors && color
            ? color
            : color && "#fff" === color
            ? "#000"
            : "#fff"
        }
      }}
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();

            if (1 === selection.length && !fromDash) {
              const [selectedItem] = selection;

              if (null === selectedItem.row) {
                handleOpenColor(selectedItem.column);
              }
            }
          }
        }
      ]}
      // For tests
      rootProps={{ "data-testid": "3" }}
    />
  );
}

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  handleOpenColor: PropTypes.func.isRequired,
  fromDash: PropTypes.bool,
  extraData: PropTypes.object.isRequired
};

export default PieChart;
