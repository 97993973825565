import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  FormControl,
  makeStyles,
  TextField,
  withStyles
} from "@material-ui/core";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { COLOR_APP_HEX } from "../../constants";

const useStyles = makeStyles(styles);

const CssTextField = withStyles({
  root: {
    "& label.MuiFormLabel-root": {
      color: "#AAA",
      fontSize: "1rem"
    },
    "& label.Mui-focused": {
      color: COLOR_APP_HEX
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#D2D2D2"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLOR_APP_HEX
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR_APP_HEX
      },
      "&:hover fieldset": {
        borderColor: COLOR_APP_HEX
      },
      "&.Mui-focused fieldset": {
        borderColor: COLOR_APP_HEX
      }
    }
  }
})(TextField);

export default function CustomInput(props) {
  const classes = useStyles();
  const { label, formControlProps, formControlRoot, style, ...rest } = props;

  return (
    <FormControl
      {...formControlProps}
      className={!formControlRoot ? classes.formControl : null}
      style={style}
    >
      <CssTextField label={label ? label : null} InputProps={{ ...rest }} />
    </FormControl>
  );
}

CustomInput.propTypes = {
  label: PropTypes.string,
  formControlProps: PropTypes.object,
  formControlRoot: PropTypes.bool,
  style: PropTypes.object
};
