import { call, put, select, delay } from "redux-saga/effects";
import { isEmpty } from "lodash";
import { apiGETParam } from "../api";
import { urlAnuncios } from "../api/urls";
import {
  setMessageNotificationACTI,
  setOpenNotificationErrorACTI,
  setOpenNotificationSuccessACTI,
  setOpenNotificationWarningACTI
} from "../actions";
import {
  addAnnouncementsStoreACTI,
  addHeaderNotificationsStoreACTI,
  addNotificationsStoreACTI
} from "../actions/notification";

const downloadNotifications = (pageNo, pageSize, params) =>
  apiGETParam(
    `${urlAnuncios}/usuario/pagina/${pageNo + 1}/${pageSize}`,
    params
  ).then(notifications => notifications);
export const downloadAnnouncements = params =>
  apiGETParam(`${urlAnuncios}/listar-banner`, params).then(
    announcements => announcements
  );

export function* launchError(error) {
  yield put(setMessageNotificationACTI(error));
  yield put(setOpenNotificationErrorACTI(true));
  yield delay(6000);
  yield put(setOpenNotificationErrorACTI(false));
}

export function* launchSuccess(message) {
  yield put(setMessageNotificationACTI(message));
  yield put(setOpenNotificationSuccessACTI(true));
  yield delay(6000);
  yield put(setOpenNotificationSuccessACTI(false));
}

export function* launchWarning(message) {
  yield put(setMessageNotificationACTI(message));
  yield put(setOpenNotificationWarningACTI(true));
  yield delay(6000);
  yield put(setOpenNotificationWarningACTI(false));
}

export function* sagaDownloadNotifications(action) {
  try {
    const user = yield select(state => state.userREDU);
    const params = {
      users: user.id.toString()
    };

    if (!isEmpty(user.perfiles)) {
      const profiles = Array.from(user.perfiles, perfil => perfil.id);
      params.profiles = profiles.toString();
    }

    const notifications = yield call(
      downloadNotifications,
      action.params.pageNo,
      action.params.pageSize,
      params
    );
    yield put(addNotificationsStoreACTI(notifications));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addNotificationsStoreACTI({ content: [] }));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaLoadHeaderNotifications() {
  try {
    const user = yield select(state => state.userREDU);
    const params = {
      users: user.id.toString()
    };

    if (!isEmpty(user.perfiles)) {
      const profiles = Array.from(user.perfiles, perfil => perfil.id);
      params.profiles = profiles.toString();
    }

    const notifications = yield call(downloadNotifications, 0, 10, params);
    yield put(addHeaderNotificationsStoreACTI(notifications.content));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaLoadAnnouncements() {
  try {
    const user = yield select(state => state.userREDU);
    const params = {
      users: user.id.toString()
    };

    if (!isEmpty(user.perfiles)) {
      const profiles = Array.from(user.perfiles, perfil => perfil.id);
      params.profiles = profiles.toString();
    }

    const announcements = yield call(downloadAnnouncements, params);
    yield put(addAnnouncementsStoreACTI(announcements));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}
