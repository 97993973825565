import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setTitleWidgetACTI,
  setCategoryWidgetACTI,
  setContentWidgetACTI
} from "../../actions/dashboard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputFormik from "components/CustomInput/InputFormik";

const FormConfWidget = props => {
  const { setTitle, setCategory, setContent, isIframe } = props;
  const handleChangeTitle = value => {
    setTitle(value);
  };
  const handleChangeCategory = value => {
    setCategory(value);
  };
  const handleChangeContent = value => {
    setContent(value);
  };

  useEffect(() => {
    return () => {
      setTitle("");
      setCategory("");
      setContent("");
    };
  }, [setTitle, setCategory, setContent]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <InputFormik
            label="Título"
            id="tituloWidget"
            name="titulo"
            type="text"
            handleChange={handleChangeTitle}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <InputFormik
            label="Categoría"
            id="categoriaWidget"
            name="categoria"
            type="text"
            handleChange={handleChangeCategory}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <InputFormik
            label={
              isIframe
                ? "Enlace (algunos sitios no permiten mostrarse en marco)"
                : "Contenido"
            }
            id="contenidoWidget"
            name="contenido"
            type="text"
            handleChange={handleChangeContent}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

FormConfWidget.propTypes = {
  setTitle: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  isIframe: PropTypes.bool
};

const mapDispatchToProps = dispatch => {
  return {
    setTitle: value => {
      dispatch(setTitleWidgetACTI(value));
    },
    setCategory: value => {
      dispatch(setCategoryWidgetACTI(value));
    },
    setContent: value => {
      dispatch(setContentWidgetACTI(value));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(FormConfWidget);
