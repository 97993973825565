import { ACTIONS } from "../constants";

export const addGlobalProfilesStoreACTI = profiles => ({
  type: ACTIONS.ADD_GLOBAL_PROFILES_STORE,
  profiles
});

export const downloadGlobalProfilesACTI = () => ({
  type: ACTIONS.DOWNLOAD_GLOBAL_PROFILES
});

export const downloadProfilesACTI = params => ({
  type: ACTIONS.DOWNLOAD_PROFILES,
  params
});
