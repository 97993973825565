import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SearchIcon from "@material-ui/icons/Search";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomCheckbox from "components/CustomInput/CustomCheckbox";
import CustomInput from "components/CustomInput/CustomInput";
import { downloadGlobalProfilesACTI } from "../../actions/profiles";
import {
  addAnnouncementUsersStoreACTI,
  findAnnouncementUserByDocumentACTI,
  findAnnouncementUsersByNameACTI
} from "../../actions/announcements";

import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";

const useStyles = makeStyles(checkboxAdnRadioStyle);
const useStylesLocal = makeStyles(theme => ({
  recipientsSub: {
    margin: 0,
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  recipientsType: {
    display: "flex",
    justifyContent: "space-around"
  },
  recipientsCont: {
    maxHeight: "120px",
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      borderLeft: "2px solid #ccc",
      maxHeight: "250px"
    }
  },
  recipientsContBorder: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "5px 15px",
      borderTop: "2px solid #ccc"
    }
  },
  searchUsers: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center"
    }
  },
  leftRecipients: {
    maxHeight: "120px",
    overflowY: "auto"
  }
}));

function ManageRecipients(props) {
  const classes = useStyles();
  const classesLocal = useStylesLocal();
  const {
    type,
    handleClickType,
    recipients,
    handleRecipients,
    profiles,
    downloadProfiles,
    users,
    findUserByDocument,
    findUsersByName,
    setUsers
  } = props;
  const [userDocument, setUserDocument] = useState(null);
  const [userName, setUserName] = useState(null);

  const renderProfiles = () => {
    if (null === profiles) {
      return (
        <FormGroup>
          <span>Cargando perfiles...</span>
        </FormGroup>
      );
    }

    return (
      <FormGroup>
        {!isEmpty(profiles) ? (
          profiles.map((profile, index) => (
            <CustomCheckbox
              checked={
                recipients.find(recipient => recipient.id === profile.id)
                  ? true
                  : false
              }
              key={profile.id}
              name={`profile-${index}`}
              label={profile.nombre}
              onClick={() => handleRecipients(profile, "ADD")}
            />
          ))
        ) : (
          <span>No hay perfiles para mostrar.</span>
        )}
      </FormGroup>
    );
  };

  const handleDocument = e => {
    if (null !== users || users.length > 0) {
      setUsers([]);
    }

    const value = e.target.value.trim();
    setUserDocument(value.length > 0 ? value : null);
  };

  const handleName = e => {
    if (null !== users || users.length > 0) {
      setUsers([]);
    }

    const value = e.target.value.trim();
    setUserName(value.length > 0 ? value : null);
  };

  const handleDocumentKeyDown = e => {
    if (e.key === "Enter") {
      if (null !== userDocument && userDocument.length >= 4) {
        findUserByDocument(userDocument);
      }
    }
  };

  const handleNameKeyDown = e => {
    if (e.key === "Enter") {
      if (null !== userName && userName.length >= 6) {
        findUsersByName(userName);
      }
    }
  };

  const handleFindUsers = () => {
    if (null !== userDocument) {
      findUserByDocument(userDocument);
    } else if (null !== userName) {
      findUsersByName(userName);
    }
  };

  const renderUsers = () => {
    if (null === users) {
      return (
        <List dense>
          <span>Buscando usuarios...</span>
        </List>
      );
    }

    let usersList = [...users];

    if (!isEmpty(users) && !isEmpty(recipients)) {
      usersList = users.filter(
        user =>
          undefined === recipients.find(recipient => recipient.id === user.id)
      );
    }

    return (
      <List dense>
        {!isEmpty(usersList) ? (
          usersList.map((user, index) => {
            const labelId = `users-list-label-${index}`;

            return (
              <ListItem key={user.id} role={undefined} dense button>
                <ListItemText
                  id={labelId}
                  primary={`${user.documento} - ${user.nombre}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="add"
                    color="primary"
                    onClick={() => handleRecipients(user, "ADD")}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        ) : (
          <span>No hay usuarios para mostrar.</span>
        )}
      </List>
    );
  };

  const renderFindUsers = () => (
    <GridContainer>
      <GridItem xs={10}>
        <GridContainer>
          <GridItem xs={12} md={4}>
            <CustomInput
              label="Documento"
              id="document"
              name="document"
              type="text"
              formControlProps={{
                fullWidth: true
              }}
              style={{ marginBottom: "8px" }}
              formControlRoot
              autoComplete="off"
              onChange={handleDocument}
              onKeyDown={handleDocumentKeyDown}
            />
          </GridItem>
          <GridItem xs={12} md={8}>
            <CustomInput
              label="Nombre"
              id="name"
              name="name"
              type="text"
              formControlProps={{
                fullWidth: true
              }}
              style={{ marginBottom: "8px" }}
              formControlRoot
              autoComplete="off"
              onChange={handleName}
              onKeyDown={handleNameKeyDown}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={2} className={classesLocal.searchUsers}>
        <IconButton
          aria-label="search users"
          color="primary"
          disabled={
            ((null === userDocument || userDocument.length < 4) &&
              (null === userName || userName.length < 6)) ||
            (null !== userDocument && null !== userName) ||
            null === users
          }
          onClick={handleFindUsers}
        >
          <SearchIcon />
        </IconButton>
      </GridItem>
      <GridItem xs={12} className={classesLocal.leftRecipients}>
        {renderUsers()}
      </GridItem>
    </GridContainer>
  );

  const renderRecipients = () => (
    <List dense>
      {!isEmpty(recipients) ? (
        recipients.map((recipient, index) => {
          const labelId = `list-label-${index}`;

          return (
            <ListItem key={recipient.id} role={undefined} dense button>
              <ListItemText id={labelId} primary={recipient.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="add"
                  color="primary"
                  onClick={() => handleRecipients(recipient, "REMOVE")}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      ) : (
        <span>No hay destinatario seleccionado.</span>
      )}
    </List>
  );

  const handleChangeType = e => {
    if (null !== users || users.length > 0) {
      setUsers([]);
    }

    if (null !== userDocument) {
      setUserDocument(null);
    }

    if (null !== userName) {
      setUserName(null);
    }

    handleRecipients(); // remueve los destinatarios que se hayan subido al estado.
    handleClickType(e);
  };

  useEffect(() => {
    if (null === profiles) {
      downloadProfiles();
    }
  }, [profiles, downloadProfiles]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Typography
          variant="subtitle1"
          className={classesLocal.recipientsSub}
          gutterBottom
        >
          Destinatarios
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6} lg={7}>
        <RadioGroup
          aria-label="recipient"
          name="recipient"
          value={type}
          onChange={handleChangeType}
        >
          <div className={classesLocal.recipientsType}>
            <FormControlLabel
              value="profiles"
              control={
                <Radio
                  checkedIcon={<Check className={classes.radioChecked} />}
                  icon={<Check className={classes.radioUnchecked} />}
                  color="default"
                  classes={{
                    checked: classes.checked,
                    root: classes.root
                  }}
                />
              }
              label="Perfiles"
            />
            <FormControlLabel
              value="users"
              control={
                <Radio
                  checkedIcon={<Check className={classes.radioChecked} />}
                  icon={<Check className={classes.radioUnchecked} />}
                  color="default"
                  classes={{
                    checked: classes.checked,
                    root: classes.root
                  }}
                />
              }
              label="Usuarios"
            />
          </div>
        </RadioGroup>
        {"profiles" === type && renderProfiles()}
        {"users" === type && renderFindUsers()}
      </GridItem>
      <div className={classesLocal.recipientsContBorder}></div>
      <GridItem xs={12} md={6} lg={5} className={classesLocal.recipientsCont}>
        {renderRecipients()}
      </GridItem>
    </GridContainer>
  );
}

ManageRecipients.propTypes = {
  type: PropTypes.string.isRequired,
  handleClickType: PropTypes.func.isRequired,
  recipients: PropTypes.array,
  handleRecipients: PropTypes.func.isRequired,
  profiles: PropTypes.array,
  downloadProfiles: PropTypes.func.isRequired,
  users: PropTypes.array,
  findUserByDocument: PropTypes.func.isRequired,
  findUsersByName: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    profiles: state.globalProfilesREDU,
    users: state.announcementUsersREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadProfiles: () => {
      dispatch(downloadGlobalProfilesACTI());
    },
    findUserByDocument: userDocument => {
      dispatch(findAnnouncementUserByDocumentACTI(userDocument));
    },
    findUsersByName: name => {
      dispatch(findAnnouncementUsersByNameACTI(name));
    },
    setUsers: users => {
      dispatch(addAnnouncementUsersStoreACTI(users));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageRecipients);
