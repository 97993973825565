import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles, SvgIcon, IconButton } from "@material-ui/core";
// @material-ui/icons
// import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";
import Linkify from "linkifyjs/react";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-react.js";
import "../../assets/css/react-grid-layout.css";
import CustomTooltip from "helpers/CustomTooltip";
import AlertDialog from "components/Dialog/AlertDialog";

const useStyles = makeStyles(styles);

const Infobox = props => {
  const classes = useStyles();
  const {
    id,
    color,
    titulo,
    categoria,
    contenido,
    cssTransition,
    pathIcon,
    fromDash,
    handleDeleteWidget,
    handleClickOpenDialog
  } = props;
  const styles = {
    iconEdi: {
      color: successColor[2]
    },
    iconDel: {
      color: dangerColor[2]
    }
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showActions, setShowActions] = React.useState(false);
  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleAccept = () => {
    handleDeleteWidget(id);
    handleCloseAlert();
  };

  let cardIconClassName =
    fromDash && showActions
      ? `card-icon-layout ${cssTransition} touch-translate-y`
      : `card-icon-layout ${cssTransition} touch-translate-reset`;

  useEffect(() => {
    let timer = setTimeout(() => {
      if (showActions) {
        setShowActions(false);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showActions]);

  return (
    <Card className="card-layout">
      <CardHeader color="danger" stats icon className="header-view-btns">
        {fromDash && (
          <div className="infobox-icons">
            <CustomTooltip title="Editar" style={styles.iconEdi}>
              <IconButton
                aria-label="editar-widget"
                style={styles.iconEdi}
                onClick={() => handleClickOpenDialog(id)}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Eliminar" style={styles.iconDel}>
              <IconButton
                aria-label="borrar-widget"
                style={styles.iconDel}
                onClick={handleClickOpenAlert}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          </div>
        )}
        <CardIcon
          color="danger"
          className={cardIconClassName}
          style={color}
          onClick={() => {
            setShowActions(showActions => !showActions);
          }}
        >
          <SvgIcon>
            <path d={pathIcon} />
          </SvgIcon>
        </CardIcon>
        <p
          className={
            fromDash
              ? classes.cardCategory + " truncate-h-infobox"
              : classes.cardCategory + " truncate-h-infobox-2"
          }
        >
          {categoria && "" !== categoria.trim() ? categoria : "Categoría"}
        </p>
        <h3
          className={
            fromDash
              ? classes.cardTitle + " truncate-h-infobox"
              : classes.cardTitle + " truncate-h-infobox-2"
          }
        >
          {titulo && "" !== titulo.trim() ? titulo : "Título"}
        </h3>
      </CardHeader>
      <CardFooter stats>
        <div className={classes.stats}>
          <span>
            <Linkify>
              {contenido && "" !== contenido.trim() ? contenido : "Contenido"}
            </Linkify>
          </span>
        </div>
      </CardFooter>
      <AlertDialog
        content="¿Deseas eliminar el widget?"
        open={openAlert}
        handleClose={handleCloseAlert}
        handleAccept={handleAccept}
      />
    </Card>
  );
};

Infobox.propTypes = {
  id: PropTypes.string,
  color: PropTypes.object.isRequired,
  titulo: PropTypes.string.isRequired,
  categoria: PropTypes.string.isRequired,
  contenido: PropTypes.string.isRequired,
  cssTransition: PropTypes.string,
  pathIcon: PropTypes.string.isRequired,
  fromDash: PropTypes.bool.isRequired,
  handleDeleteWidget: PropTypes.func,
  handleClickOpenDialog: PropTypes.func
};

export default Infobox;
