async function generateKey(secret, counter) {
  const Crypto = window.crypto.subtle;
  const encoder = new TextEncoder("utf-8");
  const secretBytes = encoder.encode(secret);
  const key = await Crypto.importKey(
    "raw",
    secretBytes,
    { name: "HMAC", hash: { name: "SHA-1" } },
    false,
    ["sign"]
  );
  const counterArray = padCounter(counter);
  const HS = await Crypto.sign("HMAC", key, counterArray);

  return HS;
}

function padCounter(counter) {
  const buffer = new ArrayBuffer(8);
  const bView = new DataView(buffer);

  const byteString = "0".repeat(64); // 8 bytes
  const bCounter = (byteString + counter.toString(2)).slice(-64);

  for (let byte = 0; byte < 64; byte += 8) {
    const byteValue = parseInt(bCounter.slice(byte, byte + 8), 2);
    bView.setUint8(byte / 8, byteValue);
  }

  return buffer;
}

function DT(HS) {
  // First we take the last byte of our generated HS and extract last 4 bits out of it.
  // This will be our _offset_, a number between 0 and 15.
  const offset = HS[19] & 0b1111;

  // Next we take 4 bytes out of the HS, starting at the offset
  const P =
    ((HS[offset] & 0x7f) << 24) |
    (HS[offset + 1] << 16) |
    (HS[offset + 2] << 8) |
    HS[offset + 3];

  // Finally, convert it into a binary string representation
  const pString = P.toString(2);

  return pString;
}

function truncate(uKey) {
  const Sbits = DT(uKey);
  const Snum = parseInt(Sbits, 2);

  return Snum;
}

export async function generateHOTP(secret, counter) {
  const key = await generateKey(secret, counter);
  const uKey = new Uint8Array(key);

  const Snum = truncate(uKey);
  // Make sure we keep leading zeroes
  // eslint-disable-next-line
  const padded = ("000000" + (Snum % (10 ** 6))).slice(-6);

  return padded;
}
