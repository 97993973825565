import { ACTIONS } from "../constants";

export const downloadWidgetsACTI = profile => ({
  type: ACTIONS.DOWNLOAD_WIDGETS,
  profile
});

export const addProfileStoreACTI = profile => ({
  type: ACTIONS.ADD_PROFILE_STORE,
  profile
});

export const addTypesStoreACTI = types => ({
  type: ACTIONS.ADD_TYPES_STORE,
  types
});

export const addWidgetsStoreACTI = widgets => ({
  type: ACTIONS.ADD_WIDGETS_STORE,
  widgets
});

export const addLayoutsStoreACTI = layouts => ({
  type: ACTIONS.ADD_LAYOUTS_STORE,
  layouts
});

export const addFullLayoutsStoreACTI = layout => ({
  type: ACTIONS.ADD_FULL_LAYOUTS_STORE,
  layout
});

export const changeBreakpointACTI = breakpoint => ({
  type: ACTIONS.CHANGE_BREAKPOINT,
  breakpoint
});

export const updateLayoutsACTI = layouts => ({
  type: ACTIONS.UPDATE_LAYOUTS,
  layouts
});

export const setAlertDoneACTI = alertDone => ({
  type: ACTIONS.SET_ALERT_DONE,
  alertDone
});

export const setSubmittingACTI = isSubmitting => ({
  type: ACTIONS.SET_SUBMITTING,
  isSubmitting
});

export const setChangeWidgetACTI = changeWidget => ({
  type: ACTIONS.SET_CHANGE_WIDGET,
  changeWidget
});

export const setViewGridACTI = viewGrid => ({
  type: ACTIONS.SET_VIEW_GRID,
  viewGrid
});

export const setPristineACTI = isPristine => ({
  type: ACTIONS.SET_PRISTINE,
  isPristine
});

export const setPristineGraphACTI = isPristine => ({
  type: ACTIONS.SET_PRISTINE_GRAPH,
  isPristine
});

export const setLimitWidgetsACTI = limit => ({
  type: ACTIONS.SET_LIMIT_WIDGETS,
  limit
});

export const openDialogWidgetACTI = isOpen => ({
  type: ACTIONS.OPEN_DIALOG_WIDGET,
  isOpen
});

export const setColorCardACTI = color => ({
  type: ACTIONS.SET_COLOR_CARD,
  color
});

export const setColorCardA_ACTI = color => ({
  type: ACTIONS.SET_COLOR_CARD_A,
  color
});

export const setColorCardB_ACTI = color => ({
  type: ACTIONS.SET_COLOR_CARD_B,
  color
});

export const setWidgetUpdateACTI = widget => ({
  type: ACTIONS.SET_WIDGET_UPDATE,
  widget
});

export const setViewContentDialogACTI = isView => ({
  type: ACTIONS.SET_VIEW_CONTENT_DIALOG,
  isView
});

export const checkedEditDashboardACTI = () => ({
  type: ACTIONS.CHECKED_EDIT_DASHBOARD
});

export const setTypeWidgetACTI = typeWidget => ({
  type: ACTIONS.SET_TYPE_WIDGET,
  typeWidget
});

export const checkColorGradWidgetACTI = isChecked => ({
  type: ACTIONS.CHECK_COLOR_GRAD_WIDGET,
  isChecked
});

export const setDegreedCardWidgetACTI = degreed => ({
  type: ACTIONS.SET_DEGREED_CARD_WIDGET,
  degreed
});

export const checkColorAltCardWidgetACTI = isChecked => ({
  type: ACTIONS.CHECK_COLOR_ALT_CARD_WIDGET,
  isChecked
});

export const setTitleWidgetACTI = title => ({
  type: ACTIONS.SET_TITLE_WIDGET,
  title
});

export const setCategoryWidgetACTI = category => ({
  type: ACTIONS.SET_CATEGORY_WIDGET,
  category
});

export const setContentWidgetACTI = content => ({
  type: ACTIONS.SET_CONTENT_WIDGET,
  content
});

export const setIconCardWidgetACTI = icon => ({
  type: ACTIONS.SET_ICON_CARD_WIDGET,
  icon
});

export const checkIframeWidgetACTI = isChecked => ({
  type: ACTIONS.CHECK_IFRAME_WIDGET,
  isChecked
});

export const setOpenDialogIconCardWidgetACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_DIALOG_ICON_CARD_WIDGET,
  isOpen
});

export const setValueTabIconCardWidgetACTI = value => ({
  type: ACTIONS.SET_VALUE_TAB_ICON_CARD_WIDGET,
  value
});

export const setIconsCardWidgetACTI = icons => ({
  type: ACTIONS.SET_ICONS_CARD_WIDGET,
  icons
});

export const insertWidgetACTI = (widget, setSubmitting) => ({
  type: ACTIONS.INSERT_WIDGET,
  widget,
  setSubmitting
});

export const updateWidgetACTI = (id, widget, setSubmitting) => ({
  type: ACTIONS.UPDATE_WIDGET,
  id,
  widget,
  setSubmitting
});

export const deleteWidgetACTI = id => ({
  type: ACTIONS.DELETE_WIDGET,
  id
});

export const setChartTypeACTI = chartType => ({
  type: ACTIONS.SET_CHART_TYPE,
  chartType
});

export const setChartDataACTI = data => ({
  type: ACTIONS.SET_CHART_DATA,
  data
});

export const setChartRangeNameACTI = name => ({
  type: ACTIONS.SET_CHART_RANGE_NAME,
  name
});

export const checkChartLegendACTI = isChecked => ({
  type: ACTIONS.CHECK_CHART_LEGEND,
  isChecked
});

export const checkChartTrendlinesACTI = isChecked => ({
  type: ACTIONS.CHECK_CHART_TRENDLINES,
  isChecked
});

export const checkChartStackedACTI = isChecked => ({
  type: ACTIONS.CHECK_CHART_STACKED,
  isChecked
});

export const checkChartPieHoleACTI = isChecked => ({
  type: ACTIONS.CHECK_CHART_PIEHOLE,
  isChecked
});
