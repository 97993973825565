import { call, put, delay, select } from "redux-saga/effects";
import CryptoJS from "crypto-js";
import { isEmpty } from "lodash";
import { urlImagenes, urlUsuarios, urlUsuariosManager } from "../api/urls";
import {
  getKeySessionVor,
  onAuthStateChangedObj,
  userLogout,
  getLocation
} from "../api/access";
import { apiDELETE, apiPOST, apiPUT } from "../api";
import { generar } from "../helpers/functions";
import {
  addImageUserProfileACTI,
  addUserStoreACTI,
  setMessageNotificationACTI,
  setOpenNotificationErrorACTI,
  setOpenNotificationSuccessACTI,
  setOpenNotificationWarningACTI
} from "../actions";

const updateSomeData = (id, usuario) =>
  apiPUT(`${urlUsuarios}/editar-opcionales`, id, usuario).then(
    reponse => reponse
  );
const updatePassword = (id, usuario, pkp) =>
  apiPUT(`${urlUsuariosManager}/editar-contrasena`, id, usuario, pkp).then(
    reponse => reponse
  );
const insertImage = image =>
  apiPOST(`${urlImagenes}/agregar`, null, null, image).then(reponse => reponse);
const updateImage = (id, image) =>
  apiPUT(`${urlImagenes}/editar`, id, null, null, image).then(
    reponse => reponse
  );
const deleteImage = (id, image) =>
  apiDELETE(`${urlImagenes}/eliminar`, id, image).then(response => response);

export function* sagaUpdateSomeData(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const usuario = {
        correo: action.user.email,
        descripcion: action.user.description,
        registradoPor: authState.data.id
      };
      const updatedUser = yield call(
        updateSomeData,
        authState.data.id,
        usuario
      );

      if (updatedUser) {
        const user = yield select(state => state.userREDU);
        user.descripcion = updatedUser.descripcion;
        yield put(addUserStoreACTI(user));
        yield put(setMessageNotificationACTI());
        yield put(setOpenNotificationSuccessACTI(true));
        yield delay(6000);
        yield put(setOpenNotificationSuccessACTI(false));
      }

      action.extraParams.setSubmitting(false);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaUpdatePassword(action) {
  try {
    if (action.user.password !== action.user.newPassword) {
      let authState = yield call(onAuthStateChangedObj);

      if (authState.data) {
        let keyEncryption = generar();
        let pass = CryptoJS.AES.encrypt(action.user.password, keyEncryption);
        let newPass = CryptoJS.AES.encrypt(
          action.user.newPassword,
          keyEncryption
        );
        let usuario = {
          codigoAcceso: getKeySessionVor(),
          nick: yield call(getLocation),
          contrasena: pass.toString(),
          cambiarContrasena: newPass.toString(),
          registradoPor: authState.data.id
        };
        const updatedPassword = yield call(
          updatePassword,
          authState.data.id,
          usuario,
          keyEncryption
        );

        if (updatedPassword.id === authState.data.id) {
          localStorage.setItem("userVor", updatedPassword.contrasena);
          action.extraParams.setOpen(false);
          yield put(setMessageNotificationACTI());
          yield put(setOpenNotificationSuccessACTI(true));
          yield delay(6000);
          yield put(setOpenNotificationSuccessACTI(false));
        } else if ("412" === updatedPassword.code) {
          localStorage.setItem("userVor", updatedPassword.contrasena);
          action.extraParams.setOpen(false);
          yield put(
            setMessageNotificationACTI(
              "La contraseña se actualizó correctamente aunque con algunos invenientes. " +
                updatedPassword.message
            )
          );
          yield put(setOpenNotificationWarningACTI(true));
          yield delay(6000);
          yield put(setOpenNotificationWarningACTI(false));
        }
      } else if (authState.error) {
        throw authState.error.toString();
      } else {
        yield put(addUserStoreACTI(userLogout()));
      }
    } else {
      action.extraParams.resetForm({});
      action.extraParams.setSubmitting(false);
      yield put(setMessageNotificationACTI("La contraseña no ha cambiado"));
      yield put(setOpenNotificationWarningACTI(true));
      yield delay(6000);
      yield put(setOpenNotificationWarningACTI(false));
    }
  } catch (error) {
    action.extraParams.resetForm({});
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaUploadProfileImage(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      // Mínimo permitido: 1MB
      if (!action.image.type.includes("image/")) {
        action.extraParams.setSubmitting(false);
        yield put(
          setMessageNotificationACTI("El archivo debe ser una imagen.")
        );
        yield put(setOpenNotificationWarningACTI(true));
        yield delay(6000);
        yield put(setOpenNotificationWarningACTI(false));
      } else if (action.image.blob.size <= 1048576) {
        const imagesUser = yield select(state => state.imagesUserREDU);
        const formData = new FormData();
        const name = action.image.name ? action.image.name : "no_name.png";
        formData.append("file", action.image.blob, name);
        formData.append("idUsuario", authState.data.id.toString());
        formData.append("tipo", "PRO");
        formData.append("registradoPor", authState.data.id.toString());
        let image;

        if (!isEmpty(imagesUser) && !isEmpty(imagesUser.profile)) {
          image = yield call(updateImage, imagesUser.profile.id, formData);

          if (!isEmpty(image)) {
            image = {
              id: imagesUser.profile.id,
              src: action.image.src
            };
          }
        } else {
          image = yield call(insertImage, formData);

          if (!isEmpty(image)) {
            image = {
              id: image.id,
              src: action.image.src
            };
          }
        }

        action.extraParams.setSubmitting(false);

        if (!isEmpty(image)) {
          yield put(addImageUserProfileACTI(image));
          action.extraParams.setOpen(false);
          yield put(setMessageNotificationACTI());
          yield put(setOpenNotificationSuccessACTI(true));
          yield delay(6000);
          yield put(setOpenNotificationSuccessACTI(false));
        }
      } else {
        action.extraParams.setSubmitting(false);
        yield put(
          setMessageNotificationACTI("El archivo debe ser máximo de 1MB.")
        );
        yield put(setOpenNotificationWarningACTI(true));
        yield delay(6000);
        yield put(setOpenNotificationWarningACTI(false));
      }
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaDeleteProfileImage(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const imagesUser = yield select(state => state.imagesUserREDU);

      if (!isEmpty(imagesUser) && !isEmpty(imagesUser.profile)) {
        yield call(deleteImage, imagesUser.profile.id, {
          tipo: "PRO",
          registradoPor: authState.data.id.toString()
        });
        action.extraParams.setSubmitting(false);
        yield put(addImageUserProfileACTI({}));
        action.extraParams.setOpen(false);
        yield put(setMessageNotificationACTI());
        yield put(setOpenNotificationSuccessACTI(true));
        yield delay(6000);
        yield put(setOpenNotificationSuccessACTI(false));
      } else {
        throw String("No hay imagen para quitar.");
      }
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}
