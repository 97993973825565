import { makeStyles } from "@material-ui/core";
import { COLOR_APP_HEX, COLOR_APP_HEX_B } from "../../../constants";

export const useButtonStyles = makeStyles(theme => ({
  progress: {
    color: COLOR_APP_HEX_B,
    position: "absolute"
  },
  buttonProgress: {
    color: COLOR_APP_HEX_B,
    position: "absolute",
    top: "calc(100% - 23px)",
    left: "calc(100% - 66px)",
    marginTop: -12
  },
  root: {
    color: theme.palette.getContrastText(COLOR_APP_HEX),
    backgroundColor: COLOR_APP_HEX,
    "&:hover": {
      backgroundColor: COLOR_APP_HEX_B
    }
  }
}));
