export const COLOR_APP_HEX = process.env.REACT_APP_COLOR_HEX;
export const COLOR_APP_HEX_B = process.env.REACT_APP_COLOR_HEX_B;
export const COLOR_APP_RGBA = process.env.REACT_APP_COLOR_RGBA;
export const COLOR_APP_RGBA_OBJ = JSON.parse(
  process.env.REACT_APP_COLOR_RGBA_OBJ
);
// Optional => [4]: box shadow color; [5]: font color active.
export const CUSTOM_COLOR = JSON.parse(process.env.REACT_APP_COLOR_CUSTOM);
export const PRIMARY_COLOR = JSON.parse(
  process.env.REACT_APP_COLOR_PRIMARY_ARRAY
);
export const ROWS_PER_PAGE = 10;
export const TEXTO = process.env.REACT_APP_COLOR_TEXTO;
export const TEXTO_BLANCO = process.env.REACT_APP_COLOR_TEXTO_BLANCO;
export const ROOT_PATH = "";
export const ACCOUNT_PATH = `${ROOT_PATH}/cuenta`;
export const TABS_ICONSMD = [
  { name: "webApps", label: "Aplicaciones web" },
  { name: "sciences", label: "Ciencias" },
  { name: "weathers", label: "Climas" },
  { name: "sports", label: "Deportes" },
  { name: "directional", label: "Direccionales" },
  { name: "emojis", label: "Emojis" },
  { name: "general", label: "Generales" },
  { name: "logos", label: "Logotipos" },
  { name: "medical", label: "Médicos" },
  { name: "multimedia", label: "Multimedias" },
  { name: "others", label: "Otros" },
  { name: "social", label: "Sociales" },
  { name: "transports", label: "Transportes" }
];
export const palette = {
  primary: {
    light: process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
    main: process.env.REACT_APP_COLOR_PRIMARY_MAIN,
    dark: process.env.REACT_APP_COLOR_PRIMARY_DARK,
    contrastText: process.env.REACT_APP_COLOR_PRIMARY_CONTRASTTEXT
  },
  secondary: {
    light: process.env.REACT_APP_COLOR_SECONDARY_LIGHT,
    main: process.env.REACT_APP_COLOR_SECONDARY_MAIN,
    dark: process.env.REACT_APP_COLOR_SECONDARY_DARK,
    contrastText: process.env.REACT_APP_COLOR_SECONDARY_CONTRASTTEXT
  }
};
export const ICONSMD_CATEGORY = {
  webApps: [
    "Home Automation",
    "Settings",
    "View",
    "Hardware / Tools",
    "Battery",
    "Notification",
    "Text / Content / Format",
    "Color",
    "Cloud",
    "Printer",
    "Developer / Languages",
    "Files / Folders",
    "Lock",
    "Edit / Modify",
    "Form",
    "Tooltip"
  ],
  sciences: ["Science", "Math"],
  weathers: ["Weather"],
  sports: ["Sport"],
  directional: ["Arrow", "Vector"],
  emojis: ["Emoji"],
  general: [
    "Account / User",
    "Alert / Error",
    "Arrange",
    "Date / Time",
    "Banking",
    "Agriculture",
    "Food / Drink",
    "Shopping",
    "Religion",
    "Animal",
    "Clothing",
    "Nature",
    "Holiday",
    "Currency",
    "Shape",
    "Drawing / Art"
  ],
  logos: ["Brand / Logo"],
  medical: ["Medical / Hospital"],
  multimedia: [
    "Audio",
    "Cellphone / Phone",
    "Video / Movie",
    "Photography",
    "Device / Tech"
  ],
  social: ["Social Media"],
  transports: [
    "Transportation + Other",
    "Transportation + Flying",
    "Navigation",
    "Places",
    "Transportation + Road",
    "Transportation + Water"
  ]
};
export const ACTIONS = {
  ADD_ANNOUNCEMENT_USERS_STORE: "ADD_ANNOUNCEMENT_USERS_STORE",
  ADD_ANNOUNCEMENTS_ADMIN_STORE: "ADD_ANNOUNCEMENTS_ADMIN_STORE",
  ADD_ANNOUNCEMENTS_STORE: "ADD_ANNOUNCEMENTS_STORE",
  ADD_APPS_STORE: "ADD_APPS_STORE",
  ADD_AUTOMATIC_USER_STORE: "ADD_AUTOMATIC_USER_STORE",
  AUTHENTICATION: "AUTHENTICATION",
  ADD_CATEGORIES_STORE: "ADD_CATEGORIES_STORE",
  ADD_CATEGORIESLIST_STORE: "ADD_CATEGORIESLIST_STORE",
  ADD_GLOBAL_PROFILES_STORE: "ADD_GLOBAL_PROFILES_STORE",
  ADD_HEAD_NOTIFICATIONS_STORE: "ADD_HEAD_NOTIFICATIONS_STORE",
  ADD_NOTIFICATIONS_STORE: "ADD_NOTIFICATIONS_STORE",
  ADD_TYPES_STORE: "ADD_TYPES_STORE",
  ADD_WIDGETS_STORE: "ADD_WIDGETS_STORE",
  ADD_FULL_LAYOUTS_STORE: "ADD_FULL_LAYOUTS_STORE",
  ADD_FUNCTIONALITY_PEOPLE_STORE: "ADD_FUNCTIONALITY_PEOPLE_STORE",
  ADD_FUNCTIONALITY_USERS_STORE: "ADD_FUNCTIONALITY_USERS_STORE",
  ADD_LAYOUTS_STORE: "ADD_LAYOUTS_STORE",
  ADD_PARENT_CATEGORIES_STORE: "ADD_PARENT_CATEGORIES_STORE",
  ADD_PROFILE_STORE: "ADD_PROFILE_STORE",
  ADD_ROLES_STORE: "ADD_ROLES_STORE",
  ADD_ROLE_APPLICATIONS_STORE: "ADD_ROLE_APPLICATIONS_STORE",
  ADD_USER_STORE: "ADD_USER_STORE",
  ADD_IMAGE_USER_PROFILE: "ADD_IMAGE_USER_PROFILE",
  ASOCIATE_APPLICATION_CATE: "ASOCIATE_APPLICATION_CATE",
  ASSOCIATE_FUNCTIONALITIES_TO_ROLE: "ASSOCIATE_FUNCTIONALITIES_TO_ROLE",
  ASSOCIATE_USER_TO_SECURITY_ROLES: "ASSOCIATE_USER_TO_SECURITY_ROLES",
  CHANGE_BREAKPOINT: "CHANGE_BREAKPOINT",
  CHECKED_EDIT_DASHBOARD: "CHECKED_EDIT_DASHBOARD",
  CHECK_COLOR_GRAD_WIDGET: "CHECK_COLOR_GRAD_WIDGET",
  CHECK_COLOR_ALT_CARD_WIDGET: "CHECK_COLOR_ALT_CARD_WIDGET",
  CHECK_IFRAME_WIDGET: "CHECK_IFRAME_WIDGET",
  CHECK_CHART_LEGEND: "CHECK_CHART_LEGEND",
  CHECK_CHART_TRENDLINES: "CHECK_CHART_TRENDLINES",
  CHECK_CHART_STACKED: "CHECK_CHART_STACKED",
  CHECK_CHART_PIEHOLE: "CHECK_CHART_PIEHOLE",
  CHECK_REMEMBER_USER: "CHECK_REMEMBER_USER",
  CONFIRM_RESET_PASSWORD: "CONFIRM_RESET_PASSWORD",
  DOWNLOAD_ANNOUNCEMENTS_ADMIN: "DOWNLOAD_ANNOUNCEMENTS_ADMIN",
  DOWNLOAD_APPLICATIONS: "DOWNLOAD_APPLICATIONS",
  DOWNLOAD_FUNCTIONALITIES_BY_APP_AND_PARENT:
    "DOWNLOAD_FUNCTIONALITIES_BY_APP_AND_PARENT",
  DOWNLOAD_NOTIFICATIONS: "DOWNLOAD_NOTIFICATIONS",
  DOWNLOAD_PARENT_CATEGORIES: "DOWNLOAD_PARENT_CATEGORIES",
  DOWNLOAD_PROGRAM_UNITS: "DOWNLOAD_PROGRAM_UNITS",
  DOWNLOAD_PROFILES: "DOWNLOAD_PROFILES",
  DOWNLOAD_ROLES: "DOWNLOAD_ROLES",
  DOWNLOAD_ROLE_APPLICATIONS: "DOWNLOAD_ROLE_APPLICATIONS",
  DOWNLOAD_ROOT_FUNCTIONALITIES_BY_APP: "DOWNLOAD_ROOT_FUNCTIONALITIES_BY_APP",
  DOWNLOAD_SECURITY_ROLES_ADMIN_FUNC: "DOWNLOAD_SECURITY_ROLES_ADMIN_FUNC",
  DOWNLOAD_UNITS: "DOWNLOAD_UNITS",
  DOWNLOAD_USER_PROFILES: "DOWNLOAD_USER_PROFILES",
  DOWNLOAD_USER_ROLES: "DOWNLOAD_USER_ROLES",
  DOWNLOAD_USERS_ADMIN_FUNC: "DOWNLOAD_USERS_ADMIN_FUNC",
  DOWNLOAD_WIDGETS: "DOWNLOAD_WIDGETS",
  DELETE_ANNOUNCEMENT: "DELETE_ANNOUNCEMENT",
  DELETE_LIST_CATEGORIES: "DELETE_LIST_CATEGORIES",
  DELETE_PROFILE_IMAGE: "DELETE_PROFILE_IMAGE",
  DELETE_ROLE: "DELETE_ROLE",
  DELETE_WIDGET: "DELETE_WIDGET",
  DISABLE_REMEMBER_USER: "DISABLE_REMEMBER_USER",
  DOWNLOAD_GLOBAL_PROFILES: "DOWNLOAD_GLOBAL_PROFILES",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FIND_ANNOUNCEMENT_USER_BY_DOCUMENT: "FIND_ANNOUNCEMENT_USER_BY_DOCUMENT",
  FIND_ANNOUNCEMENT_USERS_BY_NAME: "FIND_ANNOUNCEMENT_USERS_BY_NAME",
  FIND_EXISTING_USER: "FIND_EXISTING_USER",
  FIND_PERSON_BY_DOCUMENT: "FIND_PERSON_BY_DOCUMENT",
  FIND_PEOPLE_BY_PARAMS: "FIND_PEOPLE_BY_PARAMS",
  FIND_USER_BY_DOCUMENT: "FIND_USER_BY_DOCUMENT",
  FIND_USERS_BY_PARAMS: "FIND_USERS_BY_PARAMS",
  INSERT_ANNOUNCEMENT: "INSERT_ANNOUNCEMENT",
  INSERT_ROLE: "INSERT_ROLE",
  INSERT_WIDGET: "INSERT_WIDGET",
  LIST_APPLICATION_PAGINATE: "LIST_APPLICATION_PAGINATE",
  LIST_APPLICATION_PAGINATE_STORE: "LIST_APPLICATION_PAGINATE_STORE",
  LIST_CATEGORIES: "LIST_CATEGORIES",
  LIST_CATEGORIES_CAT: "LIST_CATEGORIES_CAT",
  LOAD_ANNOUNCEMENTS: "LOAD_ANNOUNCEMENTS",
  LOAD_HEADER_NOTIFICATIONS: "LOAD_HEADER_NOTIFICATIONS",
  LOAD_OPERATION: "LOAD_OPERATION",
  LOGOUT: "LOGOUT",
  NEW_CATEGORY: "NEW_CATEGORY",
  OPEN_DIALOG_WIDGET: "OPEN_DIALOG_WIDGET",
  PROCESS_AUTOMATIC_USER: "PROCESS_AUTOMATIC_USER",
  RANGE_TO_CHART_DATA: "RANGE_TO_CHART_DATA",
  RESET_PASSWORD: "RESET_PASSWORD",
  SAVE_USER: "SAVE_USER",
  SET_MESSAGE_ALERT: "SET_MESSAGE_ALERT",
  SET_MESSAGE_NOTIFICATION: "SET_MESSAGE_NOTIFICATION",
  SET_OPEN_DIALOG_WIDGET: "SET_OPEN_DIALOG_WIDGET",
  SET_OPEN_ALERT_SUCCESS: "SET_OPEN_ALERT_SUCCESS",
  SET_OPEN_ALERT_WARNING: "SET_OPEN_ALERT_WARNING",
  SET_OPEN_ALERT_ERROR: "SET_OPEN_ALERT_ERROR",
  SET_OPEN_ALERT_GENERAL: "SET_OPEN_ALERT_GENERAL",
  SET_OPEN_NOTIFICATION_SUCCESS: "SET_OPEN_NOTIFICATION_SUCCESS",
  SET_OPEN_NOTIFICATION_WARNING: "SET_OPEN_NOTIFICATION_WARNING",
  SET_OPEN_NOTIFICATION_ERROR: "SET_OPEN_NOTIFICATION_ERROR",
  SET_OPEN_NOTIFICATION_GENERAL: "SET_OPEN_NOTIFICATION_GENERAL",
  SET_COLOR_CARD: "SET_COLOR_CARD",
  SET_COLOR_CARD_A: "SET_COLOR_CARD_A",
  SET_COLOR_CARD_B: "SET_COLOR_CARD_B",
  SET_CHART_TYPE: "SET_CHART_TYPE",
  SET_CHART_DATA: "SET_CHART_DATA",
  SET_CHART_RANGE_NAME: "SET_CHART_RANGE_NAME",
  SET_HEAD_NOTIFICATION: "SET_HEAD_NOTIFICATION",
  SET_LIMIT_WIDGETS: "SET_LIMIT_WIDGETS",
  SET_WIDGET_UPDATE: "SET_WIDGET_UPDATE",
  SET_ALERT_DONE: "SET_ALERT_DONE",
  SET_SUBMITTING: "SET_SUBMITTING",
  SET_CHANGE_WIDGET: "SET_CHANGE_WIDGET",
  SET_VIEW_GRID: "SET_VIEW_GRID",
  SET_PRISTINE: "SET_PRISTINE",
  SET_PRISTINE_GRAPH: "SET_PRISTINE_GRAPH",
  SET_VIEW_CONTENT_DIALOG: "SET_VIEW_CONTENT_DIALOG",
  SET_TYPE_WIDGET: "SET_TYPE_WIDGET",
  SET_DEGREED_CARD_WIDGET: "SET_DEGREED_CARD_WIDGET",
  SET_TITLE_WIDGET: "SET_TITLE_WIDGET",
  SET_CATEGORY_WIDGET: "SET_CATEGORY_WIDGET",
  SET_CONTENT_WIDGET: "SET_CONTENT_WIDGET",
  SET_ICON_CARD_WIDGET: "SET_ICON_CARD_WIDGET",
  SET_ICONS_CARD_WIDGET: "SET_ICONS_CARD_WIDGET",
  SET_OPEN_DIALOG_ICON_CARD_WIDGET: "SET_OPEN_DIALOG_ICON_CARD_WIDGET",
  SET_VALUE_TAB_ICON_CARD_WIDGET: "SET_VALUE_TAB_ICON_CARD_WIDGET",
  UPDATE_APPLICATION: "UPDATE_APPLICATION",
  UPDATE_ANNOUNCEMENT: "UPDATE_ANNOUNCEMENT",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_LAYOUTS: "UPDATE_LAYOUTS",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_SOME_DATA_USER: "UPDATE_SOME_DATA_USER",
  UPDATE_USER_STATUS: "UPDATE_USER_STATUS",
  UPDATE_WIDGET: "UPDATE_WIDGET",
  UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",
  USER_LOGOUT: "USER_LOGOUT",
  VALIDATE_APPLICATION: "VALIDATE_APPLICATION"
};
export const GOOGLE_COLORS = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#3366cc",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411"
];
