import React from 'react';
import PropTypes from 'prop-types';

const LayoutItem = props => {
  const { children, ...rest } = props;

  return (
    <div {...rest} >
      {children}
    </div>
  );
};

LayoutItem.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutItem;