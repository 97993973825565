import { ACTIONS } from "../constants";

export const globalProfilesREDU = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.ADD_GLOBAL_PROFILES_STORE:
      return action.profiles;
    default:
      return state;
  }
};
