/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { isEmpty } from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/icons components
import { Avatar, Icon } from "@material-ui/core";
import Dashboard from "@material-ui/icons/Dashboard";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import PerfectScrollbar from "perfect-scrollbar";
import { activeRoute, getFirstName } from "../../helpers/functions";
import { ACCOUNT_PATH } from "../../constants";
import { USER_FUNCTIONALITIES } from "../../constants/userFunctionalities";
import CircularIndeterminate from "components/Progress/CircularIndeterminate";

const useStyles = makeStyles(styles);

const renderFunctionalityUser = (user, classes, color, userImage) => {
  const splitName = user.nombre && user.nombre.trim().split(" ");
  const firstName = getFirstName(splitName);
  let listItemClasses = classNames({
    [" " + classes[color]]: activeRoute(ACCOUNT_PATH + "/perfil")
  });

  // Active aside button
  let whiteFontClasses = classNames({
    [" " + classes.customFont]: activeRoute(ACCOUNT_PATH + "/perfil")
  });

  return (
    <NavLink
      to={ACCOUNT_PATH + "/perfil"}
      className={classes.item + " custom-profile"}
      activeClassName="active"
    >
      <ListItem button className={classes.itemLink + listItemClasses}>
        {null !== userImage ? (
          <Avatar
            alt={firstName}
            src={!isEmpty(userImage) ? userImage.src : "/broken-image.jpg"}
            className={classes.avatarSmall}
          />
        ) : (
          <Avatar className={classes.avatarSmall}>
            <CircularIndeterminate className="loader-avatar-sidebar" />
          </Avatar>
        )}
        <ListItemText
          primary={firstName}
          className={classNames(classes.itemText, whiteFontClasses)}
          disableTypography={true}
        />
      </ListItem>
    </NavLink>
  );
};

const renderRestUserFunctionalities = (classes, color) => {
  return USER_FUNCTIONALITIES.map(prop => {
    let activePro = " ";

    let listItemClasses = classNames({
      [" " + classes[color]]: activeRoute(ACCOUNT_PATH + prop.path)
    });

    // Active aside button
    let whiteFontClasses = classNames({
      [" " + classes.customFont]: activeRoute(ACCOUNT_PATH + prop.path)
    });

    return (
      <NavLink
        to={ACCOUNT_PATH + prop.path}
        className={activePro + classes.item}
        activeClassName="active"
        key={prop.name}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          <Icon className={classNames(classes.itemIcon, whiteFontClasses)}>
            {prop.icon}
          </Icon>
          <ListItemText
            primary={prop.name}
            className={classNames(classes.itemText, whiteFontClasses)}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    );
  });
};

const renderFunctionalitiesPerfil = (user, classes, color) => {
  if (user.perfiles && user.perfiles.length > 0) {
    return user.perfiles.map(prop => {
      let activePro = " ";

      let listItemClasses = classNames({
        [" " + classes[color]]: activeRoute(ACCOUNT_PATH + prop.path)
      });

      // Active aside button
      let whiteFontClasses = classNames({
        [" " + classes.customFont]: activeRoute(ACCOUNT_PATH + prop.path)
      });

      return (
        <NavLink
          to={ACCOUNT_PATH + prop.path}
          className={activePro + classes.item}
          activeClassName="active"
          key={prop.id}
        >
          <ListItem button className={classes.itemLink + listItemClasses}>
            <Dashboard
              className={classNames(classes.itemIcon, whiteFontClasses)}
            />
            <ListItemText
              primary={prop.nombre}
              className={classNames(classes.itemText, whiteFontClasses)}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      );
    });
  } else {
    return null;
  }
};

const renderFunctionalitiesAdmin = (user, classes, color) => {
  if (user.funcionalidades && user.funcionalidades.length > 0) {
    return user.funcionalidades.map(prop => {
      let activePro = " ";

      let listItemClasses = classNames({
        [" " + classes[color]]: activeRoute(ACCOUNT_PATH + prop.urlRecurso)
      });

      // Active aside button
      let whiteFontClasses = classNames({
        [" " + classes.customFont]: activeRoute(ACCOUNT_PATH + prop.urlRecurso)
      });

      return (
        <NavLink
          to={ACCOUNT_PATH + prop.urlRecurso}
          className={activePro + classes.item}
          activeClassName="active"
          key={prop.id}
        >
          <ListItem button className={classes.itemLink + listItemClasses}>
            <Icon className={classNames(classes.itemIcon, whiteFontClasses)}>
              {prop.urlImagen}
            </Icon>
            <ListItemText
              primary={prop.nombre}
              className={classNames(classes.itemText, whiteFontClasses)}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      );
    });
  } else {
    return null;
  }
};

function Sidebar(props) {
  const classes = useStyles();
  const { color, logo, image, logoText, location, user, profileImages } = props;

  var links = (
    <List className={classes.list}>
      {renderFunctionalityUser(user, classes, color, profileImages.profile)}
      {renderRestUserFunctionalities(classes, color)}
      {renderFunctionalitiesPerfil(user, classes, color)}
      {renderFunctionalitiesAdmin(user, classes, color)}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="http://www.unipamplona.edu.co"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );

  // ref to help us initialize PerfectScrollbar on windows devices
  const sidebarPanel = React.createRef();
  let ps;
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.userAgent.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.userAgent.indexOf("Win") > -1) {
        ps.destroy();
        ps = null;
      }
    };
  }, [sidebarPanel]);

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? (
              <RTLNavbarLinks />
            ) : (
              <AdminNavbarLinks pathname={location.pathname} user={user} />
            )}
            {user.id && links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper} ref={sidebarPanel}>
            {user.id && links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  color: PropTypes.string,
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  // routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  location: PropTypes.object,
  user: PropTypes.object,
  profileImages: PropTypes.object
};

const mapStateToProps = state => {
  return {
    profileImages: state.imagesUserREDU
  };
};

export default connect(
  mapStateToProps,
  null
)(Sidebar);
