import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import AutoProcessContent from "./AutoProcessContent";
import AutoProcessAction from "./AutoProcessAction";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "rgba(255, 152, 0, 0.38)",
    color: theme.palette.warning.main,
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      maxWidth: 480
    }
  },
  section1: {
    padding: theme.spacing(3, 2)
  }
}));

function AutoProcessWarning(props) {
  const { automaticUser, selectedPrograms } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.section1}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h4">
              Terminado
            </Typography>
          </Grid>
        </Grid>
        <AutoProcessContent
          automaticUser={automaticUser}
          selectedPrograms={selectedPrograms}
        />
      </div>
      <AutoProcessAction
        automaticUser={automaticUser}
        title={automaticUser.fileWithoutBackup}
        buttonColor="warning"
      />
    </div>
  );
}

AutoProcessWarning.propTypes = {
  automaticUser: PropTypes.object.isRequired,
  selectedPrograms: PropTypes.array.isRequired
};

export default AutoProcessWarning;
