import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles, Hidden, IconButton } from "@material-ui/core";
// @material-ui/icons
// import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-react.js";
import "../../assets/css/react-grid-layout.css";
import GridItem from "components/Grid/GridItem";
import Linkify from "linkifyjs/react";
import GridContainer from "components/Grid/GridContainer";
import CustomTooltip from "helpers/CustomTooltip";
import AlertDialog from "components/Dialog/AlertDialog";

const useStyles = makeStyles(styles);

const General = props => {
  const classes = useStyles();
  const {
    id,
    color,
    isAlternative,
    titulo,
    categoria,
    contenido,
    isIframe,
    fromDash,
    cssRegHover,
    cssTransition,
    handleDeleteWidget,
    handleClickOpenDialog,
    ...rest
  } = props;
  const styles = {
    contentFrame: {
      height: "100%"
    },
    iconEdi: {
      color: successColor[2]
    },
    iconDel: {
      color: dangerColor[2]
    }
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showActions, setShowActions] = React.useState(false);
  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleAccept = () => {
    handleDeleteWidget(id);
    handleCloseAlert();
  };

  let cardHeaderClassName =
    fromDash && showActions
      ? `card-header-layout ${cssTransition} touch-translate-y`
      : `card-header-layout ${cssTransition} touch-translate-reset`;

  useEffect(() => {
    let timer = setTimeout(() => {
      if (showActions) {
        setShowActions(false);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showActions]);

  return (
    <Card className="card-layout">
      <div className={`${cssRegHover} header-view-btns`}>
        <CardHeader
          color="danger"
          className={cardHeaderClassName}
          style={color}
          onClick={() => {
            setShowActions(showActions => !showActions);
          }}
        >
          <h4
            className={
              (isAlternative
                ? classes.cardTitleBlack
                : classes.cardTitleWhite) + " truncate-h-general"
            }
          >
            {titulo && "" !== titulo.trim() ? titulo : "Título"}
          </h4>
          <p
            className={
              (isAlternative
                ? classes.cardCategoryBlack
                : classes.cardCategoryWhite) + " truncate-h-general"
            }
          >
            {categoria && "" !== categoria.trim() ? categoria : "Categoría"}
          </p>
        </CardHeader>
        {fromDash && (
          <div className="general-icons">
            <CustomTooltip title="Editar" style={styles.iconEdi}>
              <IconButton
                aria-label="editar-widget"
                style={styles.iconEdi}
                onClick={() => handleClickOpenDialog(id)}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Eliminar" style={styles.iconDel}>
              <IconButton
                aria-label="borrar-widget"
                style={styles.iconDel}
                onClick={handleClickOpenAlert}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          </div>
        )}
      </div>
      <CardBody className="card-body-layout-med" {...rest}>
        {isIframe && fromDash ? (
          <GridContainer style={styles.contentFrame}>
            <Hidden only={["sm", "xs"]}>
              <GridItem md={12}>
                <iframe
                  className={classes.iframe}
                  src={contenido}
                  title="Mi sitio en Vortal"
                  sandbox="allow-same-origin allow-scripts allow-presentation"
                  referrerPolicy="strict-origin"
                >
                  <p>El navegador no soporta iframes.</p>
                </iframe>
              </GridItem>
            </Hidden>
            <Hidden only={["lg", "md"]}>
              <GridItem xs={12} sm={12} md={6}>
                <h5>
                  Dado que el iframe no funciona en dispositivos móviles y
                  tabletas, visita:&nbsp;
                  <a href={contenido} target="_blank" rel="noopener noreferrer">
                    {contenido}
                  </a>
                </h5>
              </GridItem>
            </Hidden>
          </GridContainer>
        ) : (
          <Linkify>
            {contenido && "" !== contenido.trim() ? contenido : "Contenido"}
          </Linkify>
        )}
      </CardBody>
      <AlertDialog
        content="¿Deseas eliminar el widget?"
        open={openAlert}
        handleClose={handleCloseAlert}
        handleAccept={handleAccept}
      />
    </Card>
  );
};

General.propTypes = {
  id: PropTypes.string,
  color: PropTypes.object.isRequired,
  isAlternative: PropTypes.bool,
  titulo: PropTypes.string.isRequired,
  categoria: PropTypes.string.isRequired,
  contenido: PropTypes.string.isRequired,
  isIframe: PropTypes.bool,
  fromDash: PropTypes.bool.isRequired,
  cssRegHover: PropTypes.string,
  cssTransition: PropTypes.string,
  handleDeleteWidget: PropTypes.func,
  handleClickOpenDialog: PropTypes.func
};

export default General;
