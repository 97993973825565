import { ACTIONS } from "../constants";

export const notificationsREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_NOTIFICATIONS_STORE:
      return action.notifications;
    default:
      return state;
  }
};

const initHeaderNoti = {
  data: null, // []
  selected: null // {}
};
export const headerNotificationsREDU = (state = initHeaderNoti, action) => {
  switch (action.type) {
    case ACTIONS.ADD_HEAD_NOTIFICATIONS_STORE:
      return { ...state, data: action.notifications };
    case ACTIONS.SET_HEAD_NOTIFICATION:
      return { ...state, selected: action.notification };
    default:
      return state;
  }
};

export const announcementsREDU = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.ADD_ANNOUNCEMENTS_STORE:
      return action.announcements;
    default:
      return state;
  }
};
