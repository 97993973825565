import { call, put, delay, select } from "redux-saga/effects";
import { isEmpty } from "lodash";
import { urlAplicaciones, urlCategorias } from "../api/urls";
import { apiGET, apiPUT, apiPOST, apiDELETE, apiGETParam } from "../api";
import {
  addUserStoreACTI,
  setMessageNotificationACTI,
  setOpenNotificationErrorACTI,
  setOpenNotificationSuccessACTI
} from "../actions";
import { onAuthStateChangedObj, userLogout } from "../api/access";
import {
  addCategoriesStoreCatACTI,
  listApplicationPaginateStoreACTI,
  addParentCategoriesStoreACTI
} from "../actions/categorias";
import { getObjectForPaginatedTable } from "../helpers/functions";

//listar categorias
const downloadCategories = (pageNo, pageSize, params) =>
  apiGETParam(
    `${urlCategorias}/pagina-sin-app/${pageNo + 1}/${pageSize}`,
    params
  ).then(categories => categories);

export function* sagaListCategoriesCat(action) {
  try {
    const params = {
      sortField: "nombre",
      sortDirection: "asc"
    };
    const categories = yield call(
      downloadCategories,
      action.params.pageNo,
      action.params.pageSize,
      params
    );
    yield put(addCategoriesStoreCatACTI(categories));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addCategoriesStoreCatACTI({ content: [] }));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

const downloadParentCategories = () =>
  apiGET(`${urlCategorias}/listar-padres`).then(categories => categories);

export function* sagaDownloadParentCategories(action) {
  try {
    const categories = yield call(downloadParentCategories);
    action.setParentCategories(categories);
    yield put(addParentCategoriesStoreACTI(categories));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addParentCategoriesStoreACTI([]));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

// /* ############################################################################################# */
// Insertar
const insertarCategoria = data =>
  apiPOST(`${urlCategorias}/agregar`, data).then(reponse => reponse);

const modifyParentCategory = (parentCategory, childrenCategory, operation) => {
  if (parentCategory.id === childrenCategory.idsuperior) {
    parentCategory.cantidadHijos = parentCategory.cantidadHijos + operation;
  }

  return parentCategory;
};

export function* sagaInsertCategoria(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      action.categoria.registradoPor = authState.data.id;
      const categoryAdded = yield call(insertarCategoria, action.categoria);
      const categories = yield select(state => state.categoriaListCatREDU);
      let newCategories;

      if (undefined === categories.empty || categories.empty) {
        newCategories = getObjectForPaginatedTable([categoryAdded]);
      } else {
        const categoriesList = [categoryAdded, ...categories.content];

        if (categoriesList.length > categories.size) {
          categoriesList.pop();
        }

        newCategories = {
          ...categories,
          totalElements: categories.totalElements + 1,
          content: categoriesList
        };
      }

      yield put(addCategoriesStoreCatACTI(newCategories));
      action.extraParams.setOpen(false);
      action.extraParams.setSubmitting(false);

      const parentCategories = action.extraParams.categoriesPadre;

      if (null === parentCategories) {
        throw String(
          "No se ha podido validar si hay categorías padres, por favor recarga la funcionalidad."
        );
      }

      // Actualiza en interfaz combo de categorías padres.
      if (null === categoryAdded.idsuperior) {
        categoryAdded.cantidadHijos = 0;
        action.extraParams.setCategoriesPadre([
          categoryAdded,
          ...parentCategories
        ]);
      } else {
        action.extraParams.setCategoriesPadre(
          parentCategories.map(category =>
            modifyParentCategory(category, categoryAdded, 1)
          )
        );
      }

      yield put(setMessageNotificationACTI());
      yield put(setOpenNotificationSuccessACTI(true));
      yield delay(6000);
      yield put(setOpenNotificationSuccessACTI(false));
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

//eliminar
const deleteCategoria = (id, objeto, ppk) =>
  apiDELETE(`${urlCategorias}/eliminar`, id, objeto, ppk).then(
    reponse => reponse
  );

export function* sagaDeleteCategoria(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      action.categoria.registradoPor = authState.data.id;
      yield call(deleteCategoria, action.categoria.id, action.categoria);
      const categories = yield select(state => state.categoriaListCatREDU);

      // Si la cantidad de registros totales se desplegan en una sola página, no hay necesidad de volver a consultar.
      if (categories.totalElements <= categories.size) {
        const categoriesContent = categories.content.filter(
          category => category.id !== action.categoria.id
        );

        if (isEmpty(categoriesContent)) {
          yield put(addCategoriesStoreCatACTI({ content: [] }));
        } else {
          const newCategories = getObjectForPaginatedTable(
            categoriesContent,
            categories.size,
            categories.number
          );
          yield put(addCategoriesStoreCatACTI(newCategories));
        }
      } else {
        // Se procede a realizar la consulta nuevamente para refrescar los registros con sus nuevos datos de paginación.
        const number =
          categories.number > 0 && 1 === categories.numberOfElements
            ? categories.number - 1
            : categories.number;
        action.extraParams.onChangePage(number, categories.size, number);
      }

      const parentCategories = action.extraParams.categoriesPadre;

      if (null === parentCategories) {
        throw String(
          "No se ha podido validar si hay categorías padres, por favor recarga la funcionalidad."
        );
      }

      // Actualiza en interfaz combo de categorías padres.
      if (null === action.categoria.idsuperior) {
        action.extraParams.setCategoriesPadre(
          parentCategories.filter(
            category => category.id !== action.categoria.id
          )
        );
      } else {
        action.extraParams.setCategoriesPadre(
          parentCategories.map(category =>
            modifyParentCategory(category, action.categoria, -1)
          )
        );
      }

      action.extraParams.setSubmitting(false);
      action.extraParams.handleCloseAlert();
      // Notificación de éxito.
      yield put(setMessageNotificationACTI());
      yield put(setOpenNotificationSuccessACTI(true));
      yield delay(6000);
      yield put(setOpenNotificationSuccessACTI(false));
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

// // actualizar
const actualizarCategoria = (data, id, ppk) =>
  apiPUT(`${urlCategorias}/editar`, id, data, ppk).then(reponse => reponse);

export function* sagaUpdateCategoria(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      action.categoria.registradoPor = authState.data.id;
      const categoryUpdated = yield call(
        actualizarCategoria,
        action.categoria,
        action.categoria.id
      );
      const categories = yield select(state => state.categoriaListCatREDU);
      const categoriesContent = categories.content.map(category =>
        category.id !== action.categoria.id ? category : categoryUpdated
      );
      const newCategories = {
        ...categories,
        content: categoriesContent
      };
      yield put(addCategoriesStoreCatACTI(newCategories));
      action.extraParams.setOpen(false);
      action.extraParams.setSubmitting(false);
      const parentCategories = action.extraParams.categoriesPadre;

      if (null === parentCategories) {
        throw String(
          "No se ha podido validar si hay categorías padres, por favor recarga la funcionalidad."
        );
      }

      const oldCategory = categories.content.find(
        category => category.id === categoryUpdated.id
      );

      // Actualiza en interfaz combo de categorías padres.
      if (null === categoryUpdated.idsuperior) {
        const existingCategory = parentCategories.find(
          category => category.id === categoryUpdated.id
        );

        if (existingCategory) {
          // No tenía padre, solo cambian los datos.
          categoryUpdated.cantidadHijos = existingCategory.cantidadHijos;
          action.extraParams.setCategoriesPadre(
            parentCategories.map(category =>
              category.id !== existingCategory.id ? category : categoryUpdated
            )
          );
        } else {
          // Se convierte en nuevo potencial padre; a su antiguo padre se le reduce un hijo.
          categoryUpdated.cantidadHijos = 0;
          const modifiedParentCategories = parentCategories.map(category =>
            modifyParentCategory(category, oldCategory, -1)
          );
          action.extraParams.setCategoriesPadre([
            categoryUpdated,
            ...modifiedParentCategories
          ]);
        }
      } else {
        let modifiedParentCategories;

        if (oldCategory.idsuperior !== categoryUpdated.idsuperior) {
          // Se ha modificado el padre; se reduce en uno el hijo del antiguo padre y se aumenta en uno el hijo del nuevo padre.
          modifiedParentCategories = parentCategories.map(category =>
            modifyParentCategory(category, oldCategory, -1)
          );
          modifiedParentCategories = modifiedParentCategories.map(category =>
            modifyParentCategory(category, categoryUpdated, 1)
          );
        } else {
          // No ha cambiado de padre, solo se modifican los datos.
          modifiedParentCategories = [...parentCategories];
          categoryUpdated.cantidadHijos = modifiedParentCategories.find(
            category => category.id === categoryUpdated.idsuperior
          ).cantidadHijos;
        }

        action.extraParams.setCategoriesPadre(
          modifiedParentCategories.filter(
            category => category.id !== categoryUpdated.id
          )
        );
      }

      yield put(setMessageNotificationACTI());
      yield put(setOpenNotificationSuccessACTI(true));
      yield delay(6000);
      yield put(setOpenNotificationSuccessACTI(false));
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

//listar aplicaciones paginado
const downloadApplications = (pageNo, pageSize, params) =>
  apiGETParam(
    `${urlAplicaciones}/pagina/${pageNo + 1}/${pageSize}`,
    params
  ).then(reponse => reponse);

export function* sagaListAplicacionesCat(action) {
  try {
    const params = {
      sortField: "nombre",
      sortDirection: "asc"
    };
    const applications = yield call(
      downloadApplications,
      action.params.pageNo,
      action.params.pageSize,
      params
    );
    yield put(listApplicationPaginateStoreACTI(applications));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(listApplicationPaginateStoreACTI({ content: [] }));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

const actualizarAplicacion = (data, id, ppk) =>
  apiPUT(`${urlAplicaciones}/editar-categoria`, id, data, ppk).then(
    reponse => reponse
  );

export function* sagaUpdateAplicacionCat(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      action.application.registradoPor = authState.data.id;
      const applicationUpdated = yield call(
        actualizarAplicacion,
        action.application,
        action.application.id
      );
      const applications = yield select(
        state => state.listApplicationPaginateCatREDU
      );
      const applicationsContent = applications.content.map(application =>
        application.id !== applicationUpdated.id
          ? application
          : applicationUpdated
      );
      const newApplications = {
        ...applications,
        content: applicationsContent
      };
      yield put(listApplicationPaginateStoreACTI(newApplications));
      action.extraParams.setOpen(false);
      action.extraParams.setSubmitting(false);
      yield put(setMessageNotificationACTI());
      yield put(setOpenNotificationSuccessACTI(true));
      yield delay(6000);
      yield put(setOpenNotificationSuccessACTI(false));
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}
