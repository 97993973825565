import React, { Component } from "react";
import PropTypes from "prop-types";
import { CustomPicker, SketchPicker } from "react-color";

import "../../assets/css/main.css";

class ColorPicker extends Component {
  state = {
    displayColorPicker: false
  };

  handleClick = () => {
    this.setState({ displayColorPicker: true });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const { color, handleChange, classCss } = this.props;
    const styles = {
      color: {
        width: "40px",
        height: "24px",
        borderRadius: "3px",
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
      },
      swatch: {
        padding: "3px",
        background: "#fff",
        borderRadius: "3px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer"
      },
      popover: {
        position: "absolute",
        zIndex: "4",
        top: "5%",
        right: "25%"
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px"
      }
    };

    return (
      <div className={classCss}>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={color}
              onChange={value => handleChange(value.rgb)}
              presetColors={[]}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  classCss: PropTypes.string
};

export default CustomPicker(ColorPicker);
