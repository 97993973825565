import React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Fab,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { downloadPDF } from "helpers/functions";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "rgba(255, 152, 0, 0.38)",
    color: theme.palette.warning.main,
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      maxWidth: 480
    }
  },
  section1: {
    padding: theme.spacing(3, 2)
  },
  section2: {
    padding: theme.spacing(2)
  },
  button: {
    textAlign: "center",
    marginTop: 10
  }
}));

const styles = {
  success: {
    backgroundColor: "#388e3c",
    color: "#fff"
  },
  warning: {
    backgroundColor: "#ff9800",
    color: "#fff"
  }
};

function AutoProcessAction(props) {
  const { automaticUser, title, buttonColor } = props;

  const classes = useStyles();

  const handleDownloadFile = () => {
    downloadPDF(automaticUser.report, automaticUser.reportName);
  };

  return (
    <>
      <Divider variant="middle" />
      <div className={classes.section2}>
        <Typography gutterBottom variant="body1">
          {title}
        </Typography>
        <div className={classes.button}>
          <Tooltip title="Descargar" aria-label="download-pdf">
            <Fab
              color="inherit"
              size="small"
              style={styles[buttonColor ? buttonColor : "success"]}
              onClick={handleDownloadFile}
            >
              <PictureAsPdfIcon />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </>
  );
}

AutoProcessAction.propTypes = {
  automaticUser: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  buttonColor: PropTypes.oneOf(["success", "warning"])
};

export default AutoProcessAction;
