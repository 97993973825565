import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

//material components
import { makeStyles } from "@material-ui/core/styles";

//components core
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import SelectFormik from "../../components/CustomInput/SelectFormik";

import CustomTable from "components/Table/Table.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import FormDialog from "../../components/Dialog/FormDialog";

//actions
import {
  listApplicationPaginateACTI,
  asociateApplicationCatACTI
} from "../../actions/categorias";
import { ROWS_PER_PAGE } from "../../constants/index.js";

function RenderForm({ application, categories }) {
  const index =
    !isEmpty(application) && null !== application.categoria
      ? categories.findIndex(
          category => category.id === application.categoria.id
        )
      : "";

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <SelectFormik
          label={"Categorías"}
          values={categories}
          selected={index}
          id="categoria"
          name="categoria"
          style={{ width: "100%", marginLeft: 0 }}
        ></SelectFormik>
      </GridItem>
    </GridContainer>
  );
}

RenderForm.propTypes = {
  application: PropTypes.object,
  categories: PropTypes.array.isRequired
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500
  },
  seccionAdmin: {
    marginTop: 0
  },
  labelApplication: {
    color: "#aaa",
    fontSize: 14
  },
  application: {
    fontWeight: 400
  }
});

const categoryApplicationTitleToolbar = {
  color: "textSecondary",
  content: "Asociar aplicaciones a categoría"
};

const columns = [
  { id: "nombre", label: "Nombre", minWidth: 70, width: "25%" },
  { id: "descripcion", label: "Descripción", minWidth: 75, width: "40%" },
  { id: "categoria", label: "Categoría", minWidth: 70, width: "25%" },
  {
    id: "actions",
    label: "Acciones",
    minWidth: 70,
    width: "10%",
    align: "center"
  }
];

const yupObject = {};

export const CategoryApplication = props => {
  const {
    categories,
    applications,
    downloadApplications,
    associateApplication
  } = props;
  const classes = useStyles2();
  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [applicationSelected, setApplicationSelected] = useState(null);
  const [rows, setRows] = useState(null);
  const [firstRender, setFirstRender] = useState(true);

  //asocia las aplicaciones a una categoria
  const asociateApliCate = (application, categories) => {
    const values = {
      categoria: ""
    };

    if (!isEmpty(categories) && null !== application.categoria) {
      const index = categories.findIndex(
        category => category.id === application.categoria.id
      );

      if (index >= 0) {
        values.categoria = index;
      }
    }

    setInitialValues(values);
    setApplicationSelected(application);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const extraParams = {
      setOpen,
      setSubmitting
    };
    const applicationUpdate = { ...applicationSelected };

    if (
      "" === values.categoria ||
      undefined === values.categoria ||
      null === values.categoria
    ) {
      applicationUpdate.categoria = null;
    } else {
      applicationUpdate.categoria = categories.content[values.categoria];
    }

    associateApplication(applicationUpdate, extraParams);
  };

  const getDataAplicacion = () => {
    return (
      applicationSelected && (
        <GridContainer className={classes.application}>
          <GridItem xs={12} md={2} className={classes.labelApplication}>
            Nombre
          </GridItem>
          <GridItem xs={12} md={10}>
            {applicationSelected.nombre}
          </GridItem>
        </GridContainer>
      )
    );
  };

  const onChangePage = (newPage, rowsPerPage) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage
    };
    setRows(null);
    downloadApplications(params);
  };

  const generateActions = useCallback((application, categories) => {
    return {
      type: "actions",
      values: [
        {
          title: "Asociar aplicación a categoría",
          icon: "app_registration",
          func: () => asociateApliCate(application, categories)
        }
      ]
    };
  }, []);

  const generateRows = useCallback(
    (applications, categories) => {
      if (!isEmpty(applications)) {
        const newRows = Array.from(applications, application =>
          createData(
            application.id,
            application.nombre,
            application.descripcion,
            application.categoria
              ? categories.find(
                  category => category.id === application.categoria.id
                ).nombre
              : " - ",
            generateActions(application, categories)
          )
        );
        setRows(newRows);
      } else {
        setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
      }
    },
    [generateActions]
  );

  function createData(id, nombre, descripcion, categoria, actions) {
    return { id, nombre, descripcion, categoria, actions };
  }

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE
    };

    if (undefined !== categories.content && null === rows && firstRender) {
      downloadApplications(params);
      setFirstRender(false);
    }
  }, [categories, rows, firstRender, downloadApplications]);

  useEffect(() => {
    if (
      undefined !== applications.content &&
      undefined !== categories.content
    ) {
      generateRows(applications.content, categories.content);
    }
  }, [applications, categories, generateRows]);

  return (
    <>
      <Card className={classes.seccionAdmin}>
        <CardBody>
          <CustomTable
            tableHeaderColor="primary"
            columns={columns}
            rows={rows}
            totalElements={
              isEmpty(applications) ? 0 : applications.totalElements
            }
            action="click"
            titleToolbar={categoryApplicationTitleToolbar}
            onChangePage={onChangePage}
          />
        </CardBody>
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        title="Aplicación"
        width="sm"
        initialValues={initialValues}
        yupObject={yupObject}
        handleSubmit={handleSubmit}
      >
        {getDataAplicacion()}
        <RenderForm
          application={applicationSelected}
          categories={
            undefined !== categories.content ? categories.content : []
          }
        />
      </FormDialog>
    </>
  );
};

CategoryApplication.propTypes = {
  categories: PropTypes.object.isRequired,
  applications: PropTypes.object.isRequired,
  downloadApplications: PropTypes.func.isRequired,
  associateApplication: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    categories: state.categoriaListCatREDU,
    applications: state.listApplicationPaginateCatREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadApplications: params => {
      dispatch(listApplicationPaginateACTI(params));
    },
    associateApplication: (application, extraParams) => {
      dispatch(asociateApplicationCatACTI(application, extraParams));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryApplication);
