import { ACTIONS } from "../constants";

export const downloadNotificationsACTI = params => ({
  type: ACTIONS.DOWNLOAD_NOTIFICATIONS,
  params
});

export const addNotificationsStoreACTI = notifications => ({
  type: ACTIONS.ADD_NOTIFICATIONS_STORE,
  notifications
});

export const loadHeaderNotificationsACTI = () => ({
  type: ACTIONS.LOAD_HEADER_NOTIFICATIONS
});

export const addHeaderNotificationsStoreACTI = notifications => ({
  type: ACTIONS.ADD_HEAD_NOTIFICATIONS_STORE,
  notifications
});

export const setHeaderNotificationACTI = notification => ({
  type: ACTIONS.SET_HEAD_NOTIFICATION,
  notification
});

// A fecha de 29-01-2021 esta action no se está usando.
export const loadAnnouncementsACTI = () => ({
  type: ACTIONS.LOAD_ANNOUNCEMENTS
});

export const addAnnouncementsStoreACTI = announcements => ({
  type: ACTIONS.ADD_ANNOUNCEMENTS_STORE,
  announcements
});
