import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import * as Yup from "yup";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core";
// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomTable from "components/Table/Table";
import FormDialog from "components/Dialog/FormDialog";
import AlertDialog from "components/Dialog/AlertDialog";
import ManageAd from "./ManageAd";
import { ROWS_PER_PAGE } from "../../constants";
import {
  addAnnouncementsAdminStoreACTI,
  addAnnouncementUsersStoreACTI,
  deleteAnnouncementACTI,
  downloadAnnouncementsAdminACTI,
  insertAnnouncementACTI,
  updateAnnouncementACTI
} from "../../actions/announcements";
import ViewNotification from "views/Notifications/ViewNotification";
import { openNewWindow } from "../../helpers/functions";

moment.locale("es-mx");

const styles = {
  card: { marginTop: 10 },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};
const useStyles = makeStyles(styles);

const columns = [
  { id: "content", label: "Tema", minWidth: 170, width: "70%" },
  { id: "initialDate", label: "F. Inicial", minWidth: 75, width: "10%" },
  { id: "finalDate", label: "F. Final", minWidth: 75, width: "10%" },
  {
    id: "actions",
    label: "Acciones",
    minWidth: 70,
    width: "10%",
    align: "center"
  }
];

function createData(id, content, initialDate, finalDate, actions) {
  return { id, content, initialDate, finalDate, actions };
}

const defaultValues = {
  topic: "",
  initialDate: null,
  finalDate: null,
  content: "",
  link: "",
  banner: false
};

const schemaManageAd = {
  topic: Yup.string().max(2000, "Máximo 2000 caracteres"),
  initialDate: Yup.date()
    .nullable()
    .required("Requerido"),
  finalDate: Yup.date()
    .nullable()
    .required("Requerido")
    .min(Yup.ref("initialDate"), "No debe ser menor a fecha inicio"),
  content: Yup.string().required("Requerido"),
  link: Yup.string()
    .trim()
    .url("El enlace no está correcto")
    .matches(
      // eslint-disable-next-line no-irregular-whitespace
      /(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i,
      "El enlace no está correcto (https)"
    )
    .max(4000, "Máximo 4000 caracteres"),
  banner: Yup.boolean()
};

const handleClickLaunch = link => {
  openNewWindow(link, "_blank");
};

const alertFormDialog = {
  title: "Gestionar anuncio",
  content: "¿Deseas continuar con el proceso?"
};

function Announcements(props) {
  const classes = useStyles();
  const {
    announcements,
    downloadAnnouncements,
    setAnnouncements,
    insertAnnouncement,
    updateAnnouncement,
    setUsers,
    deleteAnnouncement
  } = props;
  const [initialValues, setInitialValues] = useState({});
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [recipientType, setRecipientType] = useState("profiles");
  const [recipients, setRecipients] = useState([]);
  const [recipientsInitial, setRecipientsInitial] = useState([]);
  const [rows, setRows] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [topic, setTopic] = useState(null);
  const [content, setContent] = useState("");
  const [link, setLink] = useState(null);
  const [announcementEdit, setAnnouncementEdit] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [pristine, setPristine] = useState(false);
  const [page, setPage] = useState(undefined);

  const actionsToolbar = [
    // {
    //   ariaLabel: "filter list",
    //   color: "primary",
    //   func: () => {
    //     console.log("filter list click!");
    //   },
    //   icon: "filter_list",
    //   title: "Filtrar lista"
    // },
    {
      ariaLabel: "add announcement",
      color: "primary",
      func: () => {
        setInitialValues(defaultValues);
        setOpen(true);
      },
      icon: "playlist_add",
      title: "Agregar anuncio"
    }
  ];

  const handleOpenDialog = announcement => {
    setTopic(announcement.tema ? announcement.tema : "(Sin asunto)");
    setContent(announcement.contenido);
    setLink(announcement.enlace);
    setOpenView(true);
  };

  const handleCloseDialog = () => {
    setOpenView(false);
  };

  const handleOpenEdit = announcement => {
    setAnnouncementEdit(announcement);
    const values = {
      topic: null !== announcement.tema ? announcement.tema : "",
      initialDate: moment(announcement.fechaInicial),
      finalDate: moment(announcement.fechaFinal),
      content: announcement.contenido,
      link: null !== announcement.enlace ? announcement.enlace : "",
      banner: 1 === announcement.banner ? true : false
    };
    setInitialValues(values);

    if (!isEmpty(announcement.usuarios)) {
      setRecipientType("users");
      const recipientsList = announcement.usuarios.map(user => ({
        id: user.usuario.id,
        name: user.usuario.documento + " - " + user.usuario.nombre
      }));
      setRecipients(recipientsList);
      setRecipientsInitial(recipientsList);
    } else {
      const recipientsList = announcement.perfiles.map(profile => ({
        id: profile.perfil.id,
        name: profile.perfil.nombre
      }));
      setRecipients(recipientsList);
      setRecipientsInitial(recipientsList);
    }

    setOpen(true);
  };

  const handleCloseAlert = () => {
    setAnnouncementEdit(null);
    setOpenAlert(false);
  };

  const onChangePage = (newPage, rowsPerPage, newPosition) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage
    };
    setRows(null);
    downloadAnnouncements(params);

    if (undefined !== newPosition) {
      setPage(newPosition);
    }
  };

  const handleAccept = () => {
    if ("delete" === announcementEdit.onAction) {
      setSubmitting(true);
      const extraParams = {
        setSubmitting,
        onChangePage,
        handleCloseAlert
      };
      deleteAnnouncement({ id: announcementEdit.id }, extraParams);
    }
  };

  const handleOpenAlert = announcement => {
    setAnnouncementEdit(announcement);
    setOpenAlert(true);
  };

  const generateActions = useCallback(announcement => {
    return {
      type: "actions",
      values: [
        {
          title: "Ver",
          icon: "visibility",
          func: () => handleOpenDialog(announcement)
        },
        {
          title: "Modificar",
          icon: "mode_edit",
          func: () => handleOpenEdit(announcement)
        },
        {
          title: "Eliminar",
          icon: "delete_forever",
          func: () => handleOpenAlert({ ...announcement, onAction: "delete" })
        }
      ]
    };
  }, []);

  const addRecipient = data => {
    let newRecipients = [];
    const newRecipient = {
      id: data.id,
      name: data.nombre
    };

    if (!isEmpty(data.documento)) {
      newRecipient.name = data.documento + " - " + data.nombre;
    }

    if (isEmpty(recipients)) {
      newRecipients.push(newRecipient);
    } else {
      const oldRecipient = recipients.find(
        recipient => recipient.id === newRecipient.id
      );

      if (!isEmpty(oldRecipient)) {
        newRecipients = recipients.filter(
          recipient => recipient.id !== oldRecipient.id
        );
      } else {
        newRecipients = [...recipients, newRecipient];
      }
    }

    setRecipients(newRecipients);
  };

  const removeRecipient = data => {
    const newRecipients = recipients.filter(
      recipient => recipient.id !== data.id
    );

    setRecipients(newRecipients);
  };

  const handleRecipients = (data, type) => {
    switch (type) {
      case "ADD":
        addRecipient(data);
        break;

      case "REMOVE":
        removeRecipient(data);
        break;

      default:
        setRecipients(recipients => (isEmpty(recipients) ? recipients : []));
    }
  };

  const generateRows = useCallback(
    announcements => {
      if (!isEmpty(announcements)) {
        const newRows = Array.from(announcements, announcement => {
          let topic = announcement.tema ? announcement.tema : "(Sin asunto)";

          if (announcement.banner) {
            topic = `📣 ${topic}`;
          }

          return createData(
            announcement.id,
            topic,
            moment(announcement.fechaInicial).format("L"),
            moment(announcement.fechaFinal).format("L"),
            generateActions(announcement)
          );
        });
        setRows(newRows);
      } else {
        setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
      }
    },
    [generateActions]
  );

  const handleClose = () => {
    setOpen(false);
    handleRecipients(); // remueve los destinatarios que se hayan subido al estado.
    setRecipientsInitial([]);
    setRecipientType("profiles");
    setAnnouncementEdit(null);
    setUsers([]);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const extraParams = {
      handleClose,
      setSubmitting
    };

    if (null === announcementEdit) {
      insertAnnouncement(values, recipientType, recipients, extraParams);
    } else {
      values.id = announcementEdit.id;
      updateAnnouncement(values, recipientType, recipients, extraParams);
    }
  };

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE
    };

    if (null === rows && firstRender) {
      downloadAnnouncements(params);
      setFirstRender(false);
    }
  }, [rows, firstRender, downloadAnnouncements]);

  useEffect(() => {
    if (undefined !== announcements.content) {
      generateRows(announcements.content);
    }
  }, [announcements, generateRows]);

  useEffect(() => {
    return () => {
      setAnnouncements({});
    };
  }, [setAnnouncements]);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Lista de anuncios</h4>
          <p className={classes.cardCategoryWhite}>
            Administrador de notificaciones de usuarios.
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomTable
                tableHeaderColor="primary"
                columns={columns}
                rows={rows}
                totalElements={
                  isEmpty(announcements) ? 0 : announcements.totalElements
                }
                action="click"
                actionsToolbar={actionsToolbar}
                onChangePage={onChangePage}
                forcePage={page}
                setForcePage={setPage}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        title="Gestionar anuncio"
        width="md"
        initialValues={initialValues}
        yupObject={schemaManageAd}
        handleSubmit={handleSubmit}
        alert={alertFormDialog}
        pristine={pristine && isEqual(recipientsInitial, recipients)}
        disableBackClick
      >
        <ManageAd
          recipientType={recipientType}
          setRecipientType={setRecipientType}
          recipients={recipients}
          handleRecipients={handleRecipients}
          setPristine={setPristine}
        />
      </FormDialog>
      <ViewNotification
        open={openView}
        handleCloseDialog={handleCloseDialog}
        handleClickLaunch={handleClickLaunch}
        topic={topic}
        content={content}
        link={link}
      />
      <AlertDialog
        open={openAlert}
        title="Eliminar anuncio"
        content={alertFormDialog.content}
        isSubmitting={submitting}
        handleClose={handleCloseAlert}
        handleAccept={handleAccept}
      />
    </>
  );
}

Announcements.propTypes = {
  announcements: PropTypes.object.isRequired,
  downloadAnnouncements: PropTypes.func.isRequired,
  setAnnouncements: PropTypes.func.isRequired,
  insertAnnouncement: PropTypes.func.isRequired,
  updateAnnouncement: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
  deleteAnnouncement: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    announcements: state.announcementsAdminREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadAnnouncements: params => {
      dispatch(downloadAnnouncementsAdminACTI(params));
    },
    setAnnouncements: announcements => {
      dispatch(addAnnouncementsAdminStoreACTI(announcements));
    },
    insertAnnouncement: (
      announcement,
      recipientType,
      recipients,
      extraParams
    ) => {
      dispatch(
        insertAnnouncementACTI(
          announcement,
          recipientType,
          recipients,
          extraParams
        )
      );
    },
    updateAnnouncement: (
      announcement,
      recipientType,
      recipients,
      extraParams
    ) => {
      dispatch(
        updateAnnouncementACTI(
          announcement,
          recipientType,
          recipients,
          extraParams
        )
      );
    },
    setUsers: users => {
      dispatch(addAnnouncementUsersStoreACTI(users));
    },
    deleteAnnouncement: (announcement, extraParams) => {
      dispatch(deleteAnnouncementACTI(announcement, extraParams));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Announcements);
