import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont
} from "assets/jss/material-dashboard-react.js";
import { hexToRgb } from "helpers/functions";

const tableStyle = theme => ({
  cimmerianTableHeader: {
    color: "#555",
    "& svg": {
      color: `rgba(${hexToRgb("#555")}, 0.54) !important`
    },
    "& .MuiTableCell-paddingCheckbox svg": {
      color: `rgb(${hexToRgb("#555")}) !important`
    }
  },
  warningTableHeader: {
    color: warningColor[0],
    "& svg": {
      color: `rgba(${hexToRgb(warningColor[0])}, 0.54) !important`
    },
    "& .MuiTableCell-paddingCheckbox svg": {
      color: `rgb(${hexToRgb(warningColor[0])}) !important`
    }
  },
  primaryTableHeader: {
    color: primaryColor[0],
    "& svg": {
      color: `rgba(${hexToRgb(primaryColor[0])}, 0.54) !important`
    },
    "& .MuiTableCell-paddingCheckbox svg": {
      color: `rgb(${hexToRgb(primaryColor[0])}) !important`
    }
  },
  dangerTableHeader: {
    color: dangerColor[0],
    "& svg": {
      color: `rgba(${hexToRgb(dangerColor[0])}, 0.54) !important`
    },
    "& .MuiTableCell-paddingCheckbox svg": {
      color: `rgb(${hexToRgb(dangerColor[0])}) !important`
    }
  },
  successTableHeader: {
    color: successColor[0],
    "& svg": {
      color: `rgba(${hexToRgb(successColor[0])}, 0.54) !important`
    },
    "& .MuiTableCell-paddingCheckbox svg": {
      color: `rgb(${hexToRgb(successColor[0])}) !important`
    }
  },
  infoTableHeader: {
    color: infoColor[0],
    "& svg": {
      color: `rgba(${hexToRgb(infoColor[0])}, 0.54) !important`
    },
    "& .MuiTableCell-paddingCheckbox svg": {
      color: `rgb(${hexToRgb(infoColor[0])}) !important`
    }
  },
  roseTableHeader: {
    color: roseColor[0],
    "& svg": {
      color: `rgba(${hexToRgb(roseColor[0])}, 0.54) !important`
    },
    "& .MuiTableCell-paddingCheckbox svg": {
      color: `rgb(${hexToRgb(roseColor[0])}) !important`
    }
  },
  grayTableHeader: {
    color: grayColor[0],
    "& svg": {
      color: `rgba(${hexToRgb(grayColor[0])}, 0.54) !important`
    },
    "& .MuiTableCell-paddingCheckbox svg": {
      color: `rgb(${hexToRgb(grayColor[0])}) !important`
    }
  },
  cimmerianBodyCell: {
    color: "#555",
    "& svg": {
      color: `rgb(${hexToRgb("#555")}) !important`
    }
  },
  warningBodyCell: {
    color: warningColor[0],
    "& svg": {
      color: `rgb(${hexToRgb(warningColor[0])}) !important`
    }
  },
  primaryBodyCell: {
    color: primaryColor[0],
    "& svg": {
      color: `rgb(${hexToRgb(primaryColor[0])}) !important`
    }
  },
  dangerBodyCell: {
    color: dangerColor[0],
    "& svg": {
      color: `rgb(${hexToRgb(dangerColor[0])}) !important`
    }
  },
  successBodyCell: {
    color: successColor[0],
    "& svg": {
      color: `rgb(${hexToRgb(successColor[0])}) !important`
    }
  },
  infoBodyCell: {
    color: infoColor[0],
    "& svg": {
      color: `rgb(${hexToRgb(infoColor[0])}) !important`
    }
  },
  roseBodyCell: {
    color: roseColor[0],
    "& svg": {
      color: `rgb(${hexToRgb(roseColor[0])}) !important`
    }
  },
  grayBodyCell: {
    color: grayColor[0],
    "& svg": {
      color: `rgb(${hexToRgb(grayColor[0])}) !important`
    }
  },
  cimmerianSelectedRow: {
    "& .Mui-selected, .MuiTableRow-hover:hover": {
      backgroundColor: `rgba(${hexToRgb("#555")}, 0.1) !important`
    }
  },
  warningSelectedRow: {
    "& .Mui-selected, .MuiTableRow-hover:hover": {
      backgroundColor: `rgba(${hexToRgb(warningColor[0])}, 0.1) !important`
    }
  },
  primarySelectedRow: {
    "& .Mui-selected, .MuiTableRow-hover:hover": {
      backgroundColor: `rgba(${hexToRgb(primaryColor[0])}, 0.1) !important`
    }
  },
  dangerSelectedRow: {
    "& .Mui-selected, .MuiTableRow-hover:hover": {
      backgroundColor: `rgba(${hexToRgb(dangerColor[0])}, 0.1) !important`
    }
  },
  successSelectedRow: {
    "& .Mui-selected, .MuiTableRow-hover:hover": {
      backgroundColor: `rgba(${hexToRgb(successColor[0])}, 0.1) !important`
    }
  },
  infoSelectedRow: {
    "& .Mui-selected, .MuiTableRow-hover:hover": {
      backgroundColor: `rgba(${hexToRgb(infoColor[0])}, 0.1) !important`
    }
  },
  roseSelectedRow: {
    "& .Mui-selected, .MuiTableRow-hover:hover": {
      backgroundColor: `rgba(${hexToRgb(roseColor[0])}, 0.1) !important`
    }
  },
  graySelectedRow: {
    "& .Mui-selected, .MuiTableRow-hover:hover": {
      backgroundColor: `rgba(${hexToRgb(grayColor[0])}, 0.1) !important`
    }
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse"
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    "&, &$tableCell": {
      fontSize: "1em"
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem"
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  tableHeadRow: {
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle"
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle"
  }
});

export default tableStyle;
