import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import FormDialog from "components/Dialog/FormDialog";
import ManageRole from "components/Roles/ManageRole";
import ProcessOption from "./ProcessOption";
import UserAutomatic from "./UserAutomatic";
import UserManual from "./UserManual";
import { addRoleApplicationsStoreACTI } from "../../actions/administrator";
import {
  insertRoleACTI,
  processAutomaticUserACTI,
  saveUserACTI,
  updateRoleACTI
} from "../../actions/users";
import functionalityStyle from "assets/jss/material-dashboard-react/functionalityStyle";

const styles = theme => ({
  ...functionalityStyle,
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  btnProcesss: {
    textAlign: "right"
  },
  submit: {
    margin: "20px 0px 0px"
  }
});
const useStyles = makeStyles(styles);

const initialValuesManual = {
  username: "",
  pass: "",
  passConfirm: ""
};

const initialValuesAuto = {
  profile: ""
};

const defaultRoleValues = {
  name: "",
  description: "",
  profile: ""
};

const schemaManageRole = {
  name: Yup.string()
    .trim()
    .required("Requerido")
    .max(100, "Máximo 100 caracteres"),
  description: Yup.string().max(100, "Máximo 100 caracteres"),
  profile: Yup.string()
};

function ManageUsers(props) {
  const {
    profiles,
    saveUser,
    insertRole,
    updateRole,
    setRoleApplications,
    processAutomaticUser
  } = props;
  const classes = useStyles();

  const [process, setProcess] = useState("manual");
  const [initialValues, setInitialValues] = useState(initialValuesManual);
  const [schemaManageUsers, setSchemaManageUsers] = useState({});
  const [selectedUserManual, setSelectedUserManual] = useState(null);
  const [initialValuesManageRole, setInitialValuesManageRole] = useState({});
  const [openManageRole, setOpenManageRole] = useState(false);
  const [roleEdit, setRoleEdit] = useState(null);
  const [functionalitiesToSave, setFunctionalitiesToSave] = useState({});
  const [applicationIds, setApplicationIds] = useState([]);
  const [pristine, setPristine] = useState(undefined);
  const [roleIds, setRoleIds] = useState([]);
  const [profileIds, setProfileIds] = useState([]);
  const [programIds, setProgramIds] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [operation, setOperation] = useState("noAction");
  const [resetProfile, setResetProfile] = useState([]);

  const formRef = useRef(null);

  const handleClickProcess = e => {
    setProcess(e.target.value);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const extraParams = {
      setSubmitting,
      process,
      setProcess
    };

    if ("manual" === process) {
      const user = {
        pegeId: selectedUserManual.pegeId,
        documento: selectedUserManual.documento,
        nombre: selectedUserManual.nombre,
        usuario: values.username,
        tipo: "GENERAL",
        cambiarContrasena: !isEmpty(values.pass) ? values.pass : null,
        nick: selectedUserManual.nick
          ? selectedUserManual.nick
          : selectedUserManual.nombre,
        correo: selectedUserManual.correo,
        preguntaSecreta: "¿Usuario general?",
        respuestaPregunta: "Usuario general",
        idsPerfiles: profileIds,
        idsRoles: roleIds
      };
      saveUser(
        undefined !== selectedUserManual.id ? selectedUserManual.id : null,
        user,
        extraParams
      );
    } else if ("automatic" === process) {
      const user = {
        idsPerfiles: [selectedProfile.id],
        idsRoles: null,
        idsUnidadPrograma: programIds
      };

      if ("addUserRoles" === operation) {
        user.message = "ADD_USER_ROLES";
        user.idsRoles = roleIds;
      } else if ("rmUserRoles" === operation) {
        user.message = "RM_USER_ROLES";
        user.idsRoles = [];
      }

      extraParams.setOperation = setOperation;
      extraParams.setResetProfile = setResetProfile;
      processAutomaticUser(user, extraParams);
    } else {
      setSubmitting(false);
    }
  };

  const handleCloseManageRole = () => {
    setOpenManageRole(false);
  };

  const handleOpenRoleAdd = () => {
    setRoleEdit(null);
    setInitialValuesManageRole(defaultRoleValues);
    setFunctionalitiesToSave(oldFunctionalities =>
      isEmpty(oldFunctionalities) ? oldFunctionalities : {}
    );
    setRoleApplications({});
    setOpenManageRole(true);
  };

  const handleOpenRoleEdit = role => {
    setRoleEdit(role);
    const values = {
      name: role.nombre,
      description: null !== role.descripcion ? role.descripcion : "",
      profile:
        null !== role.perfil && !isEmpty(profiles)
          ? profiles.findIndex(profile => profile.id === role.perfil.id)
          : ""
    };

    setInitialValuesManageRole(values);
    setRoleApplications({});
    setOpenManageRole(true);
  };

  const handleSubmitManageRole = (values, { setSubmitting }) => {
    const extraParams = {
      handleCloseManageRole,
      setSubmitting
    };
    values.applicationIds = applicationIds;

    if (null === roleEdit) {
      extraParams.functionalitiesToSave = functionalitiesToSave;
      insertRole(values, extraParams);
    } else {
      values.id = roleEdit.id;
      updateRole(values, extraParams);
    }
  };

  useEffect(() => {
    if ("manual" === process) {
      setInitialValues(initialValuesManual);
    } else if ("automatic" === process) {
      setInitialValues(initialValuesAuto);
    }
  }, [process]);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Gestión de usuarios</h4>
          <p className={classes.cardCategoryWhite}>
            Procesamiento automático o manual de usuarios del sistema.
          </p>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object(schemaManageUsers)}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, handleReset }) => (
              <Form className={classes.form} ref={formRef}>
                <ProcessOption
                  process={process}
                  handleClickProcess={handleClickProcess}
                />
                {"manual" === process ? (
                  <UserManual
                    classes={classes}
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    handleReset={handleReset}
                    setSchemaManageUsers={setSchemaManageUsers}
                    setSelectedUserManual={setSelectedUserManual}
                    handleOpenRoleAdd={handleOpenRoleAdd}
                    handleOpenRoleEdit={handleOpenRoleEdit}
                    setRoleIds={setRoleIds}
                    setProfileIds={setProfileIds}
                  />
                ) : "automatic" === process ? (
                  <UserAutomatic
                    classes={classes}
                    isSubmitting={isSubmitting}
                    setProgramIds={setProgramIds}
                    handleOpenRoleAdd={handleOpenRoleAdd}
                    handleOpenRoleEdit={handleOpenRoleEdit}
                    setRoleIds={setRoleIds}
                    formRef={formRef}
                    setSchemaManageUsers={setSchemaManageUsers}
                    selectedProfile={selectedProfile}
                    setSelectedProfile={setSelectedProfile}
                    operation={operation}
                    setOperation={setOperation}
                    resetProfile={resetProfile}
                  />
                ) : null}
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
      <FormDialog
        open={openManageRole}
        handleClose={handleCloseManageRole}
        title="Gestionar rol"
        width="md"
        initialValues={initialValuesManageRole}
        yupObject={schemaManageRole}
        handleSubmit={handleSubmitManageRole}
        pristine={pristine}
        disableBackClick
      >
        <ManageRole
          roleEdit={roleEdit}
          setFunctionalitiesToSave={setFunctionalitiesToSave}
          setApplicationIds={setApplicationIds}
          setPristine={setPristine}
        />
      </FormDialog>
    </>
  );
}

ManageUsers.propTypes = {
  profiles: PropTypes.array,
  saveUser: PropTypes.func.isRequired,
  insertRole: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  setRoleApplications: PropTypes.func.isRequired,
  processAutomaticUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    profiles: state.globalProfilesREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveUser: (id, user, extraParams) => {
      dispatch(saveUserACTI(id, user, extraParams));
    },
    insertRole: (role, extraParams) => {
      dispatch(insertRoleACTI(role, extraParams));
    },
    updateRole: (role, extraParams) => {
      dispatch(updateRoleACTI(role, extraParams));
    },
    setRoleApplications: applications => {
      dispatch(addRoleApplicationsStoreACTI(applications));
    },
    processAutomaticUser: (user, extraParams) => {
      dispatch(processAutomaticUserACTI(user, extraParams));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsers);
