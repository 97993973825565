/*eslint-disable*/
import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import {
  DialogContentText,
  Fab,
  IconButton,
  makeStyles,
  SvgIcon,
  Tooltip
} from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";

import PerfectScrollbar from "perfect-scrollbar";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import FormDialog from "components/Dialog/FormDialog";
import SelectFormik from "components/CustomInput/SelectFormik";
import { hexToRgb } from "assets/jss/material-dashboard-react";
import { validateApplicationACTI } from "../../actions";

// import imagine1 from "assets/img/sidebar-1.jpg";
// import imagine2 from "assets/img/sidebar-2.jpg";
// import imagine3 from "assets/img/sidebar-3.jpg";
// import imagine4 from "assets/img/sidebar-4.jpg";

// import Button from "components/CustomButtons/Button.js";

const styles = {
  select: { width: "100%", marginLeft: 0 }
};

const initStyles = apps => {
  const styles = {};

  if (!isEmpty(apps)) {
    apps.forEach(category => {
      if (!isEmpty(category.aplicaciones)) {
        category.aplicaciones.forEach(app => {
          let color = isEmpty(app.estilo) ? "#000" : app.estilo;
          styles["icon_" + app.id] = {
            color,
            backgroundColor: `rgba(${hexToRgb(color)}, 0.3)`,
            "&:hover": {
              backgroundColor: `rgba(${hexToRgb(color)}, 0.45)`
            }
          };
        });
      }

      if (!isEmpty(category.subcategorias)) {
        category.subcategorias.forEach(subcategory => {
          if (!isEmpty(subcategory.aplicaciones)) {
            subcategory.aplicaciones.forEach(app => {
              let color = isEmpty(app.estilo) ? "#000" : app.estilo;
              styles["icon_" + app.id] = {
                color,
                backgroundColor: `rgba(${hexToRgb(color)}, 0.3)`,
                "&:hover": {
                  backgroundColor: `rgba(${hexToRgb(color)}, 0.45)`
                }
              };
            });
          }
        });
      }
    });
  }

  return makeStyles(styles); // returns a hook
};

const renderIcon = (application, classes, handleClickApp) => (
  <Tooltip title={application.nombre} key={application.id}>
    <IconButton
      aria-label={`badge-icon-${application.id}`}
      className={
        !isEmpty(classes)
          ? classes[`icon_${application.id}`]
          : "color-icon-default"
      }
      onClick={() => handleClickApp({ ...application, imagen: null })}
      // disabled
    >
      <SvgIcon>
        <path d={application.imagen} />
      </SvgIcon>
    </IconButton>
  </Tooltip>
);

const renderApps = (apps, classes, handleClickApp) => {
  if (isEmpty(apps)) {
    return <li className="apps-not-found">No tienes aplicaciones asociadas</li>;
  } else {
    return apps.map((category, ic) => (
      <span key={category.id}>
        {ic > 0 && <li className="adjustments-line" />}
        <li className="header-title">{category.nombre}</li>
        {!isEmpty(category.aplicaciones) && (
          <li className="button-container">
            <div>
              {category.aplicaciones.map(app =>
                renderIcon(app, classes, handleClickApp)
              )}
            </div>
          </li>
        )}
        {!isEmpty(category.subcategorias) &&
          category.subcategorias.map(subcategory => (
            <span key={subcategory.id}>
              <li className="header-title sub">{subcategory.nombre}</li>
              {!isEmpty(subcategory.aplicaciones) && (
                <li className="button-container">
                  <div>
                    {subcategory.aplicaciones.map(appSub =>
                      renderIcon(appSub, classes, handleClickApp)
                    )}
                  </div>
                </li>
              )}
            </span>
          ))}
      </span>
    ));
  }
};

function FixedPlugin(props) {
  const {
    handleFixedClick,
    fixedClasses,
    classes,
    applications,
    validateApplication
  } = props;
  // const [classes, setClasses] = React.useState("dropdown show");
  // const [bg_checked, setBg_checked] = React.useState(true);
  // const [bgImage, setBgImage] = React.useState(props.bgImage);
  const [application, setApplication] = useState({});
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    handleFixedClick();
  };

  const useStyles = initStyles(applications);
  const customClasses = useStyles();

  const handleClickApp = application => {
    if (application.roles.length > 1) {
      setApplication(application);
      setOpen(true);
    } else {
      validateApplication(application);
    }
  };

  // ref to help us initialize PerfectScrollbar on windows devices
  const appsPanel = React.createRef();
  let ps;
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(appsPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        ps = null;
      }
    };
  }, [appsPanel]);

  return (
    <div className="fixed-plugin">
      <div className={fixedClasses}>
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          className={classes.floatingButton}
          onClick={handleClick}
        >
          <AppsIcon />
        </Fab>
        <div className="dropdown-menu">
          <ul ref={appsPanel}>
            {/* <li className="header-title">SIDEBAR FILTERS</li>
          <li className="adjustments-line">
            <a className="switch-trigger">
              <div>
                <span
                  className={
                    props.bgColor === "purple"
                      ? "badge filter badge-purple active"
                      : "badge filter badge-purple"
                  }
                  data-color="purple"
                  onClick={() => {
                    props.handleColorClick("purple");
                  }}
                />
                <span
                  className={
                    props.bgColor === "blue"
                      ? "badge filter badge-blue active"
                      : "badge filter badge-blue"
                  }
                  data-color="blue"
                  onClick={() => {
                    props.handleColorClick("blue");
                  }}
                />
                <span
                  className={
                    props.bgColor === "green"
                      ? "badge filter badge-green active"
                      : "badge filter badge-green"
                  }
                  data-color="green"
                  onClick={() => {
                    props.handleColorClick("green");
                  }}
                />
                <span
                  className={
                    props.bgColor === "red"
                      ? "badge filter badge-red active"
                      : "badge filter badge-red"
                  }
                  data-color="red"
                  onClick={() => {
                    props.handleColorClick("red");
                  }}
                />
                <span
                  className={
                    props.bgColor === "orange"
                      ? "badge filter badge-orange active"
                      : "badge filter badge-orange"
                  }
                  data-color="orange"
                  onClick={() => {
                    props.handleColorClick("orange");
                  }}
                />
              </div>
            </a>
          </li>
          <li className="header-title">Images</li>
          <li className={bgImage === imagine1 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine1);
                props.handleImageClick(imagine1);
              }}
            >
              <img src={imagine1} alt="..." />
            </a>
          </li>
          <li className={bgImage === imagine2 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine2);
                props.handleImageClick(imagine2);
              }}
            >
              <img src={imagine2} alt="..." />
            </a>
          </li>
          <li className={bgImage === imagine3 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine3);
                props.handleImageClick(imagine3);
              }}
            >
              <img src={imagine3} alt="..." />
            </a>
          </li>
          <li className={bgImage === imagine4 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine4);
                props.handleImageClick(imagine4);
              }}
            >
              <img src={imagine4} alt="..." />
            </a>
          </li> */}
            {renderApps(applications, customClasses, handleClickApp)}
          </ul>
        </div>
      </div>
      {application.roles && (
        <FormDialog
          open={open}
          handleClose={handleClose}
          title={application.nombre}
          width="xs"
          initialValues={{ role: "" }}
          yupObject={{
            role: Yup.string().required("Requerido")
          }}
          handleSubmit={(values, { setSubmitting }) => {
            const extraParams = {
              roleIndex: values.role,
              setOpen,
              setSubmitting
            };
            validateApplication(application, extraParams);
          }}
          disableBackClick
        >
          <DialogContentText>
            Tienes varios roles asociados a esta aplicación, a continuación
            elije el rol con el que deseas ingresar.
          </DialogContentText>
          <SelectFormik
            labelId="roles-label"
            id="roles"
            name="role"
            label="Rol"
            values={application.roles}
            style={styles.select}
          />
        </FormDialog>
      )}
    </div>
  );
}

FixedPlugin.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  rtlActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  applications: PropTypes.array,
  validateApplication: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    applications: state.applicationsREDU,
    validateApplication: PropTypes.func.isRequired
  };
};

const mapDispatchToProps = dispatch => {
  return {
    validateApplication: (application, extraParams) => {
      dispatch(validateApplicationACTI(application, extraParams));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FixedPlugin);
