import { ACTIONS } from "../constants";

export const categoriaListCatREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_CATEGORIESLIST_STORE:
      return action.categories;
    case ACTIONS.ADD_CATEGORY_LIST_STORE:
      return [...state, action.categoria];
    case ACTIONS.REMOVE_LIST_CATEGORIES_STORE:
      return [...state.filter(cate => cate.id !== action.categoria.id)];
    case ACTIONS.CHANGE_CATEGORIE_STORE:
      return handleUdpateCategoria(state, action.categoria);
    default:
      return state;
  }
};

export const parentCategoriesREDU = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.ADD_PARENT_CATEGORIES_STORE:
      return action.categories;
    default:
      return state;
  }
};

const handleUdpateCategoria = (lista, categoria) => {
  const newList = lista.map(cate =>
    cate.id !== categoria.id ? cate : categoria
  );
  return newList;
};

export const listApplicationPaginateCatREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.LIST_APPLICATION_PAGINATE_STORE:
      return action.applications;
    case ACTIONS.UPD_APPLICATION_CATE_STORE:
      return handleUdpateApplication(state, action.application);
    default:
      return state;
  }
};

const handleUdpateApplication = (lista, application) => {
  const newList = lista.map(app =>
    app.id !== application.id ? app : application
  );
  return newList;
};
