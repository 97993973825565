import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import General from "components/Widget/General";
import Infobox from "components/Widget/Infobox";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Graph from "components/Widget/Graph";

import "../../assets/css/main.css";
import ConfigurationWidget from "components/Widget/ConfigurationWidget";
import { setPristineGraphACTI, setChartDataACTI } from "actions/dashboard";
const ContentWidget = props => {
  const {
    types,
    color,
    colorGradA,
    colorGradB,
    type,
    isColorGrad,
    degreed,
    isAlternative,
    title,
    category,
    content,
    icon,
    isIframe,
    chartType,
    chartData,
    range,
    legend,
    trendlines,
    setChartData,
    setPristine,
    isStacked,
    pieHole
  } = props;

  const styles = {
    color: {
      background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      boxShadow:
        "0 4px 20px 0 rgba(" +
        `${color.r}, ${color.g}, ${color.b}` +
        ",.14), 0 7px 10px -5px rgba(" +
        `${color.r}, ${color.g}, ${color.b}` +
        ",.4)",
      color: isAlternative ? "#000" : "#fff"
    },
    colorGrad: {
      background: `linear-gradient(${degreed}deg, rgba(${colorGradA.r}, ${colorGradA.g}, ${colorGradA.b}, ${colorGradA.a}), rgba(${colorGradB.r}, ${colorGradB.g}, ${colorGradB.b}, ${colorGradB.a}))`,
      boxShadow:
        "0 4px 20px 0 rgba(" +
        `${colorGradA.r}, ${colorGradA.g}, ${colorGradA.b}` +
        ",.14), 0 7px 10px -5px rgba(" +
        `${colorGradA.r}, ${colorGradA.g}, ${colorGradA.b}` +
        ",.4)",
      color: isAlternative ? "#000" : "#fff"
    },
    altoGen: {
      minHeight: "150px"
    },
    default: {}
  };
  const isColor = () => {
    if (isColorGrad) return styles.colorGrad;
    else if (color) return styles.color;
    else return styles.default;
  };

  const extraData = {};

  if ("graph" === type && chartType) {
    extraData.range = range;
    extraData.legend = legend;

    if ("TR" === chartType.conf) {
      extraData.trendlines = trendlines;
    } else if ("ST" === chartType.conf) {
      extraData.isStacked = isStacked;
    } else if ("PI" === chartType.conf) {
      extraData.pieHole = pieHole;
    }
  }

  useEffect(() => {
    return () => {
      setPristine(true);
    };
  }, [setPristine]);

  return (
    <div>
      <ConfigurationWidget
        types={types}
        type={type}
        color={color}
        colorGradA={colorGradA}
        colorGradB={colorGradB}
        isAlternative={isAlternative}
        isColorGrad={isColorGrad}
        degreed={degreed}
        icon={icon}
        isIframe={isIframe}
      />
      <GridContainer justify="center">
        {"infobox" === type ? (
          <GridItem xs={12} sm={6} md={4}>
            <Infobox
              color={isColor()}
              titulo={title}
              categoria={category}
              contenido={content}
              pathIcon={icon.path}
              fromDash={false}
            />
          </GridItem>
        ) : "graph" === type ? (
          chartType && (
            <GridItem xs={12} md={5}>
              <Graph
                color={isColor()}
                isAlternative={isAlternative}
                titulo={title}
                categoria={category}
                contenido={content}
                fromDash={false}
                chartType={chartType}
                datos={chartData}
                extraData={extraData}
                setChartData={setChartData}
                setPristine={setPristine}
              />
            </GridItem>
          )
        ) : "general" === type ? (
          <GridItem xs={12} md={7}>
            <General
              style={styles.altoGen}
              color={isColor()}
              isAlternative={isAlternative}
              titulo={title}
              categoria={category}
              contenido={content}
              isIframe={isIframe}
              fromDash={false}
            />
          </GridItem>
        ) : (
          "No hay widget configurado"
        )}
      </GridContainer>
    </div>
  );
};

ContentWidget.propTypes = {
  types: PropTypes.array.isRequired,
  color: PropTypes.object.isRequired,
  colorGradA: PropTypes.object.isRequired,
  colorGradB: PropTypes.object.isRequired,
  type: PropTypes.string,
  isColorGrad: PropTypes.bool.isRequired,
  degreed: PropTypes.number.isRequired,
  isAlternative: PropTypes.bool.isRequired,
  title: PropTypes.string,
  category: PropTypes.string,
  content: PropTypes.string,
  icon: PropTypes.object.isRequired,
  isIframe: PropTypes.bool,
  chartType: PropTypes.object,
  chartData: PropTypes.object,
  range: PropTypes.string,
  legend: PropTypes.bool,
  trendlines: PropTypes.bool,
  setChartData: PropTypes.func.isRequired,
  setPristine: PropTypes.func.isRequired,
  isStacked: PropTypes.bool,
  pieHole: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    type: state.typeWidgetREDU,
    isColorGrad: state.colorGradWidgetREDU,
    degreed: state.degreedCardWidgetREDU,
    isAlternative: state.colorAltCardWidgetREDU,
    title: state.titleWidgetREDU,
    category: state.categoryWidgetREDU,
    content: state.contentWidgetREDU,
    icon: state.iconCardWidgetREDU,
    isIframe: state.iframeWidgetREDU,
    chartType: state.chartTypeREDU,
    chartData: state.chartDataREDU,
    range: state.chartRangeNameREDU,
    legend: state.chartLegendREDU,
    trendlines: state.chartTrendlinesREDU,
    isStacked: state.chartStackedREDU,
    pieHole: state.chartPieHoleREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setChartData: data => {
      dispatch(setChartDataACTI(data));
    },
    setPristine: pristine => {
      dispatch(setPristineGraphACTI(pristine));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentWidget);
