import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ContentActionsFD from "./ContentActionsFD";
import PaperComponent from "components/PaperComponent/PaperComponent";

const defaultHeader = {
  color: "#797979"
};
const styles = {
  dialogContent: {
    padding: "4px"
  }
};

const styles2 = theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(styles2);

function SimpleDialog(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    dialogId,
    title,
    children,
    disableBackClick,
    width,
    viewFooter,
    draggable,
    isSubmitting,
    stylesContent,
    withIconClose
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const paperProps = {
    dialogid: dialogId
  };

  if (draggable && !fullScreen) {
    styles.header = {
      ...defaultHeader,
      cursor: "move"
    };
    paperProps.draggable = "1";
  } else {
    styles.header = defaultHeader;
  }

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;

      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        disableBackdropClick={!!viewFooter || !!disableBackClick}
        disableEscapeKeyDown={!!viewFooter || !!disableBackClick}
        fullScreen={fullScreen}
        maxWidth={width ? width : false}
        PaperComponent={PaperComponent}
        PaperProps={paperProps}
        aria-labelledby={dialogId}
      >
        {title && (
          <DialogTitle id={dialogId} style={styles.header}>
            {title}
            {withIconClose ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
        )}
        <DialogContent
          style={stylesContent ? stylesContent : styles.dialogContent}
          className="custom-simple-dialog"
        >
          <div id="scroll-dialog-content" ref={descriptionElementRef}>
            {children}
          </div>
        </DialogContent>
        {undefined === viewFooter || viewFooter ? (
          <DialogActions>
            <ContentActionsFD
              handleClose={handleClose}
              isSubmitting={isSubmitting}
            />
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dialogId: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  disableBackClick: PropTypes.bool,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  viewFooter: PropTypes.bool,
  draggable: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  stylesContent: PropTypes.object,
  withIconClose: PropTypes.bool
};

export default SimpleDialog;
