import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

const inputProps = { "aria-label": "select all desserts" };
const styleCell = column => {
  const styles = {
    minWidth: column.minWidth,
    color: "inherit"
  };

  if (column.width) {
    styles.width = column.width;
  }

  return styles;
};
const customStyles = {
  sortLabel: {
    color: "inherit"
  }
};

export default function CustomTableHead(props) {
  const classes2 = useStyles();
  const {
    columns,
    columnsColor,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    action,
    checkboxAllClick
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes2[columnsColor + "TableHeader"]}>
      <TableRow>
        {"checkbox" === action && (
          <TableCell padding="checkbox">
            {checkboxAllClick && (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={inputProps}
              />
            )}
          </TableCell>
        )}
        {"radio" === action && <TableCell padding="checkbox"></TableCell>}
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.align}
            style={styleCell(column)}
            sortDirection={orderBy === column.id ? order : false}
          >
            {!column.sortable ? (
              column.label
            ) : (
              <TableSortLabel
                style={customStyles.sortLabel}
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsColor: PropTypes.oneOf([
    "cimmerian",
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number,
  action: PropTypes.string,
  checkboxAllClick: PropTypes.bool
};
