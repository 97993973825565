// core components/views for Admin
import UserProfile from "views/UserProfile/UserProfile.js";
import Notifications from "views/Notifications/Notifications";
import Users from "views/Users/Users";
import Administrator from "views/Administrator/Administrator";
import Announcements from "views/Announcements/Announcements";
import Categoria from "views/Categorias/Categoria";

const componentRoutes = {
  profile: UserProfile,
  notifications: Notifications,
  usuarios: Users,
  administrador: Administrator,
  announcements: Announcements,
  categories: Categoria
};

export default componentRoutes;
