import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import CustomTable from "components/Table/Table";
import FormDialog from "components/Dialog/FormDialog";
import ManageApplication from "./ManageApplication";
import {
  downloadApplicationsACTI,
  updateApplicationACTI
} from "../../actions/administrator";
import { ROWS_PER_PAGE } from "../../constants";

const titleToolbar = {
  color: "textSecondary",
  content: "Aplicaciones"
};

const columns = [
  { id: "nombre", label: "Nombre", minWidth: 70, width: "90%" },
  {
    id: "actions",
    label: "Acciones",
    minWidth: 70,
    width: "10%",
    align: "center"
  }
];

const defaultInitialValues = {
  name: "",
  url: "",
  description: "",
  icon: "",
  color: "",
  style: ""
};

const schema = {
  name: Yup.string()
    .trim()
    .required("Requerido")
    .max(100, "Máximo 100 caracteres"),
  url: Yup.string()
    .trim()
    .required("Requerido")
    .max(500, "Máximo 500 caracteres")
    .url("El enlace no está correcto"),
  description: Yup.string().trim(),
  icon: Yup.string()
    .trim()
    .required("Requerido")
    .max(1400, "Máximo 1400 caracteres"),
  color: Yup.string()
    .trim()
    .required("Requerido")
    .min(7, "Mínimo 7 caracteres")
    .max(7, "Máximo 7 caracteres"),
  style: Yup.string()
    .trim()
    .max(3, "Máximo 3 caracteres")
};

function ManageApplications(props) {
  const { downloadApplications, updateApplication } = props;

  const [applications, setApplications] = useState({});
  const [rows, setRows] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [applicationEdit, setApplicationEdit] = useState({});
  const [initialIcon, setInitialIcon] = useState("");
  const [initialColorIcon, setInitialColorIcon] = useState("");

  const handleOpenEdit = application => {
    setApplicationEdit(application);
    const values = {
      name: application.nombre,
      url: application.url,
      description:
        null !== application.descripcion ? application.descripcion : "",
      icon: application.imagen ? application.imagen : "",
      color: application.perfil ? application.perfil : "#010101",
      style: application.estilo ? application.estilo : ""
    };
    setInitialIcon(values.icon);
    setInitialColorIcon(values.color);
    setInitialValues(values);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const extraParams = {
      applications,
      setApplications,
      setOpen,
      setSubmitting
    };

    const application = {
      id: applicationEdit.id,
      nombre: values.name,
      url: values.url,
      descripcion: values.description,
      imagen: values.icon,
      perfil: values.color,
      estilo: values.style
    };
    updateApplication(application, extraParams);
  };

  const onChangePage = (newPage, rowsPerPage) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage,
      setApplications
    };
    setRows(null);
    downloadApplications(params);
  };

  const generateActions = useCallback(application => {
    return {
      type: "actions",
      values: [
        {
          title: "Modificar",
          icon: "mode_edit",
          func: () => handleOpenEdit(application)
        }
      ]
    };
  }, []);

  const generateRows = useCallback(
    applications => {
      if (!isEmpty(applications)) {
        const newRows = Array.from(applications, application =>
          createData(
            application.id,
            application.nombre,
            generateActions(application)
          )
        );
        setRows(newRows);
      } else {
        setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
      }
    },
    [generateActions]
  );

  function createData(id, nombre, actions) {
    return { id, nombre, actions };
  }

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE,
      setApplications
    };

    if (null === rows && firstRender) {
      downloadApplications(params);
      setFirstRender(false);
    }
  }, [rows, firstRender, downloadApplications]);

  useEffect(() => {
    if (undefined !== applications.content) {
      generateRows(applications.content);
    }
  }, [applications, generateRows]);

  return (
    <>
      <CustomTable
        tableHeaderColor="primary"
        columns={columns}
        rows={rows}
        totalElements={
          isEmpty(applications.content) ? 0 : applications.totalElements
        }
        action="click"
        titleToolbar={titleToolbar}
        onChangePage={onChangePage}
      />
      <FormDialog
        open={open}
        handleClose={handleClose}
        title="Gestionar aplicación"
        width="md"
        initialValues={initialValues}
        yupObject={schema}
        handleSubmit={handleSubmit}
        disableBackClick
      >
        <ManageApplication
          initialIcon={initialIcon}
          initialColorIcon={initialColorIcon}
        />
      </FormDialog>
    </>
  );
}

ManageApplications.propTypes = {
  downloadApplications: PropTypes.func.isRequired,
  updateApplication: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    downloadApplications: params => {
      dispatch(downloadApplicationsACTI(params));
    },
    updateApplication: (application, extraParams) => {
      dispatch(updateApplicationACTI(application, extraParams));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ManageApplications);
