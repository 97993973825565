import CryptoJS from "crypto-js";
import {
  setMessageAlertACTI,
  setOpenAlertErrorACTI,
  setOpenAlertSuccessACTI
} from "../actions";
import { call, delay, put } from "redux-saga/effects";
import { apiPOST, apiPUT } from "../api";
import { urlUsuarios } from "../api/urls";
import { generar, history } from "../helpers/functions";

const forgotPassword = usuario =>
  apiPOST(`${urlUsuarios}/olvido-contrasena`, usuario).then(reponse => reponse);
const confirmReset = usuario =>
  apiPOST(`${urlUsuarios}/verificar-restablecer-contrasena`, usuario).then(
    reponse => reponse
  );
const resetPassword = (id, usuario, pkp) =>
  apiPUT(`${urlUsuarios}/restablecer-contrasena`, id, usuario, pkp).then(
    reponse => reponse
  );

export function* sagaForgotPassword(action) {
  try {
    const usuario = {
      usuario: action.username
    };
    const result = yield call(forgotPassword, usuario);
    action.extraParams.setSubmitting(false);
    action.extraParams.setOpen(false);
    yield put(setMessageAlertACTI(result.response));
    yield delay(200);
    yield put(setOpenAlertSuccessACTI(true));
  } catch (error) {
    action.extraParams.setSubmitting(false);
    let msg = error;

    if ("El tiempo de espera se ha agotado, intenta más tarde." === error) {
      action.extraParams.setOpen(false);
      msg =
        "La solicitud tarda demasiado, en unos minutos debería llegar al correo asociado a tu usuario un vínculo de recuperación. Si no es así, vuelve a intentar más tarde.";
    }

    yield put(setMessageAlertACTI(msg));
    yield delay(200);
    yield put(setOpenAlertErrorACTI(true));
  }
}

export function* sagaConfirmResetPass(action) {
  try {
    const tokenConfirmacion = {
      token: action.token
    };
    const result = yield call(confirmReset, tokenConfirmacion);
    action.extraParams.setUserId(result.code);
    action.extraParams.setEnabled(true);
  } catch (error) {
    yield put(setMessageAlertACTI(error));
    yield put(setOpenAlertErrorACTI(true));
    action.extraParams.setEnabled(false);
    yield delay(3000);
    history.replace("/e-campus/login");
  }
}

export function* sagaResetPassword(action) {
  try {
    const keyEncryption = generar();
    const pass = CryptoJS.AES.encrypt(action.user.password, keyEncryption);
    const usuario = {
      firma: action.user.token,
      contrasena: pass.toString()
    };
    const result = yield call(
      resetPassword,
      action.user.id,
      usuario,
      keyEncryption
    );
    action.extraParams.resetForm({});
    action.extraParams.setSubmitting(false);

    if (result.id) {
      yield put(
        setMessageAlertACTI("Su contraseña se ha restablecido correctamente")
      );
      yield put(setOpenAlertSuccessACTI(true));
      yield delay(3000);
    } else {
      yield put(
        setMessageAlertACTI(
          "Se ha realizado el proceso, pero no se ha podido comprobar si fue exitoso. Intente ingresar con su nueva contraseña"
        )
      );
      yield put(setOpenAlertErrorACTI(true));
      yield delay(3000);
    }

    history.replace("/e-campus/login");
  } catch (error) {
    yield put(setMessageAlertACTI(error));
    yield delay(200);
    yield put(setOpenAlertErrorACTI(true));
    action.extraParams.resetForm({});
    action.extraParams.setSubmitting(false);
  }
}
