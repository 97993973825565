/* 
  @AUTOR: GRUPO DE DESARROLLO ESPECÍFICO
  FECHA DE CREACION: 06-05-2021
  APLICACIÓN: E-CAMPUS
*/

/*!

Template by Creative Tim:
=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

// core components
import Admin from "layouts/Admin.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import "assets/css/react-grid-layout.css";
import "assets/css/react-resizable.css";
import SignInSide from "components/Account/SignInSide";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import {
  ACCOUNT_PATH,
  // COLOR_APP_HEX,
  palette,
  ROOT_PATH
} from "../src/constants";
import { /* hexToRgb, */ history } from "./helpers/functions";
import RedirectHandler from "components/Account/RedirectHandler";
import ResetPassword from "components/Account/ResetPassword";
// import { initFirebaseApp } from "firebaseConfig";

const theme = createMuiTheme({
  palette: palette
  // overrides: {
  //   MuiTableRow: {
  //     root: {
  //       "&$selected, &$selected:hover, &$hover:hover": {
  //         backgroundColor: `rgba(${hexToRgb(COLOR_APP_HEX)}, 0.1)`
  //       }
  //     }
  //   }
  // }
});

// initFirebaseApp();

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={`${ROOT_PATH}/confirm-reset-pass`}
            component={ResetPassword}
          />
          <PrivateRoute path={ACCOUNT_PATH} component={Admin} />
          <Route exact path={`${ROOT_PATH}/login`} component={SignInSide} />
          <Route
            path={`${ROOT_PATH}/login/redirect`}
            component={RedirectHandler}
          />
          <Redirect from="*" to={ACCOUNT_PATH} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
/*
  06-05-2021  &: Jesús M. Sierra  @: Desarrollador UP
*/
