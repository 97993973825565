import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";
import { useButtonStyles } from "assets/jss/material-dashboard-react/buttonStyle";

function ContentActionsFD(props) {
  const { handleClose, pristine, isSubmitting, handleAccept } = props;
  const classes = useButtonStyles();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClose}
        size="small"
        className={classes.root}
        disabled={isSubmitting}
      >
        Cancelar
      </Button>
      <Button
        onClick={e =>
          handleAccept ? handleAccept(e) : handleClose(e, "accept")
        }
        variant="contained"
        color="primary"
        size="small"
        className={classes.root}
        disabled={pristine || isSubmitting}
      >
        Aceptar
      </Button>
      {isSubmitting && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </>
  );
}

ContentActionsFD.propTypes = {
  handleClose: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleAccept: PropTypes.func
};

export default ContentActionsFD;
