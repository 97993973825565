import React from "react";
import PropTypes from "prop-types";

const styles = {
  root: {
    padding: 0,
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400,
    lineHeight: 1
  }
};

function ViewData(props) {
  const { value, m, my, mt, mb } = props;

  if (undefined !== m) {
    styles.root = {
      ...styles.root,
      margin: `${m}px`
    };
  }

  if (undefined !== my) {
    styles.root = {
      ...styles.root,
      marginTop: `${my}px`,
      marginBottom: `${my}px`
    };
  } else {
    if (undefined !== mt) {
      styles.root = {
        ...styles.root,
        marginTop: `${mt}px`
      };
    }

    if (undefined !== mb) {
      styles.root = {
        ...styles.root,
        marginBottom: `${mb}px`
      };
    }
  }

  return <p style={styles.root}>{value ? value : "-"}</p>;
}

ViewData.propTypes = {
  value: PropTypes.string,
  m: PropTypes.number,
  my: PropTypes.number,
  mt: PropTypes.number,
  mb: PropTypes.number
};

export default ViewData;
