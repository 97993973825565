import React from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle";

const styles = theme => ({
  radioAuto: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "right"
    }
  },
  ...checkboxAdnRadioStyle
});
const useStyles = makeStyles(styles);

function ProcessOption(props) {
  const { process, handleClickProcess } = props;
  const classes = useStyles();

  return (
    <RadioGroup
      aria-label="proceso"
      name="proceso"
      value={process}
      onChange={e => handleClickProcess(e)}
    >
      <GridContainer spacing={0}>
        <GridItem xs={12} sm={6} className={classes.radioAuto}>
          <FormControlLabel
            value="automatic"
            control={
              <Radio
                checkedIcon={<Check className={classes.radioChecked} />}
                icon={<Check className={classes.radioUnchecked} />}
                color="default"
                classes={{
                  checked: classes.checked,
                  root: classes.root
                }}
              />
            }
            label="Automático"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlLabel
            value="manual"
            control={
              <Radio
                checkedIcon={<Check className={classes.radioChecked} />}
                icon={<Check className={classes.radioUnchecked} />}
                color="default"
                classes={{
                  checked: classes.checked,
                  root: classes.root
                }}
              />
            }
            label="Manual"
          />
        </GridItem>
      </GridContainer>
    </RadioGroup>
  );
}

ProcessOption.propTypes = {
  process: PropTypes.string.isRequired,
  handleClickProcess: PropTypes.func.isRequired
};

export default ProcessOption;
