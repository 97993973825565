import React from "react";
import PropTypes from "prop-types";
import Chart from "react-google-charts";

function BarChart(props) {
  const {
    data,
    handleOpenColor,
    handleOpenColorSingle,
    fromDash,
    extraData
  } = props;
  const { width, height, values, hTitle, vTitle, color, colors } = data;
  const { range, legend, isStacked } = extraData;

  return (
    <Chart
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      chartType="BarChart"
      loader={<div>Cargando gráfica...</div>}
      data={values}
      options={{
        bar: { groupWidth: "95%" },
        hAxis: {
          title: legend
            ? range && "" !== range.trim()
              ? range
              : vTitle
              ? vTitle
              : "Título"
            : null,
          titleTextStyle: {
            color: color ? color : "#fff"
          },
          textStyle: {
            color: color ? color : "#fff"
          },
          baselineColor: color ? color : "#fff",
          gridlines: { color: color ? color : "#fff" }
        },
        vAxis: {
          title: legend
            ? hTitle && "" !== hTitle.trim()
              ? hTitle
              : "Título"
            : null,
          titleTextStyle: {
            color: color ? color : "#fff"
          },
          textStyle: {
            color: color ? color : "#fff"
          },
          baselineColor: color ? color : "#fff",
          gridlines: { color: color ? color : "#fff" }
        },
        backgroundColor: {
          fill: "transparent"
        },
        colors: colors ? colors : [color],
        legend: {
          position: "top",
          maxLines: 2,
          textStyle: { color: color ? color : "#fff" }
        },
        tooltip: {
          showColorCode: !!colors
        },
        isStacked: isStacked
      }}
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();

            if (1 === selection.length && !fromDash) {
              const [selectedItem] = selection;

              if (null === selectedItem.row) {
                handleOpenColor(selectedItem.column);
              } else if (2 === data.values[0].length && null === data.colors) {
                handleOpenColorSingle(selectedItem.row);
              } else if (
                3 === data.values[0].length &&
                null === data.colors &&
                typeof data.values[0][1] === "string" &&
                typeof data.values[0][2] === "object"
              ) {
                handleOpenColorSingle(selectedItem.row);
              }
            }
          }
        }
      ]}
      // For tests
      rootProps={{ "data-testid": "6" }}
    />
  );
}

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  handleOpenColor: PropTypes.func.isRequired,
  handleOpenColorSingle: PropTypes.func.isRequired,
  fromDash: PropTypes.bool,
  extraData: PropTypes.object.isRequired
};

export default BarChart;
