import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomTable from "components/Table/Table";
import AlertDialog from "components/Dialog/AlertDialog";
import SearchUserForm from "./SearchUserForm";
import { findUserByDocumentACTI, findUsersByParamsACTI } from "../../actions";
import { updateUserStatusACTI } from "actions/users";

const styles = theme => ({
  searchUsers: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center"
    }
  },
  userStatus: {
    margin: 0,
    justifyContent: "center"
  }
});
const useStyles = makeStyles(styles);

const existingUsersTitleToolbar = {
  color: "textSecondary",
  content: "Usuarios existentes"
};

const columns = [
  { id: "documento", label: "Documento", minWidth: 70, width: "25%" },
  { id: "nombres", label: "Nombre", minWidth: 75, width: "40%" },
  { id: "usuario", label: "Usuario", minWidth: 70, width: "25%" },
  {
    id: "actions",
    label: "Estado",
    minWidth: 70,
    width: "10%",
    align: "center"
  }
];

function SearchUser(props) {
  const {
    setSelectedUser,
    clearRows,
    findUserByDocument,
    findUsersByParams,
    updateUserStatus
  } = props;
  const classes = useStyles();

  const [users, setUsers] = useState({});
  const [userDocument, setUserDocument] = useState(null);
  const [userName, setUserName] = useState(null);
  const [rows, setRows] = useState([]);
  const [resetSelections, setResetSelections] = useState([]);
  const [page, setPage] = useState(undefined);
  const [openAlert, setOpenAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userToDisable, setUserToDisable] = useState(null);

  const handleSetUserDocument = value => {
    setUserDocument(value);
    setSelectedUser(null);
  };

  const handleSetUserName = value => {
    setUserName(value);
    setSelectedUser(null);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setUserToDisable(null);
  };

  const handleAccept = () => {
    setSubmitting(true);
    const extraParams = {
      setSubmitting,
      setUsers,
      handleCloseAlert
    };
    updateUserStatus(userToDisable, extraParams);
  };

  const handleChangeUserStatus = useCallback(
    user => {
      if (!submitting) {
        if ("0" === user.estado) {
          setSubmitting(true);
          const extraParams = {
            setSubmitting,
            setUsers
          };
          updateUserStatus({ ...user, estado: "1" }, extraParams);
        } else if ("1" === user.estado) {
          setUserToDisable({ ...user, estado: "0" });
          setOpenAlert(true);
        }
      }
    },
    [submitting, updateUserStatus]
  );

  const onChangePage = (newPage, rowsPerPage) => {
    if (null !== userName) {
      const params = {
        setRows,
        pageNo: newPage,
        pageSize: rowsPerPage,
        userName,
        setUsers,
        isPaginated: true
      };
      findUsersByParams(params);
    }
  };

  const generateActions = useCallback(
    user => {
      return (
        <FormGroup>
          <FormControlLabel
            className={classes.userStatus}
            control={
              <Switch
                size="small"
                color="primary"
                checked={"1" === user.estado}
                onChange={() => handleChangeUserStatus(user)}
              />
            }
          />
        </FormGroup>
      );
    },
    [classes, handleChangeUserStatus]
  );

  function createData(id, documento, nombres, usuario, actions) {
    return { id, documento, nombres, usuario, actions };
  }

  const generateRows = useCallback(
    users => {
      if (!isEmpty(users)) {
        const newRows = Array.from(users, user =>
          createData(
            user.id,
            user.documento,
            user.nombre,
            user.usuario,
            generateActions(user)
          )
        );
        setRows(newRows);
      } else {
        setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
      }
    },
    [generateActions]
  );

  const handleSelect = useCallback(
    value => {
      if (!isEmpty(users.content) && !isEmpty(value)) {
        setSelectedUser(oldUser => {
          if (oldUser && oldUser.id === value[0]) {
            return oldUser;
          } else {
            const user = users.content.find(user => user.id === value[0]);

            return user ? user : null;
          }
        });
      } else if (!isEmpty(value)) {
        setSelectedUser(null);
      }
    },
    [users, setSelectedUser]
  );

  useEffect(() => {
    generateRows(users.content);
  }, [generateRows, users]);

  useEffect(() => {
    if (null !== clearRows) {
      setUsers(oldUsers => {
        if (!isEmpty(oldUsers.content)) {
          setResetSelections([]); // limpia selección de radio.
          return { content: [] };
        }

        return oldUsers;
      });
    }
  }, [clearRows]);

  return (
    <>
      <GridContainer>
        <SearchUserForm
          users={users}
          rows={rows}
          setUsers={setUsers}
          setRows={setRows}
          findUserByDocument={findUserByDocument}
          findUsersByParams={findUsersByParams}
          setResetSelections={setResetSelections}
          setPage={setPage}
          userDocument={userDocument}
          setUserDocument={handleSetUserDocument}
          userName={userName}
          setUserName={handleSetUserName}
        />
        <GridItem xs={12}>
          <CustomTable
            checkColor="primary"
            columns={columns}
            rows={rows}
            totalElements={isEmpty(users.content) ? 0 : users.totalElements}
            action="radio"
            titleToolbar={existingUsersTitleToolbar}
            onChangePage={onChangePage}
            getSelected={handleSelect}
            resetSelections={resetSelections}
            forcePage={page}
            setForcePage={setPage}
          />
        </GridItem>
      </GridContainer>
      <AlertDialog
        open={openAlert}
        title="Deshabilitar usuario"
        content={
          "El usuario perderá el acceso al sistema.\n¿Deseas continuar con el proceso?"
        }
        isSubmitting={submitting}
        handleClose={handleCloseAlert}
        handleAccept={handleAccept}
      />
    </>
  );
}

SearchUser.propTypes = {
  setSelectedUser: PropTypes.func.isRequired,
  clearRows: PropTypes.object,
  findUserByDocument: PropTypes.func.isRequired,
  findUsersByParams: PropTypes.func.isRequired,
  updateUserStatus: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    findUserByDocument: params => {
      dispatch(findUserByDocumentACTI(params));
    },
    findUsersByParams: params => {
      dispatch(findUsersByParamsACTI(params));
    },
    updateUserStatus: (user, extraParams) => {
      dispatch(updateUserStatusACTI(user, extraParams));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SearchUser);
