export const urlBase = process.env.REACT_APP_API_URL;

export const urlAnuncios = `${urlBase}/anuncios`;
export const urlAuth = `${urlBase}/security/oauth/token`;
export const urlAppAuth = `${urlBase}/app-security/oauth/token`;
export const urlUsuarios = `${urlBase}/usuarios`;
export const urlImagenes = `${urlBase}/imagenes`;
export const urlUsuariosAdmin = `${urlBase}/usuarios-admin`;
export const urlUsuariosManager = `${urlBase}/usuarios-manager`;
export const urlVerify = `${urlUsuariosManager}/estado-auth`;
export const urlLogout = `${urlUsuariosManager}/logout`;
export const urlRolesSeguridad = `${urlBase}/roles-seguridad`;
export const urlWidgets = `${urlBase}/widgets`;
export const urlTipos = `${urlBase}/tipos-widget`;
export const urlLayout = `${urlBase}/layout`;
export const urlLayouts = `${urlBase}/layouts`;
export const urlCategorias = `${urlBase}/categorias`;
export const urlPerfiles = `${urlBase}/perfiles`;
export const urlGeojs = "https://get.geojs.io/v1/ip/geo.json";
export const urlRoles = `${urlBase}/roles`;
export const urlAplicaciones = `${urlBase}/aplicaciones`;
export const urlFuncionalidades = `${urlBase}/funcionalidades`;
export const urlPersonas = `${urlBase}/personas`;
export const urlUnidades = `${urlBase}/unidades`;
export const urlProgramas = `${urlBase}/programas`;
