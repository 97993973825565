import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Tooltip
} from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle";

const styles = () => ({
  fieldset: {
    paddingLeft: "16px"
  },
  legend: {
    color: "rgba(0, 0, 0, 0.54) !important",
    fontSize: "1.25rem",
    fontWeight: 500,
    margin: 0,
    minHeight: "64px",
    display: "flex",
    alignItems: "center"
  },
  customRoot: {
    padding: "4px 8px 4px 4px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  formControlLabel: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginLeft: "2px"
    }
  },
  ...checkboxAdnRadioStyle
});
const useStyles = makeStyles(styles);

function RoleOperation(props) {
  const { operation, handleChangeOperation } = props;
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      <FormLabel
        component="legend"
        color="secondary"
        className={classes.legend}
      >
        Operación de roles
      </FormLabel>
      <RadioGroup
        aria-label="roleOperation"
        name="roleOperation"
        value={operation}
        onChange={e => handleChangeOperation(e)}
      >
        <FormControlLabel
          value="noAction"
          control={
            <Radio
              checkedIcon={<Check className={classes.radioChecked} />}
              icon={<Check className={classes.radioUnchecked} />}
              color="default"
              classes={{
                checked: classes.checked,
                root: classes.customRoot
              }}
            />
          }
          label={
            <span className={classes.formControlLabel}>
              Sin acción
              <Tooltip
                title="Solo asocia a los nuevos usuarios, el rol o los roles que corresponden al perfil escogido."
                leaveDelay={1000}
                placement="top"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </span>
          }
        />
        <FormControlLabel
          value="addUserRoles"
          control={
            <Radio
              checkedIcon={<Check className={classes.radioChecked} />}
              icon={<Check className={classes.radioUnchecked} />}
              color="default"
              classes={{
                checked: classes.checked,
                root: classes.customRoot
              }}
            />
          }
          label={
            <span className={classes.formControlLabel}>
              Agregar roles a usuarios
              <Tooltip
                title="Puedes chequear para los nuevos usuarios, roles extras a los correspondientes al perfil escogido. En caso de no marcar ningún rol, solo asocia el rol o los roles correspondientes al perfil escogido."
                leaveDelay={1000}
                placement="top"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </span>
          }
        />
        <FormControlLabel
          value="rmUserRoles"
          control={
            <Radio
              checkedIcon={<Check className={classes.radioChecked} />}
              icon={<Check className={classes.radioUnchecked} />}
              color="default"
              classes={{
                checked: classes.checked,
                root: classes.customRoot
              }}
            />
          }
          label={
            <span className={classes.formControlLabel}>
              Remover roles de usuarios
              <Tooltip
                title="A los nuevos y antiguos usuarios solo les quedan asociados el rol o los roles correspondientes al perfil escogido."
                leaveDelay={1000}
                placement="top"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </span>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}

RoleOperation.propTypes = {
  operation: PropTypes.string.isRequired,
  handleChangeOperation: PropTypes.func.isRequired
};

export default RoleOperation;
