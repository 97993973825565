import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es-mx";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import InputFormik from "components/CustomInput/InputFormik";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ManageRecipients from "./ManageRecipients";
import KeyboardDatePickerFormik from "components/CustomInput/KeyboardDatePickerFormik";
import TinymceFormik from "components/CustomInput/TinymceFormik";
import CheckboxFormik from "components/CustomInput/CheckboxFormik";
import { makeStyles } from "@material-ui/core";

moment.locale("es-mx");

const initialDateButtonProps = {
  "aria-label": "initial date"
};
const finalDateButtonProps = {
  "aria-label": "final date"
};

const formControlProps = {
  fullWidth: true
};
const marginInputFormik = { marginBottom: "8px" };

const useStyles = makeStyles(theme => ({
  manageRecipients: {
    marginBottom: 5
  },
  banner: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
}));

function ManageAd(props) {
  const classes = useStyles();
  const {
    dirty,
    setFieldError,
    setFieldValue,
    recipientType,
    setRecipientType,
    recipients,
    handleRecipients,
    setPristine
  } = props;

  const handleClickRecipientType = e => {
    setRecipientType(e.target.value);
  };

  useEffect(() => {
    setPristine(!dirty);
  }, [dirty, setPristine]);

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale="es-mx"
    >
      <GridContainer>
        <GridItem xs={12} md={6}>
          <InputFormik
            label="Tema"
            id="topic"
            name="topic"
            type="text"
            formControlProps={formControlProps}
            style={marginInputFormik}
            formControlRoot
            autoComplete="off"
          />
        </GridItem>
        <GridItem xs={12} md={3}>
          <KeyboardDatePickerFormik
            id="date-picker-initial"
            label="Fecha inicio"
            KeyboardButtonProps={initialDateButtonProps}
            name="initialDate"
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />
        </GridItem>
        <GridItem xs={12} md={3}>
          <KeyboardDatePickerFormik
            id="date-picker-final"
            label="Fecha fin"
            KeyboardButtonProps={finalDateButtonProps}
            name="finalDate"
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />
        </GridItem>
        <GridItem xs={12} md={12} className={classes.manageRecipients}>
          <ManageRecipients
            type={recipientType}
            handleClickType={handleClickRecipientType}
            recipients={recipients}
            handleRecipients={handleRecipients}
          />
        </GridItem>
        <GridItem xs={12} md={12}>
          <TinymceFormik name="content" />
        </GridItem>
        <GridItem xs={12} md={10}>
          <InputFormik
            label="Enlace"
            id="link"
            name="link"
            type="text"
            formControlProps={formControlProps}
            style={marginInputFormik}
            formControlRoot
            autoComplete="off"
          />
        </GridItem>
        <GridItem xs={12} md={2} className={classes.banner}>
          <CheckboxFormik label="Banner" name="banner" />
        </GridItem>
      </GridContainer>
    </MuiPickersUtilsProvider>
  );
}

ManageAd.propTypes = {
  dirty: PropTypes.bool,
  setFieldError: PropTypes.func,
  setFieldValue: PropTypes.func,
  recipientType: PropTypes.string.isRequired,
  setRecipientType: PropTypes.func.isRequired,
  recipients: PropTypes.array,
  handleRecipients: PropTypes.func.isRequired,
  setPristine: PropTypes.func.isRequired
};

export default ManageAd;
