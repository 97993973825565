import { apiGET, apiPOST, apiPUT, apiDELETE } from "../api";
import { urlTipos, urlWidgets, urlLayouts } from "../api/urls";
import { call, put, select, delay } from "redux-saga/effects";
import { isEmpty, isNumber } from "lodash";
import {
  addTypesStoreACTI,
  addWidgetsStoreACTI,
  addLayoutsStoreACTI,
  addFullLayoutsStoreACTI,
  setAlertDoneACTI,
  setChangeWidgetACTI,
  openDialogWidgetACTI,
  setViewContentDialogACTI,
  setViewGridACTI,
  setLimitWidgetsACTI
} from "../actions/dashboard";
import {
  setMessageNotificationACTI,
  setOpenNotificationSuccessACTI,
  setOpenNotificationErrorACTI,
  setOpenNotificationWarningACTI
} from "../actions";

const downloadTypes = () => apiGET(`${urlTipos}/listar`).then(types => types);
const downloadWidgets = (pegeId, perfilId, usuaId) =>
  apiGET(`${urlWidgets}/listar/${pegeId}/${perfilId}/${usuaId}`).then(
    widgets => widgets
  );
const downloadLayouts = (pegeId, perfilId) =>
  apiGET(`${urlLayouts}/buscar/${pegeId}/${perfilId}`).then(layouts => layouts);
const insertLayouts = (layouts, pegeId) =>
  apiPOST(`${urlLayouts}/agregar/${pegeId}`, layouts).then(reponse => reponse);
const updateLayouts = (id, layouts) =>
  apiPUT(`${urlLayouts}/editar`, id, layouts).then(reponse => reponse);
const insertWidget = (widget, pegeId) =>
  apiPOST(`${urlWidgets}/agregar/${pegeId}`, widget).then(reponse => reponse);
const updateWidget = (id, widget) =>
  apiPUT(`${urlWidgets}/editar`, id, widget).then(reponse => reponse);
const deleteWidget = (id, widget) =>
  apiDELETE(`${urlWidgets}/eliminar`, id, widget).then(reponse => reponse);

function* viewNotificationSuccess(message) {
  yield put(setMessageNotificationACTI(message));
  yield put(setOpenNotificationSuccessACTI(true));
}

export function* viewNotificationError(message) {
  yield put(setMessageNotificationACTI(message ? message.toString() : null));
  yield put(setOpenNotificationErrorACTI(true));
}

const generateLayout = (widgets, types) => {
  let layouts = {};
  let breakpoints = {}; // se utiliza para almacenar las últimas coordenadas, ancho y alto para cada breakpoint.
  let x = 0,
    y = 0;
  widgets.forEach(widget => {
    types.forEach(type => {
      if (type.nombre === widget.tipo) {
        Object.entries(type.layouts).forEach(([prop, layout]) => {
          if (undefined === layouts[prop]) {
            layouts[prop] = [];
            breakpoints[prop] = {
              x,
              y,
              w: layout.w,
              h: layout.h
            };
          } else {
            x = breakpoints[prop].x + breakpoints[prop].w;

            if (x + layout.w > layout.maxW) {
              x = 0;
              y = breakpoints[prop].y + breakpoints[prop].h;
            } else {
              y = breakpoints[prop].y;
            }

            breakpoints[prop] = {
              x,
              y,
              w: layout.w,
              h: layout.h
            };
          }

          layouts[prop].push({
            i: widget.id,
            x: breakpoints[prop].x,
            y: breakpoints[prop].y,
            moved: false,
            static: false,
            estatico: false,
            ...layout,
            isResizable: layout.isResizable
              ? layout.isResizable
              : layout.resizable,
            isDraggable: layout.isDraggable
              ? layout.isDraggable
              : layout.draggable
          });
        });
      }
    });
  });

  return layouts;
};

function* handleAddLayout(widgets, profileId, userId, pegeId) {
  const types = yield select(state => state.typesREDU);
  const generatedLayouts = yield call(generateLayout, widgets, types);
  generatedLayouts.idPerfil = profileId;
  generatedLayouts.registradoPor = userId;
  const layouts = yield call(insertLayouts, generatedLayouts, pegeId);
  console.info("Layouts almacenados!");

  return layouts;
}

function* handleGenerateLayout(widgets, profile, user) {
  const generatedLayouts = yield call(
    handleAddLayout,
    widgets,
    profile.id,
    user.id.toString(),
    user.pegeId
  );
  yield put(
    addLayoutsStoreACTI({
      lg: generatedLayouts.lg,
      md: generatedLayouts.md,
      sm: generatedLayouts.sm,
      xs: generatedLayouts.xs
    })
  );
  yield put(addFullLayoutsStoreACTI(generatedLayouts));
}

export function* sagaDownloadWidgets(action) {
  try {
    const types = yield call(downloadTypes);

    if (!isEmpty(types)) {
      yield put(addTypesStoreACTI(types));
      const type = types[0];

      if (isNumber(type.limitWidgets)) {
        yield put(setLimitWidgetsACTI(type.limitWidgets));
      }

      const user = yield select(state => state.userREDU);
      const widgets = yield call(
        downloadWidgets,
        user.pegeId,
        action.profile.id,
        user.id
      );

      if (!isEmpty(widgets)) {
        yield put(addWidgetsStoreACTI(widgets));
        const layouts = yield call(
          downloadLayouts,
          user.pegeId,
          action.profile.id
        );

        if (isEmpty(layouts)) {
          yield call(handleGenerateLayout, widgets, action.profile, user);
        } else {
          const breakpoints = ["lg", "md", "sm", "xs"];
          const diffFound = Object.entries(layouts).find(
            ([prop, layout]) =>
              breakpoints.includes(prop) && layout.length !== widgets.length
          );

          if (isEmpty(diffFound)) {
            yield put(
              addLayoutsStoreACTI({
                lg: layouts.lg,
                md: layouts.md,
                sm: layouts.sm,
                xs: layouts.xs
              })
            );
            yield put(addFullLayoutsStoreACTI(layouts));
          } else {
            yield call(handleGenerateLayout, widgets, action.profile, user);
          }
        }

        const controlDashboard = yield select(
          state => state.controlDashboardREDU
        );

        if (!controlDashboard.viewGrid) {
          yield put(setViewGridACTI(true));
        }
      } else {
        yield put(addLayoutsStoreACTI({}));
        yield put(addFullLayoutsStoreACTI({}));
        yield put(addWidgetsStoreACTI([]));
      }
    } else {
      yield put(addTypesStoreACTI([]));
      yield put(addLayoutsStoreACTI({}));
      yield put(addFullLayoutsStoreACTI({}));
      yield put(addWidgetsStoreACTI([]));
    }
  } catch (error) {
    yield call(viewNotificationError, error);
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
    yield put(addLayoutsStoreACTI({}));
  }
}

export function* sagaUpdateLayouts(action) {
  try {
    const user = yield select(state => state.userREDU);
    const layout = yield select(state => state.fullLayoutsREDU);
    let remainingLayout = {};
    let tempArray = [];
    let c = 0;

    // Cuándo se elimina un widget, se elimina solo para el breakpoint en el que se está trabajando en ese momento.
    // En caso de eliminar un widget, busca el array más pequeño para suprimir dicho widget en los demás breakpoints.
    // Esto se hace para que en el proceso de listar widgets, no se reinicie la configuración del usuario
    // debido a las incongruencias entre las longitudes de los arrays de cada breakpoint.
    Object.entries(action.layouts).forEach(([, values]) => {
      if (0 === tempArray.length || tempArray.length > values.length) {
        tempArray = [...values];
        c++;
      }
    });

    // (1 === c) Cuando no se elimina widget, no es necesario entrar por if.
    if (c > 1) {
      Object.entries(action.layouts).forEach(([prop, values]) => {
        remainingLayout[prop] = values.filter(
          layout => undefined !== tempArray.find(value => value.i === layout.i)
        );
      });
    } else {
      remainingLayout = { ...action.layouts };
    }

    const newLayout = {
      ...layout,
      ...remainingLayout,
      registradoPor: user.id.toString()
    };

    if (layout.id) {
      const updatedLayout = yield call(updateLayouts, layout.id, newLayout);

      if (updatedLayout) {
        console.info("Layouts actualizados!");
        yield put(addLayoutsStoreACTI(action.layouts));
        yield put(addFullLayoutsStoreACTI(updatedLayout));
        const controlDashboard = yield select(
          state => state.controlDashboardREDU
        );

        if (isEmpty(action.layouts[controlDashboard.breakpoint])) {
          yield put(setViewGridACTI(false));
        }

        const changeWidget = yield select(
          state => state.controlDashboardREDU.changeWidget
        );

        if (changeWidget) {
          yield call(viewNotificationSuccess);
          const alertDone = yield select(
            state => state.controlDashboardREDU.alertDone
          );

          if (alertDone) {
            yield put(setAlertDoneACTI(false));
          } else {
            // Esto cierra el dialog y le limpia el contenido.
            yield put(openDialogWidgetACTI(false));
            yield delay(600);
            yield put(setViewContentDialogACTI(false));
          }

          yield put(setChangeWidgetACTI(false));
          // Cerrar notificación después de 6 segundos
          yield delay(6000);
          yield put(setOpenNotificationSuccessACTI(false));
        }
        // updateLayout = false;
      }
    }
  } catch (error) {
    yield call(viewNotificationError, error);
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

function* handleUpdateWidget(addedWidget, widget) {
  let newObjItem = {};
  let widgetsCopy = yield select(state => state.widgetsREDU);
  let layoutsCopy = yield select(state => state.layoutsREDU);
  let types = yield select(state => state.typesREDU);
  Object.entries(layoutsCopy).forEach(([prop, layout]) => {
    let lastItem = layout[layout.length - 1];
    let type = types.find(type => type.nombre === widget.tipo);
    let w = type.layouts[prop].w;
    let h = type.layouts[prop].h;
    let x = lastItem.x + lastItem.w;
    x = x + w > lastItem.maxW ? 0 : x;
    let newItem = {
      i: addedWidget.id,
      x,
      y: 999999,
      w,
      h,
      isDraggable: true,
      isResizable: true,
      draggable: true,
      resizable: true,
      maxH: 49,
      minW: type.layouts[prop].minW,
      minH: type.layouts[prop].minH,
      maxW: type.layouts[prop].maxW,
      moved: false,
      static: false,
      estatico: false
    };
    layoutsCopy[prop].push(newItem);
    newObjItem[prop] = newItem;
  });
  addedWidget.newItem = newObjItem;
  widgetsCopy.push(addedWidget);
  yield put(addWidgetsStoreACTI(widgetsCopy));
  yield put(addLayoutsStoreACTI(layoutsCopy));
  const layout = yield select(state => state.fullLayoutsREDU);
  yield put(addFullLayoutsStoreACTI({ ...layout, ...layoutsCopy }));
  yield put(setChangeWidgetACTI(true));
}

export function* sagaInsertWidget(action) {
  try {
    const user = yield select(state => state.userREDU);
    action.widget.registradoPor = user.id.toString();
    const profile = yield select(state => state.profileREDU);
    action.widget.idPerfil = profile.id;

    if (undefined === action.widget.grados) {
      action.widget.grados = 60;
    }

    const addedWidget = yield call(insertWidget, action.widget, user.pegeId);

    if (412 === addedWidget.code) {
      yield put(
        setMessageNotificationACTI(
          addedWidget.message
            ? addedWidget.message.toString()
            : "Supera el límite permitido."
        )
      );
      yield put(setOpenNotificationWarningACTI(true));
      yield delay(600);
      // Esto cierra el dialog y le limpia el contenido.
      yield put(openDialogWidgetACTI(false));
      yield delay(600);
      yield put(setViewContentDialogACTI(false));
      // Cerrar notificación después de 6 segundos
      yield delay(6000);
      yield put(setOpenNotificationWarningACTI(false));
    } else {
      const widgets = yield select(state => state.widgetsREDU);

      if (isEmpty(widgets)) {
        widgets.push(addedWidget);
        const generatedLayouts = yield call(
          handleAddLayout,
          widgets,
          profile.id,
          user.id.toString(),
          user.pegeId
        );
        yield put(
          addLayoutsStoreACTI({
            lg: generatedLayouts.lg,
            md: generatedLayouts.md,
            sm: generatedLayouts.sm,
            xs: generatedLayouts.xs
          })
        );
        yield put(addFullLayoutsStoreACTI(generatedLayouts));
        yield action.setSubmitting(false);
        yield put(setViewGridACTI(true));
        yield call(successWidget);
      } else {
        yield call(handleUpdateWidget, addedWidget, action.widget);
      }
    }
  } catch (error) {
    yield action.setSubmitting(false);
    yield call(viewNotificationError, error);
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

function* successWidget() {
  yield call(viewNotificationSuccess);
  // Esto cierra el dialog y le limpia el contenido.
  yield put(openDialogWidgetACTI(false));
  yield delay(600);
  yield put(setViewContentDialogACTI(false));
  // Cerrar notificación después de 6 segundos
  yield delay(6000);
  yield put(setOpenNotificationSuccessACTI(false));
}

export function* sagaUpdateWidget(action) {
  try {
    const user = yield select(state => state.userREDU);
    action.widget.registradoPor = user.id.toString();
    const updatedWidget = yield call(updateWidget, action.id, action.widget);
    const widgets = yield select(state => state.widgetsREDU);
    const widgetsWithUpdate = widgets.map(widget =>
      widget.id !== action.id ? widget : updatedWidget
    );
    yield put(addWidgetsStoreACTI(widgetsWithUpdate));
    yield action.setSubmitting(false);
    yield call(successWidget);
  } catch (error) {
    console.error(error);
    yield action.setSubmitting(false);
    yield call(viewNotificationError, error);
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaDeleteWidget(action) {
  try {
    const user = yield select(state => state.userREDU);
    yield call(deleteWidget, action.id, {
      registradoPor: user.id.toString()
    });
    const widgets = yield select(state => state.widgetsREDU);
    const remainingWidgets = widgets.filter(widget => widget.id !== action.id);
    const remainingLayouts = {};
    const layouts = yield select(state => state.layoutsREDU);

    Object.entries(layouts).forEach(([prop, values]) => {
      remainingLayouts[prop] = values.filter(layout => layout.i !== action.id);
    });

    yield put(addWidgetsStoreACTI(remainingWidgets));
    yield put(addLayoutsStoreACTI(remainingLayouts));
    yield put(setAlertDoneACTI(true));
    yield put(setChangeWidgetACTI(true));
  } catch (error) {
    console.error(error);
    yield call(viewNotificationError, error);
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}
