import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  Checkbox,
  fade,
  makeStyles,
  withStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import SaveIcon from "@material-ui/icons/Save";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {
  downloadFunctionalitiesByAppAndParentACTI,
  downloadRootFunctionalitiesByAppACTI
} from "../../actions/administrator";
import { associateFunctionalitiesACTI } from "actions/users";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3
    }
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`
  }
}))(props => <TreeItem {...props} />);

const useStyles = makeStyles({
  root: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400
  },
  checkboxRoot: {
    padding: 0
  },
  title: {
    padding: 0,
    paddingTop: "24px",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "1.25rem"
  },
  functionality: {
    marginTop: 12
  }
});

function Functionalities(props) {
  const {
    applicationSettings,
    setFunctionalitiesToSave,
    disableSave,
    downloadRootFunctionalities,
    downloadFunctionalities,
    associateFunctionalities
  } = props;

  const classes = useStyles();

  const [functionalities, setFunctionalities] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [functionalitiesSelected, setFunctionalitiesSelected] = useState([]);
  const [saving, setSaving] = useState(false);

  const handleChecked = (event, functionality) => {
    event.preventDefault();
    setFunctionalitiesSelected(oldsSelected => {
      const functSelected = oldsSelected.find(
        oldSelected => oldSelected.id === functionality.id
      );

      if (isEmpty(functSelected)) {
        return [...oldsSelected, functionality];
      } else {
        return oldsSelected.filter(
          oldSelected => oldSelected.id !== functSelected.id
        );
      }
    });
  };

  const handleLabelClick = (event, functionality) => {
    if (
      undefined === functionality.functionalities ||
      null === functionality.functionalities
    ) {
      const params = {
        id: functionality.id,
        appId: functionality.idAplicacion,
        setFunctionalities,
        setExpanded
      };
      downloadFunctionalities(params);
    }
  };

  const handleAssociateFunctionalities = () => {
    const values = {
      idsFuncionalidades: !isEmpty(functionalitiesSelected)
        ? functionalitiesSelected.map(functionality => functionality.id)
        : [],
      rolAplicacion: {
        idRol: applicationSettings.roleId,
        idAplicacion: applicationSettings.id
      }
    };

    if (null === applicationSettings.roleId) {
      // Carga en memoria temporal.
      setFunctionalitiesToSave(oldFunctionalities => {
        return {
          ...oldFunctionalities,
          [applicationSettings.id]: values
        };
      });
    } else {
      // Almacena en base de datos.
      const extraParams = {
        setSaving
      };
      setSaving(true);
      associateFunctionalities(values, extraParams);
    }
  };

  const renderFunctionalities = functionality => {
    return (
      !isEmpty(functionality.functionalities) &&
      functionality.functionalities.map(subfunct => (
        <StyledTreeItem
          key={subfunct.id}
          nodeId={String(subfunct.id)}
          label={subfunct.nombre}
          icon={
            <Checkbox
              color="primary"
              checked={functionalitiesSelected.some(
                functionalitySelected =>
                  functionalitySelected.id === subfunct.id
              )}
              inputProps={{
                "aria-labelledby": `func-${subfunct.id}`
              }}
              className={classes.checkboxRoot}
            />
          }
          onIconClick={event => handleChecked(event, subfunct)}
          onLabelClick={event => handleLabelClick(event, subfunct)}
        >
          {renderFunctionalities(subfunct)}
        </StyledTreeItem>
      ))
    );
  };

  useEffect(() => {
    if (null !== applicationSettings) {
      const params = {
        id: applicationSettings.id,
        roleId: applicationSettings.roleId,
        setFunctionalitiesSelected,
        setFunctionalities
      };
      setFunctionalitiesSelected(oldsSelected =>
        isEmpty(oldsSelected) ? oldsSelected : []
      );
      setFunctionalities(null);
      downloadRootFunctionalities(params);
    }
  }, [applicationSettings, downloadRootFunctionalities]);

  return (
    <GridContainer m0>
      <GridItem xs={12} className={classes.title}>
        Funcionalidades
      </GridItem>
      {!isEmpty(applicationSettings) && (
        <GridItem xs={10} md={11} className={`p-0 ${classes.functionality}`}>
          <Typography variant="subtitle2" gutterBottom className="m-0">
            {applicationSettings.nombre}
          </Typography>
        </GridItem>
      )}
      {!isEmpty(applicationSettings) ? (
        null === functionalities ? (
          <div className={classes.functionality}>Cargando...</div>
        ) : (
          isEmpty(functionalities) && (
            <div className={classes.functionality}>
              <i>No hay funcionalidades para mostrar.</i>
            </div>
          )
        )
      ) : null}
      {!isEmpty(functionalities) && (
        <GridItem xs={2} md={1} className="p-0 d-flex justify-content-center">
          <Tooltip title="Guardar" aria-label="icon-save" placement="top">
            <span>
              <IconButton
                aria-label="save"
                color="primary"
                onClick={handleAssociateFunctionalities}
                disabled={saving || disableSave}
              >
                <SaveIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </GridItem>
      )}
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        disableSelection
        expanded={expanded}
        onNodeToggle={(event, nodeIds) => setExpanded(nodeIds)}
      >
        {!isEmpty(functionalities) &&
          functionalities.map(functionality => (
            <StyledTreeItem
              key={functionality.id}
              nodeId={String(functionality.id)}
              label={functionality.nombre}
              icon={
                <Checkbox
                  color="primary"
                  checked={functionalitiesSelected.some(
                    functionalitySelected =>
                      functionalitySelected.id === functionality.id
                  )}
                  inputProps={{ "aria-labelledby": `func-${functionality.id}` }}
                  className={classes.checkboxRoot}
                />
              }
              onIconClick={event => handleChecked(event, functionality)}
              onLabelClick={event => handleLabelClick(event, functionality)}
            >
              {renderFunctionalities(functionality)}
            </StyledTreeItem>
          ))}
      </TreeView>
    </GridContainer>
  );
}

Functionalities.propTypes = {
  applicationSettings: PropTypes.object,
  setFunctionalitiesToSave: PropTypes.func.isRequired,
  disableSave: PropTypes.bool,
  downloadRootFunctionalities: PropTypes.func.isRequired,
  downloadFunctionalities: PropTypes.func.isRequired,
  associateFunctionalities: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    downloadRootFunctionalities: params => {
      dispatch(downloadRootFunctionalitiesByAppACTI(params));
    },
    downloadFunctionalities: params => {
      dispatch(downloadFunctionalitiesByAppAndParentACTI(params));
    },
    associateFunctionalities: (applicationRoleFunctionality, extraParams) => {
      dispatch(
        associateFunctionalitiesACTI(applicationRoleFunctionality, extraParams)
      );
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Functionalities);
