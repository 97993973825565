import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import CustomTable from "components/Table/Table";
import { downloadRoleApplicationsACTI } from "../../actions/administrator";
import { ROWS_PER_PAGE } from "../../constants";

const columns = [
  { id: "nombre", label: "Nombre", minWidth: 75, width: "90%" },
  {
    id: "actions",
    label: "Acciones",
    minWidth: 70,
    width: "10%",
    align: "center"
  }
];

const appsTitleToolbar = {
  color: "textSecondary",
  content: "Aplicaciones"
};

function Applications(props) {
  const {
    roleEdit,
    setApplicationSettings,
    setApplicationIds,
    setPristine,
    applications,
    downloadRoleApplications
  } = props;

  const [rows, setRows] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [applicationsSelected, setApplicationsSelected] = useState([]);

  const onChangePage = (newPage, rowsPerPage) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage
    };
    downloadRoleApplications(params);
  };

  function createData(id, nombre, actions) {
    return { id, nombre, actions };
  }

  const handleSetRole = useCallback(
    application => {
      if (isEmpty(roleEdit)) {
        application.roleId = null;
      } else {
        application.roleId = roleEdit.id;
      }

      return application;
    },
    [roleEdit]
  );

  const generateActions = useCallback(
    application => {
      const actions = {
        type: "actions",
        values: [
          {
            title: "Funcionalidades",
            icon: "settings_applications",
            func: () => setApplicationSettings(handleSetRole(application))
          }
        ]
      };

      return actions;
    },
    [setApplicationSettings, handleSetRole]
  );

  const generateRows = useCallback(
    applications => {
      if (!isEmpty(applications)) {
        const newRows = Array.from(applications, application =>
          createData(
            application.id,
            application.nombre,
            generateActions(application)
          )
        );
        setRows(newRows);
      } else {
        setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
      }
    },
    [generateActions]
  );

  const handleGetSelected = selected => {
    setApplicationIds(selected);
    setPristine(
      isEqual(selected.sort(), applicationsSelected.sort()) ? undefined : false
    );
  };

  useEffect(() => {
    if (undefined !== applications.content) {
      generateRows(applications.content);
    }
  }, [applications, generateRows]);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE,
      roleId: !isEmpty(roleEdit) ? roleEdit.id : null,
      setApplicationsSelected
    };

    if (null === rows && firstRender) {
      downloadRoleApplications(params);
      setFirstRender(false);
    }
  }, [roleEdit, rows, firstRender, downloadRoleApplications]);

  return (
    <CustomTable
      tableHeaderColor="primary"
      checkColor="primary"
      columns={columns}
      rows={rows}
      totalElements={
        isEmpty(applications.content) ? 0 : applications.totalElements
      }
      action="checkbox"
      titleToolbar={appsTitleToolbar}
      onChangePage={onChangePage}
      defaultSelection={applicationsSelected}
      getSelected={handleGetSelected}
    />
  );
}

Applications.propTypes = {
  roleEdit: PropTypes.object,
  setApplicationSettings: PropTypes.func.isRequired,
  setApplicationIds: PropTypes.func.isRequired,
  setPristine: PropTypes.func.isRequired,
  applications: PropTypes.object.isRequired,
  downloadRoleApplications: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    applications: state.roleApplicationsREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadRoleApplications: params => {
      dispatch(downloadRoleApplicationsACTI(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Applications);
