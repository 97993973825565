import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Loading from "components/Progress/Loading";
import { ACCOUNT_PATH, ROOT_PATH } from "../../constants";
import {
  onAuthStateChanged,
  userLogout,
  removeCredentials,
  validateCredentials
} from "../../api/access";
import { addUserStoreACTI } from "actions";
import { userLogoutACTI } from "actions";

function RedirectHandler(props) {
  const history = useHistory();
  const { user, addUserStore, resetStore } = props;

  useEffect(() => {
    if (validateCredentials()) {
      onAuthStateChanged((user, error) => {
        if (user) {
          history.replace(ACCOUNT_PATH);
        } else if (error) {
          // cerrar sesión
          removeCredentials();
          addUserStore(userLogout());
          history.replace(`${ROOT_PATH}/login`);
        } else {
          // cerrar sesión
          addUserStore(userLogout());
          history.replace(`${ROOT_PATH}/login`);
        }
      });
    } else if (user.voluntary) {
      setTimeout(() => {
        resetStore();
      }, 1000);
    } else {
      history.push(ROOT_PATH);
    }
  }, [history, addUserStore, user.voluntary, resetStore]);

  return (
    <>
      {validateCredentials() && user.loggedIn ? (
        <Loading open text="Ingresando..." />
      ) : (
        user.voluntary && <Loading open text="Saliendo..." />
      )}
    </>
  );
}

RedirectHandler.propTypes = {
  user: PropTypes.object.isRequired,
  addUserStore: PropTypes.func.isRequired,
  resetStore: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.userREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addUserStore: user => {
      dispatch(addUserStoreACTI(user));
    },
    resetStore: () => {
      dispatch(userLogoutACTI());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectHandler);
