import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { IconButton, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import { ROWS_PER_PAGE } from "../../constants";

const styles = theme => ({
  searchUsers: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center"
    }
  }
});
const useStyles = makeStyles(styles);

function SearchUserForm(props) {
  const {
    users,
    rows,
    setUsers,
    setRows,
    findUserByDocument,
    findUsersByParams,
    setResetSelections,
    setPage,
    userDocument,
    setUserDocument,
    userName,
    setUserName
  } = props;
  const classes = useStyles();

  const handleDocument = e => {
    if (!isEmpty(users.content)) {
      setUsers({ content: [] });
      setPage(0);

      if (setResetSelections) {
        setResetSelections([]);
      }
    }

    const value = e.target.value.trim();
    setUserDocument(value.length >= 4 ? value : null);
  };

  const handleName = e => {
    if (!isEmpty(users.content)) {
      setUsers({ content: [] });
      setPage(0);

      if (setResetSelections) {
        setResetSelections([]);
      }
    }

    const value = e.target.value.trim();
    setUserName(value.length >= 6 ? value : null);
  };

  const handleDocumentKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (null !== userDocument && userDocument.length >= 4) {
        const params = {
          setRows,
          userDocument,
          setUsers,
          isPaginated: true
        };
        findUserByDocument(params);
      }
    }
  };

  const handleNameKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (null !== userName && userName.length >= 6) {
        const params = {
          setRows,
          pageNo: 0,
          pageSize: ROWS_PER_PAGE,
          userName,
          setUsers,
          isPaginated: true
        };
        findUsersByParams(params);
      }
    }
  };

  const handleFindUsers = () => {
    const params = {
      setRows,
      setUsers,
      isPaginated: true
    };

    if (null !== userDocument) {
      params.userDocument = userDocument;
      findUserByDocument(params);
    } else if (null !== userName) {
      params.pageNo = 0;
      params.pageSize = ROWS_PER_PAGE;
      params.userName = userName;
      findUsersByParams(params);
    }
  };

  return (
    <>
      <GridItem xs={10}>
        <GridContainer>
          <GridItem xs={12} md={4}>
            <CustomInput
              label="Documento"
              name="document"
              type="text"
              formControlProps={{
                fullWidth: true
              }}
              style={{ marginBottom: "8px" }}
              formControlRoot
              autoComplete="off"
              onChange={handleDocument}
              onKeyDown={handleDocumentKeyDown}
            />
          </GridItem>
          <GridItem xs={12} md={8}>
            <CustomInput
              label="Nombre"
              name="name"
              type="text"
              formControlProps={{
                fullWidth: true
              }}
              style={{ marginBottom: "8px" }}
              formControlRoot
              autoComplete="off"
              onChange={handleName}
              onKeyDown={handleNameKeyDown}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={2} className={classes.searchUsers}>
        <IconButton
          aria-label="search users"
          color="primary"
          disabled={
            ((null === userDocument || userDocument.length < 4) &&
              (null === userName || userName.length < 6)) ||
            (null !== userDocument && null !== userName) ||
            null === rows
          }
          onClick={handleFindUsers}
        >
          <SearchIcon />
        </IconButton>
      </GridItem>
    </>
  );
}

SearchUserForm.propTypes = {
  users: PropTypes.object.isRequired,
  rows: PropTypes.array,
  setUsers: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
  findUserByDocument: PropTypes.func.isRequired,
  findUsersByParams: PropTypes.func.isRequired,
  setResetSelections: PropTypes.func,
  setPage: PropTypes.func.isRequired,
  userDocument: PropTypes.string,
  setUserDocument: PropTypes.func.isRequired,
  userName: PropTypes.string,
  setUserName: PropTypes.func.isRequired
};

export default SearchUserForm;
