import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ManageUsers from "./ManageUsers";

function Users() {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <ManageUsers />
      </GridItem>
    </GridContainer>
  );
}

export default Users;
