import { call, put, delay, select } from "redux-saga/effects";
import { isEmpty } from "lodash";
import {
  addUserStoreACTI,
  setMessageNotificationACTI,
  setOpenNotificationErrorACTI,
  setOpenNotificationSuccessACTI,
  setOpenNotificationWarningACTI
} from "../actions";
import {
  addAnnouncementsAdminStoreACTI,
  addAnnouncementUsersStoreACTI
} from "../actions/announcements";
import { apiDELETE, apiGET, apiGETParam, apiPOST, apiPUT } from "../api";
import { onAuthStateChangedObj, userLogout } from "../api/access";
import { urlAnuncios, urlUsuariosManager } from "../api/urls";
import { getObjectForPaginatedTable } from "../helpers/functions";

const downloadAnnouncements = (pageNo, pageSize, params) =>
  apiGETParam(`${urlAnuncios}/pagina/${pageNo + 1}/${pageSize}`, params).then(
    notifications => notifications
  );

const findUserByDocument = userDocument =>
  apiGET(`${urlUsuariosManager}/buscar-usuario-documento/${userDocument}`).then(
    user => user
  );

const findUserByName = name =>
  apiGET(`${urlUsuariosManager}/buscar-usuarios-nombre/${name}`).then(
    user => user
  );

const insertAnnouncement = announcement =>
  apiPOST(`${urlAnuncios}/agregar`, announcement).then(reponse => reponse);

const updateAnnouncement = (id, announcement) =>
  apiPUT(`${urlAnuncios}/editar`, id, announcement).then(reponse => reponse);

const deleteAnnouncement = (id, announcement) =>
  apiDELETE(`${urlAnuncios}/eliminar`, id, announcement).then(
    response => response
  );

export function* sagaDownloadAnnouncementsAdmin(action) {
  try {
    const params = {
      sortField: "anun_fechainicial"
    };

    const announcements = yield call(
      downloadAnnouncements,
      action.params.pageNo,
      action.params.pageSize,
      params
    );
    yield put(addAnnouncementsAdminStoreACTI(announcements));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addAnnouncementsAdminStoreACTI({ content: [] }));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaFindAnnouncementUserByDocument(action) {
  try {
    yield put(addAnnouncementUsersStoreACTI(null));
    const user = yield call(findUserByDocument, action.userDocument);

    if (!isEmpty(user)) {
      const users = [user];
      yield put(addAnnouncementUsersStoreACTI(users));
    } else {
      yield put(addAnnouncementUsersStoreACTI([]));
    }
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addAnnouncementUsersStoreACTI([]));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaFindAnnouncementUsersByName(action) {
  try {
    yield put(addAnnouncementUsersStoreACTI(null));
    const users = yield call(findUserByName, action.name);
    yield put(addAnnouncementUsersStoreACTI(users));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addAnnouncementUsersStoreACTI([]));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

function* getAnnouncementObject(props) {
  if (isEmpty(props.recipients)) {
    yield props.extraParams.setSubmitting(false);
    yield put(
      setMessageNotificationACTI(
        "No se ha encontrado destinatario para el anuncio."
      )
    );
    yield put(setOpenNotificationWarningACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationWarningACTI(false));
  } else {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const announcement = {
        tema:
          props.announcement.topic.trim().length > 0
            ? props.announcement.topic
            : null,
        contenido: props.announcement.content,
        fechaInicial: props.announcement.initialDate.startOf("day").format(),
        fechaFinal: props.announcement.finalDate.startOf("day").format(),
        enlace:
          props.announcement.link.trim().length > 0
            ? props.announcement.link
            : null,
        banner: props.announcement.banner ? 1 : 0,
        registradoPor: authState.data.id
      };

      if ("profiles" === props.recipientType) {
        announcement.perfiles = props.recipients.map(recipient => ({
          perfil: {
            id: recipient.id,
            nombre: recipient.name
          }
        }));
      } else {
        // users
        announcement.usuarios = props.recipients.map(recipient => {
          const dataArray = recipient.name.split(" - ");

          return {
            usuario: {
              id: recipient.id,
              documento: dataArray[0],
              nombre: dataArray[1]
            }
          };
        });
      }

      return announcement;
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  }
}

export function* sagaInsertAnnouncement(action) {
  try {
    const announcement = yield call(getAnnouncementObject, action);

    if (announcement) {
      const announcementAdded = yield call(insertAnnouncement, announcement);
      const announcements = yield select(state => state.announcementsAdminREDU);
      let newAnnouncements;

      if (undefined === announcements.empty || announcements.empty) {
        newAnnouncements = getObjectForPaginatedTable([announcementAdded]);
      } else {
        const announcementsTemp = [...announcements.content];
        announcementsTemp.pop();
        newAnnouncements = {
          ...announcements,
          totalElements: announcements.totalElements + 1,
          content: [announcementAdded, ...announcementsTemp]
        };
      }

      yield put(addAnnouncementsAdminStoreACTI(newAnnouncements));
      yield action.extraParams.handleClose();
      yield action.extraParams.setSubmitting(false);
      yield put(setMessageNotificationACTI());
      yield put(setOpenNotificationSuccessACTI(true));
      yield delay(6000);
      yield put(setOpenNotificationSuccessACTI(false));
    }
  } catch (error) {
    yield action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaUpdateAnnouncement(action) {
  try {
    const announcement = yield call(getAnnouncementObject, action);

    if (announcement) {
      const announcementUpdated = yield call(
        updateAnnouncement,
        action.announcement.id,
        announcement
      );
      const announcements = yield select(state => state.announcementsAdminREDU);
      const announcementsTemp = announcements.content.map(announcement =>
        announcement.id !== action.announcement.id
          ? announcement
          : announcementUpdated
      );
      const newAnnouncements = {
        ...announcements,
        content: announcementsTemp
      };

      yield put(addAnnouncementsAdminStoreACTI(newAnnouncements));
      yield action.extraParams.handleClose();
      yield action.extraParams.setSubmitting(false);
      yield put(setMessageNotificationACTI());
      yield put(setOpenNotificationSuccessACTI(true));
      yield delay(6000);
      yield put(setOpenNotificationSuccessACTI(false));
    }
  } catch (error) {
    yield action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaDeleteAnnouncement(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    // Verificación de usuario autenticado.
    if (authState.data) {
      const announcement = {
        registradoPor: authState.data.id
      };
      yield call(deleteAnnouncement, action.announcement.id, announcement); // elimina el anuncio.
      const announcements = yield select(state => state.announcementsAdminREDU);

      // Si la cantidad de registros totales se desplegan en una sola página, no hay necesidad de volver a consultar.
      if (announcements.totalElements <= announcements.size) {
        const announcementsTemp = announcements.content.filter(
          announcement => announcement.id !== action.announcement.id
        );

        if (isEmpty(announcementsTemp)) {
          yield put(addAnnouncementsAdminStoreACTI({ content: [] }));
        } else {
          const newAnnouncements = getObjectForPaginatedTable(
            announcementsTemp,
            announcements.size,
            announcements.number
          );
          yield put(addAnnouncementsAdminStoreACTI(newAnnouncements));
        }
      } else {
        // Se procede a realizar la consulta nuevamente para refrescar los registros con sus nuevos datos de paginación.
        const number =
          announcements.number > 0 && 1 === announcements.numberOfElements
            ? announcements.number - 1
            : announcements.number;
        action.extraParams.onChangePage(number, announcements.size, number);
      }

      action.extraParams.setSubmitting(false);
      action.extraParams.handleCloseAlert();
      // Notificación de éxito.
      yield put(setMessageNotificationACTI());
      yield put(setOpenNotificationSuccessACTI(true));
      yield delay(6000);
      yield put(setOpenNotificationSuccessACTI(false));
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}
