import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import LayoutItem from "components/Grid/LayoutItem";
import {
  downloadWidgetsACTI,
  changeBreakpointACTI,
  updateLayoutsACTI,
  setSubmittingACTI,
  deleteWidgetACTI,
  openDialogWidgetACTI,
  setColorCardACTI,
  setColorCardA_ACTI,
  setColorCardB_ACTI,
  setWidgetUpdateACTI,
  setViewContentDialogACTI,
  setTypeWidgetACTI,
  checkColorGradWidgetACTI,
  setDegreedCardWidgetACTI,
  checkColorAltCardWidgetACTI,
  setTitleWidgetACTI,
  setCategoryWidgetACTI,
  setContentWidgetACTI,
  setIconCardWidgetACTI,
  checkIframeWidgetACTI,
  setChartTypeACTI,
  checkChartLegendACTI,
  checkChartTrendlinesACTI,
  checkChartStackedACTI,
  checkChartPieHoleACTI,
  addProfileStoreACTI,
  addLayoutsStoreACTI
} from "../../actions/dashboard";
import { handleChangeViewGrid } from "../../actions/handleActions";
// @material-ui/core
import { withStyles } from "@material-ui/core/styles";

import Infobox from "components/Widget/Infobox";
import Graph from "components/Widget/Graph";
import General from "components/Widget/General";
import { equalObjects } from "helpers/functions";
import ResponsiveDialog from "components/Dialog/ResponsiveDialog";
import ContentWidget from "components/Dialog/ContentWidget";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
// import iconStyles from "assets/jss/material-dashboard-react/views/iconsStyle.js";
import "../../assets/css/react-grid-layout.css";
import "../../assets/css/main.css";
import { TYPES_CHARTS } from "constants/dataGraphs";
import NoDataDisplay from "views/NotFound/NoDataDisplay";
import CircularIndeterminate from "components/Progress/CircularIndeterminate";

const ResponsiveGridLayout = WidthProvider(Responsive);
const breakpoints = { lg: 1280, md: 960, sm: 600, xs: 0 };
const cols = { lg: 18, md: 12, sm: 6, xs: 1 };

var updateLayout = false;

const stylesAux = {
  colorFondo: {
    backgroundColor: "#eee"
  },
  contentLoad: {
    height: "200px"
  },
  contentLoadLayouts: {
    height: "calc(100vh - 145px)"
  }
};

class MiDashboard extends Component {
  componentDidMount() {
    // window.addEventListener("resize", this.resize.bind(this));
    // this.resize();
    this.contentGrid = React.createRef();
    this.props.setProfile(this.props.profile);

    if (isEmpty(this.props.layouts) || this.isChangeProfile()) {
      this.props.downloadWidgets(this.props.profile);
    }
  }

  componentWillUnmount() {
    this.props.setLayout(null);
  }

  isChangeProfile() {
    if (
      isEmpty(this.props.widgets) ||
      this.props.profile.id !== this.props.widgets[0].idPerfil
    ) {
      return true;
    }

    return false;
  }

  // resize() {
  //   console.log("window:", window.innerWidth);
  //   this.setState({ widthWindow: 0 });
  //   setTimeout( () => this.setState({ widthWindow: window.innerWidth }), 100 );
  // }

  getColor = (
    color,
    colorGradA,
    colorGradB,
    isGrad,
    degreed,
    isAlternative
  ) => {
    let styles;

    if (isGrad) {
      styles = {
        color: {
          background: `linear-gradient(${degreed}deg, rgba(${colorGradA.r}, ${colorGradA.g}, ${colorGradA.b}, ${colorGradA.a}), rgba(${colorGradB.r}, ${colorGradB.g}, ${colorGradB.b}, ${colorGradB.a}))`,
          boxShadow:
            "0 4px 20px 0 rgba(" +
            `${colorGradA.r}, ${colorGradA.g}, ${colorGradA.b}` +
            ",.14), 0 7px 10px -5px rgba(" +
            `${colorGradA.r}, ${colorGradA.g}, ${colorGradA.b}` +
            ",.4)",
          color: isAlternative ? "#000" : "#fff"
        }
      };
    } else {
      styles = {
        color: {
          background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          boxShadow:
            "0 4px 20px 0 rgba(" +
            `${color.r}, ${color.g}, ${color.b}` +
            ",.14), 0 7px 10px -5px rgba(" +
            `${color.r}, ${color.g}, ${color.b}` +
            ",.4)",
          color: isAlternative ? "#000" : "#fff"
        }
      };
    }

    return styles.color;
  };

  generateWidget(widget) {
    const myColor = this.getColor(
      widget.color,
      widget.colorGradA,
      widget.colorGradB,
      widget.isColorGrad,
      widget.grados,
      widget.alternativo
    );
    const extraData = {};

    switch (widget.tipo) {
      case "infobox":
        return (
          <Infobox
            id={widget.id}
            color={myColor}
            titulo={widget.titulo}
            categoria={widget.categoria}
            contenido={widget.contenido}
            cssTransition={
              this.props.checkedEditDashboard ? "transicion translate-y" : ""
            }
            pathIcon={widget.icono.path}
            fromDash={true}
            handleDeleteWidget={this.handleDeleteWidget}
            handleClickOpenDialog={this.handleOpenDialogUpdate}
          />
        );
      case "graph":
        extraData.range = widget.rango;
        extraData.legend = widget.leyenda;
        extraData.trendlines = widget.tendencia;
        extraData.isStacked = widget.apilado;
        extraData.pieHole = widget.agujero;
        return (
          <Graph
            id={widget.id}
            color={myColor}
            titulo={widget.titulo}
            categoria={widget.categoria}
            contenido={widget.contenido}
            datos={widget.datos}
            fromDash={true}
            cssTransition={
              this.props.checkedEditDashboard
                ? "transicion translate-y"
                : undefined
            }
            handleDeleteWidget={this.handleDeleteWidget}
            handleClickOpenDialog={this.handleOpenDialogUpdate}
            extraData={extraData}
          />
        );
      case "general":
        return (
          <General
            id={widget.id}
            color={myColor}
            isAlternative={widget.alternativo}
            titulo={widget.titulo}
            categoria={widget.categoria}
            contenido={widget.contenido}
            isIframe={widget.iframe}
            fromDash={true}
            cssRegHover="region-hover"
            cssTransition={
              this.props.checkedEditDashboard ? "transicion translate-y" : ""
            }
            handleDeleteWidget={this.handleDeleteWidget}
            handleClickOpenDialog={this.handleOpenDialogUpdate}
          />
        );
      default:
        return null;
    }
  }

  generateDOM() {
    // console.info("generateDOM");
    return this.props.widgets.map(widget => {
      return (
        <LayoutItem
          key={widget.id}
          data-grid={
            widget.newItem ? widget.newItem[this.props.breakpoint] : {}
          }
        >
          {this.generateWidget(widget)}
        </LayoutItem>
      );
    });
  }

  onLayoutChange(layout, layouts) {
    if (updateLayout || !equalObjects(this.props.layouts, layouts)) {
      this.props.updateLayouts(layouts);
    }
  }

  onWidthChange(width) {
    //lg: 1280, md: 960, sm: 600, xs: 0
    if ("" === this.props.breakpoint) {
      // Solo para calcular el ancho inicial.
      let breakpoint;

      if (width <= 600) breakpoint = "xs";
      else if (width <= 960) breakpoint = "sm";
      else if (width <= 1280) breakpoint = "md";
      else breakpoint = "lg";

      this.props.updateBreakpoint(breakpoint);
    }
  }

  onBreakpointChange(newBreakpoint) {
    if ("lg" === newBreakpoint && window.innerWidth < 1367) {
      this.props.refreshViewGrid();
    }

    this.props.updateBreakpoint(newBreakpoint);
  }

  onDragStop(layout, oldItem, newItem) {
    // let widthGrid = this.contentGrid.current.offsetWidth;
    updateLayout = !equalObjects(oldItem, newItem);
  }

  onResizeStop(layout, oldItem, newItem) {
    updateLayout = !equalObjects(oldItem, newItem);
  }

  handleAcceptDialog = () => {
    document
      .getElementById("formWidget")
      .dispatchEvent(new Event("submit", { cancelable: true }));
    // document.getElementById("formWidget").submit();
  };

  handleDeleteWidget = id => {
    this.props.deleteWidget(id);
  };

  handleOpenDialogUpdate = id => {
    let widget = this.props.widgets.find(widget => widget.id === id);
    this.props.handleClickOpenDialog(widget);
  };

  render() {
    // console.log( "render called!", this.props.layouts );
    return (
      <>
        <div ref={this.contentGrid}>
          {null !== this.props.layouts ? (
            !isEmpty(this.props.layouts) && this.props.viewGrid ? (
              <ResponsiveGridLayout
                autoSize={true}
                breakpoints={breakpoints}
                rowHeight={30}
                margin={[10, 15]}
                cols={cols}
                className="js-grid-layout"
                isDraggable={this.props.checkedEditDashboard}
                isResizable={this.props.checkedEditDashboard}
                layouts={this.props.layouts}
                onLayoutChange={(layout, layouts) =>
                  this.onLayoutChange(layout, layouts)
                }
                onWidthChange={width => this.onWidthChange(width)}
                onBreakpointChange={
                  (newBreakpoint) /*, newCols*/ =>
                    this.onBreakpointChange(newBreakpoint)
                }
                onDragStop={(layout, oldItem, newItem) =>
                  this.onDragStop(layout, oldItem, newItem)
                }
                onResizeStop={(layout, oldItem, newItem) =>
                  this.onResizeStop(layout, oldItem, newItem)
                }
              >
                {this.generateDOM()}
              </ResponsiveGridLayout>
            ) : (
              <NoDataDisplay />
            )
          ) : (
            <div style={stylesAux.contentLoadLayouts}>
              <CircularIndeterminate />
            </div>
          )}
        </div>
        <ResponsiveDialog
          open={this.props.open}
          handleClose={this.props.handleClose}
          width="md"
          title={
            this.props.widgets.length >= this.props.limitWidgets
              ? "Widget (límite alcanzado)"
              : "Widget"
          }
          style={stylesAux.colorFondo}
          handleAccept={this.handleAcceptDialog}
          viewFooter={true}
        >
          {this.props.viewContentDialog ? (
            <ContentWidget
              types={this.props.types}
              color={this.props.color}
              colorGradA={this.props.colorGradA}
              colorGradB={this.props.colorGradB}
            />
          ) : (
            <div style={stylesAux.contentLoad}>Cargando...</div>
          )}
        </ResponsiveDialog>
      </>
    );
  }
}

MiDashboard.propTypes = {
  profile: PropTypes.object.isRequired,
  setProfile: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  widgets: PropTypes.array.isRequired,
  layouts: PropTypes.object,
  downloadWidgets: PropTypes.func.isRequired,
  deleteWidget: PropTypes.func.isRequired,
  updateLayouts: PropTypes.func.isRequired,
  breakpoint: PropTypes.string.isRequired,
  updateBreakpoint: PropTypes.func.isRequired,
  refreshViewGrid: PropTypes.func.isRequired,
  viewGrid: PropTypes.bool.isRequired,
  limitWidgets: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClickOpenDialog: PropTypes.func.isRequired,
  viewContentDialog: PropTypes.bool.isRequired,
  color: PropTypes.object.isRequired,
  colorGradA: PropTypes.object.isRequired,
  colorGradB: PropTypes.object.isRequired,
  checkedEditDashboard: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  chartData: PropTypes.object,
  setLayout: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    types: state.typesREDU,
    widgets: state.widgetsREDU,
    layouts: state.layoutsREDU,
    breakpoint: state.controlDashboardREDU.breakpoint,
    viewGrid: state.controlDashboardREDU.viewGrid,
    // alertDone: state.controlDashboardREDU.alertDone,
    changeWidget: state.controlDashboardREDU.changeWidget,
    limitWidgets: state.controlDashboardREDU.limitWidgets,
    open: state.dialogDashboardREDU,
    color: state.colorDashboardREDU.colorCard,
    colorGradA: state.colorDashboardREDU.colorCardA,
    colorGradB: state.colorDashboardREDU.colorCardB,
    viewContentDialog: state.viewContentDialogREDU,
    checkedEditDashboard: state.checkEditDashboardREDU,
    chartData: state.chartDataREDU
  };
};

const handleCloseDialog = dispatch => {
  dispatch(openDialogWidgetACTI(false));
  dispatch(setChartTypeACTI(null));
  setTimeout(() => {
    dispatch(setViewContentDialogACTI(false));
  }, 800);
};

const handleOpenDialog = (dispatch, widget) => {
  dispatch(setColorCardACTI(widget.color));
  dispatch(setColorCardA_ACTI(widget.colorGradA));
  dispatch(setColorCardB_ACTI(widget.colorGradB));
  dispatch(setTypeWidgetACTI(widget.tipo));
  dispatch(checkColorGradWidgetACTI(widget.isColorGrad));
  dispatch(setDegreedCardWidgetACTI(widget.grados));
  dispatch(checkColorAltCardWidgetACTI(widget.alternativo));
  dispatch(setTitleWidgetACTI(widget.titulo));
  dispatch(setCategoryWidgetACTI(widget.categoria));
  dispatch(setContentWidgetACTI(widget.contenido));

  if ("infobox" === widget.tipo) dispatch(setIconCardWidgetACTI(widget.icono));
  else if ("graph" === widget.tipo) {
    let chartType = TYPES_CHARTS.find(type => type.type === widget.datos.type);
    dispatch(setChartTypeACTI(chartType));
    dispatch(checkChartLegendACTI(widget.leyenda));

    if ("TR" === chartType.conf) {
      dispatch(checkChartTrendlinesACTI(widget.tendencia));
    } else if ("ST" === chartType.conf) {
      dispatch(checkChartStackedACTI(widget.apilado));
    } else if ("PI" === chartType.conf) {
      dispatch(checkChartPieHoleACTI(widget.agujero));
    }
  } else if ("general" === widget.tipo)
    dispatch(checkIframeWidgetACTI(widget.iframe));

  dispatch(setWidgetUpdateACTI(widget));
  dispatch(openDialogWidgetACTI(true));
  dispatch(setViewContentDialogACTI(true));
};

const mapDispatchToProps = dispatch => {
  return {
    setProfile: profile => {
      dispatch(addProfileStoreACTI(profile));
    },
    downloadWidgets: profile => {
      dispatch(downloadWidgetsACTI(profile));
    },
    updateBreakpoint: breakpoint => {
      dispatch(changeBreakpointACTI(breakpoint));
    },
    updateLayouts: layouts => {
      dispatch(updateLayoutsACTI(layouts));
    },
    refreshViewGrid: () => handleChangeViewGrid(dispatch),
    setSubmitting: isSubmitting => {
      dispatch(setSubmittingACTI(isSubmitting));
    },
    deleteWidget: id => {
      dispatch(deleteWidgetACTI(id));
    },
    handleClose: () => handleCloseDialog(dispatch),
    handleClickOpenDialog: widget => handleOpenDialog(dispatch, widget),
    setLayout: layout => {
      dispatch(addLayoutsStoreACTI(layout));
    }
  };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MiDashboard)
  )
);
