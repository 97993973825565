import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "assets/jss/material-dashboard-react/components/cardFooterStyle.js";
import PerfectScrollbar from "perfect-scrollbar";

const useStyles = makeStyles(styles);

export default function CardFooter(props) {
  const classes = useStyles();
  const { className, children, plain, profile, stats, chart, ...rest } = props;
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [classes.cardFooterProfile]: profile,
    [classes.cardFooterStats]: stats,
    [classes.cardFooterChart]: chart,
    [className]: className !== undefined
  });

  // ref to help us initialize PerfectScrollbar on windows devices
  const cardPanel = React.createRef();
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    let ps;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(cardPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      // console.log( ps );
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        // console.log( "destroy", ps );
        ps.destroy();
        ps = null;
      }
    };
  }, [cardPanel]);

  return (
    <div className={cardFooterClasses + " scroll"} {...rest} ref={cardPanel}>
      {children}
    </div>
  );
}

CardFooter.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  stats: PropTypes.bool,
  chart: PropTypes.bool,
  children: PropTypes.node
};
