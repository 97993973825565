import {
  setMessageNotificationACTI,
  setOpenNotificationSuccessACTI,
  setOpenNotificationWarningACTI,
  setOpenNotificationErrorACTI,
  setOpenNotificationGeneralACTI
} from "./index";
import { setViewGridACTI } from "./dashboard";
// import { call, put, select } from "redux-saga/effects";

export const handleNotificationSuccess = (dispatch, message) => {
  dispatch(setMessageNotificationACTI(message));
  dispatch(setOpenNotificationSuccessACTI(true));

  setTimeout(() => {
    dispatch(setOpenNotificationSuccessACTI(false));
  }, 6000);
};

export const handleNotificationWarning = (dispatch, message) => {
  dispatch(setMessageNotificationACTI(message));
  dispatch(setOpenNotificationWarningACTI(true));

  setTimeout(() => {
    dispatch(setOpenNotificationWarningACTI(false));
  }, 6000);
};

export const handleNotificationError = (dispatch, message) => {
  dispatch(setMessageNotificationACTI(message));
  dispatch(setOpenNotificationErrorACTI(true));

  setTimeout(() => {
    dispatch(setOpenNotificationErrorACTI(false));
  }, 6000);
};

export const handleNotificationGeneral = (dispatch, message) => {
  dispatch(setMessageNotificationACTI(message));
  dispatch(setOpenNotificationGeneralACTI(true));

  setTimeout(() => {
    dispatch(setOpenNotificationGeneralACTI(false));
  }, 6000);
};

export const handleChangeViewGrid = dispatch => {
  dispatch(setViewGridACTI(false));

  setTimeout(() => {
    dispatch(setViewGridACTI(true));
  }, 100);
};
