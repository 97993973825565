import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "rgba(244, 67, 54, 0.38)",
    color: theme.palette.error.dark,
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      maxWidth: 480
    }
  },
  section1: {
    padding: theme.spacing(3, 2)
  }
}));

function AutoProcessError(props) {
  const { automaticUser } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.section1}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h4">
              Conexión interrumpida
            </Typography>
          </Grid>
        </Grid>
        <Typography color="textSecondary" variant="body2">
          Se ha interrumpido el proceso de forma inesperada {":( "}
          {automaticUser.someReportProcessed &&
            "Al menos un proceso se ha completado pero no se alcanzó a generar el reporte."}
        </Typography>
      </div>
    </div>
  );
}

AutoProcessError.propTypes = {
  automaticUser: PropTypes.object.isRequired
};

export default AutoProcessError;
