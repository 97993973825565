import React from "react";
import PropTypes from "prop-types";
import Snackbar from "./Snackbar";
// @material-ui/icons
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";

const SnackbarError = ({ message, open, handleClose }) => {
  return (
    <Snackbar
      icon={ErrorOutlineRoundedIcon}
      place="bl"
      color="danger"
      message={message ? message : "Se ha presentado un problema."}
      open={open}
      closeNotification={handleClose}
      close
    />
  );
};

SnackbarError.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default SnackbarError;
