import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles
} from "@material-ui/core";
import { useField } from "formik";
import { COLOR_APP_HEX } from "../../constants";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    "& label.MuiFormLabel-root": {
      color: "#AAA",
      fontSize: "14px"
    },
    "& label.Mui-focused": {
      color: COLOR_APP_HEX
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#D2D2D2"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLOR_APP_HEX
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR_APP_HEX
      },
      "&:hover fieldset": {
        borderColor: COLOR_APP_HEX
      },
      "&.Mui-focused fieldset": {
        borderColor: COLOR_APP_HEX
      }
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const SelectFormik = props => {
  const {
    label,
    values,
    setValue,
    style,
    selected,
    disabled,
    resetSelection
  } = props;
  const classes = useStyles();
  const [field, meta] = useField({ ...props });
  const [val, setVal] = useState("");

  const handleChange = e => {
    const { onChange } = field;
    onChange(e);
    setVal(e.target.value);

    if (setValue) {
      setValue("" !== e.target.value ? values[e.target.value] : null);
    }
  };

  useEffect(() => {
    if (undefined !== selected && "" !== selected) {
      setVal(oldVal => (oldVal !== selected ? selected : oldVal));
    }
  }, [selected]);

  useEffect(() => {
    if (resetSelection) {
      setVal(oldVal => ("" !== oldVal ? "" : oldVal));

      if (setValue) {
        setValue(null);
      }
    }
  }, [resetSelection, setValue]); // resetSelection es de tipo array para que el efecto se vuelva a ejecutar cada vez que se llame setResetSelection.

  return (
    <FormControl
      className={classes.formControl}
      style={style}
      error={meta.touched && meta.error ? true : false}
      disabled={undefined !== disabled ? disabled : false}
    >
      <InputLabel id={field.labelId}>{label}</InputLabel>
      <Select
        className={classes.selectEmpty}
        {...field}
        onChange={handleChange}
        value={val}
      >
        <MenuItem value="">
          <em>Ninguno</em>
        </MenuItem>
        {values.map((item, index) => (
          <MenuItem value={index} key={item.nombre}>
            {item.name ? item.name : item.nombre}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

SelectFormik.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  setValue: PropTypes.func,
  style: PropTypes.object,
  selected: PropTypes.any,
  disabled: PropTypes.bool,
  resetSelection: PropTypes.array
};

export default SelectFormik;
