import React from "react";
import PropTypes from "prop-types";
import { FixedSizeGrid as Grid } from "react-window";
import contentWidgetStyle from "assets/jss/material-dashboard-react/components/contentWidgetStyle";
import {
  makeStyles,
  SvgIcon,
  withStyles,
  Button,
  Tooltip
} from "@material-ui/core";
import { COLOR_APP_HEX, COLOR_APP_HEX_B } from "../../constants";

const useStyles = makeStyles(contentWidgetStyle);

const IconsMD = props => {
  const { icons, tabName, selected, handleClickIcon, height, width } = props;
  const classes = useStyles();

  const ColorButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(COLOR_APP_HEX),
      backgroundColor: COLOR_APP_HEX,
      "&:hover": {
        backgroundColor: COLOR_APP_HEX_B
      }
    }
  }))(Button);

  const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
      color: theme.palette.common.black
    },
    tooltip: {
      backgroundColor: theme.palette.common.black
    }
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  const arrayLength = icons.length;
  const columnsCount = 15;
  const quotient = Math.floor(arrayLength / columnsCount);
  const remainder = arrayLength % columnsCount;
  const rowsCount = remainder > 0 ? quotient + 1 : quotient;
  let itemsArray = [];

  if (rowsCount > 0) {
    const rowsArray = new Array(rowsCount);
    let flag;
    itemsArray = Array.from(rowsArray, (val, i) => i).map((row, index) => {
      flag = 0 === index ? 0 : flag + columnsCount;
      const items = icons.slice(flag, flag + columnsCount);
      return items;
    });
  }

  const Cell = ({ columnIndex, rowIndex, style }) => {
    const item = itemsArray[rowIndex][columnIndex];

    return undefined !== item ? (
      <div style={style}>
        <BootstrapTooltip title={item.name} placement="bottom">
          <ColorButton
            cols={1}
            variant="contained"
            color="primary"
            className={
              selected && selected === item.name
                ? classes.margin + " selected"
                : classes.margin
            }
            onClick={() =>
              handleClickIcon({
                nombre: item.name,
                path: item.path,
                nombreTab: tabName
              })
            }
          >
            <SvgIcon>
              <path d={item.path} />
            </SvgIcon>
          </ColorButton>
        </BootstrapTooltip>
      </div>
    ) : null;
  };

  Cell.propTypes = {
    columnIndex: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired
  };

  return itemsArray.length > 0 ? (
    <Grid
      columnCount={columnsCount}
      columnWidth={80}
      height={height - (49 + 48)}
      rowCount={rowsCount}
      rowHeight={50}
      width={width - 48}
    >
      {Cell}
    </Grid>
  ) : (
    <div>No hay iconos para mostrar.</div>
  );
};

IconsMD.propTypes = {
  icons: PropTypes.array,
  tabName: PropTypes.string.isRequired,
  selected: PropTypes.string,
  handleClickIcon: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default IconsMD;
