import React, { useCallback, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import csv from "csv";
import { FormGroup, SvgIcon, CircularProgress } from "@material-ui/core";
// import moment from "moment";
// import "moment/locale/es";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputFormik from "components/CustomInput/InputFormik";
import CheckboxFormik from "components/CustomInput/CheckboxFormik";
import {
  getGoogleColorsDefault,
  emptyObject,
  equalObjects,
  replaceAll
} from "../../helpers/functions";
import {
  setChartDataACTI,
  checkChartLegendACTI,
  checkChartTrendlinesACTI,
  setChartRangeNameACTI,
  setPristineGraphACTI,
  checkChartStackedACTI,
  checkChartPieHoleACTI
} from "../../actions/dashboard";
import { useButtonStyles } from "assets/jss/material-dashboard-react/buttonStyle";
import {
  handleNotificationWarning,
  handleNotificationError
} from "../../actions/handleActions";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#9e9e9e",
  outline: "none",
  transition: "border .24s ease-in-out",
  justifyContent: "center"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

const fileUploaded = {
  backgroundColor: "#5cb750"
};

const iconStyles = {
  backgroundColor: "transparent",
  height: 92,
  width: "85%",
  border: "none",
  outline: "none",
  color: "rgba(255, 255, 255, 0.84)"
};

const iconSize = {
  fontSize: 40
};

function FormGraph(props) {
  const {
    widget,
    setFieldValue,
    setPristine,
    chartType,
    setChartData,
    legend,
    trendlines,
    checkChartLegend,
    checkChartTrendlines,
    setRange,
    showNotificationWarning,
    showNotificationError,
    isStacked,
    checkChartStacked,
    pieHole,
    checkChartPieHole
  } = props;
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useButtonStyles();

  const handleChangeRange = value => {
    setRange(value);
  };

  const handleClickLegend = e => {
    let value = JSON.parse(e.target.value.toLowerCase());

    if (!emptyObject(widget)) {
      widget.leyenda !== !value ||
      (undefined !== widget.tendencia && widget.tendencia !== trendlines) ||
      (undefined !== widget.apilado && widget.apilado !== isStacked) ||
      (undefined !== widget.agujero && widget.agujero !== pieHole)
        ? setPristine(false)
        : setPristine(true);
    }

    checkChartLegend(!value);
  };

  const handleClickTrendlines = e => {
    let value = JSON.parse(e.target.value.toLowerCase());

    if (!emptyObject(widget)) {
      widget.tendencia !== !value || widget.leyenda !== legend
        ? setPristine(false)
        : setPristine(true);
    }

    checkChartTrendlines(!value);
  };

  const handleClickStacked = e => {
    let value = JSON.parse(e.target.value.toLowerCase());

    if (!emptyObject(widget)) {
      widget.apilado !== !value || widget.leyenda !== legend
        ? setPristine(false)
        : setPristine(true);
    }

    checkChartStacked(!value);
  };

  const handleClickPieHole = e => {
    let value = JSON.parse(e.target.value.toLowerCase());

    if (!emptyObject(widget)) {
      widget.agujero !== !value || widget.leyenda !== legend
        ? setPristine(false)
        : setPristine(true);
    }

    checkChartPieHole(!value);
  };

  const handleFileClose = e => {
    e.preventDefault();
    setChartData(!emptyObject(widget) ? widget.datos : null);
    !emptyObject(widget) && setPristine(true);
    setIsFileUploaded(false);
  };

  const handleFormat = value => {
    let result = Number(value);
    // moment.locale("es");
    // moment(new Date(Date.parse(value))).format("ll");

    if (Number.isNaN(result)) {
      result = new Date(Date.parse(value));

      if ("Invalid Date" === result.toString()) {
        try {
          if (
            value.includes("{") &&
            value.includes("'") &&
            value.includes("}")
          ) {
            result = replaceAll(value, "'", '"');
            result = JSON.parse(result);
          } else {
            result = value;
          }
        } catch (error) {
          result = value;
        }
      }
    }

    return result;
  };

  const onDrop = useCallback(
    acceptedFiles => {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onabort = () => {
        setIsLoading(false);
        showNotificationWarning("file reading was aborted"); // show notification warning
      };
      reader.onerror = () => {
        setIsLoading(false);
        showNotificationError("file reading has failed"); // show notification error
      };
      reader.onload = () => {
        // binaryStr = reader.result
        csv.parse(
          reader.result,
          { delimiter: [",", ";", "|"] },
          (err, data) => {
            try {
              if (undefined === err) {
                const arrayResults = [];

                data.forEach((values, index) => {
                  let data;
                  index > 0
                    ? (data = Array.from(values, (fact, index) =>
                        index > 0 ? Number(fact) : handleFormat(fact)
                      ))
                    : (data = values);
                  arrayResults.push(data);
                });

                const chartData = {
                  type: chartType.type,
                  values: arrayResults,
                  colors:
                    arrayResults[0].length > 2
                      ? getGoogleColorsDefault(0, arrayResults[0].length - 1)
                      : "PI" === chartType.conf
                      ? getGoogleColorsDefault(0, arrayResults.length - 1)
                      : null,
                  hTitle: arrayResults[0][0]
                };

                setIsLoading(false);
                setChartData(chartData);
                setIsFileUploaded(true);
                setPristine(
                  !(
                    !emptyObject(widget) &&
                    !equalObjects(widget.datos, chartData)
                  )
                );
              } else {
                showNotificationError(err.toString());
              }
            } catch (error) {
              setIsLoading(false);
              showNotificationError(error);
            }
          }
        );
      };
      acceptedFiles.forEach(file => {
        reader.readAsBinaryString(file);
      });
    },
    [
      chartType,
      setChartData,
      widget,
      setPristine,
      showNotificationWarning,
      showNotificationError
    ]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    disabled: isFileUploaded,
    accept: ".csv, application/vnd.ms-excel, text/csv",
    onDrop
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isFileUploaded ? fileUploaded : {})
    }),
    [isDragActive, isDragReject, isDragAccept, isFileUploaded]
  );

  const additionalConf = configuration => {
    switch (configuration) {
      case "TR":
        return (
          <CheckboxFormik
            name="tendencia"
            label="Tendencia"
            onClick={handleClickTrendlines}
          />
        );
      case "ST":
        return (
          <CheckboxFormik
            name="apilado"
            label="Apilado"
            onClick={handleClickStacked}
          />
        );
      case "PI":
        return (
          <CheckboxFormik
            name="agujero"
            label="Agujero"
            onClick={handleClickPieHole}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => {
      if (emptyObject(widget)) {
        setChartData(null);
        checkChartLegend(true);
        checkChartTrendlines(false);
        checkChartStacked(false);
        setRange("");
        setFieldValue("leyenda", true);
        setFieldValue("tendencia", false);
        setFieldValue("rango", "", false);
        setFieldValue("apilado", false);
        setPristine(true);
      } else {
        setChartData(widget.datos);
        checkChartLegend(widget.leyenda);
        if (widget.rango) setRange(widget.rango);
        setFieldValue("leyenda", widget.leyenda);
        setFieldValue("rango", widget.rango, false);
      }
    };
  }, [
    widget,
    setChartData,
    checkChartLegend,
    checkChartTrendlines,
    setRange,
    setFieldValue,
    setPristine,
    checkChartStacked
  ]);

  // useEffect(() => {
  //   if (!emptyObject(widget)) {
  //     let chartType = TYPES_CHARTS.find(
  //       type => type.type === widget.datos.type
  //     );

  //     if ("TR" === chartType.conf) {
  //       checkChartTrendlines(widget.tendencia);
  //       setFieldValue("tendencia", widget.tendencia);
  //     } else if ("ST" === chartType.conf) {
  //       checkChartStacked(widget.apilado);
  //       setFieldValue("apilado", widget.apilado);
  //     }
  //   }
  // }, [widget, chartType, checkChartTrendlines, checkChartStacked]);

  useEffect(() => {
    if (emptyObject(widget)) {
      setChartData(null);
      setIsFileUploaded(false);
    }
  }, [chartType, widget, setChartData]);

  return (
    <GridContainer>
      <GridItem xs={12} md={4}>
        <div style={{ marginTop: 21, minHeight: 116 }}>
          <div
            {...getRootProps({
              style
            })}
          >
            {isFileUploaded ? (
              <>
                <button style={iconStyles} onClick={e => handleFileClose(e)}>
                  <SvgIcon style={iconSize}>
                    <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20C20,21.1 19.1,22 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M14,15V11H10V15H12.3C12.6,17 12,18 9.7,19.38L10.85,20.2C13,19 14,16 14,15Z" />
                  </SvgIcon>
                </button>
                {isLoading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </>
            ) : (
              <>
                <input {...getInputProps()} />
                <p
                  style={{
                    fontSize: 12,
                    height: 70,
                    lineHeight: 1.5,
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  Arrastre su archivo csv aquí, o clic para buscarlo
                </p>
              </>
            )}
          </div>
        </div>
      </GridItem>
      <GridItem
        xs={12}
        md={8}
        style={"PI" === chartType.conf ? { marginTop: "1.3rem" } : {}}
      >
        {"PI" !== chartType.conf && (
          <GridContainer>
            <GridItem xs={12}>
              <InputFormik
                label="Eje opuesto"
                id="rango"
                name="rango"
                type="text"
                handleChange={handleChangeRange}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </GridContainer>
        )}
        <FormGroup row>
          <CheckboxFormik
            name="leyenda"
            label="Leyendas"
            onClick={handleClickLegend}
          />
          {additionalConf(chartType.conf)}
        </FormGroup>
      </GridItem>
    </GridContainer>
  );
}

FormGraph.propTypes = {
  widget: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  setPristine: PropTypes.func.isRequired,
  chartType: PropTypes.object,
  setChartData: PropTypes.func.isRequired,
  legend: PropTypes.bool.isRequired,
  trendlines: PropTypes.bool.isRequired,
  checkChartLegend: PropTypes.func.isRequired,
  checkChartTrendlines: PropTypes.func.isRequired,
  setRange: PropTypes.func.isRequired,
  showNotificationWarning: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  isStacked: PropTypes.bool.isRequired,
  checkChartStacked: PropTypes.func.isRequired,
  pieHole: PropTypes.bool.isRequired,
  checkChartPieHole: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    chartType: state.chartTypeREDU,
    legend: state.chartLegendREDU,
    trendlines: state.chartTrendlinesREDU,
    isStacked: state.chartStackedREDU,
    pieHole: state.chartPieHoleREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setChartData: data => {
      dispatch(setChartDataACTI(data));
    },
    checkChartLegend: isChecked => {
      dispatch(checkChartLegendACTI(isChecked));
    },
    checkChartTrendlines: isChecked => {
      dispatch(checkChartTrendlinesACTI(isChecked));
    },
    setRange: range => {
      dispatch(setChartRangeNameACTI(range));
    },
    setPristine: pristine => {
      dispatch(setPristineGraphACTI(pristine));
    },
    showNotificationWarning: message =>
      handleNotificationWarning(dispatch, message),
    showNotificationError: message =>
      handleNotificationError(dispatch, message),
    checkChartStacked: isChecked => {
      dispatch(checkChartStackedACTI(isChecked));
    },
    checkChartPieHole: isChecked => {
      dispatch(checkChartPieHoleACTI(isChecked));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormGraph);
