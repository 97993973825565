import { call, delay, put, select } from "redux-saga/effects";
import { isEmpty } from "lodash";
import CryptoJS from "crypto-js";
import {
  urlPerfiles,
  urlProgramas,
  urlRoles,
  urlUnidades,
  urlUsuarios,
  urlUsuariosAdmin
} from "../api/urls";
import { apiDELETE, apiGET, apiGETParam, apiPOST, apiPUT } from "../api";
import {
  addUserStoreACTI,
  setMessageNotificationACTI,
  setOpenNotificationErrorACTI
} from "../actions";
import { addAutomaticUserStoreACTI, addRolesStoreACTI } from "../actions/users";
import { onAuthStateChangedObj, userLogout } from "../api/access";
import { generar, getObjectForPaginatedTable } from "../helpers/functions";
import { launchError, launchSuccess, launchWarning } from "./notification";

const downloadRoles = (pageNo, pageSize, params) =>
  apiGETParam(`${urlRoles}/pagina/${pageNo + 1}/${pageSize}`, params).then(
    roles => roles
  );
const insertRole = role =>
  apiPOST(`${urlRoles}/agregar`, role).then(reponse => reponse);
const updateRole = (id, role) =>
  apiPUT(`${urlRoles}/editar`, id, role).then(reponse => reponse);
const deleteRole = (id, role) =>
  apiDELETE(`${urlRoles}/eliminar`, id, role).then(reponse => reponse);
const associateApplications = roleApplication =>
  apiPOST(`${urlRoles}/asociar-aplicaciones`, roleApplication).then(
    reponse => reponse
  );
const associateFunctionalities = applicationRoleFunctionality =>
  apiPOST(
    `${urlRoles}/asociar-funcionalidades`,
    applicationRoleFunctionality
  ).then(reponse => reponse);
const associateApplicationsAndFunctionalities = (
  applicationRoleFunctionalityList,
  roleId,
  registeredBy
) =>
  apiPUT(
    `${urlRoles}/asociar-aplicaciones-funcionalidades`,
    `${roleId}/${registeredBy}`,
    applicationRoleFunctionalityList
  ).then(reponse => reponse);
const downloadUserProfiles = userId =>
  apiGET(`${urlPerfiles}/listar/usuario/${userId}`).then(response => response);
const downloadUserRoles = userId =>
  apiGET(`${urlRoles}/listar/usuario/${userId}`).then(response => response);
const findExistingUser = pegeId =>
  apiGET(`${urlUsuarios}/buscar-persona/${pegeId}`).then(response => response);
const updateUserStatus = (id, user) =>
  apiPUT(`${urlUsuarios}/editar-estado`, id, user).then(reponse => reponse);
const saveUser = (user, pkp) =>
  apiPOST(`${urlUsuariosAdmin}/guardar-usuario`, user, pkp).then(
    reponse => reponse
  );
const updateUser = (id, user, pkp) =>
  apiPUT(`${urlUsuariosAdmin}/guardar-usuario`, id, user, pkp).then(
    reponse => reponse
  );
const downloadUnits = (pageNo, pageSize, params) =>
  apiGETParam(
    `${urlUnidades}/activas/pagina/${pageNo + 1}/${pageSize}`,
    params
  ).then(units => units);
const downloadProgramUnits = (pageNo, pageSize, unitId) =>
  apiGET(
    `${urlProgramas}/unidad/${unitId}/pagina/${pageNo + 1}/${pageSize}`
  ).then(programUnits => programUnits);
const processAutomaticUser = user =>
  apiPOST(`${urlUsuariosAdmin}/procesar-usuario-automatico`, user).then(
    reponse => reponse
  );

export function* sagaDownloadRoles(action) {
  try {
    const params = {
      sortField: "nombre",
      sortDirection: "asc"
    };

    const roles = yield call(
      downloadRoles,
      action.params.pageNo,
      action.params.pageSize,
      params
    );
    yield put(addRolesStoreACTI(roles));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addRolesStoreACTI({ content: [] }));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaInsertRole(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const role = {
        nombre: action.role.name,
        descripcion:
          action.role.description.trim().length > 0
            ? action.role.description
            : null,
        estado: "1",
        publico: "0"
      };

      if ("" !== action.role.profile) {
        const profiles = yield select(state => state.globalProfilesREDU);
        role.perfil = profiles[action.role.profile];
      }

      role.registradoPor = authState.data.id;
      const roleAdded = yield call(insertRole, role);
      const roles = yield select(state => state.rolesREDU);
      let newRoles;

      if (undefined === roles.empty || roles.empty) {
        newRoles = getObjectForPaginatedTable([roleAdded]);
      } else {
        const rolesList = [roleAdded, ...roles.content];

        if (rolesList.length > roles.size) {
          rolesList.pop();
        }

        newRoles = {
          ...roles,
          totalElements: roles.totalElements + 1,
          content: rolesList
        };
      }

      yield put(addRolesStoreACTI(newRoles));

      // Asociar aplicaciones a rol.
      try {
        const roleApplication = {
          idRol: roleAdded.id,
          registradoPor: authState.data.id,
          idsAplicaciones: action.role.applicationIds
        };
        yield call(associateApplications, roleApplication);
      } catch (error) {
        action.extraParams.handleCloseManageRole();
        action.extraParams.setSubmitting(false);
        yield call(
          launchWarning,
          'Se ha registrado el rol, pero ha ocurrido un problema mientras se asociaba una aplicación. Intenta gestionar nuevamente el rol a través de la opción "Editar".'
        );
        return;
      }

      // Asociar aplicaciones y funcionalidades a rol.
      if (!isEmpty(action.extraParams.functionalitiesToSave)) {
        try {
          const applicationRoleFunctionalityList = Object.entries(
            action.extraParams.functionalitiesToSave
          ).map(([, values]) => values);
          const applicationRoleFunctionalityListFiltered = applicationRoleFunctionalityList.filter(
            appRoleFunct =>
              action.role.applicationIds.some(
                appId => appId === appRoleFunct.rolAplicacion.idAplicacion
              )
          );

          if (!isEmpty(applicationRoleFunctionalityListFiltered)) {
            const associationResponse = yield call(
              associateApplicationsAndFunctionalities,
              applicationRoleFunctionalityListFiltered,
              roleAdded.id,
              authState.data.id
            );
            const found = Object.entries(associationResponse).some(
              ([, value]) => isEmpty(value)
            );

            action.extraParams.handleCloseManageRole();
            action.extraParams.setSubmitting(false);

            if (found) {
              yield call(
                launchWarning,
                'Se ha registrado el rol, pero una o varias aplicaciones y/o funcionalidades no se ha podido asociar. Revisa el rol a través de la opción "Editar".'
              );
            } else {
              yield call(launchSuccess);
            }
          } else {
            action.extraParams.handleCloseManageRole();
            action.extraParams.setSubmitting(false);
            yield call(launchSuccess);
          }
        } catch (error) {
          action.extraParams.handleCloseManageRole();
          action.extraParams.setSubmitting(false);
          yield call(
            launchWarning,
            'Se ha registrado el rol, pero ha ocurrido un problema mientras se asociaba una aplicación y/o funcionalidad. Intenta gestionar nuevamente el rol a través de la opción "Editar".'
          );
        }
      } else {
        action.extraParams.handleCloseManageRole();
        action.extraParams.setSubmitting(false);
        yield call(launchSuccess);
      }
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield call(launchError, error);
  }
}

export function* sagaUpdateRole(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const role = {
        nombre: action.role.name,
        descripcion:
          action.role.description.trim().length > 0
            ? action.role.description
            : null,
        estado: "1",
        publico: "0"
      };

      if ("" !== action.role.profile) {
        const profiles = yield select(state => state.globalProfilesREDU);
        role.perfil = profiles[action.role.profile];
      }

      role.registradoPor = authState.data.id;
      const roleUpdated = yield call(updateRole, action.role.id, role);
      const roles = yield select(state => state.rolesREDU);
      const rolesContent = roles.content.map(role =>
        role.id !== action.role.id ? role : roleUpdated
      );
      const newRoles = {
        ...roles,
        content: rolesContent
      };
      yield put(addRolesStoreACTI(newRoles));

      // Asociar aplicaciones a rol.
      try {
        const roleApplication = {
          idRol: action.role.id,
          registradoPor: authState.data.id,
          idsAplicaciones: action.role.applicationIds
        };
        yield call(associateApplications, roleApplication);
      } catch (error) {
        action.extraParams.handleCloseManageRole();
        action.extraParams.setSubmitting(false);
        yield call(
          launchWarning,
          "Se ha modificado el rol, pero ha ocurrido un problema mientras procesaba una aplicación. Intenta gestionar nuevamente el rol."
        );
        return;
      }

      action.extraParams.handleCloseManageRole();
      action.extraParams.setSubmitting(false);
      yield call(launchSuccess);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield call(launchError, error);
  }
}

export function* sagaDeleteRole(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      action.role.registradoPor = authState.data.id;
      yield call(deleteRole, action.role.id, action.role);
      const roles = yield select(state => state.rolesREDU);

      // Si la cantidad de registros totales se desplegan en una sola página, no hay necesidad de volver a consultar.
      if (roles.totalElements <= roles.size) {
        const rolesContent = roles.content.filter(
          role => role.id !== action.role.id
        );

        if (isEmpty(rolesContent)) {
          yield put(addRolesStoreACTI({ content: [] }));
        } else {
          const newRoles = getObjectForPaginatedTable(
            rolesContent,
            roles.size,
            roles.number
          );
          yield put(addRolesStoreACTI(newRoles));
        }
      } else {
        // Se procede a realizar la consulta nuevamente para refrescar los registros con sus nuevos datos de paginación.
        const number =
          roles.number > 0 && 1 === roles.numberOfElements
            ? roles.number - 1
            : roles.number;
        action.extraParams.onChangePage(number, roles.size, number);
      }

      action.extraParams.setSubmitting(false);
      action.extraParams.handleCloseAlert();
      yield call(launchSuccess);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield call(launchError, error);
  }
}

export function* sagaAssociateFuncionalities(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      action.applicationRoleFunctionality.registradoPor = authState.data.id;
      yield call(associateFunctionalities, action.applicationRoleFunctionality);
      action.extraParams.setSaving(false);
      yield call(launchSuccess);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSaving(false);
    yield call(launchError, error);
  }
}

export function* sagaDownloadUserProfiles(action) {
  try {
    const profiles = yield call(downloadUserProfiles, action.params.userId);
    action.params.setProfilesSelected(profiles.map(profile => profile.id));
  } catch (error) {
    action.params.setProfilesSelected([]);
    yield call(launchError, error);
  }
}

export function* sagaDownloadUserRoles(action) {
  try {
    const roles = yield call(downloadUserRoles, action.params.userId);
    action.params.setRolesSelected(roles.map(role => role.id));
  } catch (error) {
    action.params.setRolesSelected([]);
    yield call(launchError, error);
  }
}

export function* sagaFindExistingUser(action) {
  try {
    const user = yield call(findExistingUser, action.person.id);

    if (isEmpty(user)) {
      action.params.setSelectedUser({
        pegeId: action.person.id,
        documento: action.person.documentoIdentidad,
        nombre: action.person.nombreCompleto,
        correo: action.person.mail
      });
    } else {
      action.params.setSelectedUser(user);
    }

    action.params.setSelectedPerson(null);
    action.params.handleClose();
  } catch (error) {
    action.params.setSelectedPerson(null);
    yield call(launchError, error);
  }
}

export function* sagaUpdateUserStatus(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const user = {
        estado: action.user.estado,
        registradoPor: authState.data.id
      };
      const userUpdated = yield call(updateUserStatus, action.user.id, user);
      action.user.estado = userUpdated.estado;
      action.extraParams.setUsers(oldUsers => {
        return {
          ...oldUsers,
          content: oldUsers.content.map(user => {
            if (user.id === action.user.id) {
              return action.user;
            }

            return user;
          })
        };
      });
      action.extraParams.setSubmitting(false);

      if ("0" === user.estado) {
        action.extraParams.handleCloseAlert();
      }

      yield call(launchSuccess);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield call(launchError, error);
  }
}

export function* sagaSaveUser(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const user = {
        ...action.user,
        registradoPor: authState.data.id
      };
      let keyEncryption;

      if (null !== user.cambiarContrasena) {
        keyEncryption = generar();
        user.cambiarContrasena = CryptoJS.AES.encrypt(
          user.cambiarContrasena,
          keyEncryption
        ).toString();
      }

      if (null === action.id) {
        yield call(saveUser, user, keyEncryption);
      } else {
        yield call(updateUser, action.id, user, keyEncryption);
      }

      action.extraParams.setSubmitting(false);
      action.extraParams.setProcess("");
      action.extraParams.setProcess(action.extraParams.process);
      yield call(launchSuccess);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield call(launchError, error);
  }
}

export function* sagaDownloadUnits(action) {
  try {
    const params = {
      sortField: "unid_nombre",
      sortDirection: "asc"
    };

    const units = yield call(
      downloadUnits,
      action.params.pageNo,
      action.params.pageSize,
      params
    );
    action.params.setUnits(units);
  } catch (error) {
    action.params.setUnits({ content: [] });
    yield call(launchError, error);
  }
}

export function* sagaDownloadProgramUnits(action) {
  try {
    const programUnits = yield call(
      downloadProgramUnits,
      action.params.pageNo,
      action.params.pageSize,
      action.params.unitId
    );
    action.params.setProgramUnits(programUnits);
  } catch (error) {
    action.params.setProgramUnits({ content: [] });
    yield call(launchError, error);
  }
}

export function* sagaProcessAutomaticUser(action) {
  try {
    yield put(addAutomaticUserStoreACTI(null));
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const user = {
        ...action.user,
        registradoPor: authState.data.id
      };
      const automaticUser = yield call(processAutomaticUser, user);
      yield put(addAutomaticUserStoreACTI(automaticUser));
      action.extraParams.setSubmitting(false);
      action.extraParams.setOperation("noAction");
      action.extraParams.setResetProfile([]);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield put(addAutomaticUserStoreACTI({}));
    yield call(launchError, error);
  }
}
