import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, SvgIcon } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputFormik from "components/CustomInput/InputFormik";

const formControlProps = {
  fullWidth: true
};
const marginInputFormik = { marginBottom: "8px" };

function ManageApplication(props) {
  const { initialIcon, initialColorIcon } = props;
  const [icon, setIcon] = useState("");
  const [colorIcon, setColorIcon] = useState("#ccc");

  useEffect(() => {
    setIcon(initialIcon);
  }, [initialIcon]);

  useEffect(() => {
    setColorIcon(initialColorIcon);
  }, [initialColorIcon]);

  return (
    <GridContainer>
      <GridItem xs={12} md={6}>
        <InputFormik
          label="Nombre"
          id="name"
          name="name"
          type="text"
          formControlProps={formControlProps}
          style={marginInputFormik}
          formControlRoot
          autoComplete="off"
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <InputFormik
          label="URL"
          id="url"
          name="url"
          type="text"
          formControlProps={formControlProps}
          style={marginInputFormik}
          formControlRoot
          autoComplete="off"
        />
      </GridItem>
      <GridItem xs={12}>
        <InputFormik
          label="Descripción"
          id="description"
          name="description"
          type="text"
          formControlProps={formControlProps}
          style={marginInputFormik}
          formControlRoot
          autoComplete="off"
          multiline
          rows={3}
        />
      </GridItem>
      <GridItem xs={12} md={10}>
        <InputFormik
          label="Ícono"
          id="icon"
          name="icon"
          type="text"
          formControlProps={formControlProps}
          style={marginInputFormik}
          formControlRoot
          autoComplete="off"
          handleChange={setIcon}
        />
      </GridItem>
      <GridItem xs={2} sm={1}>
        <InputFormik
          label="Color"
          id="color"
          name="color"
          type="color"
          formControlProps={formControlProps}
          style={marginInputFormik}
          formControlRoot
          autoComplete="off"
          handleChange={setColorIcon}
        />
      </GridItem>
      <GridItem xs={3} sm={2} md={1}>
        <IconButton aria-label="display" style={{ color: colorIcon }}>
          <SvgIcon>
            <path d={icon} />
          </SvgIcon>
        </IconButton>
      </GridItem>
      <GridItem xs={7} md={6}>
        <InputFormik
          label="Estilo (obsoleto)"
          id="style"
          name="style"
          type="text"
          formControlProps={formControlProps}
          style={marginInputFormik}
          formControlRoot
          autoComplete="off"
        />
      </GridItem>
    </GridContainer>
  );
}

ManageApplication.propTypes = {
  initialIcon: PropTypes.string.isRequired,
  initialColorIcon: PropTypes.string.isRequired
};

export default ManageApplication;
