import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setOpenNotificationSuccessACTI,
  setOpenNotificationWarningACTI,
  setOpenNotificationErrorACTI,
  setOpenNotificationGeneralACTI,
  loadOperationACTI
} from "../actions";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import MiDashboard from "views/Dashboard/MiDashboard";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
// import "../assets/css/main.css";

import logo from "assets/img/logo.png";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Banner from "components/Banner/Banner";
import SnackbarSuccess from "components/Snackbar/SnackbarSuccess";
import SnackbarWarning from "components/Snackbar/SnackbarWarning";
import SnackbarError from "components/Snackbar/SnackbarError";
import SnackbarGeneral from "components/Snackbar/SnackbarGeneral";
import { ACCOUNT_PATH } from "../constants";
import componentRoutes from "../componentRoutes";
// import { firebaseRequestPermission } from "firebaseConfig";

let ps;
const SwitchRoutes = ({ user }) => (
  <Switch>
    <Route
      path={ACCOUNT_PATH + "/perfil"}
      component={componentRoutes.profile}
    />
    <Route
      path={ACCOUNT_PATH + "/notificaciones"}
      component={componentRoutes.notifications}
    />
    {user.perfiles && user.perfiles.length > 0
      ? user.perfiles.map(prop => {
          // if (prop.layout === "/e-campus/usuario") {
          return (
            <Route
              path={ACCOUNT_PATH + prop.path}
              render={props => <MiDashboard {...props} profile={prop} />}
              key={prop.id}
            />
          );
          // }
          // return null;
        })
      : null}
    {user.funcionalidades && user.funcionalidades.length > 0
      ? user.funcionalidades.map(prop => {
          return (
            <Route
              path={ACCOUNT_PATH + prop.urlRecurso}
              component={componentRoutes[prop.nombreFuncion]}
              key={prop.id}
            />
          );
        })
      : null}
    <Redirect
      from={ACCOUNT_PATH}
      to={
        ACCOUNT_PATH +
        (user.perfiles && user.perfiles.length > 0
          ? user.perfiles[0].path
          : "/perfil")
      }
    />
  </Switch>
);

SwitchRoutes.propTypes = {
  user: PropTypes.object.isRequired
};

const useStyles = makeStyles(styles("primary"));
const bgImage = process.env.REACT_APP_VORTAL_REPO_COVER_IMAGES;

function Admin(props) {
  const {
    openNotificationSuccess,
    openNotificationWarning,
    openNotificationError,
    openNotificationGeneral,
    messageNotification,
    handleCloseNotificationSuccess,
    handleCloseNotificationWarning,
    handleCloseNotificationError,
    handleCloseNotificationGeneral,
    user,
    loadOperation,
    ...rest
  } = props;
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  // const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = useState(false);
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/e-campus/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.userAgent.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.userAgent.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  React.useEffect(() => {
    loadOperation();
  }, [loadOperation]);

  // React.useEffect(() => {
  //   firebaseRequestPermission();
  // }, []);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        logoText={"Unipamplona"}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"custom"}
        user={user}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar user={user} handleDrawerToggle={handleDrawerToggle} {...rest} />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              {user.id && <SwitchRoutes user={user} />}
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            {user.id && <SwitchRoutes user={user} />}
          </div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
      {user.id && (
        <FixedPlugin
          // handleImageClick={handleImageClick}
          // handleColorClick={handleColorClick}
          // bgColor={color}
          // bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          classes={classes}
        />
      )}
      <ErrorBoundary>
        <Banner />
      </ErrorBoundary>
      <SnackbarSuccess
        open={openNotificationSuccess}
        message={messageNotification}
        handleClose={handleCloseNotificationSuccess}
      />
      <SnackbarWarning
        open={openNotificationWarning}
        message={messageNotification}
        handleClose={handleCloseNotificationWarning}
      />
      <SnackbarError
        open={openNotificationError}
        message={messageNotification}
        handleClose={handleCloseNotificationError}
      />
      <SnackbarGeneral
        open={openNotificationGeneral}
        message={messageNotification}
        handleClose={handleCloseNotificationGeneral}
      />
    </div>
  );
}

Admin.propTypes = {
  openNotificationSuccess: PropTypes.bool.isRequired,
  openNotificationWarning: PropTypes.bool.isRequired,
  openNotificationError: PropTypes.bool.isRequired,
  openNotificationGeneral: PropTypes.bool.isRequired,
  messageNotification: PropTypes.string,
  handleCloseNotificationSuccess: PropTypes.func.isRequired,
  handleCloseNotificationWarning: PropTypes.func.isRequired,
  handleCloseNotificationError: PropTypes.func.isRequired,
  handleCloseNotificationGeneral: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  loadOperation: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    openNotificationSuccess: state.notificationSuccessREDU,
    openNotificationWarning: state.notificationWarningREDU,
    openNotificationError: state.notificationErrorREDU,
    openNotificationGeneral: state.notificationGeneralREDU,
    messageNotification: state.messageNotificationREDU,
    user: state.userREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCloseNotificationSuccess: () => {
      dispatch(setOpenNotificationSuccessACTI(false));
    },
    handleCloseNotificationWarning: () => {
      dispatch(setOpenNotificationWarningACTI(false));
    },
    handleCloseNotificationError: () => {
      dispatch(setOpenNotificationErrorACTI(false));
    },
    handleCloseNotificationGeneral: () => {
      dispatch(setOpenNotificationGeneralACTI(false));
    },
    loadOperation: () => {
      dispatch(loadOperationACTI());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
