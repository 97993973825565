import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Button, makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputFormik from "components/CustomInput/InputFormik";
import RenderData from "components/CustomInput/RenderData";
import {
  passwordValidate,
  passwordValidateOptional
} from "../../yupValidations";
import NewUser from "./NewUser";
import SearchUser from "./SearchUser";
import Profiles from "./Profiles";
import Roles from "./Roles";
import {
  downloadUserProfilesACTI,
  downloadUserRolesACTI
} from "../../actions/users";

const formControlProps = {
  fullWidth: true
};
const marginInputFormik = { marginBottom: "8px" };
const styles = {
  content: {
    marginTop: "30px !important"
  }
};

const useStyles = makeStyles(styles);

function UserManual(props) {
  const {
    classes,
    isSubmitting,
    setFieldValue,
    handleReset,
    setSchemaManageUsers,
    setSelectedUserManual,
    handleOpenRoleAdd,
    handleOpenRoleEdit,
    setRoleIds,
    setProfileIds,
    downloadUserProfiles,
    downloadUserRoles
  } = props;

  const customClasses = useStyles();

  const [selectedUser, setSelectedUser] = useState(null);
  const [profilesSelected, setProfilesSelected] = useState(null);
  const [rolesSelected, setRolesSelected] = useState(null);
  const [clearRows, setClearRows] = useState(null);

  useEffect(() => {
    if (null !== selectedUser) {
      handleReset();

      if (undefined !== selectedUser.id && null !== selectedUser.id) {
        setFieldValue("username", selectedUser.usuario);
        setSchemaManageUsers(oldValues => ({
          ...oldValues,
          pass: passwordValidateOptional
        }));
        const params = {
          userId: selectedUser.id,
          setProfilesSelected,
          setRolesSelected
        };
        downloadUserProfiles(params);
        downloadUserRoles(params);
      } else {
        setSchemaManageUsers(oldValues => ({
          ...oldValues,
          pass: passwordValidate
        }));
        setProfilesSelected([]);
        setRolesSelected([]);
      }
    }
  }, [
    selectedUser,
    handleReset,
    setFieldValue,
    setSchemaManageUsers,
    downloadUserProfiles,
    downloadUserRoles
  ]);

  useEffect(() => {
    const values = {
      username: Yup.string()
        .trim()
        .required("Requerido")
        .min(8, "Mínimo 8 caracteres"),
      pass: passwordValidateOptional,
      passConfirm: Yup.string().test(
        "passwords-match",
        "Las contraseñas deben coincidir",
        function(value) {
          return this.parent.pass === value;
        }
      )
    };

    setSchemaManageUsers(values);
  }, [setSchemaManageUsers]);

  useEffect(() => {
    setSelectedUserManual(selectedUser);
  }, [setSelectedUserManual, selectedUser]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} md={2}>
          <NewUser
            setSelectedUser={setSelectedUser}
            setClearRows={setClearRows}
          />
        </GridItem>
        <GridItem xs={12} md={10}>
          <SearchUser setSelectedUser={setSelectedUser} clearRows={clearRows} />
        </GridItem>
      </GridContainer>
      {null !== selectedUser && (
        <GridContainer className={customClasses.content}>
          <GridItem xs={12} md={2}>
            <RenderData label="Documento" value={selectedUser.documento} />
          </GridItem>
          <GridItem xs={12} md={5}>
            <RenderData label="Nombre" value={selectedUser.nombre} />
          </GridItem>
          <GridItem xs={12} md={5}>
            <RenderData
              label="Correo electrónico"
              value={selectedUser.correo}
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <InputFormik
              label="Usuario"
              id="username"
              name="username"
              type="text"
              formControlProps={formControlProps}
              style={marginInputFormik}
              formControlRoot
              autoComplete="off"
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <InputFormik
              label="Contraseña"
              id="pass"
              name="pass"
              type="password"
              formControlProps={formControlProps}
              style={marginInputFormik}
              formControlRoot
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <InputFormik
              label="Confirmar contraseña"
              id="passConfirm"
              name="passConfirm"
              type="password"
              formControlProps={formControlProps}
              style={marginInputFormik}
              formControlRoot
            />
          </GridItem>
          <GridItem xs={12} md={5}>
            <Profiles
              setProfileIds={setProfileIds}
              selected={profilesSelected}
            />
          </GridItem>
          <GridItem xs={12} md={7}>
            <Roles
              handleOpenAdd={handleOpenRoleAdd}
              handleOpenEdit={handleOpenRoleEdit}
              setRoleIds={setRoleIds}
              selected={rolesSelected}
            />
          </GridItem>
          <GridItem xs={12} className={classes.btnProcesss}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}

UserManual.propTypes = {
  classes: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setSchemaManageUsers: PropTypes.func.isRequired,
  setSelectedUserManual: PropTypes.func.isRequired,
  handleOpenRoleAdd: PropTypes.func.isRequired,
  handleOpenRoleEdit: PropTypes.func.isRequired,
  setRoleIds: PropTypes.func.isRequired,
  setProfileIds: PropTypes.func.isRequired,
  downloadUserProfiles: PropTypes.func.isRequired,
  downloadUserRoles: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    downloadUserProfiles: params => {
      dispatch(downloadUserProfilesACTI(params));
    },
    downloadUserRoles: params => {
      dispatch(downloadUserRolesACTI(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserManual);
