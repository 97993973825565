import { ACTIONS } from "../constants";

export const downloadRoleApplicationsACTI = params => ({
  type: ACTIONS.DOWNLOAD_ROLE_APPLICATIONS,
  params
});

export const addRoleApplicationsStoreACTI = applications => ({
  type: ACTIONS.ADD_ROLE_APPLICATIONS_STORE,
  applications
});

export const downloadRootFunctionalitiesByAppACTI = params => ({
  type: ACTIONS.DOWNLOAD_ROOT_FUNCTIONALITIES_BY_APP,
  params
});

export const downloadFunctionalitiesByAppAndParentACTI = params => ({
  type: ACTIONS.DOWNLOAD_FUNCTIONALITIES_BY_APP_AND_PARENT,
  params
});

export const downloadApplicationsACTI = params => ({
  type: ACTIONS.DOWNLOAD_APPLICATIONS,
  params
});

export const updateApplicationACTI = (application, extraParams) => ({
  type: ACTIONS.UPDATE_APPLICATION,
  application,
  extraParams
});

export const downloadUsersAdminFuncACTI = params => ({
  type: ACTIONS.DOWNLOAD_USERS_ADMIN_FUNC,
  params
});

export const downloadSecurityRolesAdminFuncACTI = params => ({
  type: ACTIONS.DOWNLOAD_SECURITY_ROLES_ADMIN_FUNC,
  params
});

export const associateUserToSecurityRolesACTI = params => ({
  type: ACTIONS.ASSOCIATE_USER_TO_SECURITY_ROLES,
  params
});
