import { call, delay, put } from "redux-saga/effects";
import { isEmpty } from "lodash";
import { apiGET, apiGETParam, apiPOST, apiPUT } from "../api";
import { onAuthStateChangedObj, userLogout } from "../api/access";
import {
  urlAplicaciones,
  urlFuncionalidades,
  urlRolesSeguridad,
  urlUsuariosManager
} from "../api/urls";
import {
  addUserStoreACTI,
  setMessageNotificationACTI,
  setOpenNotificationErrorACTI
} from "../actions";
import { addRoleApplicationsStoreACTI } from "../actions/administrator";
import { launchError, launchSuccess } from "./notification";

const downloadApplications = (pageNo, pageSize) =>
  apiGET(`${urlAplicaciones}/resumidas/pagina/${pageNo + 1}/${pageSize}`).then(
    applications => applications
  );
const downloadApplicationsByRole = roleId =>
  apiGET(`${urlAplicaciones}/listar-por-rol/${roleId}`).then(
    applications => applications
  );
const downloadRootFunctionalitiesByApp = appId =>
  apiGET(`${urlFuncionalidades}/listar-padres-por-aplicacion/${appId}`).then(
    functionalities => functionalities
  );
const downloadFunctionalitiesByAppAndParent = (appId, parentFunc) =>
  apiGET(
    `${urlFuncionalidades}/listar-por-aplicacion/${appId}/padre/${parentFunc}`
  ).then(functionalities => functionalities);
const downloadFunctionalitiesByAppAndRole = (appId, roleId) =>
  apiGET(
    `${urlFuncionalidades}/listar-por-aplicacion/${appId}/rol/${roleId}`
  ).then(functionalities => functionalities);
const downloadApplicationsFunc = (pageNo, pageSize, params) =>
  apiGETParam(
    `${urlAplicaciones}/pagina/${pageNo + 1}/${pageSize}`,
    params
  ).then(reponse => reponse);
const updateApplication = (data, id) =>
  apiPUT(`${urlAplicaciones}/editar`, id, data).then(reponse => reponse);
const downloadUsersWithRoles = (pageNo, pageSize, params) =>
  apiGETParam(
    `${urlUsuariosManager}/usuarios-con-roles/pagina/${pageNo + 1}/${pageSize}`,
    params
  ).then(reponse => reponse);
const downloadSecurityRoles = () =>
  apiGET(`${urlRolesSeguridad}/listar-sin-perfil`).then(reponse => reponse);
const associateUser = data =>
  apiPOST(`${urlRolesSeguridad}/asociar-usuario`, data).then(
    reponse => reponse
  );

function* sagaDownloadApplicationsByRole(params) {
  try {
    const applications = yield call(downloadApplicationsByRole, params.roleId);
    params.setApplicationsSelected(
      applications.map(application => application.id)
    );
  } catch (error) {
    throw String(
      "Problemas al obtener las aplicaciones para el rol seleccionado.\n" +
        error
    );
  }
}

export function* sagaDownloadRoleApplications(action) {
  try {
    if (undefined !== action.params.roleId && null !== action.params.roleId) {
      yield call(sagaDownloadApplicationsByRole, action.params);
    }

    const applications = yield call(
      downloadApplications,
      action.params.pageNo,
      action.params.pageSize
    );
    yield put(addRoleApplicationsStoreACTI(applications));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addRoleApplicationsStoreACTI({ content: [] }));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

function* sagaDownloadFunctionalitiesByAppAndRole(params) {
  try {
    const functionalities = yield call(
      downloadFunctionalitiesByAppAndRole,
      params.id,
      params.roleId
    );
    params.setFunctionalitiesSelected(functionalities);
  } catch (error) {
    throw String(
      "Problemas al obtener las funcionalidades que tiene asociada esta aplicación para el rol seleccionado.\n" +
        error
    );
  }
}

export function* sagaDownloadRootFunctionalitiesByApp(action) {
  try {
    if (undefined !== action.params.roleId && null !== action.params.roleId) {
      yield call(sagaDownloadFunctionalitiesByAppAndRole, action.params);
    }

    const functionalities = yield call(
      downloadRootFunctionalitiesByApp,
      action.params.id
    );
    action.params.setFunctionalities(functionalities);
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    action.params.setFunctionalities([]);
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

const setFunctionalities = (functionalities, id, childFunctionalities) => {
  if (functionalities) {
    const functionality = functionalities.find(funct => funct.id === id);

    if (functionality) {
      functionality.functionalities = childFunctionalities;
    } else {
      functionalities.forEach(funct => {
        setFunctionalities(funct.functionalities, id, childFunctionalities);
      });
    }
  }
};

export function* sagaDownloadFunctionalitiesByAppAndParent(action) {
  try {
    const functionalities = yield call(
      downloadFunctionalitiesByAppAndParent,
      action.params.appId,
      action.params.id
    );
    action.params.setFunctionalities(oldFunctionalities => {
      const modifyFunctionalities = [...oldFunctionalities];
      setFunctionalities(
        modifyFunctionalities,
        action.params.id,
        functionalities
      );

      return modifyFunctionalities;
    });

    if (!isEmpty(functionalities)) {
      action.params.setExpanded(oldExpanded =>
        isEmpty(oldExpanded)
          ? [String(action.params.id)]
          : [...oldExpanded, String(action.params.id)]
      );
    }
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    action.params.setFunctionalities(oldFunctionalities => {
      const modifyFunctionalities = [...oldFunctionalities];
      setFunctionalities(modifyFunctionalities, action.params.id, null);

      return modifyFunctionalities;
    });
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaDownloadApplications(action) {
  try {
    const params = {
      sortField: "nombre",
      sortDirection: "asc"
    };
    const applications = yield call(
      downloadApplicationsFunc,
      action.params.pageNo,
      action.params.pageSize,
      params
    );
    action.params.setApplications(applications);
  } catch (error) {
    action.params.setApplications({ content: [] });
    yield call(launchError, error);
  }
}

export function* sagaUpdateApplication(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const application = {
        ...action.application,
        registradoPor: authState.data.id
      };
      const applicationUpdated = yield call(
        updateApplication,
        application,
        application.id
      );
      const applicationsContent = action.extraParams.applications.content.map(
        application =>
          application.id !== applicationUpdated.id
            ? application
            : applicationUpdated
      );
      const newApplications = {
        ...action.extraParams.applications,
        content: applicationsContent
      };
      action.extraParams.setApplications(newApplications);
      action.extraParams.setOpen(false);
      action.extraParams.setSubmitting(false);
      yield call(launchSuccess);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.extraParams.setSubmitting(false);
    yield call(launchError, error);
  }
}

export function* sagaDownloadUsersWithRoles(action) {
  try {
    const params = {
      sortField: "usua_nombre",
      sortDirection: "asc"
    };
    const users = yield call(
      downloadUsersWithRoles,
      action.params.pageNo,
      action.params.pageSize,
      params
    );
    action.params.setUsers(users);
  } catch (error) {
    action.params.setUsers({ content: [] });
    yield call(launchError, error);
  }
}

export function* sagaDownloadSecurityRolesAdminFunc(action) {
  try {
    const securityRoles = yield call(downloadSecurityRoles);
    action.params.setSecurityRoles(securityRoles);
  } catch (error) {
    action.params.setSecurityRoles([]);
    yield call(launchError, error);
  }
}

export function* sagaAssociateUserToSecurityRoles(action) {
  try {
    const authState = yield call(onAuthStateChangedObj);

    if (authState.data) {
      const user = {
        id: action.params.user.id,
        idsRoles: action.params.roles.map(role => role.id),
        registradoPor: authState.data.id
      };
      yield call(associateUser, user);
      const userUpdated = { ...action.params.user };
      userUpdated.roles = action.params.roles;
      const usersContent = action.params.users.content.map(user =>
        user.id !== userUpdated.id ? user : userUpdated
      );
      const newUsers = {
        ...action.params.users,
        content: usersContent
      };
      action.params.setUsers(newUsers);
      action.params.setOpen(false);
      action.params.setSubmitting(false);
      yield call(launchSuccess);
    } else if (authState.error) {
      throw authState.error.toString();
    } else {
      yield put(addUserStoreACTI(userLogout()));
    }
  } catch (error) {
    action.params.setSubmitting(false);
    yield call(launchError, error);
  }
}
