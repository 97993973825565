import React from "react";
import PropTypes from "prop-types";
import Chart from "react-google-charts";

function LineChart(props) {
  const { data, handleOpenColor, fromDash, extraData } = props;
  const { width, height, values, hTitle, vTitle, color, colors } = data;
  const { range, legend, trendlines } = extraData;
  const trendlinesVal = { 0: {} };

  if (trendlines && values[0].length > 2) {
    for (let j = 1; j < values[0].length - 1; j++) {
      trendlinesVal[j] = {};
    }
  }

  return (
    <Chart
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      chartType="LineChart"
      loader={<div>Cargando gráfica...</div>}
      data={values}
      options={{
        hAxis: {
          title: legend
            ? hTitle && "" !== hTitle.trim()
              ? hTitle
              : "Título"
            : null,
          titleTextStyle: {
            color: color ? color : "#fff"
          },
          textStyle: {
            color: color ? color : "#fff"
          },
          baselineColor: color ? color : "#fff",
          gridlines: { color: color ? color : "#fff" }
        },
        vAxis: {
          title: legend
            ? range && "" !== range.trim()
              ? range
              : vTitle
              ? vTitle
              : "Título"
            : null,
          titleTextStyle: {
            color: color ? color : "#fff"
          },
          textStyle: {
            color: color ? color : "#fff"
          },
          baselineColor: color ? color : "#fff",
          gridlines: { color: color ? color : "#fff" }
        },
        backgroundColor: {
          fill: "transparent"
        },
        colors: colors ? colors : [color],
        legend: legend
          ? {
              textStyle: { color: color ? color : "#fff" }
            }
          : "none",
        tooltip: {
          // isHtml: true,
          // textStyle: { color: "#FF0000" }
          showColorCode: !!colors
        },
        aggregationTarget: "auto",
        curveType: "function",
        // orientation: "vertical",
        trendlines: trendlines ? trendlinesVal : null
      }}
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();

            if (1 === selection.length && !fromDash) {
              const [selectedItem] = selection;

              if (!selectedItem.row) {
                handleOpenColor(selectedItem.column);
              }
            }
          }
        }
      ]}
      rootProps={{ "data-testid": "1" }} // investigar pa que sirve.
    />
  );
}

LineChart.propTypes = {
  data: PropTypes.object.isRequired,
  handleOpenColor: PropTypes.func,
  fromDash: PropTypes.bool,
  extraData: PropTypes.object.isRequired
};

export default LineChart;
