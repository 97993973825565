import { isEmpty, isObject } from "lodash";
import CryptoJS from "crypto-js";
import { urlAppAuth, urlAuth, urlGeojs, urlLogout, urlVerify } from "./urls";
import {
  b64EncodeUnicode,
  getCookie,
  generar,
  getPayloadJwt
} from "../helpers/functions";

export const validateCredentials = () => {
  if (
    !isEmpty(getUserKey()) &&
    !isEmpty(getToken()) &&
    !isEmpty(getKeySessionVor()) &&
    !isEmpty(getCookie("keySessionVor"))
  ) {
    return true;
  }

  return false;
};

export const removeCredentials = () => {
  localStorage.removeItem("userVor");
  localStorage.removeItem("keyVor");
  localStorage.removeItem("keyVorRefresh");
  localStorage.removeItem("keySessionVor");
  document.cookie =
    "keySessionVor=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/";
};

export const getUserKey = () => {
  return localStorage.getItem("userVor");
};

export const getToken = () => {
  return localStorage.getItem("keyVor");
};

export const getTokenRefresh = () => {
  return localStorage.getItem("keyVorRefresh");
};

export const getKeySessionVor = () => {
  return localStorage.getItem("keySessionVor");
};

export const getKeyLoginVor = () => {
  return localStorage.getItem("keyLoginVor");
};

export const getObjectLocal = key => {
  const object = localStorage.getItem(key);

  if (isEmpty(object)) {
    return null;
  }

  return JSON.parse(object);
};

export const userLogout = voluntary => {
  if (voluntary) {
    localStorage.removeItem("rememberUserVor");
    localStorage.removeItem("keyLoginVor");

    return {
      loggedIn: false,
      voluntary
    };
  } else {
    return { loggedIn: false };
  }
};

export const refreshToken = () => {
  let credentials = new URLSearchParams();
  credentials.append("grant_type", "refresh_token");
  credentials.append("refresh_token", getTokenRefresh());

  return fetch(urlAuth, {
    method: "POST",
    body: credentials,
    headers: new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic " +
        b64EncodeUnicode(
          process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD
        )
    })
  })
    .then(response => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (response.ok) {
          if (data.access_token) {
            localStorage.setItem("keyVor", data.access_token);
            // localStorage.setItem("keyVorRefresh", data.refresh_token);
          } else {
            document.cookie =
              "keySessionVor=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/";
            return Promise.reject("No se ha generado token de acceso");
          }
        } else {
          document.cookie =
            "keySessionVor=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/";
          return Promise.reject("No se pudo renovar el token");
        }

        return data;
      });
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

const geoLocation = () => {
  const lsLocation = sessionStorage.getItem("locVor");

  if (isEmpty(lsLocation)) {
    return fetch(urlGeojs, {
      method: "GET"
    })
      .then(response => response.json())
      .catch(() => {
        console.error("Fallo en geo intento de conexión");
      })
      .then(json => {
        const location = {
          city: json.city,
          region: json.region,
          country: json.country
        };
        sessionStorage.setItem("locVor", JSON.stringify(location));

        return json;
      });
  } else {
    return JSON.parse(lsLocation);
  }
};

export const getLocation = async () => {
  const location = await geoLocation();
  let strLocation = "";

  if (!isEmpty(location.city) || !isEmpty(location.region)) {
    if (!isEmpty(location.city)) {
      strLocation = location.city;
    }

    if (!isEmpty(location.region)) {
      if (!isEmpty(location.city)) {
        strLocation += ", ";
      }

      strLocation += location.region;
    }
  }

  return strLocation;
};

export const strEncrypt = strValue => {
  if (isEmpty(strValue)) {
    return null;
  }

  const keyEncryption = generar();
  const strEncrypted = CryptoJS.AES.encrypt(strValue, keyEncryption);

  return {
    encryptValue: strEncrypted.toString(),
    keyEncryption
  };
};

export const strDecrypt = (cipherText, key) => {
  if (isEmpty(cipherText)) {
    return null;
  }

  const strDecrypted = CryptoJS.AES.decrypt(cipherText, key).toString(
    CryptoJS.enc.Utf8
  );

  return strDecrypted;
};

/**
 * Función que genera token y credenciales de acceso a la aplicación.
 * Requisitos: librería crypto-js
 *
 * El token generado lo almacena en localStorage bajo la nomenclatura "keyVor".
 * Retorna promise con objeto json (then) o string con mensaje de error (catch).
 */
export const access = async (username, password, rememberme) => {
  let keyEncryption = generar();
  let key = generar();
  let user = CryptoJS.AES.encrypt(username, keyEncryption);
  let pass = CryptoJS.AES.encrypt(password, keyEncryption);
  let credentials = new URLSearchParams();
  credentials.append("username", user.toString());
  credentials.append("password", pass.toString());
  credentials.append("grant_type", "password");
  credentials.append("key", key);
  credentials.append("location", await getLocation());

  return fetch(urlAuth, {
    method: "POST",
    body: credentials,
    headers: new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic " +
        b64EncodeUnicode(
          process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD
        ),
      "Public-Key-Pins": keyEncryption
    })
  })
    .then(response => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (response.ok) {
          if (data.access_token) {
            localStorage.setItem("userVor", response.headers.get("Public-Id"));
            localStorage.setItem("keyVor", data.access_token);
            localStorage.setItem("keyVorRefresh", data.refresh_token);
            localStorage.setItem("keySessionVor", key);

            if (rememberme) {
              // eslint-disable-next-line
              document.cookie = `keySessionVor=${generar()}; SameSite=Strict; Path=/;max-age=${60*60*24*7}`;
            } else {
              document.cookie = `keySessionVor=${generar()}; SameSite=Strict; Path=/`;
            }
          } else {
            return Promise.reject("No se ha generado token de acceso.");
          }
        } else if (data && "invalid_grant" === data.error) {
          return Promise.reject("El usuario o contraseña está incorrecto.");
        } else {
          return Promise.reject("No se pudo procesar tu solicitud.");
        }

        return data;
      });
    })
    .catch(error => {
      console.error("Hubo un problema con la petición: " + error);

      if (
        "TypeError: Failed to fetch" === error.toString() ||
        "TypeError: NetworkError when attempting to fetch resource." ===
          error.toString()
      ) {
        return Promise.reject("Lo siento, no se pudo establecer la conexión.");
      } else {
        return Promise.reject(error);
      }
    });
};

const verify = async userId => {
  let credentials = {
    codigoAcceso: getKeySessionVor(),
    ubicacion: await getLocation()
  };

  if (!isEmpty(userId)) {
    credentials.idUsuario = userId;
  }

  return fetch(urlVerify, {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
      "Public-Key-Pins": getUserKey()
    })
  })
    .then(response => {
      return response.text().then(text => {
        if (response.ok) {
          return JSON.parse(text);
        } else if (401 === response.status) {
          try {
            let errorHandler = JSON.parse(text);
            console.error(errorHandler);
            removeCredentials();
          } catch (errorParse) {
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(text, "text/xml");
            let search = xmlDoc.getElementsByTagName("error_description")[0]
              .childNodes[0].nodeValue;

            if (-1 !== search.indexOf("Access token expired")) {
              return refreshToken()
                .then(() => {
                  return verify(userId);
                })
                .catch(error2 => {
                  console.error(error2);
                  removeCredentials();
                });
            }
          }
        } else {
          return Promise.reject(
            "Hubo un problema, por favor intenta más tarde."
          );
        }
      });
    })
    .catch(error => {
      console.error("Hubo un problema con la petición: " + error);

      if (
        "TypeError: Failed to fetch" === error.toString() ||
        "TypeError: NetworkError when attempting to fetch resource." ===
          error.toString()
      ) {
        return Promise.reject("Lo siento, no se pudo establecer la conexión.");
      } else {
        return Promise.reject(error);
      }
    });
};

export const onAuthStateChanged = async callback => {
  let data = null;
  let errorStr = null;

  if (validateCredentials()) {
    try {
      data = await verify();
    } catch (error) {
      errorStr = error;
    }
  } else {
    removeCredentials();
  }

  callback(data, errorStr);
};

export const onAuthStateChangedObj = async (userId = null) => {
  let data = null;
  let errorStr = null;

  if (validateCredentials()) {
    try {
      data = await verify(userId);
    } catch (error) {
      errorStr = error;
    }
  } else {
    removeCredentials();
  }

  return { data, error: errorStr };
};

export const logout = () => {
  let credentials = {
    codigoAcceso: getKeySessionVor()
  };

  return fetch(urlLogout, {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken()
    })
  })
    .then(() => {
      removeCredentials();
    })
    .catch(() => {
      removeCredentials();
    });
};

export const accessAppValidate = async (url, objectData, key) => {
  const code = CryptoJS.AES.encrypt(objectData.code, key);
  const pass = CryptoJS.AES.encrypt(objectData.password, key);
  const username = CryptoJS.AES.encrypt(objectData.username, key);
  const credentials = new URLSearchParams();
  credentials.append("codigoUsuario", objectData.userCode.toString());
  credentials.append("password", pass.toString());
  credentials.append("username", username.toString());

  return fetch(url, {
    method: "POST",
    body: credentials,
    headers: new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
      "Public-Key-Pins": code.toString()
    })
  })
    .then(response => {
      return response.text().then(text => {
        if (response.ok) {
          return text && JSON.parse(text);
        } else {
          return Promise.reject("No se pudo procesar tu solicitud.");
        }
      });
    })
    .catch(error => {
      console.error("Hubo un problema con la petición: " + error);

      if (
        "TypeError: Failed to fetch" === error.toString() ||
        "TypeError: NetworkError when attempting to fetch resource." ===
          error.toString()
      ) {
        return Promise.reject("Lo siento, no se pudo establecer la conexión.");
      } else {
        return Promise.reject(error);
      }
    });
};

export const accessAppOauth = data => {
  const payload = getPayloadJwt(getToken());

  if (null === payload) {
    return Promise.reject(
      "Credenciales de autenticación incompletas, recarga la página e intenta nuevamente."
    );
  }

  const keyEncryption = generar();
  const roleId = CryptoJS.AES.encrypt(
    data.role.id.toString(),
    process.env.REACT_APP_VORTAL_PRIVATE_KEY + keyEncryption
  );
  const username = CryptoJS.AES.encrypt(
    payload.user_name,
    process.env.REACT_APP_VORTAL_PRIVATE_KEY + keyEncryption
  );
  const password = CryptoJS.AES.encrypt(
    getKeySessionVor(),
    process.env.REACT_APP_VORTAL_PRIVATE_KEY + keyEncryption
  );
  const code = CryptoJS.AES.encrypt(
    data.code,
    process.env.REACT_APP_VORTAL_PRIVATE_KEY + keyEncryption
  );
  const token = `${process.env.REACT_APP_VORTAL_CLIENT}:${process.env.REACT_APP_VORTAL_CLIENT_PASS}`;
  const credentials = new URLSearchParams();
  credentials.append("username", username.toString());
  credentials.append("password", password.toString());
  credentials.append("grant_type", "password");
  credentials.append("roleId", roleId.toString());
  credentials.append("code", code.toString());

  return fetch(urlAppAuth, {
    method: "POST",
    body: credentials,
    headers: new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${b64EncodeUnicode(token)}`,
      "Public-Key-Pins": keyEncryption,
      Key: getUserKey()
    })
  })
    .then(response => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (response.ok) {
          if (data.access_token) {
            return { data, keyEncryption, status: 200 };
          } else {
            return Promise.reject("No se ha generado token de acceso.");
          }
        } else if (data && "invalid_grant" === data.error) {
          return Promise.reject("El usuario o contraseña está incorrecto.");
        } else {
          return Promise.reject("No se pudo procesar tu solicitud.");
        }
      });
    })
    .catch(error => {
      if (
        "TypeError: Failed to fetch" === error.toString() ||
        "TypeError: NetworkError when attempting to fetch resource." ===
          error.toString()
      ) {
        return Promise.reject("Lo siento, no se pudo establecer la conexión.");
      } else {
        let msgError =
          "Se ha presentado un inconveniente, por favor intenta más tarde.";

        if (isObject(error)) {
          if (error.mensaje) {
            msgError = error.mensaje;
          } else if (error.request) {
            msgError = "No hay conexión a internet.";
          } else {
            msgError = "No se pudo establecer la conexión.";
          }
        }

        return Promise.reject(msgError);
      }
    });
};
