import { ACTIONS } from "../constants";

export const addAnnouncementUsersStoreACTI = users => ({
  type: ACTIONS.ADD_ANNOUNCEMENT_USERS_STORE,
  users
});

export const addAnnouncementsAdminStoreACTI = announcements => ({
  type: ACTIONS.ADD_ANNOUNCEMENTS_ADMIN_STORE,
  announcements
});

export const downloadAnnouncementsAdminACTI = params => ({
  type: ACTIONS.DOWNLOAD_ANNOUNCEMENTS_ADMIN,
  params
});

export const findAnnouncementUserByDocumentACTI = userDocument => ({
  type: ACTIONS.FIND_ANNOUNCEMENT_USER_BY_DOCUMENT,
  userDocument
});

export const findAnnouncementUsersByNameACTI = name => ({
  type: ACTIONS.FIND_ANNOUNCEMENT_USERS_BY_NAME,
  name
});

export const insertAnnouncementACTI = (
  announcement,
  recipientType,
  recipients,
  extraParams
) => ({
  type: ACTIONS.INSERT_ANNOUNCEMENT,
  announcement,
  recipientType,
  recipients,
  extraParams
});

export const updateAnnouncementACTI = (
  announcement,
  recipientType,
  recipients,
  extraParams
) => ({
  type: ACTIONS.UPDATE_ANNOUNCEMENT,
  announcement,
  recipientType,
  recipients,
  extraParams
});

export const deleteAnnouncementACTI = (announcement, extraParams) => ({
  type: ACTIONS.DELETE_ANNOUNCEMENT,
  announcement,
  extraParams
});
