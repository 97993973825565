import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { isEmpty } from "lodash";
//core material
import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/Table/Table";
//core components
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import FormDialog from "../../components/Dialog/FormDialog";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import InputFormik from "../../components/CustomInput/InputFormik";
import SelectFormik from "../../components/CustomInput/SelectFormik";
import AlertDialog from "components/Dialog/AlertDialog";

import {
  listCategoriesACTI,
  newCategoriesACTI,
  deleteCategoriesACTI,
  updateCategoriesACTI,
  downloadParentCategoriesACTI
} from "../../actions/categorias";
import { ROWS_PER_PAGE } from "constants/index.js";

function RenderForm({ categoryUpdate, categoriesPadre }) {
  const iParentCategory =
    !isEmpty(categoryUpdate) && null !== categoryUpdate.idsuperior
      ? categoriesPadre.findIndex(
          category => category.id === categoryUpdate.idsuperior
        )
      : "";

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <InputFormik
          label="Nombre"
          id="nombre"
          name="nombre"
          type="text"
          formControlProps={{
            fullWidth: true
          }}
          style={{ marginBottom: "8px" }}
          formControlRoot
        />
        <InputFormik
          label="Descripción"
          id="descripcion"
          name="descripcion"
          type="text"
          formControlProps={{
            fullWidth: true
          }}
          style={{ marginBottom: "8px" }}
          formControlRoot
        />
        {(isEmpty(categoryUpdate) || 0 === categoryUpdate.cantidadHijos) && (
          <SelectFormik
            label={"Categoría padre"}
            values={categoriesPadre !== null ? categoriesPadre : []}
            selected={iParentCategory}
            id="parentCategory"
            name="parentCategory"
            style={{ width: "100%", marginLeft: 0 }}
          ></SelectFormik>
        )}
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={8} className={classes.contenidoFunc}>
        <FuncEdit />
      </GridItem> */}
    </GridContainer>
  );
}

RenderForm.propTypes = {
  categoryUpdate: PropTypes.object,
  categoriesPadre: PropTypes.array
};

//estilos
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  seccionAdmin: {
    marginTop: 0
  }
});

const categoriesTitleToolbar = {
  color: "textSecondary",
  content: "Lista de categorías"
};

const defaultValues = {
  nombre: "",
  descripcion: "",
  parentCategory: ""
};

function CategoriaList(props) {
  //constantes
  const {
    listaCategorias,
    downloadCategories,
    downloadParentCategories,
    addCategoryHand,
    deleteCategoryHand,
    updateCategoryHand
  } = props;
  const classes = useStyles();
  const yupObject = {
    nombre: Yup.string().required("Requerido"),
    descripcion: Yup.string().required("Requerido")
  };
  const alertFormDialog = {
    title: "Gestionar categoria",
    content: "¿Deseas continuar con el proceso?"
  };

  //hooks
  const [initialValues, setInitialValues] = useState({});
  const [open, setOpen] = useState(false);
  const [dataUpdate, setDataUpdate] = useState({});
  const [categoriesPadre, setCategoriesPadre] = useState(null);
  const [parentCategoriesCopy, setParentCategoriesCopy] = useState(null);
  const [accion, setAccion] = useState(null);
  const [rows, setRows] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [categoryEdit, setcategoryEdit] = useState(null);
  const [page, setPage] = useState(undefined);

  const columns = [
    { id: "nombre", label: "Nombre", minWidth: 70, width: "25%" },
    { id: "descripcion", label: "Descripción", minWidth: 75, width: "40%" },
    { id: "padre", label: "Categoría padre", minWidth: 75, width: "25%" },
    {
      id: "actions",
      label: "Acciones",
      minWidth: 70,
      width: "10%",
      align: "center"
    }
  ];

  const actionsToolbar = [
    {
      ariaLabel: "add category",
      color: "primary",
      func: () => {
        setInitialValues(defaultValues);
        addCategoria();
      },
      icon: "playlist_add",
      title: "Agregar categoría"
    }
  ];

  //metodo que busca y retorna el nombre de la categoría superior
  const getCategoryPadre = (idPadre, listCate) => {
    let category = listCate.find(cate => cate.id === idPadre);
    return category.nombre;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    let extraParams = {
      setOpen: setOpen,
      setSubmitting,
      categoriesPadre,
      setCategoriesPadre
    };

    const parentCategory =
      "" !== values.parentCategory && categoriesPadre[values.parentCategory];
    values.idsuperior = parentCategory ? parentCategory.id : null;

    if (accion === "upd") {
      values.id = dataUpdate.id;
      updateCategoryHand(values, extraParams);
    }
    if (accion === "add") {
      addCategoryHand(values, extraParams);
    }
  };

  const handleOpenAlert = data => {
    setcategoryEdit(data);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setcategoryEdit(null);
    setOpenAlert(false);
  };

  const onChangePage = (newPage, rowsPerPage, newPosition) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage
    };
    setRows(null);
    downloadCategories(params);

    if (undefined !== newPosition) {
      setPage(newPosition);
    }
  };

  const handleAccept = () => {
    if ("delete" === categoryEdit.onAction) {
      setSubmitting(true);
      const extraParams = {
        setSubmitting,
        handleCloseAlert,
        categoriesPadre,
        setCategoriesPadre,
        onChangePage
      };
      deleteCategoryHand(
        { id: categoryEdit.id, idsuperior: categoryEdit.idsuperior },
        extraParams
      );
    }
  };

  const editCate = (data, categoriesPadre) => {
    setAccion("upd");
    let cantidadHijos = 0;
    const values = {
      nombre: data.nombre,
      descripcion: data.descripcion,
      parentCategory: ""
    };

    if (categoriesPadre !== null) {
      const index = categoriesPadre.findIndex(
        cat => cat.id === data.idsuperior
      );

      if (index >= 0) {
        values.parentCategory = index;
      }

      if (null === data.idsuperior) {
        const currentCategory = categoriesPadre.find(
          category => category.id === data.id
        );
        cantidadHijos = currentCategory.cantidadHijos;
        setParentCategoriesCopy(
          categoriesPadre.filter(category => category.id !== data.id)
        );
      } else {
        setParentCategoriesCopy([...categoriesPadre]);
      }
    } else {
      setParentCategoriesCopy([]);
    }

    setInitialValues(values);
    setDataUpdate({ ...data, cantidadHijos });
    setOpen(true);
  };

  //metodo para generar las acciones que se ejecutan sobre los registros
  const generateActions = useCallback((data, categoriesPadre) => {
    const actions = {
      type: "actions",
      values: [
        {
          title: "Modificar",
          icon: "mode_edit",
          func: () => editCate(data, categoriesPadre)
        }
      ]
    };
    const parentCategory = categoriesPadre.find(
      category => category.id === data.id
    );

    if (isEmpty(parentCategory) || 0 === parentCategory.cantidadHijos) {
      actions.values.push({
        title: "Eliminar",
        icon: "delete_forever",
        func: () => handleOpenAlert({ ...data, onAction: "delete" })
      });
    }

    return actions;
  }, []);

  const generateRows = useCallback(
    (listaCategorias, categoriesPadre) => {
      if (!isEmpty(listaCategorias)) {
        const newRows = Array.from(listaCategorias, category =>
          createData(
            category.id,
            category.nombre,
            category.descripcion,
            category.idsuperior === null
              ? null
              : getCategoryPadre(category.idsuperior, categoriesPadre),
            generateActions(category, categoriesPadre)
          )
        );
        setRows(newRows);
      } else {
        setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
      }
    },
    [generateActions]
  );

  function createData(id, nombre, descripcion, padre, actions) {
    return { id, nombre, descripcion, padre, actions };
  }

  // const deleteCate = data => {
  //   deleteCategoryHand(data);
  // };

  const addCategoria = () => {
    setAccion("add");
    setDataUpdate({});
    setOpen(true);

    if (null !== categoriesPadre) {
      setParentCategoriesCopy([...categoriesPadre]);
    } else {
      setParentCategoriesCopy([]);
    }
  };

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE
    };

    if (null === rows && firstRender) {
      downloadCategories(params);
      setFirstRender(false);
    }
  }, [rows, firstRender, downloadCategories]);

  useEffect(() => {
    if (undefined !== listaCategorias.content && null !== categoriesPadre) {
      generateRows(listaCategorias.content, categoriesPadre);
    }
  }, [listaCategorias, categoriesPadre, generateRows]);

  useEffect(() => {
    if (null === categoriesPadre) {
      downloadParentCategories(setCategoriesPadre);
    }
  }, [categoriesPadre, downloadParentCategories]);

  return (
    <>
      <Card className={classes.seccionAdmin}>
        <CardBody>
          <CustomTable
            tableHeaderColor="primary"
            columns={columns}
            rows={rows}
            totalElements={
              isEmpty(listaCategorias) ? 0 : listaCategorias.totalElements
            }
            action="click"
            actionsToolbar={actionsToolbar}
            titleToolbar={categoriesTitleToolbar}
            onChangePage={onChangePage}
            forcePage={page}
            setForcePage={setPage}
          />
        </CardBody>
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        title="Categoría"
        width="sm"
        initialValues={initialValues}
        yupObject={yupObject}
        handleSubmit={handleSubmit}
        disableBackClick
      >
        <RenderForm
          categoryUpdate={dataUpdate}
          categoriesPadre={parentCategoriesCopy}
        />
      </FormDialog>
      <AlertDialog
        open={openAlert}
        title="Eliminar categoría"
        content={alertFormDialog.content}
        isSubmitting={submitting}
        handleClose={handleCloseAlert}
        handleAccept={handleAccept}
      />
    </>
  );
}

CategoriaList.propTypes = {
  listaCategorias: PropTypes.object.isRequired,
  downloadCategories: PropTypes.func.isRequired,
  downloadParentCategories: PropTypes.func.isRequired,
  addCategoryHand: PropTypes.func.isRequired,
  deleteCategoryHand: PropTypes.func.isRequired,
  updateCategoryHand: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {
    listaCategorias: state.categoriaListCatREDU
  };
};
const mapDispatchToProps = dispatch => {
  return {
    downloadCategories: params => {
      dispatch(listCategoriesACTI(params));
    },
    downloadParentCategories: setParentCategories => {
      dispatch(downloadParentCategoriesACTI(setParentCategories));
    },
    updateCategoryHand: (categoria, extraParams) => {
      dispatch(updateCategoriesACTI(categoria, extraParams));
    },
    deleteCategoryHand: (categoria, extraParams) => {
      dispatch(deleteCategoriesACTI(categoria, extraParams));
    },
    addCategoryHand: (categoria, extraParams) => {
      dispatch(newCategoriesACTI(categoria, extraParams));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriaList);
