import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputFormik from "components/CustomInput/InputFormik";
import SelectFormik from "components/CustomInput/SelectFormik";
import Applications from "./Applications";
import { downloadProfilesACTI } from "../../actions/profiles";
import Functionalities from "./Functionalities";

const formControlProps = {
  fullWidth: true
};
const marginInputFormik = { marginBottom: "8px" };
const marginSelectFormik = {
  width: "100%",
  marginTop: 0,
  marginBottom: "8px",
  marginLeft: 0
};

const getIProfile = (profiles, roleEdit) =>
  !isEmpty(profiles) && !isEmpty(roleEdit) && null !== roleEdit.perfil
    ? profiles.findIndex(profile => profile.id === roleEdit.perfil.id)
    : "";

function ManageRole(props) {
  const {
    roleEdit,
    setFunctionalitiesToSave,
    setApplicationIds,
    setPristine,
    downloadProfiles
  } = props;

  const [profiles, setProfiles] = useState(null);
  const [applicationSettings, setApplicationSettings] = useState(null);
  const [disableSave, setDisableSave] = useState(false);

  const handleApplicationIds = applicationIds => {
    setApplicationIds(applicationIds);

    if (null !== applicationSettings) {
      setDisableSave(!applicationIds.some(id => id === applicationSettings.id));
    }
  };

  useEffect(() => {
    const params = {
      setProfiles
    };

    if (null === profiles) {
      downloadProfiles(params);
    }
  }, [profiles, downloadProfiles]);

  return (
    <GridContainer>
      <GridItem xs={12} md={6}>
        <InputFormik
          label="Nombre"
          id="name"
          name="name"
          type="text"
          formControlProps={formControlProps}
          style={marginInputFormik}
          formControlRoot
          autoComplete="off"
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <SelectFormik
          label="Perfiles"
          values={null === profiles ? [] : profiles}
          selected={getIProfile(profiles, roleEdit)}
          id="profile"
          name="profile"
          style={marginSelectFormik}
        ></SelectFormik>
      </GridItem>
      <GridItem xs={12}>
        <InputFormik
          label="Descripción"
          id="description"
          name="description"
          type="text"
          formControlProps={formControlProps}
          style={marginInputFormik}
          formControlRoot
          autoComplete="off"
        />
      </GridItem>
      <GridItem xs={12} md={7}>
        <Applications
          roleEdit={roleEdit}
          setApplicationSettings={setApplicationSettings}
          setApplicationIds={handleApplicationIds}
          setPristine={setPristine}
        />
      </GridItem>
      <GridItem xs={12} md={5}>
        <Functionalities
          applicationSettings={applicationSettings}
          setFunctionalitiesToSave={setFunctionalitiesToSave}
          disableSave={disableSave}
        />
      </GridItem>
    </GridContainer>
  );
}

ManageRole.propTypes = {
  roleEdit: PropTypes.object,
  setFunctionalitiesToSave: PropTypes.func.isRequired,
  setApplicationIds: PropTypes.func.isRequired,
  setPristine: PropTypes.func.isRequired,
  downloadProfiles: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    downloadProfiles: params => {
      dispatch(downloadProfilesACTI(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ManageRole);
