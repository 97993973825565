import React from "react";
import PropTypes from "prop-types";
import { InputLabel } from "@material-ui/core";

const styles = {
  root: {
    color: "#AAA",
    fontSize: "1rem",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left"
  }
};

function InputCustomLabel(props) {
  const { label, htmlFor } = props;

  return (
    <InputLabel style={styles.root} htmlFor={htmlFor ? htmlFor : null}>
      {label}
    </InputLabel>
  );
}

InputCustomLabel.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string
};

export default InputCustomLabel;
