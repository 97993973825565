import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import FormDialog from "components/Dialog/FormDialog";
import avatar from "assets/img/faces/blank-profile.svg";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button, makeStyles, Typography } from "@material-ui/core";
import PrettoSlider from "components/PrettoSlider/PrettoSlider";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

function PicProfileEditor(props) {
  const {
    open,
    setOpen,
    userImage,
    uploadProfileImage,
    deleteProfileImage
  } = props;
  const editorPanel = createRef();
  const fileInput = createRef();
  const classes = useStyles();
  const [image, setImage] = useState(avatar);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const handleClose = () => {
    setImage(!isEmpty(userImage) ? userImage.src : avatar);
    setScale(1);
    setRotate(0);
    setOpen(false);
  };
  const handleDrop = dropped => {
    setImage(dropped[0]);
  };
  const handleChangeScale = (event, newValue) => {
    setScale(newValue);
  };
  const handleRotate = value => {
    if (value >= 0) {
      setRotate(oldRotate => {
        if (270 === oldRotate) return 0;
        else return oldRotate + value;
      });
    } else {
      setRotate(oldRotate => {
        if (0 === oldRotate) return 270;
        else return oldRotate + value;
      });
    }
  };
  const handleDeleteImage = () => {
    setImage(avatar);

    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  useEffect(() => {
    setImage(!isEmpty(userImage) ? userImage.src : avatar);
  }, [userImage]);

  return (
    <FormDialog
      open={open}
      handleClose={handleClose}
      title="Editar foto de perfil"
      width="md"
      initialValues={{}}
      yupObject={{}}
      pristine={
        ((isEmpty(userImage) && avatar === image) || image === userImage.src) &&
        1 === scale &&
        0 === rotate
      }
      handleSubmit={(values, { setSubmitting }) => {
        const extraParams = {
          setOpen,
          setSubmitting
        };

        if (avatar !== image) {
          const canvas = editorPanel.current
            .getImageScaledToCanvas()
            .toDataURL();
          const newImage = {};
          fetch(canvas)
            .then(res => {
              newImage.src = res.url;
              return res.blob();
            })
            .then(blob => {
              // const imageURL = window.URL.createObjectURL(blob);
              newImage.blob = blob;
              newImage.name = image.name;
              newImage.type = image.type;
              uploadProfileImage(newImage, extraParams);
            });
        } else if (!isEmpty(userImage)) {
          deleteProfileImage(extraParams);
        }
      }}
      disableBackClick
    >
      <Dropzone
        onDrop={handleDrop}
        noClick
        noKeyboard
        style={{ width: "250px", height: "250px" }}
      >
        {({ getRootProps, getInputProps }) => {
          // console.log(getRootProps(), getInputProps());
          return (
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <div {...getRootProps()} className="text-center">
                  <AvatarEditor
                    ref={editorPanel}
                    width={250}
                    height={250}
                    image={image}
                    border={50}
                    borderRadius={250}
                    color={[0, 0, 0, 0.6]} // RGBA
                    scale={scale}
                    rotate={rotate}
                    disableHiDPIScaling
                  />
                  <input
                    {...getInputProps()}
                    accept="image/*"
                    id="contained-button-file"
                    ref={fileInput}
                    multiple
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div className="edit-picture-controls">
                  <div className="edit-picture-controls-row text-center">
                    <Typography
                      variant="subtitle1"
                      className="custom-subtitle text-left"
                      gutterBottom
                    >
                      Enfocar
                    </Typography>
                    <div className="slider-scale">
                      <PrettoSlider
                        name="Zoom"
                        valueLabelDisplay="off"
                        aria-label="pretto slider"
                        min={1}
                        step={0.01}
                        max={2}
                        defaultValue={1}
                        onChange={handleChangeScale}
                        disabled={avatar === image}
                      />
                    </div>
                  </div>
                  <div className="edit-picture-controls-row">
                    <Typography
                      variant="subtitle1"
                      className="custom-subtitle"
                      gutterBottom
                    >
                      Girar
                    </Typography>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<RotateLeftIcon />}
                        onClick={() => handleRotate(-90)}
                        disabled={avatar === image}
                      >
                        Izquierda
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        endIcon={<RotateRightIcon />}
                        onClick={() => handleRotate(90)}
                        disabled={avatar === image}
                      >
                        Derecha
                      </Button>
                    </div>
                  </div>
                  <div className="edit-picture-controls-row">
                    <Typography
                      variant="subtitle1"
                      className="custom-subtitle"
                      gutterBottom
                    >
                      Gestionar
                    </Typography>
                    <div className="text-center">
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component="span"
                          className={classes.button}
                          startIcon={<CloudUploadIcon />}
                        >
                          Nueva foto
                        </Button>
                      </label>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        disabled={avatar === image}
                        onClick={() => handleDeleteImage()}
                      >
                        Sin foto
                      </Button>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          );
        }}
      </Dropzone>
    </FormDialog>
  );
}

PicProfileEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  userImage: PropTypes.object,
  uploadProfileImage: PropTypes.func.isRequired,
  deleteProfileImage: PropTypes.func.isRequired
};

export default PicProfileEditor;
