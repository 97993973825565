import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const styles = {
  root: {
    padding: "25px 20px 10px",
    textAlign: "center",
    color: "#797979"
  },
  title: {
    fontSize: "1.825em"
  },
  subtitle: {
    fontSize: "1.25em",
    marginTop: "5px"
  }
};

function CardHeaderAlt(props) {
  const { title, subtitle, ...rest } = props;

  return (
    <div style={styles.root} {...rest}>
      <Typography variant="h3" style={styles.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h5" style={styles.subtitle}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
}

CardHeaderAlt.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default CardHeaderAlt;
