import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
// @material-ui/icons
import { Check } from "@material-ui/icons";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";

const useStyles = makeStyles(checkboxAdnRadioStyle);

function CustomCheckbox(props) {
  const classes = useStyles();
  const { label } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          color="default"
          classes={{
            checked: classes.checked,
            root: classes.root
          }}
          {...props}
        />
      }
      label={label}
      className="espacio-check"
    />
  );
}

CustomCheckbox.propTypes = {
  label: PropTypes.string.isRequired
};

export default CustomCheckbox;
