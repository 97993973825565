import { ACTIONS } from "../constants";

export const announcementsAdminREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_ANNOUNCEMENTS_ADMIN_STORE:
      return action.announcements;
    default:
      return state;
  }
};

export const announcementUsersREDU = (state = [], action) => {
  switch (action.type) {
    case ACTIONS.ADD_ANNOUNCEMENT_USERS_STORE:
      return action.users;
    default:
      return state;
  }
};
