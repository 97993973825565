import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { Button } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SelectFormik from "components/CustomInput/SelectFormik";
import RegionalUnits from "./RegionalUnits";
import Programs from "./Programs";
import RoleOperation from "./RoleOperation";
import Roles from "./Roles";
import SimpleDialog from "components/Dialog/SimpleDialog";
import AutoProcessAlert from "../../components/UserProcess/AutoProcessAlert";
import AutoLoaderAlert from "../../components/UserProcess/AutoLoaderAlert";
import AutoProcessWarning from "../../components/UserProcess/AutoProcessWarning";
import AutoProcessSuccess from "../../components/UserProcess/AutoProcessSuccess";
import AutoProcessError from "../../components/UserProcess/AutoProcessError";
import { downloadProfilesACTI } from "../../actions/profiles";
import { addAutomaticUserStoreACTI } from "actions/users";

const styles = {
  selectStyle: { width: "100%", marginLeft: 0 },
  dialogContent: {}
};

function UserAutomatic(props) {
  const {
    classes,
    isSubmitting,
    setProgramIds,
    handleOpenRoleAdd,
    handleOpenRoleEdit,
    setRoleIds,
    formRef,
    setSchemaManageUsers,
    selectedProfile,
    setSelectedProfile,
    operation,
    setOperation,
    resetProfile,
    automaticUser,
    downloadProfiles,
    setAutomaticUser
  } = props;

  const [profiles, setProfiles] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const handleChangeOperation = e => {
    setOperation(e.target.value);
  };

  const handleClose = (e, action) => {
    if (null !== automaticUser) {
      if ("accept" === action && isEmpty(automaticUser)) {
        formRef.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      } else {
        setOpen(false);

        if (!isEmpty(automaticUser)) {
          setTimeout(() => {
            setAutomaticUser({});
          }, 200);
        }
      }
    }
  };

  const renderContentDialog = () => {
    if (null === automaticUser) {
      return <AutoLoaderAlert />;
    } else if (isEmpty(automaticUser)) {
      return <AutoProcessAlert />;
    } else if (automaticUser.fileWithoutBackup) {
      return (
        <AutoProcessWarning
          automaticUser={automaticUser}
          selectedPrograms={selectedPrograms}
        />
      );
    } else if (automaticUser.someReportProcessed && automaticUser.report) {
      return (
        <AutoProcessSuccess
          automaticUser={automaticUser}
          selectedPrograms={selectedPrograms}
        />
      );
    } else {
      return <AutoProcessError automaticUser={automaticUser} />;
    }
  };

  const handleClickProcess = () => {
    if (!isEmpty(selectedProfile) && !isEmpty(selectedPrograms)) {
      setOpen(true);
    }
  };

  useEffect(() => {
    const params = {
      setProfiles
    };
    downloadProfiles(params);
  }, [setProfiles, downloadProfiles]);

  useEffect(() => {
    const values = {
      profile: Yup.string().required("Requerido")
    };

    setSchemaManageUsers(values);
  }, [setSchemaManageUsers]);

  useEffect(() => {
    if (isEmpty(selectedProfile)) {
      setSelectedUnit(null);
    }
  }, [selectedProfile]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <SelectFormik
            label="Perfiles"
            values={profiles.filter(profile => "/estudiante" === profile.path)}
            id="profile"
            name="profile"
            style={styles.selectStyle}
            setValue={setSelectedProfile}
            resetSelection={resetProfile}
          ></SelectFormik>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {!isEmpty(selectedProfile) && (
          <>
            <GridItem xs={12} md={6}>
              <RegionalUnits setSelectedUnit={setSelectedUnit} />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Programs
                selectedUnit={selectedUnit}
                setProgramIds={setProgramIds}
                setSelectedPrograms={setSelectedPrograms}
              />
            </GridItem>
          </>
        )}
        <GridItem xs={12} md={4}>
          <RoleOperation
            operation={operation}
            handleChangeOperation={handleChangeOperation}
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          {"addUserRoles" === operation && (
            <Roles
              handleOpenAdd={handleOpenRoleAdd}
              handleOpenEdit={handleOpenRoleEdit}
              setRoleIds={setRoleIds}
            />
          )}
        </GridItem>
        <GridItem xs={12} className={classes.btnProcesss}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              isSubmitting ||
              isEmpty(selectedProfile) ||
              isEmpty(selectedPrograms)
            }
            onClick={handleClickProcess}
          >
            Procesar
          </Button>
        </GridItem>
      </GridContainer>
      <SimpleDialog
        open={open}
        handleClose={handleClose}
        dialogId="user-automatic-dialog"
        title="Proceso automático de usuarios"
        width="md"
        stylesContent={styles.dialogContent}
        isSubmitting={isSubmitting}
        disableBackClick
      >
        {renderContentDialog()}
      </SimpleDialog>
    </>
  );
}

UserAutomatic.propTypes = {
  classes: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setProgramIds: PropTypes.func.isRequired,
  handleOpenRoleAdd: PropTypes.func.isRequired,
  handleOpenRoleEdit: PropTypes.func.isRequired,
  setRoleIds: PropTypes.func.isRequired,
  formRef: PropTypes.object.isRequired,
  setSchemaManageUsers: PropTypes.func.isRequired,
  selectedProfile: PropTypes.object,
  setSelectedProfile: PropTypes.func.isRequired,
  operation: PropTypes.string.isRequired,
  setOperation: PropTypes.func.isRequired,
  resetProfile: PropTypes.array,
  automaticUser: PropTypes.object,
  downloadProfiles: PropTypes.func.isRequired,
  setAutomaticUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    automaticUser: state.automaticUserREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadProfiles: params => {
      dispatch(downloadProfilesACTI(params));
    },
    setAutomaticUser: automaticUser => {
      dispatch(addAutomaticUserStoreACTI(automaticUser));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAutomatic);
