import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import { COLOR_APP_HEX } from "../../../../constants";

const contentWidgetStyle = {
  ...checkboxAdnRadioStyle,
  configuration: {
    border: `1px solid ${COLOR_APP_HEX}`,
    borderRadius: '3px',
    margin: '18px 0',
    padding: '0px 15px 10px 15px'
  },
  configurationTitle: {
    margin: '-18px 0 0 10px',
    width: 'min-content',
    backgroundColor: '#eee',
    padding: '0 10px',
    color: `${COLOR_APP_HEX}`
  },
  option: {
    textAlign: 'center',
    //borderRight: '1px solid #ccc'
  },
  lastOption: {
    textAlign: 'center'
  },
  tabs: {
    flexGrow: 1,
    width: '100%'
  }
};

export const gridStyles = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  button: {
    textAlign: 'center',
  },
  divider: {
    margin: theme.spacing(2, 0),
  }
});

export default contentWidgetStyle;