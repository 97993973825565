import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import SimpleDialog from "components/Dialog/SimpleDialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
// @material-ui/icons
import LaunchIcon from "@material-ui/icons/Launch";

const styles = theme => ({
  actions: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      order: -1,
      justifyContent: "flex-end"
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      borderLeft: "1px solid #CCC"
    }
  },
  content: {
    "& > p": {
      margin: 0
    }
  }
});

const styles2 = {
  contentDialog: { padding: "24px", borderTop: "1px solid #ccc" },
  container: { minHeight: 100 },
  subtitle: {
    textTransform: "none",
    fontWeight: 400,
    margin: 0
  }
};

const useStyles = makeStyles(styles);

function ViewNotification(props) {
  const classes = useStyles();
  const {
    open,
    handleCloseDialog,
    handleClickLaunch,
    topic,
    content,
    link
  } = props;

  return (
    <SimpleDialog
      open={open}
      handleClose={handleCloseDialog}
      title="Anuncio"
      dialogId="notification"
      width="md"
      viewFooter={false}
      stylesContent={styles2.contentDialog}
      withIconClose
    >
      <GridContainer style={styles2.container}>
        {topic && (
          <GridItem xs={12}>
            <Typography variant="h6" style={styles2.subtitle} gutterBottom>
              {topic}
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12} md={link ? 11 : 12} className={classes.content}>
          {ReactHtmlParser(content)}
        </GridItem>
        {link && (
          <GridItem xs={12} md={1} className={classes.actions}>
            <IconButton
              color="primary"
              onClick={() => handleClickLaunch(link)}
              aria-label="access to link"
            >
              <LaunchIcon />
            </IconButton>
          </GridItem>
        )}
      </GridContainer>
    </SimpleDialog>
  );
}

ViewNotification.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleClickLaunch: PropTypes.func.isRequired,
  topic: PropTypes.string,
  content: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default ViewNotification;
