import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles, IconButton } from "@material-ui/core";
// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";
// import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-react.js";
import "../../assets/css/react-grid-layout.css";
import CustomTooltip from "helpers/CustomTooltip";
import AlertDialog from "components/Dialog/AlertDialog";
import { dataGraphs } from "constants/dataGraphs";
import ColorSketch from "components/Color/ColorSketch";
import { COLOR_APP_HEX, GOOGLE_COLORS } from "../../constants";
import SwitchCharts from "components/GoogleGraphs/SwitchCharts";
import { equalObjects } from "helpers/functions";

const useStyles = makeStyles(styles);

const Graph = props => {
  const classes = useStyles();
  const {
    id,
    color,
    titulo,
    categoria,
    contenido,
    datos,
    fromDash,
    chartType,
    cssTransition,
    handleDeleteWidget,
    handleClickOpenDialog,
    extraData,
    setChartData,
    setPristine
  } = props;
  const stylesAux = {
    iconEdi: {
      color: successColor[2]
    },
    iconDel: {
      color: dangerColor[2]
    }
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openColor, setOpenColor] = React.useState(false);
  const [colorChart, setColorChart] = React.useState(COLOR_APP_HEX);
  const [indexChart, setIndexChart] = React.useState(null);
  const [colorsAux, setColorsAux] = React.useState(null);
  const [isColorSingle, setColorSingle] = React.useState(false);
  const [valuesAux, setValuesAux] = React.useState(null);
  const [showActions, setShowActions] = React.useState(false);
  const data = datos ? datos : dataGraphs[chartType.id];
  data.color = color.color;
  const chartTypeOption = fromDash ? datos.type : chartType.type;

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleAccept = () => {
    handleDeleteWidget(id);
    handleCloseAlert();
  };

  const handleChangeColor = color => {
    if (null != indexChart) {
      const dataAux = Object.assign({}, data);
      dataAux.colors = data.colors ? data.colors.slice() : null;
      dataAux.colors[indexChart] = color;
      setColorChart(color);
      setChartData(dataAux);

      if (colorsAux && setPristine) {
        setPristine(equalObjects(colorsAux, dataAux.colors));
      }
    }
  };

  const handleChangeColorSingleBar = color => {
    if (null != indexChart) {
      const dataAux = JSON.parse(JSON.stringify(data));

      if (2 === dataAux.values[0].length) {
        dataAux.values[0].push({ role: "style" });

        for (let j = 1; j < dataAux.values.length; j++) {
          dataAux.values[j].push(GOOGLE_COLORS[j - 1]);
        }
      }

      dataAux.values[indexChart + 1][2] = color;
      setColorChart(color);
      setChartData(dataAux);

      if (valuesAux && setPristine) {
        if (3 === valuesAux[0].length) {
          setPristine(equalObjects(valuesAux, dataAux.values));
        } else if (2 === valuesAux[0].length) {
          setPristine(false);
        }
      }
    }
  };

  const handleOpenColor = index => {
    if (data.colors && data.values[0].length > 2) {
      setColorSingle(false);
      setColorChart(data.colors[index - 1]);
      setIndexChart(index - 1);
      setOpenColor(true);
    }
  };
  const handleOpenColorSingle = index => {
    if (3 === data.values[0].length) {
      setColorChart(data.values[index + 1][2]);
    }

    setColorSingle(true);
    setIndexChart(index);
    setOpenColor(true);
  };
  const handleCloseColor = () => {
    setIndexChart(null);
    setOpenColor(false);
    setColorChart(COLOR_APP_HEX);
  };

  const chartProps = {
    data,
    handleOpenColor,
    handleOpenColorSingle,
    fromDash,
    extraData
  };

  const stylesCard = () => {
    let styles = "card-graph-temp";

    if (fromDash) {
      styles = cssTransition
        ? "card-header-layout-graph " + cssTransition
        : "card-header-layout-graph";

      if (showActions) {
        styles += " touch-translate-y";
      } else {
        styles += " touch-translate-reset";
      }
    }

    return styles;
  };

  let graphIconClassName =
    fromDash && showActions
      ? "graph-icons touch-visibility"
      : "graph-icons touch-invisibility";

  useEffect(() => {
    if (!colorsAux && !fromDash) setColorsAux(data.colors);
  }, [colorsAux, fromDash, data.colors]);

  useEffect(() => {
    if (!fromDash) {
      setValuesAux(prev =>
        !prev || 2 === prev[0].length ? data.values : prev
      );
    }
  }, [fromDash, data.values]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (showActions) {
        setShowActions(false);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showActions]);

  return (
    <Card chart className="card-layout">
      <div className="header-view-btns header-graph">
        <CardHeader
          color="danger"
          className={stylesCard()}
          style={color}
          onClick={() => {
            setShowActions(showActions => !showActions);
          }}
        >
          <SwitchCharts type={chartTypeOption} componentProps={chartProps} />
        </CardHeader>
        {fromDash ? (
          <div className={graphIconClassName}>
            <CustomTooltip title="Editar" style={stylesAux.iconEdi}>
              <IconButton
                aria-label="editar-widget"
                style={stylesAux.iconEdi}
                onClick={() => handleClickOpenDialog(id)}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Eliminar" style={stylesAux.iconDel}>
              <IconButton
                aria-label="borrar-widget"
                style={stylesAux.iconDel}
                onClick={handleClickOpenAlert}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          </div>
        ) : null}
        <CardBody className="card-body-layout">
          <h4 className={classes.cardTitle + " truncate-h-general"}>
            {titulo && "" !== titulo.trim() ? titulo : "Título"}
          </h4>
          <p className={classes.cardCategory + " truncate-p-graph"}>
            {categoria && "" !== categoria.trim() ? categoria : "Categoría"}
          </p>
        </CardBody>
      </div>
      <CardFooter chart>
        <div className={classes.stats}>
          <AccessTime />{" "}
          {contenido && "" !== contenido.trim() ? contenido : "Contenido"}
        </div>
      </CardFooter>
      <AlertDialog
        content="¿Deseas eliminar el widget?"
        open={openAlert}
        handleClose={handleCloseAlert}
        handleAccept={handleAccept}
      />
      {openColor && (
        <ColorSketch
          color={colorChart}
          handleChange={
            isColorSingle ? handleChangeColorSingleBar : handleChangeColor
          }
          handleClose={handleCloseColor}
        />
      )}
    </Card>
  );
};

Graph.propTypes = {
  id: PropTypes.string,
  color: PropTypes.object.isRequired,
  titulo: PropTypes.string.isRequired,
  categoria: PropTypes.string.isRequired,
  contenido: PropTypes.string.isRequired,
  datos: PropTypes.object,
  fromDash: PropTypes.bool.isRequired,
  chartType: PropTypes.object,
  cssTransition: PropTypes.string,
  handleDeleteWidget: PropTypes.func,
  handleClickOpenDialog: PropTypes.func,
  extraData: PropTypes.object.isRequired,
  setChartData: PropTypes.func,
  setPristine: PropTypes.func
};

export default Graph;
