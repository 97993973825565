import { isEmpty, isObject, isString } from "lodash";
import { getToken, refreshToken } from "./access";

const authHeader = (pkp, formData) => {
  let headers;
  let headersCnt = {};
  let token = getToken();

  if (!isEmpty(token)) {
    headersCnt.Authorization = `Bearer ${token}`;
  }

  if (!isEmpty(pkp)) {
    headersCnt["Public-Key-Pins"] = pkp;
  }

  if (undefined === formData) {
    headersCnt["Content-Type"] = "application/json";
  }

  headers = new Headers(headersCnt);

  return headers;
};

const handleError = error => {
  let msgError =
    "Se ha presentado un inconveniente, por favor intenta más tarde.";

  if (isString(error)) {
    msgError = error;
  } else if (isObject(error)) {
    if (error.mensaje) {
      msgError = error.mensaje;
    } else if (error.request) {
      msgError = "No hay conexión a internet.";
    } else {
      msgError = "No se pudo establecer la conexión.";
    }
  }

  return Promise.reject(msgError);
};

export const apiGET = (url, pkp) => {
  if (isEmpty(url)) {
    console.error("No se ha recibido la ruta del servicio.");
    return Promise.resolve(undefined);
  }

  let requestInit = {
    method: "GET",
    headers: authHeader(pkp)
  };

  return fetch(url, requestInit)
    .then(response => handleResponse(response, url, requestInit, pkp))
    .catch(error => handleError(error));
};

export const apiGETById = (url, id, pkp) => {
  if (isEmpty(url)) {
    console.error("No se ha recibido la ruta del servicio.");
    return Promise.resolve(undefined);
  }

  if (undefined === id) {
    console.error("No se ha recibido el id.");
    return Promise.resolve(undefined);
  }

  let requestInit = {
    method: "GET",
    headers: authHeader(pkp)
  };

  return fetch(`${url}/${id}`, requestInit)
    .then(response => handleResponse(response, url, requestInit, pkp))
    .catch(error => handleError(error));
};

export const apiGETParam = (url, params, pkp) => {
  if (isEmpty(url)) {
    console.error("No se ha recibido la ruta del servicio.");
    return Promise.resolve(undefined);
  }

  if (isEmpty(params)) {
    console.error("No se ha recibido el objeto de parámetros.");
    return Promise.resolve(undefined);
  }

  let objUrl = new URL(url);
  let requestInit = {
    method: "GET",
    headers: authHeader(pkp)
  };
  let urlParams = new URLSearchParams(params);
  objUrl.search = urlParams.toString();

  return fetch(objUrl, requestInit)
    .then(response => handleResponse(response, url, requestInit, pkp))
    .catch(error => handleError(error));
};

export const apiPUT = (url, id, objeto, pkp, formData) => {
  if (isEmpty(url)) {
    console.error("No se ha recibido la ruta del servicio.");
    return Promise.resolve(undefined);
  }

  if (undefined === id) {
    console.error("No se ha recibido el id.");
    return Promise.resolve(undefined);
  }

  if (isEmpty(objeto) && undefined === formData) {
    console.error("No se ha recibido el objeto de parámetros.");
    return Promise.resolve(undefined);
  }

  let requestInit = {
    method: "PUT",
    body: objeto ? JSON.stringify(objeto) : formData,
    headers: authHeader(pkp, formData)
  };

  return fetch(`${url}/${id}`, requestInit)
    .then(response => handleResponse(response, url, requestInit, pkp, formData))
    .catch(error => handleError(error));
};

export const apiPOST = (url, objeto, pkp, formData) => {
  if (isEmpty(url)) {
    console.error("No se ha recibido la ruta del servicio.");
    return Promise.resolve(undefined);
  }

  if (isEmpty(objeto) && undefined === formData) {
    console.error("No se ha recibido el objeto de parámetros.");
    return Promise.resolve(undefined);
  }

  let requestInit = {
    method: "POST",
    body: objeto ? JSON.stringify(objeto) : formData,
    headers: authHeader(pkp, formData)
  };

  return fetch(url, requestInit)
    .then(response => handleResponse(response, url, requestInit, pkp, formData))
    .catch(error => handleError(error));
};

export const apiDELETE = (url, id, objeto, pkp) => {
  if (isEmpty(url)) {
    console.error("No se ha recibido la ruta del servicio.");
    return Promise.resolve(undefined);
  }

  if (undefined === id) {
    console.error("No se ha recibido el id.");
    return Promise.resolve(undefined);
  }

  if (isEmpty(objeto)) {
    console.error("No se ha recibido el objeto de parámetros.");
    return Promise.resolve(undefined);
  }

  let requestInit = {
    method: "DELETE",
    body: JSON.stringify(objeto),
    headers: authHeader(pkp)
  };

  return fetch(`${url}/${id}`, requestInit)
    .then(response => handleResponse(response, url, requestInit, pkp))
    .catch(error => handleError(error));
};

const handleResponse = (response, url, requestInit, pkp, formData) => {
  return response.text().then(text => {
    try {
      // console.log( response );
      if (response.ok) {
        if (404 === response.status) {
          return Promise.reject(
            "Recurso no encontrado, verifica la conexión a internet."
          );
        }

        if (!isEmpty(text)) {
          return JSON.parse(text);
        }
      } else if (401 === response.status) {
        try {
          const errorHandler = JSON.parse(text);
          console.error("[401] Recurso prohibido");

          if (401 === errorHandler.code && errorHandler.message) {
            return Promise.reject(errorHandler.message);
          } else {
            return Promise.reject(
              "No se pudo acceder al recurso, intenta iniciando sesión nuevamente."
            );
          }
        } catch (errorParse) {
          let parser = new DOMParser();
          let xmlDoc = parser.parseFromString(text, "text/xml");
          let search = xmlDoc.getElementsByTagName("error_description")[0]
            .childNodes[0].nodeValue;

          if (-1 !== search.indexOf("Access token expired")) {
            return refreshToken()
              .then(() => {
                requestInit.headers = authHeader(pkp, formData);

                return fetch(url, requestInit)
                  .then(response2nd => response2nd.json())
                  .catch(error => {
                    console.error("[184] " + error);
                    return Promise.reject("No se pudo procesar tu solicitud.");
                  });
              })
              .catch(error => {
                console.error("[189] " + error);
                return Promise.reject("No se pudo procesar tu solicitud.");
              });
          }
        }
      } else if (412 === response.status) {
        const data = text && JSON.parse(text);
        return data; // condición previa fallida, decida si lo procesa normal o lo procesa como error.
      } else if (500 === response.status) {
        try {
          const errorHandler = text && JSON.parse(text);

          if (errorHandler.code)
            console.error("[" + errorHandler.code + "] " + errorHandler.error);
          else if (errorHandler.error) console.error(errorHandler.error);

          return Promise.reject(
            errorHandler.message
              ? errorHandler.message
              : "No se pudo procesar tu solicitud, intenta más tarde."
          );
        } catch (errorParse) {
          return Promise.reject(
            "No se pudo procesar tu solicitud, intenta más tarde."
          );
        }
      } else if (504 === response.status) {
        return Promise.reject(
          "El tiempo de espera se ha agotado, intenta más tarde."
        );
      } else {
        try {
          const errorHandler = text && JSON.parse(text);

          if (errorHandler.code)
            console.error("[" + errorHandler.code + "] " + errorHandler.error);
          else if (errorHandler.error) console.error(errorHandler.error);

          return Promise.reject(
            errorHandler.message
              ? errorHandler.message
              : "Algo anda mal, intenta más tarde (" + response.status + ")."
          );
        } catch (errorParse) {
          return Promise.reject(
            "Algo anda mal, intenta más tarde (" + response.status + ")."
          );
        }
      }
    } catch (error) {
      return handleError(error);
    }
  });
};
