import { combineReducers } from "redux";
import { isString } from "lodash";
import {
  notificationsREDU,
  headerNotificationsREDU,
  announcementsREDU
} from "./notifications";
import {
  profileREDU,
  typesREDU,
  widgetsREDU,
  layoutsREDU,
  fullLayoutsREDU,
  controlDashboardREDU,
  dialogDashboardREDU,
  colorDashboardREDU,
  widgetUpdateREDU,
  viewContentDialogREDU,
  checkEditDashboardREDU,
  typeWidgetREDU,
  colorGradWidgetREDU,
  degreedCardWidgetREDU,
  colorAltCardWidgetREDU,
  titleWidgetREDU,
  categoryWidgetREDU,
  contentWidgetREDU,
  iconCardWidgetREDU,
  iframeWidgetREDU,
  dialogIconCardWidgetREDU,
  valueTabIconCardWidgetREDU,
  iconsCardWidgetREDU,
  chartTypeREDU,
  chartDataREDU,
  chartRangeNameREDU,
  chartLegendREDU,
  chartTrendlinesREDU,
  chartStackedREDU,
  chartPieHoleREDU
} from "./dashboard";
import { roleApplicationsREDU } from "./administrator";
import { automaticUserREDU, rolesREDU } from "./users";
import {
  categoriaListCatREDU,
  parentCategoriesREDU,
  listApplicationPaginateCatREDU
} from "./categorias";
import { globalProfilesREDU } from "./profiles";
import { announcementsAdminREDU, announcementUsersREDU } from "./announcements";
import { ACTIONS } from "../constants";
import { getUserKey } from "api/access";
import { getCookie } from "helpers/functions";
import { getObjectLocal } from "api/access";

const messageNotificationREDU = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_MESSAGE_NOTIFICATION:
      return action.message
        ? isString(action.message)
          ? action.message
          : "Ocurrió un error"
        : null;
    default:
      return state;
  }
};

const notificationSuccessREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_NOTIFICATION_SUCCESS:
      return action.isOpen;
    default:
      return state;
  }
};

const notificationWarningREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_NOTIFICATION_WARNING:
      return action.isOpen;
    default:
      return state;
  }
};

const notificationErrorREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_NOTIFICATION_ERROR:
      return action.isOpen;
    default:
      return state;
  }
};

const notificationGeneralREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_NOTIFICATION_GENERAL:
      return action.isOpen;
    default:
      return state;
  }
};

const messageAlertREDU = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_MESSAGE_ALERT:
      return action.message
        ? isString(action.message)
          ? action.message
          : "Ocurrió un error"
        : null;
    default:
      return state;
  }
};

const alertSuccessREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_ALERT_SUCCESS:
      return action.isOpen;
    default:
      return state;
  }
};

const alertWarningREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_ALERT_WARNING:
      return action.isOpen;
    default:
      return state;
  }
};

const alertErrorREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_ALERT_ERROR:
      return action.isOpen;
    default:
      return state;
  }
};

const alertGeneralREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_ALERT_GENERAL:
      return action.isOpen;
    default:
      return state;
  }
};

const initialState =
  getUserKey() && getCookie("keySessionVor")
    ? {
        loggedIn: true
      }
    : {};

const userREDU = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_USER_STORE:
      return action.user;
    default:
      return state;
  }
};

const handleLogout = user => {
  // for all keys defined in your persistConfig(s)
  // storage.removeItem('persist:root')
  // storage.removeItem('persist:otherKey')
  // return undefined;
  return {
    userREDU: user
  };
};

const applicationsREDU = (state = [], action) => {
  switch (action.type) {
    case ACTIONS.ADD_APPS_STORE:
      return action.categories;
    default:
      return state;
  }
};

const initialRemember = () => {
  const userTemp = getObjectLocal("rememberUserVor");

  if (userTemp && localStorage.getItem("keyLoginVor")) {
    return {
      loggedIn: true,
      name: userTemp.name,
      user: userTemp.user,
      userImage: userTemp.userImage
    };
  }

  return {
    loggedIn: false,
    state: false
  };
};

const rememberUserREDU = (state = initialRemember(), action) => {
  switch (action.type) {
    case ACTIONS.CHECK_REMEMBER_USER:
      return { ...state, state: action.isRemember };
    case ACTIONS.DISABLE_REMEMBER_USER:
      return { loggedIn: false, state: false };
    default:
      return state;
  }
};

const initialImages = {
  profile: null
};

const imagesUserREDU = (state = initialImages, action) => {
  switch (action.type) {
    case ACTIONS.ADD_IMAGE_USER_PROFILE:
      return { ...state, profile: action.profileImage };
    default:
      return state;
  }
};

const funcionalityUsersREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_FUNCTIONALITY_USERS_STORE:
      return action.users;
    default:
      return state;
  }
};

const funcionalityPeopleREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_FUNCTIONALITY_PEOPLE_STORE:
      return action.people;
    default:
      return state;
  }
};

const appReducer = combineReducers({
  globalProfilesREDU,
  profileREDU,
  notificationsREDU,
  headerNotificationsREDU,
  announcementsAdminREDU,
  announcementsREDU,
  announcementUsersREDU,
  typesREDU,
  widgetsREDU,
  layoutsREDU,
  fullLayoutsREDU,
  controlDashboardREDU,
  dialogDashboardREDU,
  colorDashboardREDU,
  widgetUpdateREDU,
  viewContentDialogREDU,
  checkEditDashboardREDU,
  typeWidgetREDU,
  colorGradWidgetREDU,
  degreedCardWidgetREDU,
  colorAltCardWidgetREDU,
  titleWidgetREDU,
  categoryWidgetREDU,
  contentWidgetREDU,
  iconCardWidgetREDU,
  iframeWidgetREDU,
  dialogIconCardWidgetREDU,
  valueTabIconCardWidgetREDU,
  iconsCardWidgetREDU,
  messageNotificationREDU,
  notificationSuccessREDU,
  notificationWarningREDU,
  notificationErrorREDU,
  notificationGeneralREDU,
  messageAlertREDU,
  alertSuccessREDU,
  alertWarningREDU,
  alertErrorREDU,
  alertGeneralREDU,
  userREDU,
  chartTypeREDU,
  chartDataREDU,
  chartRangeNameREDU,
  chartLegendREDU,
  chartTrendlinesREDU,
  chartStackedREDU,
  chartPieHoleREDU,
  applicationsREDU,
  rememberUserREDU,
  imagesUserREDU,
  categoriaListCatREDU,
  parentCategoriesREDU,
  listApplicationPaginateCatREDU,
  funcionalityUsersREDU,
  rolesREDU,
  roleApplicationsREDU,
  funcionalityPeopleREDU,
  automaticUserREDU
});

const rootReducer = (state, action) => {
  if (
    ACTIONS.ADD_USER_STORE === action.type &&
    action.user &&
    !action.user.loggedIn
  ) {
    state = handleLogout(action.user);
  }

  return appReducer(state, action);
};

export default rootReducer;
