import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Typography,
  Link,
  makeStyles,
  Grid,
  CssBaseline,
  Paper,
  Avatar,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  DialogContentText,
  IconButton
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// import backImg from "../../assets/img/campusup.jpg";
import InputFormik from "components/CustomInput/InputFormik";
import {
  setOpenAlertErrorACTI,
  authenticationACTI,
  setOpenAlertSuccessACTI,
  changeStateRememberACTI
} from "../../actions";
import AlertError from "components/Snackbar/AlertError";
import FormDialog from "components/Dialog/FormDialog";
import { forgotPasswordACTI } from "actions/forgotPassword";
import AlertSuccess from "components/Snackbar/AlertSuccess";
import SignInMarketing from "components/Announcement/SignInMarketing";
import SimpleDialog from "components/Dialog/SimpleDialog";
import LockScreen from "./LockScreen";
import Copyright from "components/Copyright/Copyright";
import { schemaCredentialsLogin } from "yupValidations";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const urlImage = process.env.REACT_APP_VORTAL_REPO_IMAGES;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: `url(${urlImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%"
  },
  loginForm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  closeMarketing: {
    position: "absolute",
    top: "0px",
    right: "2px",
    color: "#fff",
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0s 2s, opacity 2s linear",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    marginTop: "2px"
  }
}));

function ForgotPassword({ open, setOpen, forgotPassword }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormDialog
      open={open}
      handleClose={handleClose}
      title="Restablecer contraseña"
      width="xs"
      initialValues={{ username: "" }}
      yupObject={{
        username: Yup.string()
          .min(8, "Mínimo 8 caracteres")
          .required("Requerido")
      }}
      handleSubmit={(values, { setSubmitting }) => {
        const extraParams = {
          setOpen,
          setSubmitting
        };
        forgotPassword(values.username, extraParams);
      }}
      disableBackClick
    >
      <DialogContentText>
        Se enviará un vínculo de recuperación al correo electrónico asociado a
        la cuenta de usuario.
      </DialogContentText>
      <InputFormik
        label="Usuario"
        id="username"
        name="username"
        type="text"
        formControlProps={{
          fullWidth: true
        }}
        style={{ marginBottom: "8px" }}
        formControlRoot
        autoFocus
      />
    </FormDialog>
  );
}

ForgotPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired
};

function SignInSide(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    user,
    openAlertError,
    messageAlert,
    handlerAlertError,
    authentication,
    forgotPassword,
    openAlertSuccess,
    handlerAlertSuccess,
    rememberMe,
    handleChangeRemember
  } = props;

  useEffect(() => {
    if (user.loggedIn) {
      history.replace("/e-campus/login/redirect");
    }
  }, [user, history]);

  const [open, setOpen] = useState(false);
  const [openMarketing, setOpenMarketing] = useState(false);
  const handleCloseMarketing = () => {
    setOpenMarketing(false);
  };

  return rememberMe ? (
    <LockScreen />
  ) : (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.loginForm}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Ingreso
          </Typography>
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={schemaCredentialsLogin}
            onSubmit={(values, { setSubmitting, setFieldValue, resetForm }) => {
              authentication(
                values,
                setSubmitting,
                setFieldValue,
                resetForm,
                0
              );
            }}
          >
            {({ isSubmitting }) => (
              <Form className={classes.form}>
                <InputFormik
                  label="Usuario"
                  id="username"
                  name="username"
                  type="text"
                  formControlProps={{
                    margin: "normal",
                    fullWidth: true
                  }}
                  formControlRoot
                  autoFocus
                />
                <InputFormik
                  label="Contraseña"
                  id="password"
                  name="password"
                  type="password"
                  formControlProps={{
                    margin: "normal",
                    fullWidth: true
                  }}
                  formControlRoot
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="remember"
                      color="primary"
                      onClick={e => {
                        handleChangeRemember(e.target.checked);
                      }}
                    />
                  }
                  label="Recuérdame"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Ingreso
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      href="#"
                      variant="body2"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      ¿Olvidaste la contraseña?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      onClick={() => {
                        setOpenMarketing(true);
                      }}
                    >
                      {"¿No tienes una cuenta?"}
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <ErrorBoundary>
                    <Copyright />
                  </ErrorBoundary>
                </Box>
                <AlertError
                  open={openAlertError}
                  setOpen={handlerAlertError}
                  text={messageAlert}
                />
                <AlertSuccess
                  open={openAlertSuccess}
                  setOpen={handlerAlertSuccess}
                  text={messageAlert}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
      <ForgotPassword
        open={open}
        setOpen={setOpen}
        forgotPassword={forgotPassword}
      />
      <SimpleDialog
        open={openMarketing}
        handleClose={handleCloseMarketing}
        dialogId="marketing"
        width="md"
        viewFooter={false}
        stylesContent={{ padding: 0 }}
      >
        <SignInMarketing open={openMarketing} setOpen={setOpenMarketing} />
        <IconButton
          aria-label="close"
          className={"custom-close " + classes.closeMarketing}
          onClick={() => setOpenMarketing(false)}
        >
          <CloseIcon />
        </IconButton>
      </SimpleDialog>
    </Grid>
  );
}

SignInSide.propTypes = {
  user: PropTypes.object.isRequired,
  openAlertError: PropTypes.bool.isRequired,
  messageAlert: PropTypes.string,
  handlerAlertError: PropTypes.func.isRequired,
  authentication: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  openAlertSuccess: PropTypes.bool.isRequired,
  handlerAlertSuccess: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  handleChangeRemember: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.userREDU,
    openAlertError: state.alertErrorREDU,
    messageAlert: state.messageAlertREDU,
    openAlertSuccess: state.alertSuccessREDU,
    rememberMe: state.rememberUserREDU.loggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handlerAlertError: isOpen => {
      dispatch(setOpenAlertErrorACTI(isOpen));
    },
    authentication: (values, setSubmitting, setFieldValue, resetForm) => {
      dispatch(
        authenticationACTI(values, setSubmitting, setFieldValue, resetForm)
      );
    },
    forgotPassword: (username, extraParams) => {
      dispatch(forgotPasswordACTI(username, extraParams));
    },
    handlerAlertSuccess: isOpen => {
      dispatch(setOpenAlertSuccessACTI(isOpen));
    },
    handleChangeRemember: isRemember => {
      dispatch(changeStateRememberACTI(isRemember));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInSide);
