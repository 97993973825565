import { ACTIONS } from "../constants";

export const updateSomeDataACTI = (user, extraParams) => ({
  type: ACTIONS.UPDATE_SOME_DATA_USER,
  user,
  extraParams
});

export const updatePasswordACTI = (user, extraParams) => ({
  type: ACTIONS.UPDATE_PASSWORD,
  user,
  extraParams
});

export const uploadProfileImageACTI = (image, extraParams) => ({
  type: ACTIONS.UPLOAD_PROFILE_IMAGE,
  image,
  extraParams
});

export const deleteProfileImageACTI = extraParams => ({
  type: ACTIONS.DELETE_PROFILE_IMAGE,
  extraParams
});
