import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  contentDialog: {
    paddingTop: theme.spacing(1) + "px !important"
  },
  root: {
    display: "flex",
    width: "10rem",
    minHeight: "7rem",
    justifyContent: "center",
    alignItems: "center"
  },
  contentLoader: {
    textAlign: "center"
  },
  contentText: {
    marginTop: ".4rem"
  }
}));

function Loading(props) {
  const { open, text } = props;
  const classes = useStyles();

  return (
    <Dialog aria-labelledby="dialog-loader" open={open}>
      <DialogContent className={classes.contentDialog}>
        <div className={classes.root}>
          <div className={classes.contentLoader}>
            <CircularProgress />
            {text && (
              <Typography
                variant="subtitle2"
                color="primary"
                className={classes.contentText}
              >
                {text}
              </Typography>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

Loading.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string
};

export default Loading;
