import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import Draggable from "react-draggable";

function PaperComponent(props) {
  const { dialogid, draggable } = props;
  const id = dialogid ? dialogid : "form-dialog";

  return (
    <Draggable
      handle={draggable ? `#${id}` : "none"}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

PaperComponent.propTypes = {
  dialogid: PropTypes.string,
  draggable: PropTypes.string,
  fullscreen: PropTypes.string
};

export default PaperComponent;
