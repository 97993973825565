import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import CustomTable from "components/Table/Table";
import { ROWS_PER_PAGE } from "../../constants";
import { downloadProgramUnitsACTI } from "../../actions/users";

const columns = [
  { id: "codigo", label: "Código", minWidth: 75, width: "20%" },
  { id: "nombre", label: "Nombre", minWidth: 75, width: "80%" }
];

const titleToolbar = {
  color: "textSecondary",
  content: "Programas"
};

function Programs(props) {
  const {
    selectedUnit,
    setProgramIds,
    setSelectedPrograms,
    downloadProgramUnits
  } = props;

  const [programUnits, setProgramUnits] = useState({});
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(undefined);
  const [resetSelections, setResetSelections] = useState([]);

  const onChangePage = (newPage, rowsPerPage) => {
    const params = {
      pageNo: newPage,
      pageSize: rowsPerPage,
      unitId: selectedUnit.id,
      setProgramUnits
    };
    setRows(null);
    setPage(undefined);
    downloadProgramUnits(params);
  };

  function createData(id, codigo, nombre) {
    return { id, codigo, nombre };
  }

  const generateRows = useCallback(programUnits => {
    if (!isEmpty(programUnits)) {
      const newRows = Array.from(programUnits, programUnit =>
        createData(
          programUnit.id,
          programUnit.programa.codigoIcfes,
          programUnit.programa.nombre
        )
      );
      setRows(newRows);
    } else {
      setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
    }
  }, []);

  const handleSetProgramIds = values => {
    setProgramIds(values);

    if (setSelectedPrograms) {
      setSelectedPrograms(oldValues => {
        if (isEmpty(values)) {
          return isEmpty(oldValues) ? oldValues : [];
        }

        const missing = values.filter(
          value =>
            undefined === oldValues.find(oldValue => oldValue.id === value)
        );

        if (!isEmpty(missing)) {
          const selectedPrograms = missing.map(value =>
            rows.find(row => row.id === value)
          );

          return [...oldValues, ...selectedPrograms];
        }

        const surplus = oldValues.filter(
          oldValue => undefined !== values.find(value => value === oldValue.id)
        );

        if (!isEqual(oldValues, surplus)) {
          return surplus;
        }

        return oldValues;
      });
    }
  };

  useEffect(() => {
    if (undefined !== programUnits.content) {
      generateRows(programUnits.content);
    }
  }, [programUnits, generateRows]);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: ROWS_PER_PAGE,
      setProgramUnits
    };

    if (null !== selectedUnit) {
      setRows(null);
      setPage(0);
      setResetSelections([]);
      params.unitId = selectedUnit.id;
      downloadProgramUnits(params);
    }
  }, [selectedUnit, setProgramIds, downloadProgramUnits]);

  return (
    <CustomTable
      tableHeaderColor="primary"
      checkColor="primary"
      columns={columns}
      rows={rows}
      totalElements={
        isEmpty(programUnits.content) ? 0 : programUnits.totalElements
      }
      action="checkbox"
      titleToolbar={titleToolbar}
      onChangePage={onChangePage}
      forcePage={page}
      setForcePage={setPage}
      getSelected={handleSetProgramIds}
      resetSelections={resetSelections}
    />
  );
}

Programs.propTypes = {
  selectedUnit: PropTypes.object,
  setProgramIds: PropTypes.func.isRequired,
  setSelectedPrograms: PropTypes.func,
  downloadProgramUnits: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    downloadProgramUnits: params => {
      dispatch(downloadProgramUnitsACTI(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Programs);
