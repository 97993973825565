import React from "react";
// import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const styles = {
  root: {
    textAlign: "center"
  }
};

function AutoLoaderAlert() {
  return (
    <Typography variant="body1" gutterBottom style={styles.root}>
      Este proceso puede demorar algunos minutos. Por favor espera...
    </Typography>
  );
}

// AutoLoaderAlert.propTypes = {

// };

export default AutoLoaderAlert;
