import { ACTIONS } from "../constants";

export const setMessageNotificationACTI = message => ({
  type: ACTIONS.SET_MESSAGE_NOTIFICATION,
  message
});

export const setOpenNotificationSuccessACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_NOTIFICATION_SUCCESS,
  isOpen
});

export const setOpenNotificationWarningACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_NOTIFICATION_WARNING,
  isOpen
});

export const setOpenNotificationErrorACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_NOTIFICATION_ERROR,
  isOpen
});

export const setOpenNotificationGeneralACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_NOTIFICATION_GENERAL,
  isOpen
});

export const setMessageAlertACTI = message => ({
  type: ACTIONS.SET_MESSAGE_ALERT,
  message
});

export const setOpenAlertSuccessACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_ALERT_SUCCESS,
  isOpen
});

export const setOpenAlertWarningACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_ALERT_WARNING,
  isOpen
});

export const setOpenAlertErrorACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_ALERT_ERROR,
  isOpen
});

export const setOpenAlertGeneralACTI = isOpen => ({
  type: ACTIONS.SET_OPEN_ALERT_GENERAL,
  isOpen
});

export const authenticationACTI = (
  values,
  setSubmitting,
  setFieldValue,
  resetForm,
  fromLocked
) => ({
  type: ACTIONS.AUTHENTICATION,
  values,
  setSubmitting,
  setFieldValue,
  resetForm,
  fromLocked
});

export const addUserStoreACTI = user => ({
  type: ACTIONS.ADD_USER_STORE,
  user
});

export const loadOperationACTI = () => ({
  type: ACTIONS.LOAD_OPERATION
});

export const logoutACTI = () => ({
  type: ACTIONS.LOGOUT
});

// reset store
export const userLogoutACTI = () => ({
  type: ACTIONS.USER_LOGOUT
});

export const addAppsStoreACTI = categories => ({
  type: ACTIONS.ADD_APPS_STORE,
  categories
});

export const validateApplicationACTI = (application, extraParams) => ({
  type: ACTIONS.VALIDATE_APPLICATION,
  application,
  extraParams
});

export const changeStateRememberACTI = isRemember => ({
  type: ACTIONS.CHECK_REMEMBER_USER,
  isRemember
});

export const disableRememberUserACTI = () => ({
  type: ACTIONS.DISABLE_REMEMBER_USER
});

export const addImageUserProfileACTI = profileImage => ({
  type: ACTIONS.ADD_IMAGE_USER_PROFILE,
  profileImage
});

export const addFunctionalityUsersACTI = users => ({
  type: ACTIONS.ADD_FUNCTIONALITY_USERS_STORE,
  users
});

export const findUserByDocumentACTI = params => ({
  type: ACTIONS.FIND_USER_BY_DOCUMENT,
  params
});

export const findUsersByParamsACTI = params => ({
  type: ACTIONS.FIND_USERS_BY_PARAMS,
  params
});

export const addFunctionalityPeopleACTI = people => ({
  type: ACTIONS.ADD_FUNCTIONALITY_PEOPLE_STORE,
  people
});

export const findPersonByDocumentACTI = params => ({
  type: ACTIONS.FIND_PERSON_BY_DOCUMENT,
  params
});

export const findPeopleByParamsACTI = params => ({
  type: ACTIONS.FIND_PEOPLE_BY_PARAMS,
  params
});
