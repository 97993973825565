import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { IconButton, makeStyles } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import SimpleDialog from "components/Dialog/SimpleDialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomTable from "components/Table/Table";
import SearchUserForm from "./SearchUserForm";
import {
  findPeopleByParamsACTI,
  findPersonByDocumentACTI
} from "../../actions";
import { findExistingUserACTI } from "actions/users";
import CircularIndeterminate from "components/Progress/CircularIndeterminate";

const stylesContent = { color: "inherit" };

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      borderRight: "2px solid #ccc"
    }
  },
  loader: {
    position: "fixed",
    height: "100%",
    zIndex: 9,
    backgroundColor: "rgba(0, 0, 0, .3)",
    inset: 0
  }
});
const useStyles = makeStyles(styles);

const columns = [
  { id: "documento", label: "Documento", minWidth: 70, width: "25%" },
  { id: "nombres", label: "Nombre", minWidth: 75, width: "75%" }
];

const peopleTitleToolbar = {
  color: "textSecondary",
  content: "Personas"
};

function NewUser(props) {
  const {
    setSelectedUser,
    setClearRows,
    findPersonByDocument,
    findPeopleByParams,
    findExistingUser
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [people, setPeople] = useState({});
  const [rows, setRows] = useState([]);
  const [userDocument, setUserDocument] = useState(null);
  const [userName, setUserName] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [page, setPage] = useState(undefined);

  const handleSearchPerson = () => {
    setSelectedUser(null);
    setUserName(null);
    setUserDocument(null);
    setPeople(oldPeople => {
      if (!isEmpty(oldPeople.content)) {
        return { content: [] };
      }

      return oldPeople;
    });
    setClearRows({}); // limpia los registros de la tabla de usuarios del componente SearchUser.
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangePage = (newPage, rowsPerPage) => {
    if (null !== userName) {
      const params = {
        setRows,
        pageNo: newPage,
        pageSize: rowsPerPage,
        userName,
        setUsers: setPeople,
        isPaginated: true
      };
      findPeopleByParams(params);
    }
  };

  function createData(id, documento, nombres) {
    return { id, documento, nombres };
  }

  const generateRows = useCallback(people => {
    if (!isEmpty(people)) {
      const newRows = Array.from(people, person =>
        createData(person.id, person.documentoIdentidad, person.nombreCompleto)
      );
      setRows(newRows);
    } else {
      setRows(rows => (null === rows ? [] : rows.length > 0 ? [] : rows));
    }
  }, []);

  const handleClickRow = row => {
    if (null === selectedPerson) {
      setSelectedPerson(row);
      const person = people.content.find(person => person.id === row.id);
      const params = {
        setSelectedUser,
        setSelectedPerson,
        handleClose
      };
      findExistingUser(person, params);
    }
  };

  useEffect(() => {
    generateRows(people.content);
  }, [generateRows, people]);

  return (
    <>
      <div className={classes.root}>
        <IconButton
          aria-label="search users"
          color="primary"
          onClick={handleSearchPerson}
        >
          <PersonAdd fontSize="large" />
        </IconButton>
      </div>
      <SimpleDialog
        open={open}
        handleClose={handleClose}
        dialogId="new-user"
        title="Nuevo usuario"
        width="md"
        stylesContent={stylesContent}
        disableBackClick={null !== selectedPerson}
        viewFooter={false}
        withIconClose
      >
        <GridContainer>
          <SearchUserForm
            users={people}
            rows={rows}
            setUsers={setPeople}
            setRows={setRows}
            findUserByDocument={findPersonByDocument}
            findUsersByParams={findPeopleByParams}
            setPage={setPage}
            userDocument={userDocument}
            setUserDocument={setUserDocument}
            userName={userName}
            setUserName={setUserName}
          />
          <GridItem xs={12}>
            <CustomTable
              columns={columns}
              rows={rows}
              totalElements={isEmpty(people.content) ? 0 : people.totalElements}
              action="click"
              titleToolbar={peopleTitleToolbar}
              onChangePage={onChangePage}
              onClickRow={handleClickRow}
              forcePage={page}
              setForcePage={setPage}
            />
          </GridItem>
        </GridContainer>
        {null !== selectedPerson && (
          <CircularIndeterminate className={classes.loader} />
        )}
      </SimpleDialog>
    </>
  );
}

NewUser.propTypes = {
  setSelectedUser: PropTypes.func.isRequired,
  setClearRows: PropTypes.func.isRequired,
  findPersonByDocument: PropTypes.func.isRequired,
  findPeopleByParams: PropTypes.func.isRequired,
  findExistingUser: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    findPersonByDocument: params => {
      dispatch(findPersonByDocumentACTI(params));
    },
    findPeopleByParams: params => {
      dispatch(findPeopleByParamsACTI(params));
    },
    findExistingUser: (person, params) => {
      dispatch(findExistingUserACTI(person, params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(NewUser);
