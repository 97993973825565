import React, {
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ContentActionsFD from "./ContentActionsFD";
import PaperComponent from "components/PaperComponent/PaperComponent";
import AlertDialog from "./AlertDialog";

const defaultHeader = {
  color: "#797979"
};
const styles = {
  dialogContent: {
    paddingTop: "4px"
  }
};

const useStyles = makeStyles(() => ({
  form: {
    width: "100%" // Fix IE 11 issue.
  }
}));

function FormDialog(props) {
  const {
    open,
    handleClose,
    handleSubmit,
    pristine,
    dialogId,
    title,
    children,
    disableBackClick,
    width,
    viewFooter,
    draggable,
    initialValues,
    yupObject,
    alert
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [dirty, setDirty] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dataSubmit, setDataSubmit] = useState([]);
  const paperProps = {
    dialogid: dialogId
  };

  if (draggable && !fullScreen) {
    styles.header = {
      ...defaultHeader,
      cursor: "move"
    };
    paperProps.draggable = "1";
  } else {
    styles.header = defaultHeader;
  }

  const handleCloseAlert = () => {
    setOpenAlert(false);
    const { setSubmitting } = dataSubmit[1];
    setSubmitting(false);
    setDataSubmit([]);
  };

  const handleAcceptAlert = () => {
    setOpenAlert(false);
    handleSubmit(dataSubmit[0], dataSubmit[1]);
    setDataSubmit([]);
  };

  const formElementRef = useRef(null);
  const handleAccept = () => {
    const { current: formElement } = formElementRef;
    formElement.dispatchEvent(new Event("submit", { cancelable: true }));
  };

  const onHandleSubmit = (values, props) => {
    if (undefined !== alert) {
      setDataSubmit([values, props]);
      setOpenAlert(true);
    } else {
      handleSubmit(values, props);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;

      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        disableBackdropClick={!!viewFooter || !!disableBackClick}
        disableEscapeKeyDown={!!viewFooter || !!disableBackClick}
        fullScreen={fullScreen}
        maxWidth={width ? width : false}
        PaperComponent={PaperComponent}
        PaperProps={paperProps}
        aria-labelledby={dialogId ? dialogId : "form-dialog"}
      >
        {title && (
          <DialogTitle
            id={dialogId ? dialogId : "form-dialog"}
            style={styles.header}
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent style={styles.dialogContent}>
          <div id="scroll-dialog-content" ref={descriptionElementRef}>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object(yupObject)}
              onSubmit={onHandleSubmit}
            >
              {({
                dirty,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              }) => {
                setDirty(dirty);
                setSubmitting(isSubmitting);

                return (
                  <Form className={classes.form} ref={formElementRef}>
                    {isValidElement(children)
                      ? cloneElement(children, {
                          dirty,
                          isSubmitting,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        })
                      : children}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </DialogContent>
        {undefined === viewFooter || viewFooter ? (
          <DialogActions>
            <ContentActionsFD
              handleClose={handleClose}
              isSubmitting={isSubmitting}
              pristine={undefined !== pristine ? pristine : !dirty}
              handleAccept={handleAccept}
            />
          </DialogActions>
        ) : null}
      </Dialog>
      {undefined !== alert && (
        <AlertDialog
          open={openAlert}
          title={alert.title}
          content={alert.content}
          handleClose={handleCloseAlert}
          handleAccept={handleAcceptAlert}
        />
      )}
    </>
  );
}

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  dialogId: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  disableBackClick: PropTypes.bool,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  viewFooter: PropTypes.bool,
  draggable: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  yupObject: PropTypes.object.isRequired,
  alert: PropTypes.object
};

export default FormDialog;
