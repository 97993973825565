import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import "typeface-roboto";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import InputFormik from "components/CustomInput/InputFormik";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeaderAlt from "components/Card/CardHeaderAlt";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import InputCustomLabel from "components/CustomInput/InputCustomLabel";

import ViewData from "components/CustomInput/ViewData";
import ViewDataList from "components/CustomInput/ViewDataList";
import { getFirstName, getLetters } from "../../helpers/functions";
import FormDialog from "components/Dialog/FormDialog";
import { updatePasswordACTI } from "actions/userProfile";
import { updateSomeDataACTI } from "actions/userProfile";
import { schemaPasswordReset } from "yupValidations";
import PicProfileEditor from "./PicProfileEditor";
import { Avatar } from "@material-ui/core";
import { uploadProfileImageACTI } from "actions/userProfile";
import CircularIndeterminate from "components/Progress/CircularIndeterminate";
import { deleteProfileImageACTI } from "actions/userProfile";

const styles = theme => ({
  presentation: {
    order: 0,
    [theme.breakpoints.up("md")]: {
      order: 1
    }
  },
  dataUser: {
    order: 1,
    [theme.breakpoints.up("md")]: {
      order: 0
    }
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  cardCategoryCustom: {
    color: "#999",
    margin: "10px 0",
    fontSize: ".8em",
    fontWeight: "500"
  },
  cardTitleCustom: {
    color: "#3C4858",
    marginTop: "0",
    marginBottom: "3px"
  },
  descriptionCustom: {
    color: "#999",
    margin: "0 0 10px",
    lineHeight: "1.5em"
  },
  avatarCustom: {
    width: "130px",
    height: "130px",
    fontSize: "3rem",
    backgroundColor: "#272727"
  },
  email: {
    marginBottom: 10
  }
});

const useStyles = makeStyles(styles);

function UserProfile(props) {
  const {
    user,
    updateSomeData,
    updatePassword,
    userImage,
    uploadProfileImage,
    deleteProfileImage
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openPicProfileEditor, setOpenPicProfileEditor] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePicProfileEditor = e => {
    e.preventDefault();

    if (null !== userImage) {
      setOpenPicProfileEditor(true);
    }
  };

  const viewCategory = () => {
    let category = "-";

    if (user.perfiles) {
      for (let j = 0; j < user.perfiles.length; j++) {
        if (j > 0) {
          category += " / ";
        }

        category = user.perfiles[j].nombre;
      }
    }

    return category;
  };

  const viewName = () => {
    const splitName = user.nombre && user.nombre.trim().split(" ");
    return getFirstName(splitName);
  };

  const avatarLetter = () => {
    const splitName = user.nombre && user.nombre.trim().split(" ");
    return getLetters(splitName);
  };

  const initialValues = {
    password: "",
    newPassword: "",
    repeatPassword: ""
  };

  const yupObject = schemaPasswordReset;
  yupObject.password = Yup.string().required("Requerido");

  const handleSubmitDataUser = (values, { setSubmitting }) => {
    const extraParams = {
      setSubmitting
    };
    updateSomeData(values, extraParams);
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const extraParams = {
      resetForm,
      setSubmitting,
      setOpen
    };
    updatePassword(values, extraParams);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8} className={classes.dataUser}>
          <Card>
            <Formik
              initialValues={{
                email: user.correo ? user.correo : "",
                description: user.descripcion ? user.descripcion : ""
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .trim()
                  .required("Requerido")
                  .email("Ingrese un correo válido")
                  .max(300, "Máximo 300 caracteres"),
                description: Yup.string()
                  .trim()
                  .max(1000, "Límite de caracteres superado")
                  .required("Requerido")
              })}
              onSubmit={handleSubmitDataUser}
            >
              {({ dirty, isSubmitting }) => (
                <Form>
                  <CardHeaderAlt title="Datos personales" />
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={6}>
                        <InputCustomLabel label="Nombre" />
                        <ViewData value={user.nombre} m={0} mb={5} />
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <InputCustomLabel label="Documento" />
                        <ViewData value={user.documento} m={0} mb={5} />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} className={classes.email}>
                        <InputCustomLabel label="Correo" htmlFor="email" />
                        <InputFormik
                          id="email"
                          name="email"
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          formControlRoot
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12}>
                        <InputCustomLabel label="Perfil(es)" />
                        <ViewDataList
                          values={user.perfiles}
                          propView="nombre"
                          m={0}
                          mb={5}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12}>
                        <InputCustomLabel
                          label="Sobre mí"
                          htmlFor="description"
                        />
                        <InputFormik
                          id="description"
                          name="description"
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          formControlRoot
                          multiline
                          rows={4}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button
                      type="submit"
                      color="primary"
                      size="sm"
                      disabled={!dirty || isSubmitting}
                    >
                      Actualizar
                    </Button>
                  </CardFooter>
                </Form>
              )}
            </Formik>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} className={classes.presentation}>
          <Card profile>
            <CardAvatar profile>
              <a href="#avatar" onClick={handlePicProfileEditor}>
                {!isEmpty(userImage) ? (
                  <img src={userImage.src} alt="User" />
                ) : (
                  <Avatar alt={user.nombre} className={classes.avatarCustom}>
                    {null !== userImage ? (
                      avatarLetter()
                    ) : (
                      <CircularIndeterminate className="loader-avatar-user-profile" />
                    )}
                  </Avatar>
                )}
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategoryCustom}>{viewCategory()}</h6>
              <h4 className={classes.cardTitleCustom}>{viewName()}</h4>
              <p className={classes.descriptionCustom}>
                {user.descripcion
                  ? user.descripcion
                  : "Es un orgullo ser parte de esta maravillosa comunidad"}
              </p>
              <Button
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
                size="sm"
              >
                Contraseña
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <FormDialog
        open={open}
        handleClose={handleClose}
        title="Actualizar contraseña"
        width="xs"
        initialValues={initialValues}
        yupObject={yupObject}
        handleSubmit={handleSubmit}
      >
        <InputFormik
          label="Contraseña"
          id="password"
          name="password"
          type="password"
          formControlProps={{
            fullWidth: true
          }}
          style={{ marginBottom: "8px" }}
          formControlRoot
        />
        <InputFormik
          label="Nueva contraseña"
          id="newPassword"
          name="newPassword"
          type="password"
          formControlProps={{
            margin: "normal",
            fullWidth: true
          }}
          formControlRoot
        />
        <InputFormik
          label="Confirmar contraseña"
          id="repeatPassword"
          name="repeatPassword"
          type="password"
          formControlProps={{
            margin: "normal",
            fullWidth: true
          }}
          formControlRoot
        />
      </FormDialog>
      <PicProfileEditor
        open={openPicProfileEditor}
        setOpen={setOpenPicProfileEditor}
        userImage={userImage}
        uploadProfileImage={uploadProfileImage}
        deleteProfileImage={deleteProfileImage}
      />
    </>
  );
}

UserProfile.propTypes = {
  user: PropTypes.object.isRequired,
  updateSomeData: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  userImage: PropTypes.object,
  uploadProfileImage: PropTypes.func.isRequired,
  deleteProfileImage: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.userREDU,
    userImage: state.imagesUserREDU.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSomeData: (user, extraParams) => {
      dispatch(updateSomeDataACTI(user, extraParams));
    },
    updatePassword: (user, extraParams) => {
      dispatch(updatePasswordACTI(user, extraParams));
    },
    uploadProfileImage: (userImage, extraParams) => {
      dispatch(uploadProfileImageACTI(userImage, extraParams));
    },
    deleteProfileImage: extraParams => {
      dispatch(deleteProfileImageACTI(extraParams));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
