import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

const styles = {
  root: {
    padding: 0,
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400,
    listStyle: "none"
  }
};

const renderData = (index, value) => <li key={value}>{value}</li>;

function ViewDataList(props) {
  const { values, propView, m, my, mt, mb } = props;

  if (undefined !== m) {
    styles.root = {
      ...styles.root,
      margin: `${m}px`
    };
  }

  if (undefined !== my) {
    styles.root = {
      ...styles.root,
      marginTop: `${my}px`,
      marginBottom: `${my}px`
    };
  } else {
    if (undefined !== mt) {
      styles.root = {
        ...styles.root,
        marginTop: `${mt}px`
      };
    }

    if (undefined !== mb) {
      styles.root = {
        ...styles.root,
        marginBottom: `${mb}px`
      };
    }
  }

  if (!isEmpty(values) && propView) {
    return (
      <ul style={styles.root}>
        {values.map((value, index) => renderData(index, value[propView]))}
      </ul>
    );
  } else {
    return (
      <p style={styles.root}>
        <i>No hay datos para mostrar</i>
      </p>
    );
  }
}

ViewDataList.propTypes = {
  values: PropTypes.array,
  propView: PropTypes.string,
  m: PropTypes.number,
  my: PropTypes.number,
  mt: PropTypes.number,
  mb: PropTypes.number
};

export default ViewDataList;
