export const TYPES_CHARTS = [
  { id: "BAR_CHART", name: "Barras", type: "BarChart", conf: "ST" },
  { id: "PIE_CHART", name: "Circulares", type: "PieChart", conf: "PI" },
  { id: "COLUMN_CHART", name: "Columnas", type: "ColumnChart", conf: "ST" },
  { id: "LINE_CHART", name: "Lineales", type: "LineChart", conf: "TR" }
];
export const dataGraphs = {
  LINE_CHART: {
    values: [
      [
        "x",
        "Vista previa"
        // { type: "string", role: "tooltip", p: { html: true } }
      ],
      [0, 0],
      [1, 10],
      [2, 23],
      [3, 17],
      [4, 18]
    ],
    hTitle: "Horizontal",
    vTitle: "Vertical"
  },
  LINE: {
    values: [
      ["Horizontal", "Vista previa"],
      [1, 80.8],
      [2, 69.5],
      [3, 57],
      [4, 18.8],
      [5, 17.6],
      [6, 13.6],
      [7, 12.3],
      [8, 29.2],
      [9, 42.9],
      [10, 30.9],
      [11, 7.9],
      [12, 8.4],
      [13, 6.3],
      [14, 6.2]
    ]
  },
  BAR_CHART: {
    values: [
      ["Elemento", "Densidad"],
      ["Cobre", 8.94],
      ["Plata", 10.49],
      ["Oro", 19.3],
      ["Platino", 21.45]
    ],
    vTitle: "Horizontal",
    hTitle: "Vertical"
  },
  COLUMN_CHART: {
    values: [
      ["Elemento", "Densidad"],
      ["Cobre", 8.94],
      ["Plata", 10.49],
      ["Oro", 19.3],
      ["Platino", 21.45]
    ],
    hTitle: "Horizontal",
    vTitle: "Vertical"
  },
  PIE_CHART: {
    values: [["Tarea", "Horas por día"], ["Trabajo", 8]]
  }
};
