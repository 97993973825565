import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";
import { useButtonStyles } from "assets/jss/material-dashboard-react/buttonStyle";

function ContentActions(props) {
  const { handleClose, handleAccept, pristine, isSubmitting } = props;
  const classes = useButtonStyles();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClose}
        size="small"
        className={classes.root}
        disabled={isSubmitting}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAccept}
        size="small"
        className={classes.root}
        disabled={pristine || isSubmitting}
      >
        Aceptar
      </Button>
      {isSubmitting && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </>
  );
}

ContentActions.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  isSubmitting: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    pristine: state.controlDashboardREDU.pristine,
    isSubmitting: state.controlDashboardREDU.isSubmitting
  };
};

export default connect(
  mapStateToProps,
  null
)(ContentActions);
