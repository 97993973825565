import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

const CustomTooltip = props => {
  const useStyles = makeStyles( theme => ({
    arrow: {
      color: props.style && props.style.color ? props.style.color : theme.palette.common.gray,
    },
    tooltip: {
      backgroundColor: props.style && props.style.color ? props.style.color : theme.palette.common.gray,
    },
  }));
  const classes = useStyles();
  
  return <Tooltip classes={classes} {...props} />;
};

export default CustomTooltip;