import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import Roles from "views/Users/Roles";
import FormDialog from "components/Dialog/FormDialog";
import ManageRole from "components/Roles/ManageRole";
import { insertRoleACTI } from "actions/users";
import { updateRoleACTI } from "actions/users";
import { addRoleApplicationsStoreACTI } from "actions/administrator";

const defaultValues = {
  name: "",
  description: "",
  profile: ""
};

const schema = {
  name: Yup.string()
    .trim()
    .required("Requerido")
    .max(100, "Máximo 100 caracteres"),
  description: Yup.string().max(100, "Máximo 100 caracteres"),
  profile: Yup.string()
};

function GovernRole(props) {
  const { profiles, insertRole, updateRole, setRoleApplications } = props;

  const [roleEdit, setRoleEdit] = useState(null);
  const [initialValues, setinitialValues] = useState(defaultValues);
  const [open, setOpen] = useState(false);
  const [functionalitiesToSave, setFunctionalitiesToSave] = useState({});
  const [applicationIds, setApplicationIds] = useState([]);
  const [pristine, setPristine] = useState(undefined);

  const handleCloseManageRole = () => {
    setOpen(false);
  };

  const handleOpenRoleAdd = () => {
    setRoleEdit(null);
    setinitialValues(defaultValues);
    setFunctionalitiesToSave(oldFunctionalities =>
      isEmpty(oldFunctionalities) ? oldFunctionalities : {}
    );
    setRoleApplications({});
    setOpen(true);
  };

  const handleOpenRoleEdit = role => {
    setRoleEdit(role);
    const values = {
      name: role.nombre,
      description: null !== role.descripcion ? role.descripcion : "",
      profile:
        null !== role.perfil && !isEmpty(profiles)
          ? profiles.findIndex(profile => profile.id === role.perfil.id)
          : ""
    };

    setinitialValues(values);
    setRoleApplications({});
    setOpen(true);
  };

  const handleSubmitManageRole = (values, { setSubmitting }) => {
    const extraParams = {
      handleCloseManageRole,
      setSubmitting
    };
    values.applicationIds = applicationIds;

    if (null === roleEdit) {
      extraParams.functionalitiesToSave = functionalitiesToSave;
      insertRole(values, extraParams);
    } else {
      values.id = roleEdit.id;
      updateRole(values, extraParams);
    }
  };

  return (
    <>
      <Roles
        action="click"
        handleOpenAdd={handleOpenRoleAdd}
        handleOpenEdit={handleOpenRoleEdit}
      />
      <FormDialog
        open={open}
        handleClose={handleCloseManageRole}
        title="Gestionar rol"
        width="md"
        initialValues={initialValues}
        yupObject={schema}
        handleSubmit={handleSubmitManageRole}
        pristine={pristine}
        disableBackClick
      >
        <ManageRole
          roleEdit={roleEdit}
          setFunctionalitiesToSave={setFunctionalitiesToSave}
          setApplicationIds={setApplicationIds}
          setPristine={setPristine}
        />
      </FormDialog>
    </>
  );
}

GovernRole.propTypes = {
  profiles: PropTypes.array,
  insertRole: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  setRoleApplications: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    profiles: state.globalProfilesREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    insertRole: (role, extraParams) => {
      dispatch(insertRoleACTI(role, extraParams));
    },
    updateRole: (role, extraParams) => {
      dispatch(updateRoleACTI(role, extraParams));
    },
    setRoleApplications: applications => {
      dispatch(addRoleApplicationsStoreACTI(applications));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GovernRole);
