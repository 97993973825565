import { ACTIONS } from "../constants";

export const roleApplicationsREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_ROLE_APPLICATIONS_STORE:
      return action.applications;
    default:
      return state;
  }
};
