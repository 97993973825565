import React from "react";
import { Typography } from "@material-ui/core";

const styles = {
  root: {
    textAlign: "center"
  }
};

export default function AutoProcessAlert() {
  return (
    <Typography variant="body1" gutterBottom style={styles.root}>
      Este proceso puede demorar algunos minutos. <br />
      ¿Deseas continuar?
    </Typography>
  );
}
