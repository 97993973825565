import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

function AutoProcessContent(props) {
  const { automaticUser, selectedPrograms } = props;

  return (
    <>
      {Object.entries(automaticUser.process).map(([prop, value]) => {
        const program = selectedPrograms.find(program => program.id === prop);

        if (!program) return null; // para evitar error undefined.

        return (
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
            key={program.id}
          >
            {`${program.codigo} ${program.nombre}: `}
            <b>{value}</b>
          </Typography>
        );
      })}
    </>
  );
}

AutoProcessContent.propTypes = {
  automaticUser: PropTypes.object.isRequired,
  selectedPrograms: PropTypes.array.isRequired
};

export default AutoProcessContent;
