import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/plugins/autolink";
import "tinymce/plugins/code";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/wordcount";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import { useField } from "formik";

const styles = theme => ({
  root: {
    marginBottom: 8
  },
  borderError: {
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 5
  },
  error: {
    fontSize: "0.75rem",
    color: theme.palette.error.main,
    margin: 0,
    marginTop: -8
  }
});
const useStyles = makeStyles(styles);

function TinymceFormik(props) {
  const classes = useStyles();
  const { handleChange } = props;
  const [field, meta] = useField(props);
  const { name, onBlur, onChange, value } = field;
  const { error, initialValue } = meta;

  const handleEditorChange = value => {
    onChange({ target: { name, value } });

    if (handleChange) {
      handleChange(value);
    }
  };

  return (
    <>
      <div
        className={
          error ? classes.root + " " + classes.borderError : classes.root
        }
      >
        <Editor
          initialValue={initialValue}
          textareaName={name}
          init={{
            skin: false,
            content_css: false,
            language: "es_MX",
            language_url: "/langs/tinymce/es_MX.js",
            // directionality: "rtl",
            height: 300,
            menubar: true,
            plugins: [
              "autolink lists link image",
              "visualblocks code",
              "insertdatetime media table paste code wordcount"
            ],
            toolbar:
              "undo redo | fontselect fontsizeselect formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
            selector: "textarea"
          }}
          onEditorChange={handleEditorChange}
          // onBlur={e => {console.log(e)}}
          onBlur={onBlur}
          // onChange={field.onChange}
          value={value[name]}
        />
      </div>
      {error && <p className={classes.error}>{error}</p>}
    </>
  );
}

TinymceFormik.propTypes = {
  handleChange: PropTypes.func
};

export default TinymceFormik;
