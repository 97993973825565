import { ACTIONS, COLOR_APP_RGBA_OBJ } from "../constants";

export const profileREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_PROFILE_STORE:
      return action.profile;
    default:
      return state;
  }
};

export const typesREDU = (state = [], action) => {
  switch (action.type) {
    case ACTIONS.ADD_TYPES_STORE:
      return action.types;
    default:
      return state;
  }
};

export const widgetsREDU = (state = [], action) => {
  switch (action.type) {
    case ACTIONS.ADD_WIDGETS_STORE:
      return action.widgets;
    default:
      return state;
  }
};

export const layoutsREDU = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.ADD_LAYOUTS_STORE:
      return action.layouts;
    default:
      return state;
  }
};

export const fullLayoutsREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_FULL_LAYOUTS_STORE:
      return action.layout;
    default:
      return state;
  }
};

const changeBreakpoint = (state, breakpoint) => {
  let newState = Object.assign({}, state);
  newState.breakpoint = breakpoint;

  return newState;
};

const setAlertDone = (state, alertDone) => {
  let newState = Object.assign({}, state);
  newState.alertDone = alertDone;

  return newState;
};

const setSubmitting = (state, isSubmitting) => {
  let newState = Object.assign({}, state);
  newState.isSubmitting = isSubmitting;

  return newState;
};

const setChangeWidget = (state, changeWidget) => {
  let newState = Object.assign({}, state);
  newState.changeWidget = changeWidget;

  return newState;
};

const setViewGrid = (state, viewGrid) => {
  let newState = Object.assign({}, state);
  newState.viewGrid = viewGrid;

  return newState;
};

const setPristine = (state, isPristine) => {
  let newState = Object.assign({}, state);
  newState.pristine = isPristine;

  return newState;
};

const setPristineGraph = (state, isPristine) => {
  let newState = Object.assign({}, state);
  newState.pristineGraph = isPristine;

  return newState;
};

const setLimitWidgets = (state, limit) => {
  let newState = Object.assign({}, state);
  newState.limitWidgets = limit;

  return newState;
};

export const controlDashboardREDU = (
  state = {
    breakpoint: "",
    viewGrid: true,
    alertDone: false,
    changeWidget: false,
    pristine: false,
    isSubmitting: false,
    pristineGraph: true,
    limitWidgets: 10
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.CHANGE_BREAKPOINT:
      return changeBreakpoint(state, action.breakpoint);
    case ACTIONS.SET_ALERT_DONE:
      return setAlertDone(state, action.alertDone);
    case ACTIONS.SET_SUBMITTING:
      return setSubmitting(state, action.isSubmitting);
    case ACTIONS.SET_CHANGE_WIDGET:
      return setChangeWidget(state, action.changeWidget);
    case ACTIONS.SET_VIEW_GRID:
      return setViewGrid(state, action.viewGrid);
    case ACTIONS.SET_PRISTINE:
      return setPristine(state, action.isPristine);
    case ACTIONS.SET_PRISTINE_GRAPH:
      return setPristineGraph(state, action.isPristine);
    case ACTIONS.SET_LIMIT_WIDGETS:
      return setLimitWidgets(state, action.limit);
    default:
      return state;
  }
};

export const dialogDashboardREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_DIALOG_WIDGET:
      return action.isOpen;
    default:
      return state;
  }
};

const setColorCard = (state, colorCard) => {
  let newState = Object.assign({}, state);
  newState.colorCard = colorCard;

  return newState;
};

const setColorCardA = (state, colorCardA) => {
  let newState = Object.assign({}, state);
  newState.colorCardA = colorCardA;

  return newState;
};

const setColorCardB = (state, colorCardB) => {
  let newState = Object.assign({}, state);
  newState.colorCardB = colorCardB;

  return newState;
};

export const colorDashboardREDU = (
  state = {
    colorCard: COLOR_APP_RGBA_OBJ,
    colorCardA: COLOR_APP_RGBA_OBJ,
    colorCardB: COLOR_APP_RGBA_OBJ
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.SET_COLOR_CARD:
      return setColorCard(state, action.color);
    case ACTIONS.SET_COLOR_CARD_A:
      return setColorCardA(state, action.color);
    case ACTIONS.SET_COLOR_CARD_B:
      return setColorCardB(state, action.color);
    default:
      return state;
  }
};

export const widgetUpdateREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.SET_WIDGET_UPDATE:
      return action.widget;
    default:
      return state;
  }
};

export const viewContentDialogREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_VIEW_CONTENT_DIALOG:
      return action.isView;
    default:
      return state;
  }
};

export const checkEditDashboardREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.CHECKED_EDIT_DASHBOARD:
      return !state;
    default:
      return state;
  }
};

export const typeWidgetREDU = (state = "infobox", action) => {
  switch (action.type) {
    case ACTIONS.SET_TYPE_WIDGET:
      return action.typeWidget;
    default:
      return state;
  }
};

export const colorGradWidgetREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.CHECK_COLOR_GRAD_WIDGET:
      return action.isChecked;
    default:
      return state;
  }
};

export const degreedCardWidgetREDU = (state = 60, action) => {
  switch (action.type) {
    case ACTIONS.SET_DEGREED_CARD_WIDGET:
      return action.degreed ? action.degreed : state;
    default:
      return state;
  }
};

export const colorAltCardWidgetREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.CHECK_COLOR_ALT_CARD_WIDGET:
      return action.isChecked;
    default:
      return state;
  }
};

export const titleWidgetREDU = (state = "Título", action) => {
  switch (action.type) {
    case ACTIONS.SET_TITLE_WIDGET:
      return action.title;
    default:
      return state;
  }
};

export const categoryWidgetREDU = (state = "Categoría", action) => {
  switch (action.type) {
    case ACTIONS.SET_CATEGORY_WIDGET:
      return action.category;
    default:
      return state;
  }
};

export const contentWidgetREDU = (state = "Contenido...", action) => {
  switch (action.type) {
    case ACTIONS.SET_CONTENT_WIDGET:
      return action.content;
    default:
      return state;
  }
};

const icon = {
  nombre: "account",
  path:
    "M12,4C14.21,4 16,5.79 16,8C16,10.21 14.21,12 12,12C9.79,12 8,10.21 8,8C8,5.79 9.79,4 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z",
  nombreTab: "webApps"
};

export const iconCardWidgetREDU = (state = icon, action) => {
  switch (action.type) {
    case ACTIONS.SET_ICON_CARD_WIDGET:
      return action.icon ? action.icon : icon;
    default:
      return state;
  }
};

export const iframeWidgetREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.CHECK_IFRAME_WIDGET:
      return !!action.isChecked;
    default:
      return state;
  }
};

export const dialogIconCardWidgetREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_DIALOG_ICON_CARD_WIDGET:
      return action.isOpen;
    default:
      return state;
  }
};

export const valueTabIconCardWidgetREDU = (state = 0, action) => {
  switch (action.type) {
    case ACTIONS.SET_VALUE_TAB_ICON_CARD_WIDGET:
      return action.value;
    default:
      return state;
  }
};

export const iconsCardWidgetREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.SET_ICONS_CARD_WIDGET:
      return action.icons ? action.icons : state;
    default:
      return state;
  }
};

export const chartTypeREDU = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_CHART_TYPE:
      return action.chartType;
    default:
      return state;
  }
};

export const chartDataREDU = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_CHART_DATA:
      return action.data;
    default:
      return state;
  }
};

export const chartRangeNameREDU = (state = "", action) => {
  switch (action.type) {
    case ACTIONS.SET_CHART_RANGE_NAME:
      return action.name;
    default:
      return state;
  }
};

export const chartLegendREDU = (state = true, action) => {
  switch (action.type) {
    case ACTIONS.CHECK_CHART_LEGEND:
      return action.isChecked;
    default:
      return state;
  }
};

export const chartTrendlinesREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.CHECK_CHART_TRENDLINES:
      return action.isChecked;
    default:
      return state;
  }
};

export const chartStackedREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.CHECK_CHART_STACKED:
      return action.isChecked;
    default:
      return state;
  }
};

export const chartPieHoleREDU = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.CHECK_CHART_PIEHOLE:
      return action.isChecked;
    default:
      return state;
  }
};
