import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  setColorCardACTI,
  setColorCardA_ACTI,
  setColorCardB_ACTI,
  setWidgetUpdateACTI,
  openDialogWidgetACTI,
  setViewContentDialogACTI,
  checkedEditDashboardACTI
} from "../../actions/dashboard";
import classNames from "classnames";
// @material-ui/core components
import { Switch, FormControlLabel } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import {
  ACCOUNT_PATH,
  COLOR_APP_HEX,
  COLOR_APP_HEX_B,
  COLOR_APP_RGBA_OBJ
} from "../../constants";
import { logoutACTI } from "../../actions";
import {
  loadHeaderNotificationsACTI,
  setHeaderNotificationACTI
} from "../../actions/notification";

const useStyles = makeStyles(styles);

const CustomSwitch = withStyles({
  switchBase: {
    color: COLOR_APP_HEX_B,
    "&$checked": {
      color: COLOR_APP_HEX
    },
    "&$checked + $track": {
      backgroundColor: COLOR_APP_HEX
    }
  },
  checked: {},
  track: {
    backgroundColor: "#999"
  }
})(Switch);

const viewAllStyle = {
  textAlign: "center",
  textTransform: "uppercase"
};

function AdminNavbarLinks(props) {
  const {
    pathname,
    user,
    handleClickOpenDialog,
    checkedEditDashboard,
    handleCheckEditDashboard,
    notifications,
    loadNotifications,
    setNotification,
    logout
  } = props;
  const history = useHistory();
  const classes = useStyles();
  const [openNotification, setOpenNotification] = useState(null);
  const [openProfile, setOpenProfile] = useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = notification => {
    if (notification) {
      setNotification(notification);
      history.push(`${ACCOUNT_PATH}/notificaciones`);
    }

    setOpenNotification(null);
  };
  const handleCloseNotification2 = () => {
    history.push(`${ACCOUNT_PATH}/notificaciones`);
    setOpenNotification(null);
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const renderNotifications = () => {
    if (null === notifications) {
      return <MenuItem disabled>Buscando notificaciones...</MenuItem>;
    }

    if (!isEmpty(notifications)) {
      return notifications.map((notification, i) => {
        if (i < 5) {
          return (
            <MenuItem
              key={notification.id}
              onClick={() => handleCloseNotification(notification)}
              className={classes.dropdownItem + " " + classes.notificationItem}
            >
              {notification.tema ? notification.tema : "(Sin asunto)"}
            </MenuItem>
          );
        } else if (5 === i) {
          return (
            <MenuItem
              key={notification.id}
              onClick={handleCloseNotification2}
              className={classes.dropdownItem + " " + classes.notificationItem}
              style={viewAllStyle}
            >
              Ver todo
            </MenuItem>
          );
        } else {
          return null;
        }
      });
    } else {
      return <MenuItem disabled>No hay notificaciones para mostrar</MenuItem>;
    }
  };

  useEffect(() => {
    if (undefined !== user.id && null === notifications) {
      loadNotifications();
    }
  }, [user, notifications, loadNotifications]);
  return (
    <div>
      {/* <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search"
            }
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div> */}
      {pathname &&
      !isEmpty(user.perfiles) &&
      undefined !==
        user.perfiles.find(
          perfil => pathname.indexOf(ACCOUNT_PATH + perfil.path) !== -1
        ) ? (
        <>
          <FormControlLabel
            className="switch-sidebar"
            control={
              <CustomSwitch
                checked={checkedEditDashboard}
                onChange={handleCheckEditDashboard}
                name="editDashboard"
              />
            }
            label={window.innerWidth > 959 ? null : "Editar"}
          />
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Gestionar"
            className={classes.buttonLink}
            onClick={handleClickOpenDialog}
          >
            <Dashboard className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Gestionar</p>
            </Hidden>
          </Button>
        </>
      ) : null}
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          {/* <span className={classes.notifications}>5</span> */}
          <Hidden mdUp implementation="css">
            <p
              onClick={() => handleCloseNotification()}
              className={classes.linkText}
            >
              Notificaciones
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => handleCloseNotification()}
                >
                  <MenuList role="menu">{renderNotifications()}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Mi cuenta</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/* <Divider light /> */}
                    <MenuItem
                      onClick={logout}
                      className={classes.dropdownItem}
                      style={{
                        textAlign: "center",
                        textTransform: "uppercase"
                      }}
                    >
                      Salir
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}

AdminNavbarLinks.propTypes = {
  pathname: PropTypes.string,
  user: PropTypes.object.isRequired,
  handleClickOpenDialog: PropTypes.func,
  checkedEditDashboard: PropTypes.bool,
  handleCheckEditDashboard: PropTypes.func,
  notifications: PropTypes.array,
  loadNotifications: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    checkedEditDashboard: state.checkEditDashboardREDU,
    notifications: state.headerNotificationsREDU.data
  };
};

const handleOpenDialog = dispatch => {
  dispatch(setColorCardACTI(COLOR_APP_RGBA_OBJ));
  dispatch(setColorCardA_ACTI(COLOR_APP_RGBA_OBJ));
  dispatch(setColorCardB_ACTI(COLOR_APP_RGBA_OBJ));
  dispatch(setWidgetUpdateACTI({}));
  dispatch(openDialogWidgetACTI(true));
  dispatch(setViewContentDialogACTI(true));
};

const mapDispatchToProps = dispatch => {
  return {
    handleClickOpenDialog: () => handleOpenDialog(dispatch),
    handleCheckEditDashboard: () => {
      dispatch(checkedEditDashboardACTI());
    },
    loadNotifications: params => {
      dispatch(loadHeaderNotificationsACTI(params));
    },
    setNotification: notification => {
      dispatch(setHeaderNotificationACTI(notification));
    },
    logout: () => dispatch(logoutACTI())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNavbarLinks);
