import React from "react";
import PropTypes from "prop-types";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { COLOR_APP_HEX_B } from "../../constants";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "rgba(0, 0, 0, 0.2)",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  progress: {
    color: COLOR_APP_HEX_B
  }
}));

const CircularIndeterminate = ({ className }) => {
  const classes = useStyles();
  return (
    <div className={className ? className + " " + classes.root : classes.root}>
      <CircularProgress className={classes.progress} />
    </div>
  );
};

CircularIndeterminate.propTypes = {
  className: PropTypes.string
};

export default CircularIndeterminate;
