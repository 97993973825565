import { call, delay, put, select } from "redux-saga/effects";
import { isEmpty } from "lodash";
import { apiGET } from "../api";
import { urlPerfiles } from "../api/urls";
import {
  setMessageNotificationACTI,
  setOpenNotificationErrorACTI
} from "../actions";
import { addGlobalProfilesStoreACTI } from "../actions/profiles";
import { getObjectForPaginatedTable } from "helpers/functions";

export const downloadProfiles = () =>
  apiGET(`${urlPerfiles}/listar`).then(profiles => profiles);

export function* sagaDownloadGlobalProfiles() {
  try {
    const profiles = yield call(downloadProfiles);
    yield put(addGlobalProfilesStoreACTI(profiles));
  } catch (error) {
    yield put(setMessageNotificationACTI(error));
    yield put(addGlobalProfilesStoreACTI([]));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}

export function* sagaDownloadProfiles(action) {
  try {
    let profiles = yield select(state => state.globalProfilesREDU);

    if (isEmpty(profiles)) {
      profiles = yield call(downloadProfiles);
      yield put(addGlobalProfilesStoreACTI(profiles));
    }

    if (action.params.isPaginated) {
      action.params.setProfiles(
        getObjectForPaginatedTable(
          profiles,
          action.params.pageSize,
          action.params.pageNo
        )
      );
    } else {
      action.params.setProfiles(profiles);
    }
  } catch (error) {
    if (action.params.isPaginated) {
      action.params.setProfiles({ content: [] });
    } else {
      action.params.setProfiles([]);
    }

    yield put(setMessageNotificationACTI(error));
    yield put(setOpenNotificationErrorACTI(true));
    yield delay(6000);
    yield put(setOpenNotificationErrorACTI(false));
  }
}
