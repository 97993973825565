import React from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import RenderData from "components/CustomInput/RenderData";
import CheckboxFormik from "components/CustomInput/CheckboxFormik";

function ManageSecurityRoleAssociation(props) {
  const { user, securityRoles } = props;

  return (
    <GridContainer>
      <GridItem xs={12} md={3}>
        <RenderData label="Documento" value={user.documento} />
      </GridItem>
      <GridItem xs={12} md={9}>
        <RenderData label="Nombre" value={user.nombre} />
      </GridItem>
      <GridItem xs={12}>
        {securityRoles.map((securityRole, index) => (
          <div key={securityRole.id}>
            <CheckboxFormik
              label={securityRole.nombre}
              name={`securityRole_${index}`}
            />
          </div>
        ))}
      </GridItem>
    </GridContainer>
  );
}

ManageSecurityRoleAssociation.propTypes = {
  user: PropTypes.object.isRequired,
  securityRoles: PropTypes.array
};

export default ManageSecurityRoleAssociation;
