import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import {
  TextField,
  makeStyles,
  FormControl,
  withStyles
} from "@material-ui/core";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { COLOR_APP_HEX } from "../../constants";

const useStyles = makeStyles(styles);

const CssTextField = withStyles({
  root: {
    "& label.MuiFormLabel-root": {
      color: "#AAA",
      fontSize: "1rem"
    },
    "& label.Mui-focused": {
      color: COLOR_APP_HEX
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#D2D2D2"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLOR_APP_HEX
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR_APP_HEX
      },
      "&:hover fieldset": {
        borderColor: COLOR_APP_HEX
      },
      "&.Mui-focused fieldset": {
        borderColor: COLOR_APP_HEX
      }
    }
  }
})(TextField);

const InputFormik = ({
  label,
  handleChange,
  formControlProps,
  formControlRoot,
  style,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta] = useField(props);

  const handleOnChange = e => {
    const { onChange } = field;
    onChange(e);

    if (handleChange) {
      handleChange(e.target.value);
    }
  };

  return (
    <FormControl
      {...formControlProps}
      className={!formControlRoot ? classes.formControl : null}
      style={style}
    >
      <CssTextField
        {...field}
        error={meta.touched && meta.error ? true : false}
        label={label ? label : null}
        InputProps={{ ...props }}
        helperText={meta.touched && meta.error ? meta.error : null}
        onChange={handleOnChange}
      />
    </FormControl>
  );
};

InputFormik.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  formControlProps: PropTypes.object,
  formControlRoot: PropTypes.bool,
  style: PropTypes.object
};

export default InputFormik;
