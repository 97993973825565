import React from "react";
import PropTypes from "prop-types";
import InputCustomLabel from "./InputCustomLabel";
import ViewData from "./ViewData";

function RenderData(props) {
  const { label, value } = props;

  return (
    <>
      <InputCustomLabel label={label} />
      <ViewData value={value} m={0} mb={5} />
    </>
  );
}

RenderData.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default RenderData;
