import { ACTIONS } from "../constants";

export const forgotPasswordACTI = (username, extraParams) => ({
  type: ACTIONS.FORGOT_PASSWORD,
  username,
  extraParams
});

export const confirmResetPasswordACTI = (token, extraParams) => ({
  type: ACTIONS.CONFIRM_RESET_PASSWORD,
  token,
  extraParams
});

export const resetPasswordACTI = (user, extraParams) => ({
  type: ACTIONS.RESET_PASSWORD,
  user,
  extraParams
});
