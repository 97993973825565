import React from "react";
import PropTypes from "prop-types";
import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { COLOR_APP_HEX, TEXTO_BLANCO } from "../../constants";
import ContentActions from "./ContentActions";
// import PerfectScrollbar from 'perfect-scrollbar';

export default function ResponsiveDialog(props) {
  const {
    open,
    handleClose,
    width,
    title,
    children,
    handleAccept,
    viewHeader,
    viewFooter,
    ...rest
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = {
    colorHeader: {
      backgroundColor: COLOR_APP_HEX,
      color: TEXTO_BLANCO
    }
  };

  // const handleWindowBeforeUnload = () => {
  //   alert("onclose");
  // };

  // ref to help us initialize PerfectScrollbar on windows devices
  // const dialogPanel = React.createRef();
  // initialize and destroy the PerfectScrollbar plugin
  // React.useEffect(() => {
  //   console.info( "Dialog" );
  //   window.addEventListener("beforeunload", handleWindowBeforeUnload);
  //   let ps;
  //   console.log( dialogPanel );
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(dialogPanel.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //   }
  // return function cleanup() {
  //   window.removeEventListener("beforeunload", handleWindowBeforeUnload);
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //       ps = null;
  //     }
  //   };
  // });
  window.onbeforeunload = function() {
    alert("onclose");
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth={width}
        disableBackdropClick={!!viewFooter}
        disableEscapeKeyDown={!!viewFooter}
        aria-labelledby="rd-title"
      >
        {undefined === viewHeader || viewHeader ? (
          <DialogTitle id="rd-title" style={styles.colorHeader}>
            {title}
          </DialogTitle>
        ) : null}
        <DialogContent {...rest}>
          <>{children}</>
        </DialogContent>
        {undefined === viewHeader || viewHeader ? (
          <DialogActions>
            <ContentActions
              handleClose={handleClose}
              handleAccept={handleAccept}
            />
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
}

ResponsiveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleAccept: PropTypes.func,
  viewHeader: PropTypes.bool,
  width: PropTypes.string,
  viewFooter: PropTypes.bool
};
