import React from "react";
import PropTypes from "prop-types";
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  withStyles,
  CircularProgress
} from "@material-ui/core";
import { useButtonStyles } from "assets/jss/material-dashboard-react/buttonStyle";
import { COLOR_APP_HEX, TEXTO_BLANCO, COLOR_APP_HEX_B } from "../../constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(COLOR_APP_HEX),
    backgroundColor: COLOR_APP_HEX,
    "&:hover": {
      backgroundColor: COLOR_APP_HEX_B
    }
  }
}))(Button);

const zIndex = { zIndex: 1301 };

const AlertDialog = props => {
  const {
    title,
    content,
    open,
    isSubmitting,
    handleClose,
    handleAccept
  } = props;
  const classes = useButtonStyles();

  const styles = {
    colorHeader: {
      backgroundColor: COLOR_APP_HEX,
      color: TEXTO_BLANCO,
      padding: "4px 8px"
    },
    content: {
      margin: "15px 0px",
      textAlign: "center",
      whiteSpace: "pre"
    }
  };

  return (
    <div>
      <Dialog
        style={zIndex}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-slide-title" style={styles.colorHeader}>
          {title ? title : "Atención"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={styles.content}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ColorButton
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleClose}
            size="small"
            disabled={isSubmitting}
          >
            Cancelar
          </ColorButton>
          <ColorButton
            variant="contained"
            color="primary"
            onClick={handleAccept}
            size="small"
            disabled={isSubmitting}
          >
            Aceptar
          </ColorButton>
          {isSubmitting && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired
};

export default AlertDialog;
