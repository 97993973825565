import { GOOGLE_COLORS, ROWS_PER_PAGE } from "../constants";
import { createBrowserHistory } from "history";

export const getFirstName = arrayName => {
  let firstName = "";

  if (Array.isArray(arrayName) && arrayName.length > 0) {
    if (arrayName.length > 1) {
      firstName = arrayName[0] + " " + arrayName[1];

      if (arrayName.length > 2 && arrayName[1].length < 3) {
        firstName += " " + arrayName[2];
      }
    } else {
      firstName = arrayName[0];
    }
  }

  return firstName;
};

export const getFirstLetter = stringIn => {
  let firstLetter = "";

  if (stringIn) {
    firstLetter = stringIn.substring(0, 1);
  }

  return firstLetter;
};

export const getLetters = arrayIn => {
  let letters = "";

  if (Array.isArray(arrayIn) && arrayIn.length > 0) {
    if (arrayIn.length > 1) {
      letters = getFirstLetter(arrayIn[0]);

      if (arrayIn.length > 2) {
        letters += getFirstLetter(arrayIn[2]);
      } else {
        letters += getFirstLetter(arrayIn[1]);
      }
    } else {
      letters = getFirstLetter(arrayIn[0]);
    }
  }

  return letters;
};

export const equalObjects = (objectA, objectB) => {
  if (!objectA && !objectB) return true;
  else if (!objectA) return false;
  else if (!objectB) return false;

  var aKeys = Object.keys(objectA).sort();
  var bKeys = Object.keys(objectB).sort();

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  if (aKeys.join("") !== bKeys.join("")) {
    return false;
  }

  for (var i = 0; i < aKeys.length; i++) {
    if (
      (Array.isArray(objectA[aKeys[i]]) && Array.isArray(objectB[bKeys[i]])) ||
      ("object" === typeof objectA[aKeys[i]] && "object" === objectB[bKeys[i]])
    ) {
      if (
        JSON.stringify(objectA[aKeys[i]]) !== JSON.stringify(objectB[bKeys[i]])
      ) {
        return false;
      }
    } else if (objectA[aKeys[i]] !== objectB[bKeys[i]]) {
      return false;
    }
  }

  return true;
};

export const emptyObject = obj => {
  if (!obj) return true;
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

// Verifies if routeName is the one active (in browser input)
export const activeRoute = routeName => {
  let route = window.location.origin + window.location.pathname;
  let position = route.search(routeName);

  if (-1 !== position) {
    let found = route.substring(position);
    let aux = route.substring(position, position + routeName.length + 1);
    return routeName === found || routeName + "/" === aux;
  }

  return false;
};

export const delay = time => new Promise(resolve => setTimeout(resolve, time));

export const getGoogleColorsDefault = (start, end) => {
  const colors = GOOGLE_COLORS.slice(start, end);

  return colors;
};

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, "g"), replace);
};

export const b64EncodeUnicode = str => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
};

export const history = createBrowserHistory();

function codigoAleatorio(chars, lon) {
  let code = "";
  let rand;

  for (let x = 0; x < lon; x++) {
    rand = Math.floor(Math.random() * chars.length);
    code += chars.substr(rand, 1);
  }

  return code;
}

// Función que genera cadenas de caracteres de forma dinámica.
export const generar = () => {
  let caracteres =
    "0123456789abcdefghijklmnopqrstuvwyzABCDEFGHIJKLMNOPQRSTUVWXYZ_";
  let valor = codigoAleatorio(caracteres, 20);
  return valor;
};

export const getCookie = name => {
  let match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
};

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
export const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const openNewWindow = (link, target) => {
  const win = window.open(link, target);
  win.focus();
};

const calulateTotalPages = arrayLength => {
  const quotient = Math.floor(arrayLength / ROWS_PER_PAGE);
  const remainder = arrayLength % ROWS_PER_PAGE;
  return quotient + (remainder > 0 ? 1 : 0);
};

export const getObjectForPaginatedTable = (arrayObjects, size, pageNo) => ({
  content: arrayObjects, // falta obtener el arreglo correspondiente a la página cuándo hay varias páginas.
  pageable: {
    sort: {
      unsorted: false,
      sorted: true,
      empty: false
    },
    offset: 0,
    pageSize: undefined !== size ? size : ROWS_PER_PAGE,
    pageNumber: undefined !== pageNo ? pageNo : 0,
    paged: true,
    unpaged: false
  },
  last: true,
  totalElements: arrayObjects.length,
  totalPages: calulateTotalPages(arrayObjects.length),
  number: undefined !== pageNo ? pageNo : 0,
  size: undefined !== size ? size : ROWS_PER_PAGE,
  sort: {
    unsorted: false,
    sorted: true,
    empty: false
  },
  first: true,
  numberOfElements: arrayObjects.length,
  empty: false
});

export function downloadPDF(pdf, name) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = name ? name : "informe.pdf";

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export const getPayloadJwt = token => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
