import React from "react";

//components core
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import CategoriaList from "./CategoriaList";
import CategoryApplication from "./CategoryApplication";

function Categoria() {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <CategoriaList />
      </GridItem>
      <GridItem xs={12}>
        <CategoryApplication />
      </GridItem>
    </GridContainer>
  );
}

export default Categoria;
