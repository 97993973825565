import { ACTIONS } from "../constants";

export const rolesREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_ROLES_STORE:
      return action.roles;
    default:
      return state;
  }
};

export const automaticUserREDU = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_AUTOMATIC_USER_STORE:
      return action.automaticUser;
    default:
      return state;
  }
};
