import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ViewNotification from "views/Notifications/ViewNotification";
import { openNewWindow } from "helpers/functions";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#3d3d3d",
    zIndex: 999999999,
    padding: 16,
    position: "fixed",
    bottom: 20,
    width: "80%",
    borderRadius: 5,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow:
      "0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "normal"
    }
  },
  child1: {
    flex: "1 1 0px"
  },
  child2: {
    textAlign: "center",
    marginLeft: 15,
    [theme.breakpoints.down("sm")]: {
      textAlign: "right"
    }
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    paddingBottom: 0
  },
  content: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    paddingLeft: 5,
    paddingBottom: 0
  },
  chevronIcon: {
    color: "#fff"
  },
  margin: {
    margin: theme.spacing(1)
  },
  closeButton: {
    margin: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

function Banner(props) {
  const classes = useStyles();
  const { announcements } = props;
  const [announcement, setAnnouncement] = useState(null);
  const [topic, setTopic] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState(null);
  const [openAnnouncement, setOpenAnnouncement] = useState(false);
  const [openBanner, setOpenBanner] = useState(true);

  const handleClose = () => {
    setOpenBanner(false);
  };

  const handleOpenDialog = () => {
    setOpenAnnouncement(true);
  };

  const handleCloseDialog = () => {
    setOpenAnnouncement(false);
  };

  const handleClickLaunch = launchLink => {
    openNewWindow(launchLink, "_blank");
  };

  const handleNext = () => {
    const index = announcements.findIndex(ann => announcement.id === ann.id);

    if (index + 1 === announcements.length) {
      setAnnouncement(announcements[0]);
    } else {
      setAnnouncement(announcements[index + 1]);
    }
  };

  const handlePrevious = () => {
    const index = announcements.findIndex(ann => announcement.id === ann.id);

    if (index > 0) {
      setAnnouncement(announcements[index - 1]);
    } else {
      setAnnouncement(announcements[announcements.length - 1]);
    }
  };

  const customStyle = () => {
    if (openAnnouncement) {
      return { visibility: "hidden" };
    } else {
      return { visibility: "visible" };
    }
  };

  useEffect(() => {
    if (null === announcement && !isEmpty(announcements)) {
      setAnnouncement(announcements[0]);
    }
  }, [announcement, announcements]);

  useEffect(() => {
    if (isEmpty(announcement)) {
      setTopic(oldTopic => (!isEmpty(oldTopic) ? "" : oldTopic));
      setContent(oldContent => (!isEmpty(oldContent) ? "" : oldContent));
      setLink(oldLink => (!isEmpty(oldLink) ? null : oldLink));
    } else {
      setTopic(announcement.tema ? announcement.tema : "(Sin asunto)");
      setContent(announcement.contenido);

      if (announcement.enlace) {
        setLink(announcement.enlace);
      } else {
        setLink(oldLink => (!isEmpty(oldLink) ? null : oldLink));
      }
    }
  }, [announcement]);

  if (isEmpty(announcements) || !openBanner) {
    return null;
  }

  return (
    <>
      <div className={classes.root} style={customStyle()}>
        <div className={classes.child1}>
          <GridContainer>
            <GridItem xs={2} md={1} className={classes.buttons}>
              <IconButton
                aria-label="left"
                className={classes.chevronIcon}
                onClick={handlePrevious}
                color="inherit"
                disabled={1 === announcements.length}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            </GridItem>
            <GridItem xs={8} md={10} className={classes.content}>
              <Typography
                variant="body1"
                color="inherit"
                align="left"
                className="banner-ellipsis"
              >
                {topic}
              </Typography>
            </GridItem>
            <GridItem xs={2} md={1} className={classes.buttons}>
              <IconButton
                aria-label="right"
                className={classes.chevronIcon}
                onClick={handleNext}
                color="inherit"
                disabled={1 === announcements.length}
              >
                <ChevronRightIcon fontSize="large" />
              </IconButton>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.child2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.margin}
            onClick={handleOpenDialog}
          >
            Ver
          </Button>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      <ViewNotification
        open={openAnnouncement}
        handleCloseDialog={handleCloseDialog}
        handleClickLaunch={handleClickLaunch}
        content={content}
        link={link}
      />
    </>
  );
}

Banner.propTypes = {
  announcements: PropTypes.array
};

const mapStateToProps = state => {
  return {
    announcements: state.announcementsREDU
  };
};

export default connect(
  mapStateToProps,
  null
)(Banner);
