import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  setTypeWidgetACTI,
  setColorCardACTI,
  setColorCardA_ACTI,
  setColorCardB_ACTI,
  checkColorAltCardWidgetACTI,
  checkColorGradWidgetACTI,
  setDegreedCardWidgetACTI,
  setIconCardWidgetACTI,
  checkIframeWidgetACTI,
  setOpenDialogIconCardWidgetACTI,
  setValueTabIconCardWidgetACTI,
  setIconsCardWidgetACTI,
  setPristineACTI,
  setSubmittingACTI,
  setChartTypeACTI,
  setChartDataACTI,
  setChartRangeNameACTI,
  checkChartLegendACTI,
  checkChartTrendlinesACTI,
  insertWidgetACTI,
  updateWidgetACTI
} from "../../actions/dashboard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ColorPicker from "components/Color/ColorPicker";
// @material-ui/core components
import {
  FormGroup,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Radio,
  withStyles,
  Button,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Input
} from "@material-ui/core";
// @material-ui/icons

import Check from "@material-ui/icons/Check";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CheckboxFormik from "components/CustomInput/CheckboxFormik";

import contentWidgetStyle from "assets/jss/material-dashboard-react/components/contentWidgetStyle";
import "../../assets/css/main.css";
import "../../assets/css/formik.css";
import FormConfWidget from "./FormConfWidget";
import ResponsiveDialog from "components/Dialog/ResponsiveDialog";
import SelectFormik from "components/CustomInput/SelectFormik";
import IconsMD from "./IconsMD";
import { emptyObject, equalObjects } from "../../helpers/functions";
import {
  COLOR_APP_HEX,
  COLOR_APP_HEX_B,
  ICONSMD_CATEGORY,
  TABS_ICONSMD
} from "../../constants";
import { iconsMD } from "../../constants/iconsMD";
import { TYPES_CHARTS } from "../../constants/dataGraphs";
import FormGraph from "./FormGraph";
import { handleNotificationWarning } from "actions/handleActions";
import PrettoSlider from "components/PrettoSlider/PrettoSlider";

const styles = {
  dialogContentRoot: {
    backgroundColor: "#eee",
    padding: "0px !important",
    minHeight: 420,
    "& > div:first-child": {
      width: "auto !important"
    }
  }
};

const useStyles = makeStyles(contentWidgetStyle);
const useStyles2 = makeStyles(styles);

const CustomTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  },
  indicator: {
    backgroundColor: COLOR_APP_HEX
  }
})(Tabs);

const CustomTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: COLOR_APP_HEX_B,
      opacity: 1
    },
    "&$selected": {
      color: COLOR_APP_HEX,
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: COLOR_APP_HEX
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const ConfigurationWidget = props => {
  const {
    types,
    type,
    handleClickType,
    color,
    handleChangeColor,
    colorGradA,
    handleChangeColorA,
    colorGradB,
    handleChangeColorB,
    isAlternative,
    checkAlternative,
    isColorGrad,
    checkColorGrad,
    degreed,
    handleChangeSlider,
    icon,
    handleIcon,
    isIframe,
    checkIframe,
    widget,
    open,
    setOpen,
    value,
    setValue,
    icons,
    setIcons,
    setPristine,
    setSubmitting,
    chartType,
    setChartType,
    legend,
    trendlines,
    chartData,
    setChartData,
    setChartRange,
    checkChartLegend,
    checkChartTrendlines,
    pristine,
    pristineGraph,
    isStacked,
    pieHole,
    showNotificationWarning,
    insertWidget,
    updateWidget
  } = props;
  const [isSetData, setIsSetData] = useState(false);
  const classes = useStyles();
  const classes2 = useStyles2();

  const ColorButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(COLOR_APP_HEX),
      backgroundColor: COLOR_APP_HEX,
      "&:hover": {
        backgroundColor: COLOR_APP_HEX_B
      }
    }
  }))(Button);

  const thisHandleClickType = (e, setFieldTouched) => {
    if ("graph" === type) {
      setFieldTouched("rango", false);
      setChartType(null);
    } else if ("general" === type) setFieldTouched("contenido", false);

    handleClickType(e.target.value);
  };

  const handleClickAlternative = () => {
    checkAlternative(!isAlternative);
  };
  const handleClickColorGrad = () => {
    checkColorGrad(!isColorGrad);
  };
  const handleClickIframe = () => {
    checkIframe(!isIframe);
  };
  const handleClickOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  // Valor inicial para iconos.
  const initialValue = useCallback(() => {
    if (widget.icono && widget.icono.nombreTab) {
      let index = TABS_ICONSMD.findIndex(
        tab => tab.name === widget.icono.nombreTab
      );

      if (-1 !== index) return index;
    }

    return 0;
  }, [widget.icono]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`icons-${index}`}
        aria-labelledby={`icons-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  const propsTab = index => {
    return {
      id: `icons-${index}`,
      "aria-controls": `icons-${index}`
    };
  };

  const generateIcons = prop => {
    let tags = [];

    if ("others" === prop) {
      tags = iconsMD.filter(icon => 0 === icon.tags.length);
    } else {
      tags = iconsMD.filter(icon => {
        let categories = ICONSMD_CATEGORY[prop];

        if (categories) {
          for (let j = 0; j < icon.tags.length; j++) {
            let found = categories.find(category => category === icon.tags[j]);

            if (undefined !== found) return true;
          }
        }

        return false;
      });
    }

    return tags;
  };

  const handleClickIcon = icon => {
    handleIcon(icon);
    setOpen(false);
  };

  const generateIconsDom = () => {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <div className={classes.tabs}>
            <AppBar position="sticky" color="default">
              <CustomTabs
                value={value}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Categorías"
              >
                {TABS_ICONSMD.map((tab, key) => {
                  return (
                    <CustomTab
                      label={tab.label}
                      key={tab.name}
                      {...propsTab(key)}
                    />
                  );
                })}
              </CustomTabs>
            </AppBar>
            {TABS_ICONSMD.map((tab, key) => {
              if (key === value) {
                if (!icons[tab.name]) {
                  icons[tab.name] = generateIcons(tab.name);
                  setIcons(icons);
                }

                return (
                  <TabPanel key={tab.name} value={value} index={key}>
                    <IconsMD
                      icons={icons[tab.name]}
                      tabName={tab.name}
                      selected={
                        !emptyObject(widget) && widget.icono
                          ? widget.icono.nombre
                          : undefined
                      }
                      handleClickIcon={handleClickIcon}
                      height={height}
                      width={width}
                    />
                  </TabPanel>
                );
              }

              return null;
            })}
          </div>
        )}
      </AutoSizer>
    );
  };

  const verifyDirtyUpdate = dirty => {
    let modify = dirty;

    if (!emptyObject(widget)) {
      if (
        widget.alternativo !== isAlternative ||
        widget.isColorGrad !== isColorGrad ||
        !equalObjects(widget.color, color) ||
        !equalObjects(widget.colorGradB, colorGradB) ||
        !equalObjects(widget.colorGradA, colorGradA) ||
        (widget.grados && widget.grados !== degreed)
      ) {
        modify = true;
      }

      if (!modify) {
        if ("infobox" === widget.tipo) {
          if (!equalObjects(widget.icono, icon)) {
            modify = true;
          }
        } else if ("general" === widget.tipo) {
          if (widget.iframe !== isIframe) {
            modify = true;
          }
        }
      }
    }

    return modify;
  };

  const initialValues = {
    tipo: type,
    alternativo: isAlternative,
    isColorGrad: isColorGrad,
    iframe: isIframe,
    titulo: "",
    categoria: "",
    contenido: "",
    tipoGrafica: "",
    rango: "",
    leyenda: legend,
    tendencia: trendlines,
    apilado: isStacked,
    agujero: pieHole
  };

  let selectedTypeChart;

  if (!emptyObject(widget)) {
    if ("graph" === type) {
      selectedTypeChart = TYPES_CHARTS.findIndex(
        typeChart => typeChart.type === widget.datos.type
      );
      initialValues.tipoGrafica = selectedTypeChart;
      initialValues.rango = widget.datos.vTitle;

      if (!isSetData) {
        if (widget.rango) setChartRange(widget.rango);
        setChartType(TYPES_CHARTS[selectedTypeChart]);
        setChartData(widget.datos);
        setIsSetData(true);
      }
    }

    initialValues.titulo = widget.titulo;
    initialValues.categoria = widget.categoria;
    initialValues.contenido = widget.contenido;
  }

  const handleSubmit = setSubmitting => {
    // Si está en condición pristina, no realiza ningún proceso.
    if (pristine) {
      setSubmitting(false);
      return;
    }

    let target = document.getElementById("formWidget");
    let widgetUpdate = {};

    for (let j = 0; j < target.length; j++) {
      if ("radio" === target[j].type) {
        if (target[j].checked) widgetUpdate[target[j].name] = target[j].value;
      } else if ("icono" === target[j].name) {
        widgetUpdate[target[j].name] = JSON.parse(target[j].value);
      } else if ("" !== target[j].name) {
        widgetUpdate[target[j].name] =
          "checkbox" === target[j].type
            ? target[j].checked
            : target[j].value.trim();
      }
    }

    widgetUpdate.color = color;
    widgetUpdate.colorGradA = colorGradA;
    widgetUpdate.colorGradB = colorGradB;

    if (widgetUpdate.isColorGrad) {
      widgetUpdate.grados = Number(widgetUpdate.grados);
    }

    if (
      types.length > 0 &&
      widgetUpdate.tipo &&
      types.find(type => type.nombre === widgetUpdate.tipo)
    ) {
      if ("graph" === widgetUpdate.tipo) {
        if (chartData) {
          widgetUpdate.datos = chartData;
          widgetUpdate.datos.vTitle = widgetUpdate.rango;
        }
      }

      if ("graph" === widgetUpdate.tipo && !widgetUpdate.datos) {
        setSubmitting(false);
        showNotificationWarning("Faltan los datos de la gráfica.");
      } else if (
        widgetUpdate.iframe &&
        -1 !==
          widgetUpdate.contenido
            .toLowerCase()
            .indexOf(window.location.origin.toLowerCase() + "/vortal")
      ) {
        setSubmitting(false);
        showNotificationWarning(
          "¿Me intentas ingresar a mi en mi propio sitio?"
        );
      } else {
        if (emptyObject(widget)) {
          // agregar widget.
          insertWidget(widgetUpdate, setSubmitting);
        } else {
          if (
            "graph" === widgetUpdate.tipo &&
            widget.datos.type !== TYPES_CHARTS[widgetUpdate.tipoGrafica].type
          ) {
            setSubmitting(false);
            showNotificationWarning("No puede modificar el tipo de gráfica");
          } else {
            if (undefined === widgetUpdate.grados) {
              widgetUpdate.grados = widget.grados;
            }

            // actualizar widget.
            updateWidget(widget.id, widgetUpdate, setSubmitting);
          }
        }
      }
    } else {
      showNotificationWarning(
        "No hay tipos de widgets configurados, no se puede procesar su solicitud."
      );
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (!emptyObject(widget)) {
      if ("infobox" === type) {
        setValue(initialValue()); // esto es para iconos
      }
    }
  }, [widget, type, setValue, initialValue]);

  React.useEffect(() => {
    return () => {
      checkIframe(false);
      checkAlternative(false);
      checkColorGrad(false);
      handleChangeSlider(null, 60);
      setValue(0);
      handleIcon(null);
      setChartData(null);
      setChartType(null);
      setChartRange("");
      checkChartLegend(true);
      checkChartTrendlines(false);
      handleClickType("infobox");
    };
  }, [
    checkIframe,
    checkAlternative,
    checkColorGrad,
    handleChangeSlider,
    setValue,
    handleIcon,
    setChartData,
    setChartType,
    setChartRange,
    checkChartLegend,
    checkChartTrendlines,
    handleClickType
  ]);

  return (
    <div className={classes.configuration}>
      <h4 className={classes.configurationTitle}>Configuración</h4>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          tipo: Yup.string().required("Requerido"),
          alternativo: Yup.boolean(),
          isColorGrad: Yup.boolean(),
          iframe: Yup.boolean(),
          titulo: Yup.string()
            .max(20, "Máximo 20 caracteres")
            .required("Requerido"),
          categoria: Yup.string().required("Requerido"),
          contenido:
            "general" === type && isIframe
              ? Yup.string()
                  .trim()
                  .required("Requerido")
                  .url("El enlace no está correcto")
                  .matches(
                    // eslint-disable-next-line no-irregular-whitespace
                    /(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i,
                    "Solo se permite https"
                  )
              : Yup.string().required("Requerido"),
          tipoGrafica:
            "graph" === type
              ? Yup.string().required("Requerido")
              : Yup.string(),
          rango:
            "graph" === type &&
            legend &&
            (!chartType || "PI" !== chartType.conf)
              ? Yup.string().required("Requerido")
              : Yup.string(),
          leyenda: Yup.boolean(),
          tendencia: Yup.boolean(),
          apilado: Yup.boolean(),
          agujero: Yup.boolean()
        })}
        onSubmit={(values, { setSubmitting }) => {
          // setSubmitting(false);
          handleSubmit(setSubmitting);
        }}
      >
        {propsTemp => {
          const {
            dirty,
            isSubmitting,
            setFieldValue,
            setFieldTouched
          } = propsTemp;
          setPristine(pristineGraph ? !verifyDirtyUpdate(dirty) : false);
          setSubmitting(isSubmitting);
          return (
            <Form id="formWidget">
              <RadioGroup
                aria-label="tipo"
                name="tipo"
                value={type}
                onChange={e => thisHandleClickType(e, setFieldTouched)}
              >
                <GridContainer spacing={0}>
                  {types.map(type => {
                    if (emptyObject(widget) || type.nombre === widget.tipo) {
                      return (
                        <GridItem xs={4} key={type.id}>
                          <div className={classes.option}>
                            <FormControlLabel
                              value={type.nombre}
                              control={
                                <Radio
                                  checkedIcon={
                                    <Check className={classes.radioChecked} />
                                  }
                                  icon={
                                    <Check className={classes.radioUnchecked} />
                                  }
                                  color="default"
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.root
                                  }}
                                />
                              }
                              label={
                                window.innerWidth > 599
                                  ? type.nombre
                                  : type.nombre.substring(0, 3)
                              }
                            />
                          </div>
                        </GridItem>
                      );
                    } else return null;
                  })}
                </GridContainer>
              </RadioGroup>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <FormGroup row>
                        <CheckboxFormik
                          name="alternativo"
                          label="Color alternativo"
                          onClick={handleClickAlternative}
                        />
                        <CheckboxFormik
                          name="isColorGrad"
                          label="Degradado"
                          onClick={handleClickColorGrad}
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={6}>
                      <div className="content-color">
                        {isColorGrad ? (
                          <div className="doble-fondo">
                            <ColorPicker
                              color={colorGradA}
                              handleChange={handleChangeColorA}
                              classCss="caja-a"
                            />
                            <ColorPicker
                              color={colorGradB}
                              handleChange={handleChangeColorB}
                              classCss="caja-b"
                            />
                            <PrettoSlider
                              name="grados"
                              valueLabelDisplay="auto"
                              aria-label="pretto slider"
                              min={-90}
                              max={180}
                              defaultValue={
                                !emptyObject(widget) && widget.isColorGrad
                                  ? widget.grados
                                  : 60
                              }
                              onChange={handleChangeSlider}
                            />
                          </div>
                        ) : (
                          <ColorPicker
                            color={color}
                            handleChange={handleChangeColor}
                            classCss="caja-b"
                          />
                        )}
                      </div>
                    </GridItem>
                    <GridItem xs={12}>
                      {"infobox" === type ? (
                        <div>
                          <ColorButton
                            variant="contained"
                            color="primary"
                            className={classes.margin}
                            onClick={handleClickOpenDialog}
                          >
                            Ícono
                          </ColorButton>
                          <Input
                            id="iconInfobox"
                            name="icono"
                            type="hidden"
                            value={JSON.stringify(icon)}
                          />
                        </div>
                      ) : "graph" === type ? (
                        <div>
                          <SelectFormik
                            labelId="chartType-label"
                            id="chartType"
                            name="tipoGrafica"
                            label="Tipo de gráfica"
                            values={TYPES_CHARTS}
                            setValue={setChartType}
                            style={{ width: "100%", marginLeft: 0 }}
                            selected={selectedTypeChart}
                            disabled={!emptyObject(widget)}
                          />
                          {chartType && (
                            <FormGraph
                              widget={widget}
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </div>
                      ) : "general" === type ? (
                        <FormGroup row className="align-items-center">
                          <CheckboxFormik
                            name="iframe"
                            label="Marco"
                            onClick={handleClickIframe}
                          />
                        </FormGroup>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <FormConfWidget isIframe={"general" === type && isIframe} />
                </GridItem>
              </GridContainer>
            </Form>
          );
        }}
      </Formik>
      <ResponsiveDialog
        open={open}
        handleClose={handleCloseDialog}
        width="lg"
        title="Widget"
        viewHeader={false}
        viewFooter={false}
        className={classes2.dialogContentRoot}
      >
        {open && generateIconsDom()}
      </ResponsiveDialog>
    </div>
  );
};

ConfigurationWidget.propTypes = {
  types: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleClickType: PropTypes.func.isRequired,
  color: PropTypes.object.isRequired,
  handleChangeColor: PropTypes.func.isRequired,
  colorGradA: PropTypes.object.isRequired,
  handleChangeColorA: PropTypes.func.isRequired,
  colorGradB: PropTypes.object.isRequired,
  handleChangeColorB: PropTypes.func.isRequired,
  isAlternative: PropTypes.bool.isRequired,
  checkAlternative: PropTypes.func.isRequired,
  isColorGrad: PropTypes.bool.isRequired,
  checkColorGrad: PropTypes.func.isRequired,
  handleChangeSlider: PropTypes.func.isRequired,
  degreed: PropTypes.number,
  icon: PropTypes.object,
  handleIcon: PropTypes.func,
  isIframe: PropTypes.bool,
  checkIframe: PropTypes.func.isRequired,
  handleClickIframe: PropTypes.func,
  widget: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  icons: PropTypes.object.isRequired,
  setIcons: PropTypes.func.isRequired,
  setPristine: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  chartType: PropTypes.object,
  setChartType: PropTypes.func.isRequired,
  legend: PropTypes.bool,
  trendlines: PropTypes.bool,
  chartData: PropTypes.object,
  setChartData: PropTypes.func.isRequired,
  setChartRange: PropTypes.func.isRequired,
  checkChartLegend: PropTypes.func.isRequired,
  checkChartTrendlines: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  pristineGraph: PropTypes.bool.isRequired,
  isStacked: PropTypes.bool,
  pieHole: PropTypes.bool,
  showNotificationWarning: PropTypes.func.isRequired,
  insertWidget: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    open: state.dialogIconCardWidgetREDU,
    value: state.valueTabIconCardWidgetREDU,
    icons: state.iconsCardWidgetREDU,
    chartType: state.chartTypeREDU,
    legend: state.chartLegendREDU,
    trendlines: state.chartTrendlinesREDU,
    widget: state.widgetUpdateREDU,
    chartData: state.chartDataREDU,
    pristine: state.controlDashboardREDU.pristine,
    pristineGraph: state.controlDashboardREDU.pristineGraph,
    isStacked: state.chartStackedREDU,
    pieHole: state.chartPieHoleREDU
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleClickType: value => {
      dispatch(setTypeWidgetACTI(value));
    },
    handleChangeColor: color => {
      dispatch(setColorCardACTI(color));
    },
    handleChangeColorA: color => {
      dispatch(setColorCardA_ACTI(color));
    },
    handleChangeColorB: color => {
      dispatch(setColorCardB_ACTI(color));
    },
    checkColorGrad: isChecked => {
      dispatch(checkColorGradWidgetACTI(isChecked));
    },
    handleChangeSlider: (event, value) => {
      dispatch(setDegreedCardWidgetACTI(value));
    },
    checkAlternative: isChecked => {
      dispatch(checkColorAltCardWidgetACTI(isChecked));
    },
    handleIcon: icon => {
      dispatch(setIconCardWidgetACTI(icon));
    },
    checkIframe: isChecked => {
      dispatch(checkIframeWidgetACTI(isChecked));
    },
    setOpen: isOpen => {
      dispatch(setOpenDialogIconCardWidgetACTI(isOpen));
    },
    setValue: value => {
      dispatch(setValueTabIconCardWidgetACTI(value));
    },
    setIcons: icons => {
      dispatch(setIconsCardWidgetACTI(icons));
    },
    setPristine: pristine => {
      dispatch(setPristineACTI(pristine));
    },
    setSubmitting: isSubmitting => {
      dispatch(setSubmittingACTI(isSubmitting));
    },
    setChartType: (index, name) => {
      dispatch(setChartTypeACTI(index, name));
    },
    setChartData: data => {
      dispatch(setChartDataACTI(data));
    },
    setChartRange: range => {
      dispatch(setChartRangeNameACTI(range));
    },
    checkChartLegend: isChecked => {
      dispatch(checkChartLegendACTI(isChecked));
    },
    checkChartTrendlines: isChecked => {
      dispatch(checkChartTrendlinesACTI(isChecked));
    },
    showNotificationWarning: message =>
      handleNotificationWarning(dispatch, message),
    insertWidget: (widget, setSubmitting) => {
      dispatch(insertWidgetACTI(widget, setSubmitting));
    },
    updateWidget: (id, widget, setSubmitting) => {
      dispatch(updateWidgetACTI(id, widget, setSubmitting));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationWidget);
