import React from "react";
import PropTypes from "prop-types";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { SvgIcon, Typography } from "@material-ui/core";
import "../../assets/css/main.css";

function NoDataDisplay({ styles }) {
  return (
    <div className="no-data-display" style={styles}>
      <SvgIcon>
        <path d="M2,10.96C1.5,10.68 1.35,10.07 1.63,9.59L3.13,7C3.24,6.8 3.41,6.66 3.6,6.58L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.66,6.72 20.82,6.88 20.91,7.08L22.36,9.6C22.64,10.08 22.47,10.69 22,10.96L21,11.54V16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V10.96C2.7,11.13 2.32,11.14 2,10.96M12,4.15V4.15L12,10.85V10.85L17.96,7.5L12,4.15M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V12.69L14,15.59C13.67,15.77 13.3,15.76 13,15.6V19.29L19,15.91M13.85,13.36L20.13,9.73L19.55,8.72L13.27,12.35L13.85,13.36Z" />
      </SvgIcon>
      <Typography variant="subtitle1">No hay datos para mostrar</Typography>
    </div>
  );
}

NoDataDisplay.propTypes = {
  styles: PropTypes.object
};

export default NoDataDisplay;
