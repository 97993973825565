import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
// @material-ui/core components
import {
  Icon,
  IconButton,
  makeStyles,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: "flex-end"
  },
  title: {
    flex: "1 1 100%"
  }
}));

function CustomTableToolbar(props) {
  const classes = useToolbarStyles();
  const { actions, title } = props;

  return (
    <Toolbar className={classes.root}>
      {!isEmpty(title) && (
        <Typography
          className={classes.title}
          variant="h6"
          component="div"
          color={title.color ? title.color : "inherit"}
        >
          {title.content}
        </Typography>
      )}
      {!isEmpty(actions) &&
        actions.map(action => (
          <Tooltip key={action.title} title={action.title}>
            <IconButton
              aria-label={action.ariaLabel}
              onClick={() => action.func()}
            >
              <Icon color={action.color ? action.color : "inherit"}>
                {action.icon}
              </Icon>
            </IconButton>
          </Tooltip>
        ))}
    </Toolbar>
  );
}

CustomTableToolbar.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      ariaLabel: PropTypes.string.isRequired,
      color: PropTypes.string,
      func: PropTypes.func.isRequired,
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  ),
  title: PropTypes.shape({
    color: PropTypes.string,
    content: PropTypes.string.isRequired
  })
};

export default CustomTableToolbar;
